import { Component, OnInit } from '@angular/core';
import { TextFieldComponent } from '@shared/form-fields/text-field/text-field.component';

@Component({
  selector: 'aaos-text-area-field',
  templateUrl: './text-area-field.component.html',
  styleUrls: ['./text-area-field.component.scss']
})
export class TextAreaFieldComponent extends TextFieldComponent implements OnInit {

  constructor() {
    super();
  }

  ngOnInit() {
  }

}
