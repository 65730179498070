import { Subject } from 'rxjs';
// create a new instance of this class for each file being read
export class CustomerAttachmentReadHandler {
    fileExtensionPrefix: string;
    reader = new FileReader();
    fileReadSubject = new Subject<string>();
    errorAlertSubject = new Subject<string>();
    fileName: string;
    allowedFileExtensions: string[];
    sessionStoragePrefix: string;
    constructor(allowedFileExtensions: Array<string>, sessionStoragePrefix: string) {
        this.allowedFileExtensions = allowedFileExtensions;
        this.sessionStoragePrefix = sessionStoragePrefix;
    }
    public readFile(file: File) {
        if (file.size > 0) {
            const reader = new FileReader();
            this.fileName = file.name;
            let fileIsOfAllowedType = false;
            this.allowedFileExtensions.forEach(ext => {
                const extWithoutPeriod = ext.replace('.', '');
                if (file.type.match(extWithoutPeriod)) {
                    fileIsOfAllowedType = true;
                    this.fileExtensionPrefix = `data:${file.type}';base64,`;
                }
            });
            if (fileIsOfAllowedType) {
                if (file.size > 10485760) {
                    this.errorAlertSubject.next('The maximum image size allowed is 10MB. Please try again with a smaller File.');
                } else {
                    reader.onload = this._handleReaderLoaded.bind(this);
                    reader.readAsArrayBuffer(file);
                }
            } else {
                this.errorAlertSubject.next(`Attachments have one of the following extension: ${this.allowedFileExtensions.toString()}`);
            }
        }
    }
    private _handleReaderLoaded(readerEvt): any {
        let binary = '';
        const bytes = new Uint8Array(readerEvt.target.result);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        const base64String = btoa(binary);
        const profileImageBase64String = base64String;
        const tmpProfilePictureUrl = this.fileExtensionPrefix +
            profileImageBase64String;
        const sessionStorageKey = `${this.sessionStoragePrefix}${this.fileName}`;
        sessionStorage.setItem(sessionStorageKey, tmpProfilePictureUrl);
        this.fileReadSubject.next(sessionStorageKey);
    }
}
