import { Component, Input, OnInit } from '@angular/core';
import { Customer, PhoneNumberStructure, Preference } from '@shared/models/data';
import { BaseFieldComponent } from '@shared/form-fields/baseFieldComponent';
import { FormFieldValidatorsService } from '@shared/form-fields/form-field-validators.service';
import { FieldOptionsMapperService } from '@shared/services/membershipForm/field-options-mapper.service';
import { FormFieldRuleConstants } from '@shared/form-fields/models/form-field';
import { UserProfileService } from '@shared/services/api';
import { tap } from 'rxjs/operators';
import { Constants } from '@shared/services/constants';
import { Utilities } from '@shared/services/utils';

@Component({
    selector: 'aaos-cell-phone-field',
    templateUrl: './cell-phone-field.component.html',
    styleUrls: ['./cell-phone-field.component.scss']
})
export class CellPhoneFieldComponent extends BaseFieldComponent implements OnInit {

    @Input() user: Customer;
    @Input() editMode: boolean;
    @Input() covidOnly: boolean = false;

    constructor(
        private profileService: UserProfileService,
        private formFieldValidatorsService: FormFieldValidatorsService,
        private fieldOptionsMapperService: FieldOptionsMapperService) {
        super();
    }

    countriesOptions: Preference[] = [];
    formControlName: string;
    maskForCountryCode: string;
    maskForPhoneNumber: string;
    maskForAreaCode: string;
    maskForExtension: string;
    phoneCountryCode: string;
    isAreaCodeAvailable: boolean;
    countryCode: string;
    minLength: number;
    maxLength: number;
    isCountryCodeUsaOrCan: boolean;
    MobileId: string;
    readonlyPhoneNumber: string;
    PhoneFieldName: string;
    show: boolean;
    mobileOptin: boolean;

    ngOnInit() {
        super.ngOnInit();
        this.countriesOptions = this.fieldOptionsMapperService.getOptionsForAddressField(this.formOptions).applicationCountries;
        if (this.field && this.field.ruleId.toLowerCase() === FormFieldRuleConstants.OptionalEditableId.toLowerCase()) {
            const emptyPreference = new Preference();
            emptyPreference.PreferenceCode = '';
            emptyPreference.PreferenceSubCode = '';
            emptyPreference.PreferenceDescription = '';
            this.countriesOptions.unshift(emptyPreference);
        }

        if (!this.form) {
            return;
        }

        this.countryCode = this.form.get('countryCode').value;
        this.countryCodeChanged(this.countryCode);
        const phoneNumberField = this.form.get('phoneNumber');
        if (phoneNumberField) {
            this.readonlyPhoneNumber = phoneNumberField.value;
        }
        if (this.countryCode === 'USA' || this.countryCode === 'CAN') {
            this.isCountryCodeUsaOrCan = true;
        } else {
            this.isCountryCodeUsaOrCan = false;
        }
        this.MobileId = this.form.get('Id').value;
        this.PhoneFieldName = Constants.FieldName.Mobile;
        this.formControlName = Constants.FormSubFieldNameConstants.countryCode;

        if (this.covidOnly && !this.user.Communications.some(x =>
            x.CommunicationType.toUpperCase() === 'MOBILE' &&
            x.LocationCode.toUpperCase() === 'PERSONAL')) {
            this.form.get(Constants.FormSubFieldNameConstants.phoneType).setValue('PERSONAL');
            this.form.get(Constants.AdditionalFieldConstants.newflag).setValue(true);
        }

        // show when covidOnly === false so that other mobile communications display if we ever show them
        // show when covidOnly === true
        //   if there are no mobile communications with personal type - the user needs to add that for their covid number
        //   if there are mobile communications with personal on this form - the user can modify their cell phone for covid
        this.show = this.covidOnly === false ||
            (this.covidOnly && !this.user.Communications.some(x =>
                x.CommunicationType.toUpperCase() === 'MOBILE' &&
                x.LocationCode.toUpperCase() === 'PERSONAL')) ||
            (this.covidOnly && this.form.get(Constants.FormSubFieldNameConstants.phoneType).value.toUpperCase() === 'PERSONAL');

        this.mobileOptin = this.form.get('mobileOptinFlag').value === 'YES' ? true : false;
    }

    flipChecked() {
        this.mobileOptin = !this.mobileOptin;
        if (this.mobileOptin === true && this.form.get('phoneNumber').value) {
            this.form.get('mobileOptinFlag').setValue('YES');
        } else {
            this.form.get('mobileOptinFlag').setValue('NO');
        }
    }

    countryCodeChanged(countryCode: string) {
        const sub$ = this.profileService
            .getPhoneNumberStructure(countryCode)
            .pipe(
                tap(_ => {
                    const fields = _;
                    const phoneCountryCodeField = fields.PhoneNumberFields.find(x => x.FieldName === 'PHONE_COUNTRY_CODE');
                    const phoneAreaCodeField = fields.PhoneNumberFields.find(x => x.FieldName === 'PHONE_AREA_CODE');
                    const phoneExtensionField = fields.PhoneNumberFields.find(x => x.FieldName === 'PHONE_EXTENSION');
                    const phoneNumberField = fields.PhoneNumberFields.find(x => x.FieldName === 'PHONE_NUMBER');

                    this.maskForCountryCode = null;
                    this.maskForPhoneNumber = null;
                    this.maskForAreaCode = null;
                    this.maskForExtension = null;

                    this.phoneCountryCode = fields.PhoneCountryCode;
                    this.isAreaCodeAvailable = phoneAreaCodeField != null;
                    this.countryCode = countryCode;
                    const phoneControl = this.form.get(Constants.FormSubFieldNameConstants.phoneNumber);
                    const phoneCountryCodeNumberControl = this.form.get(Constants.FormSubFieldNameConstants.countryCodeNumber);
                    if (fields.PhoneCountryCode) {
                        phoneCountryCodeNumberControl.setValue(fields.PhoneCountryCode);
                    } else {
                        phoneCountryCodeNumberControl.setValue('');
                    }
                    const validators = [];
                    phoneControl.clearValidators();
                    validators.push(this.requiredValidation());
                    if (this.countryCode === Constants.CountryConstants.USA || this.countryCode === Constants.CountryConstants.Canada) {
                        validators.push(this.formFieldValidatorsService.phoneNumberValidation(this.field));
                        this.buildPhoneFieldMask(phoneNumberField, phoneAreaCodeField, phoneCountryCodeField, phoneExtensionField);
                    }
                    const maxLengthValidator = this.formFieldValidatorsService.maxPhoneLengthValidator();
                    validators.push(maxLengthValidator);
                    phoneControl.setValidators(validators);
                    this.setCountryCodeIsUsOrCan();

                }),
            )
            .subscribe();
    }

    requiredValidation() {
        return this.formFieldValidatorsService.requiredValidation(this.field);
    }

    private buildPhoneFieldMask(
        phoneNumberField: PhoneNumberStructure,
        phoneAreaCodeField: PhoneNumberStructure,
        phoneCountryCodeField: PhoneNumberStructure,
        phoneExtensionField: PhoneNumberStructure) {
        let maskForPhoneNumber = '';
        const maskForAreaCode = '';
        let maskForCountryCode = '';
        let maskForExtension = '';
        if (phoneNumberField !== undefined && phoneAreaCodeField !== undefined) {
            maskForPhoneNumber = phoneAreaCodeField.Prefix + phoneAreaCodeField.Format
                + phoneAreaCodeField.Suffix + phoneNumberField.Prefix + ' ' + phoneNumberField.Format
                + phoneNumberField.Suffix;
        } else {
            if (phoneCountryCodeField !== undefined) {
                maskForCountryCode = phoneCountryCodeField.Prefix + phoneCountryCodeField.Format + phoneCountryCodeField.Suffix;
            }
            if (phoneNumberField !== undefined) {
                maskForPhoneNumber = phoneNumberField.Format
                    + phoneNumberField.Suffix;
            }
        }
        if (phoneExtensionField !== undefined) {
            maskForExtension = phoneExtensionField.Format;
            this.maxLength = phoneExtensionField.MaxLength;
            this.minLength = phoneExtensionField.MinLength;
        }
        this.maskForCountryCode = maskForCountryCode.replace(/\#/gi, '0');
        this.maskForPhoneNumber = maskForPhoneNumber.replace(/\#/gi, '0');
        this.maskForAreaCode = maskForAreaCode.replace(/\#/gi, '0');
        this.maskForExtension = maskForExtension.replace(/\#/gi, '0');
    }

    setCountryCodeIsUsOrCan() {
        if (this.countryCode === 'USA' || this.countryCode === 'CAN') {
            this.isCountryCodeUsaOrCan = true;
        } else {
            this.isCountryCodeUsaOrCan = false;
        }
    }

    showRequiredErrorForFieldName(fieldName: string) {
        return this.formFieldValidatorsService.showRequiredError(fieldName, this.field, this.form);
    }

    showValidationError(fieldName: string) {
        return this.formFieldValidatorsService.showValidationError(fieldName, this.field, this.form);
    }

    showErrorByName(name: string) {
        if (this.form && this.field) {
            return this.form.get(Constants.FormSubFieldNameConstants.phoneNumber).hasError(name);
        }
    }

    callAll(event: any) {
        this.uncheckRadioButtons(event.value);
    }

    uncheckRadioButtons(phoneNumber: any) {
        if (this.field.ruleId.toLowerCase() === FormFieldRuleConstants.OptionalEditableId.toLowerCase()) {
            if (phoneNumber === Utilities.stringEmpty) {
                const countryCodeControl = this.form.get(Constants.FormSubFieldNameConstants.countryCode);
                countryCodeControl.setValue('');
                this.phoneCountryCode = '';
            }
        }
    }
}
