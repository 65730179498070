<div *ngIf="!hide">
    <div *ngIf="isModalActive" id="notification-modal-container">
        <button type="button" class="close" (click)="closeModal()" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        <h2 class="title title--lg">{{ title }}</h2>
        <p>{{ text }}</p>
        <div id="notification-modal-button-container" *ngIf="isButtonActive">
            <hr />
            <div id="notification-modal-button-flex">
                <a id="notification-modal-button" (click)="closeModal()" class="btn btn--solid btn--red" href="{{ buttonLink }}" target="_blank">{{ buttonText }}</a>
            </div>
        </div>
    </div>
</div>