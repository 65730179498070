import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Customer } from '@shared/models/data';
import { UserProfileService } from '@shared/services/api';
import { tap, take } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { Constants } from '@shared/services/constants';

@Component({
  selector: 'aaos-image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.scss']
})
export class ImageUploaderComponent implements OnInit, OnDestroy {
  static readonly MAX_IMAGE_SIZE = 10485760;

  @Input() user: Customer;
  @Input() parentForm: FormGroup; // should be top level form which controls whether save is allowed
  @Input() saveSubject: Subject<void>;
  @Input()
  editMode: boolean;
  @Input() saveButtonText: string = Constants.ButtonText.Save;
  @Input() maapApplication: boolean;

  fileExtension: string;
  profileImageDirty: boolean;
  profileImageDeleted: boolean;
  uploadButtonHover: boolean;
  tmpProfilePictureUrl: string;
  placeholderImage: string;
  tmpImageType: string = 'square';
  imageType: string = 'square';
  private _destroy$: Subject<void> = new Subject<void>();
  canSave: boolean;
  parentFormValid: boolean;

  constructor(private userProfileService: UserProfileService) { }

  ngOnInit() {
    this.buildPlaceholderImage();
    if (!this.parentForm) {
      return;
    }
    this.parentFormValid = this.parentForm.valid;
    this.parentForm.statusChanges.subscribe(() => { this.parentFormValid = this.parentForm.valid; });
  }
  imageLoaded(event: any) {
    this.imageType = this.getImageType(event);
  }
  tmpImageLoaded(event: any) {
    this.tmpImageType = this.getImageType(event);
  }

  private getImageType(event: any): string {
    if (event.target.naturalWidth === event.target.naturalHeight) {
      event.target.style.marginLeft = '';
      return 'square';
    } else if (event.target.naturalWidth > event.target.naturalHeight) {
      const scalingFactor = 125 / event.target.naturalHeight;
      const width = scalingFactor * event.target.naturalWidth;
      const marginLeft = (125 - width) * .5;
      event.target.style.marginLeft = `${marginLeft.toString()}px`;
      return 'landscape';
    } else {
      event.target.style.marginLeft = '';
      return 'portrait';
    }
  }


  ngOnDestroy() {
    this._destroy$.next();
  }

  setFile(event) {
    if (event.target.files !== undefined && event.target.files.length > 0) {
      const reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this);
      const allowed = ['jpeg', 'png', 'gif', 'jpg'];
      let found = false;
      allowed.forEach(ext => {
        if (event.target.files[0].type.match('image/' + ext)) {
          found = true;
          this.fileExtension = ext;
        }
      });

      if (found) {
        if (event.target.files[0].size > ImageUploaderComponent.MAX_IMAGE_SIZE) {
          alert('The maximum image size allowed is 10MB. Please try again with a smaller image.');
        } else {
          reader.readAsArrayBuffer(event.target.files[0]);
        }
      }
    }
    const input = document.getElementById('image-uploader-file-input') as HTMLInputElement;
    if (input) {
      input.value = null;
    }
  }

  onEnterButton() {
    this.uploadButtonHover = true;
  }

  onLeaveButton() {
    this.uploadButtonHover = false;
  }

  deleteFile() {
    this.user.CustomerProfile.ProfileImageBase64String = this.placeholderImage;
    this.tmpProfilePictureUrl = `data:image/png;base64,${
      this.user.CustomerProfile.ProfileImageBase64String
      }`;
    sessionStorage.setItem('tmpProfilePictureUrl', this.tmpProfilePictureUrl);

    this.profileImageDeleted = true;
    this.profileImageDirty = false;
  }

  updateUrl() {
    this.tmpProfilePictureUrl = sessionStorage.getItem('tmpProfilePictureUrl');
  }

  _handleReaderLoaded(readerEvt) {
    let binary = '';
    const bytes = new Uint8Array(readerEvt.target.result);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    const base64String = btoa(binary);
    this.user.CustomerProfile.ProfileImageBase64String = base64String;
    this.tmpProfilePictureUrl =
      'data:image/' +
      this.fileExtension +
      ';base64,' +
      this.user.CustomerProfile.ProfileImageBase64String;

    sessionStorage.setItem('tmpProfilePictureUrl', null);
    sessionStorage.setItem('tmpProfilePictureUrl', this.tmpProfilePictureUrl);

    this.profileImageDirty = true;
    this.profileImageDeleted = false;

  }

  buildPlaceholderImage() {
    if (!this.user) {
      return;
    }
    const initials = this.user.NameCredentials.FirstName.slice(0, 1).concat(
      this.user.NameCredentials.LastName.slice(0, 1)
    );
    this.userProfileService
      .buildPlaceholderImage(initials)
      .pipe(
        take(1),
        tap((image: { Image: string }) => {
          this.placeholderImage = image.Image;
        })
      )
      .subscribe();
  }
  save() {
    this.saveSubject.next();
  }
}
