
export type AttachmentAction = 'upload' | 'delete';

export class CustomerAttachment {
    public FileName: string;
    public AttachmentBase64String: string;
    public Action: AttachmentAction;
    public AttachmentUrl: string;
    public CustomerRelatedDocumentId: string;
    public sessionStorageKey: string;
    public RelatedDocCode: string;
    public IsAttachmentNew: boolean;
}


