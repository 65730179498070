import { Pipe, PipeTransform } from '@angular/core';
import { Preference } from '@shared/models/data';
import { Constants } from '@shared/services/constants';

@Pipe({
  name: 'aaosPreferenceValue'
})
export class PreferenceValuePipe implements PipeTransform {
  transform(preferenceCode: string, prefs: Preference[], type: 'specialty' | 'other'): string {
    if (!prefs || !preferenceCode) {
      return null;
    }

    const k_prefCode: keyof Preference =
      type === 'specialty' ? 'PreferenceSubCode' : 'PreferenceCode';

    const pref = prefs.find(
      _ => (_[k_prefCode] || '').toLowerCase() === preferenceCode.toString().toLowerCase()
    );

    return pref ? pref.PreferenceDescription : '';
  }
}
