import { Observable, Subject } from 'rxjs';
import { EventEmitter } from 'events';

export class FormField {
    public fieldName: string;
    public fieldId: string;
    public ruleName: string;
    public ruleId: string;
    public uiFieldRuleId: string;
    public groupName: string;
    public groupId: string;
    public groupToolTipText: string;
    public groupToolTipTextDetail: string;
    public toolTipText: string;
    public placeholderText: string;
    public groupSortOrder: number;
    public fieldSortOrder: number;
    public fieldTypeId: string;
    public fieldTypeName: string;
    public value: any;
    public validValues: any[];
    public hiddenInReadonly: boolean;
    public uiRuleUpdated: Subject<void> = new Subject<void>();
}

export class FormFieldRuleConstants {

    /// <summary>
    /// RD: (Required Display Only;
    /// </summary>
    public static DisplayOnlyId = 'D71FB75E-0521-487E-9D76-78022404CB5B';
    public static DisplayOnlyIdName = 'DisplayOnly';

    /// <summary>
    /// O: Optional Editable
    /// </summary>
    public static OptionalEditableId = 'B9801F93-1B27-45B5-AD3F-636BFAA23EAB';
    public static OptionalEditableName = 'OptionalEditable';

    /// <summary>
    /// RE: Required Editable
    /// </summary>
    public static RequiredEditableId = 'A5ABFE83-947F-4B9E-85B1-B5C985A1D5B9';
    public static RequiredEditableIdName = 'RequiredEditable';

    /// <summary>
    /// N: Not collected/Displayed
    /// </summary>
    public static HiddenId = '95E084B5-4320-4812-B7D6-8B4F504B5BC2';
    public static HiddenIdName = 'Hidden';

    /// <summary>
    /// Required, only if value is blank, else read only
    /// </summary>
    ///
    public static RequiredIfBlankId = 'BE31ACAD-BD5E-4342-A4E1-A2DCFEECD7A4';
    public static RequiredIfBlankIdName = 'RequiredIfBlank';

    /// <summary>
    /// Read, only if value is not blank
    /// </summary>
    ///
    public static ReadOnlyIfNotBlankId = '184330BF-DBF7-463E-A52B-743F42C7B3AF';
    public static ReadOnlyIfNotBlankName = 'ReadOnlyIfNotBlank';
}

export class FieldTypeConstants {
    public static TextBoxId = 'b6bcfbde-fca3-45aa-afa9-d5b1c0194656';
    public static TextBoxName = 'text-box';

    public static DropDownId = 'd3300f1c-3460-4709-a633-47379da7821e';
    public static DropDownName = 'drop-down';

    public static MultiSelectId = 'd940d050-d173-4be2-abf3-483d872482b8';
    public static MultiSelectName = 'multi-select';

    public static PhoneId = 'D4167553-3418-40BB-81D1-1E804F4850C5';
    public static PhoneName = 'phone';
    public static MobileId = 'b0825492-b8fe-484b-9cef-e011edbf2052';
    public static MobileName = 'mobile';
    public static RadioButtonId = '99abec88-7768-44d7-9586-71051a9089d8';
    public static RadioButtonName = 'radio-button';
    public static CheckBoxId = 'ffdf61e5-3fd2-497d-acfe-987a1b998cc1';
    public static CheckBoxName = 'check-box';
    public static TextAreaId = 'aa49500d-269e-41c8-88a7-e53dabac7ccc';
    public static TextAreaName = 'text-area';
    public static WysiwygId = '18b2af37-c378-49fd-bf08-5597ec6c1ee7';
    public static WysiwygName = 'wysiwyg';
    public static WorkStatusId = '13d05ae7-54a6-42ee-002a-13cfd1b8041b';
    public static WorkStatusName = 'work-status';
    public static PhotoId = '15a05ae7-54a6-42ee-002a-13cfd1b8042c';
    public static PhotoName = 'photo';
    public static EmailId = '20a05ae7-54a6-42ee-002a-13dfd1b8041b';
    public static EmailName = 'email';
    public static DateId = '477828B2-8C27-4741-BF1F-3692802314C5';
    public static DateName = 'date';
    public static AddressId = 'CE3718D7-F14E-4437-86B7-31B3E54419D5';
    public static AddressName = 'address';
    public static EmergencyContactId = 'F85B94E6-7FEA-4BF8-86DD-03F6F0E8A981';
    public static EmergencyContactName = 'emergency-contact';
    public static BiographyId = '9F368638-4401-4CDE-8905-47CBDB4A4233';
    public static BiographyName = 'biography';
    public static MasterCustomerIdId = 'DD0049F7-C4D2-4C52-8911-5706BF8865AF';
    public static MasterCustomerIdName = 'master-customer-id';
    public static WebSocialLinkId = '9a80ec45-65c2-4b9c-b51c-f9a7b04b11ba';
    public static WebSocialLinkName = 'web-social';

}

export class FormFieldConstants {
    public static PhotoId = '2eec339c-2aef-4a0a-92da-244671f81c2c';

    public static NameSuffixId = '6339a894-a760-4b1f-866f-41542785b711';

    public static NamePrefixId = '690211a6-a072-4a26-8fd6-ddb33373697a';


    public static AAOSIDId = 'de3e4689-d88a-4d0f-bb2f-c7a75deee0b6';

    public static MemberGroupDescriptionId = '98837cde-091c-4413-a21c-86a01f3f2ec9';
    public static MemberSummaryId = '0ffbb81a-9d8d-48cb-89f3-faeef0df5edd';
    public static CustomerNumberId = 'e1be9041-d9e8-42ef-8a99-394ad407a25c';
    public static FirstNameId = 'f95041d1-2423-4a82-b8be-fc456166c0d5';
    public static MiddleNameId = 'a87c621e-31f3-4315-ba25-2bc18234aa38';
    public static LastNameId = 'af3574d5-9e3d-4127-b2bf-0b58776764a5';
    public static NameCredentialsId = 'b4d2b1de-88d4-4e58-8f84-e0207381e651';
    public static FAAOSCredentialId = 'b7cf2189-0d81-4468-b557-6834d1a9cf2b';
    public static BiographyId = '043e5af7-c403-4c7d-9cc8-d65753c1ec5c';
    public static EmergencyContactInformationId = 'cfd5c6eb-6ff7-4322-a867-d8312690bddc';
    public static DOBDateId = '8e2911df-7e60-48a3-8c46-987b36795233';
    public static GenderId = '11324eeb-1efe-4318-bb8b-dd83b58ba806';
    public static IdentityId = '5C0A602F-A0A0-4A8A-9EB2-85BA6FF29728'.toLowerCase();
    public static IdentityPronounId = 'F8CF9792-5ED2-468F-9823-8438E671F65B'.toLowerCase();
    public static RaceEthnicityId = 'dc21764b-6fd8-4180-a4ef-910ce392e9ce';
    public static AddressId = '99425195-7b49-49e5-9a4f-786e10b1e049';
    public static EmailAddressId = 'a91ddfb4-27f2-4ba4-acbd-bdc0c7266d35';
    public static WebSocialMediaLinksId = '6ab942b6-f218-4301-b7b6-68a6bfee89c5';
    public static TelephoneId = 'bdd780e7-813f-4022-a5b4-dbb8fc96fb13';
    public static MobileId = '4e4d4778-568e-4027-960e-bab29878775b';
    public static PrimaryPracticeSpecializationId = '9a2c9a9c-b986-45e3-aa0b-e9c1b0a3749d';
    public static SecondaryPracticeSpecializationId = 'e4aa0feb-9e4a-4f3b-8872-4d5eddc27819';
    public static PracticeEmploymentSettingId = '828632ce-7772-415c-a74f-5b5d43ea7955';
    public static JobTitleId = '1bab61c9-a5f3-424f-a3e2-cf19e870d959';
    public static LevelofSpecializationId = '91027a28-eb97-4ca8-9b41-1c6c380940f1';
    public static WorkStatusId = '0006f867-0733-4204-a78b-df3d91098212';
    public static BeginPracticeYearId = '64195bd4-24ee-47b8-a88b-f8c62e9723b3';
    public static LanguagesSpokenId = '5fad42a8-ed61-45dd-97ea-9da76553fabb';
    public static AgeGroupsTreatedId = '3969e733-968a-45a7-95b2-c74300cc5d45';
    public static AreasofInterestId = 'acaa7787-4f6b-4098-b360-e3a7d1b690f5';
    public static OtherAreaofInterest = '4a1ec5ae-cff4-4d60-8cb2-996b5c551ce3';
    public static ABOSRecertificationYear = '474d4818-b583-4223-8f42-07a7b381d8ee';
    public static NPId = '9e01e2c8-c0a9-4bdc-a641-db8b954f8b29';
    public static ABOSDiplomateId = 'b484bbf5-0518-42cb-86b8-26911105b2e3';
    public static BiographyAttachmentsId = '51F494A7-6487-4142-8469-1B652D9AD241';
    public static BiographyAttachmentsName = 'emergency-contact';
    public static NameCredentialsMaxLength = 40;

    public static MembershipExpirationDateId = 'C8143373-6F6C-45D9-B1D2-3C3FDD0EC2E5';
    public static CurrentDisclosrueSummaryId = '62DEA29F-BE45-419D-971C-938FF6833CF6';
    public static PracticeSizeId = 'de5d6698-e4e2-4d41-88ff-7e4779568cea';
}
export class FormFieldGroupConstants {
    public static PhotoId = 'a1bc271f-ef73-48fd-97e6-1dbb7d2bbba3';
    public static PhotoName = 'Photo';
    public static MemberGroupId = 'A9456C19-829F-4C1D-A9D1-285DDA8D1D33';
    public static MemberGroupName = 'Member Group';
    public static PersonalInformationId = 'e2cc271f-ef73-48fd-97e6-1dbb7d2bbba3';
    public static PersonalInformationName = 'Personal Information';
    public static DemographicInformationId = 'E28AABDF-D36D-49E4-B144-3CA443925354';
    public static DemographicInformationName = 'Demographic';
    public static AddressId = 'afad593d-de7a-4152-85fe-78b3407fdb26';
    public static AddressName = 'Address';
    public static MemberDirectoryId = '8338c934-7f58-488e-b33b-0ba4ce47b150';
    public static MemberDirectoryName = 'Member Directory';
    public static EmailId = 'f431fa0e-a3bc-4c30-8e2e-35d930be9fd2';
    public static EmailName = 'Email';
    public static PhoneId = 'b556763a-d081-4bae-9fe5-dc8847f2ce24';
    public static PhoneName = 'Phone';
    public static PracticeInformationId = '33977091-d4b8-4431-b0a2-9071c5a2ae81';
    public static PracticeInformationName = 'Practice Information';
    public static AreasOfInterestId = 'e0d75e19-a15a-4127-886d-3811ad51261c';
    public static AreasOfInterestName = 'Areas of Interest(s)';
    public static CertificationsId = '7098e9c7-0cd6-4537-b183-b3606d459344';
    public static CertificationsName = 'Certifications';
    public static BiographyId = 'B5B5EAB9-A2B3-4261-947F-DBB8ADF95361';
    public static BiographyName = 'Biography';
    public static EmergencyContactId = 'B8DC46B4-F7B2-4A62-96D7-530BF09FE782';
    public static EmergencyContactName = 'Emergency Contacts';
    public static ProfileId = 'BD0BB8B2-EA71-448A-9800-9F41DB8C4854';
    public static ProfileName = 'Profile';

}
export class MostUsedCredentialsConstants {
    public static DO = 'DO';
    public static MD = 'MD';
    public static FACS = 'FACS';
    public static FAOrthA = 'FAOrthA';
    public static FRCSC = 'FRCSC';
}

export class SelectCredentialsConstants {
    public static  MedicalDoctor = 'MD';
    public static  OsteopathicDoctor = 'DO';
}

export class MemberGroupsWithSelectCredentialsConstants {
    public static readonly PhysicianAssistantOrthoPA = 'PA';
}

export class GenderConstants {
    public static Male = 'MALE';
    public static Female = 'FEMALE';
    public static TransgenderMale = 'TRANSGENDERMAN';
    public static TransgenderFemale = 'TRANSGENDERWOMAN';
    public static NonBinary = 'NONBINARY';
    public static Other = 'OTHER';
    public static WishNotToDisclose = 'DONOTDISCLOSE';
}

export class IdentityConstants {
    public static Straight = 'STRAIGHT';
    public static Lesbian = 'LESBIAN';
    public static Gay = 'GAY';
    public static Bisexual = 'BISEXUAL';
    public static Queer = 'QUEER';
    public static Other = 'OTHER';
    public static NotSure = 'NOTSURE';
    public static WishNotToDisclose = 'DONOTDISCLOSE';
}

export class IdentityPronounConstants {
    public static He = 'HE';
    public static She = 'SHE';
    public static They = 'THEY';
    public static Other = 'OTHER';
    public static WishNotToDisclose = 'DONOTDISCLOSE';
}

export class CountriesConstants {
    public static USA = 'USA';
}
export class FormFieldTypeChecker {
    /**
     * static fieldHasOptions
     */
    public static fieldHasOptions(formField: FormField): boolean {
        switch (formField.fieldTypeId) {
            case FieldTypeConstants.CheckBoxId:
            case FieldTypeConstants.DropDownId:
            case FieldTypeConstants.RadioButtonId:
            case FieldTypeConstants.PhoneId:
            case FieldTypeConstants.WorkStatusId:
            case FieldTypeConstants.EmailId:
            case FieldTypeConstants.AddressId:
                return true;
            default:
                return false;
        }
    }
}

export class ReadonlyConstants {
    public static FieldsHiddenInReadonlyMode = [
        FormFieldConstants.NameCredentialsId.toLowerCase(),
        FormFieldConstants.NameSuffixId.toLowerCase()
    ];
}

export class NoToolTipGroup {
    public static GroupWithNoToolTip = [
        FormFieldGroupConstants.MemberGroupName,
        FormFieldGroupConstants.AreasOfInterestName
    ];
}
export class Credentials {
    public static MostUsedCredentials = [
        MostUsedCredentialsConstants.DO,
        MostUsedCredentialsConstants.MD,
        MostUsedCredentialsConstants.FACS,
        MostUsedCredentialsConstants.FAOrthA,
        MostUsedCredentialsConstants.FRCSC
    ];
}
export class Country {
    public static MostUsedCountries = [
        CountriesConstants.USA
    ];
}
export class Gender {
    public static GendersOrderedList = [
        GenderConstants.Female,
        GenderConstants.Male,
        GenderConstants.TransgenderMale,
        GenderConstants.TransgenderFemale,
        GenderConstants.NonBinary,
        GenderConstants.Other,
        GenderConstants.WishNotToDisclose
    ];
}

export class Identity {
    public static IdentityOrderedList = [
        IdentityConstants.Straight,
        IdentityConstants.Lesbian,
        IdentityConstants.Gay,
        IdentityConstants.Bisexual,
        IdentityConstants.Queer,
        IdentityConstants.Other,
        IdentityConstants.NotSure,
        IdentityConstants.WishNotToDisclose
    ];
}

export class IdentityPronoun {
    public static IdentityPronounOrderedList = [
        IdentityPronounConstants.He,
        IdentityPronounConstants.She,
        IdentityPronounConstants.They,
        IdentityPronounConstants.Other,
        IdentityPronounConstants.WishNotToDisclose
    ];
}

export class MemberGroupConstants {
    public static ResidentMemberId = 'F9FB9208-AB47-4305-9365-23CDF23AF67E';
    public static ResidentMemberName = 'Resident Member';

    public static CandidatesFellowsAssociateMemberId = '6C89A142-C7EE-4D50-B8C0-0F5A0C11BC25';
    public static CandidatesFellowsAssociateMemberName = 'Candidates, Fellows, Associate Members';

    public static EmeritusId = '1EA79922-B368-4AF4-8D46-89B2B402B7D3';
    public static EmeritusName = 'Emeritus';

    public static NonMemberInactiveId = 'bd8b3e98-b584-442e-84bf-2269262a91a1';
    public static NonMemberInactiveName = 'Non-Member, Inactive Fellow, Inactive Associate';

    public static InternationalResidentMemberId = '7f2dd502-88bb-4702-84fa-f3ffe4cced45';
    public static InternationalResidentMemberName = 'International Resident,International Member';

    public static AssociateMemberId = 'c55f4d05-95c0-4960-bd3b-2981580fbe04';
    public static AssociateMemberName = 'Associate,Emeritus,Honorary,Physician Assistant';

    public static NonMemberId = '4a753227-7fb5-47ce-a024-a6f49080e515';
    public static NonMemberName = 'Non-Member';
}

export class SelectCredentials {
    public static SelectCredentialsList = [
        SelectCredentialsConstants.MedicalDoctor,
        SelectCredentialsConstants.OsteopathicDoctor
    ];
}

export class MemberGroupsWithSelectCredentials {
    public static MemberGroupsWithSelectCredentialsList = [
        MemberGroupsWithSelectCredentialsConstants.PhysicianAssistantOrthoPA];
}


