<div class="sticky" *ngIf="continueOn$ | async">
  <div class="container">
    <div class="flexbox">
      <div>
        <a class="btn-round btn--blue" [attr.href]="continueOn$ | async">Continue to Site</a>
      </div>
    </div>
  </div>
</div>
<header>
  <div class="container header--container">
    <div class="flexbox">
      <div class="col-3 image-container">
        <img src="/assets/images/aaos-logo.svg" class="logo aaos-logo" alt="AAOS Logo" />
      </div>
      <div class="col-9">
        <div class="row justify-flex-end">
          <div *ngIf="!(isCsr$ | async)">
            <a *ngIf="isProfilePage && showEmailPreferencesLink" class="btn--round"
              href="https://communications.aaos.org/preference-center">Email Preferences</a>
            <a *ngIf="!isProfilePage" class="btn--round" routerLink="/user">Manage My Profile</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="showGlobalNavigation">
    <aaos-global-nav></aaos-global-nav>
  </div>
</header>