import { Component, OnInit, OnDestroy } from '@angular/core';
import { EmailPreferencesService } from '@shared/services/api';
import { Subject, Observable, combineLatest, BehaviorSubject, of, throwError } from 'rxjs';
import {
  ActivatedRoute,
  ParamMap,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import {
  tap,
  filter,
  switchMap,
  takeUntil,
  map,
  take,
  delay,
  catchError,
  distinctUntilChanged,
  share
} from 'rxjs/operators';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { PreferenceCategory, EmailPreferencesLogicService } from './logic.service';
import {
  FormConfirmgDialogComponent,
  UpdateProfileModalSuccessComponent,
  UpdateProfileModalFailComponent
} from '@shared/components';
import { FormConfirmType, FormFinishType } from '@shared/models/enums';
import { Utilities } from '@shared/services/utils';
import { LoggedInGuard } from '@shared/guards';

@Component({
  selector: 'aaos-email-preferences',
  templateUrl: './email-preferences.component.html',
  styleUrls: ['./email-preferences.component.scss']
})
export class EmailPreferencesComponent implements OnInit, OnDestroy {
  preferencesForm: FormGroup = new FormGroup({ unsubscribeFromAll: new FormControl(false) });
  isMember$: Observable<boolean>;
  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  emailTopics$: Observable<PreferenceCategory[]>;
  error: string;
  email$: Observable<string>;
  marketoId$: Observable<string>;
  isCsr$: Observable<boolean>;
  errorTitle: string;
  private baseSnapshot: any;
  private snapshot: any;
  private noEmit = { onlySelf: true, emitEvent: false };
  protected _destroy$: Subject<void> = new Subject<void>();

  constructor(
    protected emailService: EmailPreferencesService,
    protected dialog: MatDialog,
    protected route: ActivatedRoute,
    protected logic: EmailPreferencesLogicService,
    private auth: LoggedInGuard
  ) { }

    ngOnInit() {
    this.isCsr$ = this.getIsCsr$();
    this.email$ = this.getEmailFromQp$();
    this.marketoId$ = this.getIdFromQp$();
    this.isMember$ = this.getEmailFromQp$().pipe(
      switchMap(email => this.emailService.userIsMember(email)),
      share()
    );
    const userPreferences$ = this.getUserPreferences$();
    const emailTopics$ = this.route.queryParamMap.pipe(
      switchMap((qp: ParamMap) => {
        const emailType = qp.get('emailtype');
        return Utilities.isDefined(emailType)
          ? this.emailService.getCategoryByType(emailType)
          : this.emailService.getAllCategories();
      }),
      map(result => {
        return Utilities.isDefined(result) ? this.logic.allCategoriesToPreferences(result) : null;
      })
    );
    this.emailTopics$ = combineLatest([emailTopics$, userPreferences$, this.isMember$]).pipe(
      switchMap(_ => {
        const [emailTopics, id, isMember] = _;
        if (Utilities.isDefined(emailTopics) && Utilities.isDefined(id)) {
          const preferences = this.logic.mergeUserTopics([emailTopics, id]);
          this.preferencesForm = this.logic.preferencesToForm(preferences, isMember);
          this.baseSnapshot = this.preferencesForm.value;
          this._registerFormListeners();
          return of(preferences);
        } else {
          this.errorTitle = null;
          this.error = `We were unable to find email preferences for you in our system.`;
          return of([]);
        }
      }),
      tap(_ => this.loading$.next(false)),
      takeUntil(this._destroy$)
        );
    this.setFormToPristine();
    }

  ngOnDestroy() {
    this._destroy$.next();
  }

  openUnsubscribeDialog(): void {
    const dialogRef = this.dialog.open(FormConfirmgDialogComponent, {
      panelClass: 'amp-dialog',
      disableClose: true,
      autoFocus: false
    } as MatDialogConfig);

    dialogRef.componentInstance.actionTaken
      .pipe(
        switchMap((result: FormConfirmType) => {
          switch (result) {
            case FormConfirmType.Save:
              dialogRef.componentInstance.beginSpinner();
              return this.save();
            case FormConfirmType.DontSave:
              this.dontSave();
              return of(FormFinishType.Cancel);
            default:
              // tslint:disable-next-line: deprecation
              return of(null);
          }
        }),
        take(1)
      )
      .subscribe();
    }

    setFormToPristine() {
        const toggles = this.preferencesForm;
        let allTogglesJSON;
        let baseSnapJSON;

        toggles.valueChanges.pipe(
            tap(_ => {
                this.snapshot = this.preferencesForm.value;
                allTogglesJSON = JSON.stringify(this.preferencesForm.value);
                baseSnapJSON = JSON.stringify(this.baseSnapshot);
                if (baseSnapJSON === allTogglesJSON) {
                    toggles.markAsPristine();
                    toggles.updateValueAndValidity();
                }
            }),
            takeUntil(this._destroy$)
        ).subscribe();
    }

        protected getUserPreferences$() {
    return combineLatest(this.isCsr$, this.route.queryParamMap).pipe(
      switchMap(_ => {
        const [isCsr, qp] = _;
        const q_email = qp.get('email');
        const q_id = qp.get('id');
        const email = Utilities.isDefined(q_email);
        const id = Utilities.isDefined(q_id);

        if (email || id) {
          let lookup = ' for ';
          if (email) {
            lookup += `${q_email}`;
          } else if (id) {
            lookup += `${q_id}`;
          } else {
            lookup += `this user`;
          }
          return of([isCsr, lookup]);
        } else {
          return of([true]);
        }
      }),
      switchMap(_ => {
        const [isAuthorized, lookup] = _;
        if (!isAuthorized) {
          return throwError(`You are not authorized to view email preferences${lookup}.`);
        } else {
          return of(isAuthorized);
        }
      }),
      catchError(err => {
        this.errorTitle = this.logic.ERROR_TITLE;
        this.error = err;
        this.loading$.next(false);
        return null;
      }),
      filter(_ => _ === true),
      switchMap(authorized => this.getPreferencesByEmail$())
    );
  }

  protected getIsCsr$() {
    return this.emailService.isAuthorizedCsr();
  }

  protected save() {
    return combineLatest(this.isCsr$, this.email$, this.marketoId$).pipe(
      take(1),
      switchMap(_ => {
        const [isCsr, email, marketoId] = _;
        const preferences = this.logic.formToPreferences(this.preferencesForm);
        return isCsr && Utilities.isDefined(email)
          ? this.emailService.updateInterestAreas(marketoId, preferences, email)
          : this.emailService.updateMemberInterestAreas(preferences, email);
      }),
      switchMap(result => {
        return this.confirm(result);
      })
        );
  }

  protected getPreferencesByEmail$() {
    return this.email$.pipe(
      take(1),
      switchMap(email => {
        return this.emailService.getPreferencesByMarketoId(null, email);
      }),
      map(result => {
        this.loading$.next(false);
        return Utilities.isDefined(result) ? result : null;
      })
    );
  }

  protected getEmailFromQp$() {
    return this.route.queryParamMap.pipe(
      take(1),
      map(params => params.get('email'))
    );
  }

  protected getIdFromQp$() {
    return this.route.queryParamMap.pipe(
      take(1),
      map(params => params.get('id'))
    );
  }

  protected confirm(result) {
    this.dialog.closeAll();
    const type = Utilities.isDefined(result) ? FormFinishType.Success : FormFinishType.Error;
    const dialogRefComponent =
      type === FormFinishType.Success
        ? UpdateProfileModalSuccessComponent
        : UpdateProfileModalFailComponent;
    const dialogRef = this.dialog.open(dialogRefComponent,
      {
        width: '400px',
        data: {
            errorType: type
        }
      });
    return dialogRef.afterOpened().pipe(take(1));
  }

  protected dontSave() {
    this.preferencesForm.reset(this.baseSnapshot, this.noEmit);
    this.dialog.closeAll();
    return;
  }

    private _registerFormListeners() {
        const unsubscribeFromAll = this.preferencesForm.get('unsubscribeFromAll');
        unsubscribeFromAll.valueChanges
        .pipe(
            tap(unsubscribe => {
                if (unsubscribe) {
                this.snapshot = this.preferencesForm.getRawValue();
                this.snapshot.unsubscribeFromAll = false;
                Object.keys((this.preferencesForm.get('topics') as FormGroup).controls).forEach(key => {
                    const arr = this.preferencesForm.get(`topics.${key}`) as FormArray;
                    (arr.controls || []).forEach(control => {
                    control.get('active').setValue(false, this.noEmit);
                    });
                });
                } else {
                  this.snapshot.unsubscribefromall = true;
                  this.preferencesForm.reset(this.snapshot, this.noEmit);
                  this.snapshot = null;
                  unsubscribeFromAll.setValue(false, this.noEmit);
                }
            }),
            takeUntil(this._destroy$)
        ).subscribe();

        const topics = this.preferencesForm.get('topics');

        topics.valueChanges
            .pipe(
                tap(_ => {
                    if (unsubscribeFromAll.value) {
                        this.snapshot = this.preferencesForm.getRawValue();
                        this.snapshot.unsubscribeFromAll = false;
                        unsubscribeFromAll.setValue(false, this.noEmit);
                        unsubscribeFromAll.updateValueAndValidity();
                     }
                }),
            takeUntil(this._destroy$)
      ).subscribe();
  }
}
