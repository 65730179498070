import { Component, OnInit } from '@angular/core';
import { EmailPreferencesComponent } from '../email-preferences.component';
import { filter, map, switchMap, take, catchError } from 'rxjs/operators';
import { Utilities } from '@shared/services/utils';
import { of, combineLatest, throwError } from 'rxjs';
import { ninvoke } from 'q';

@Component({
  selector: 'aaos-manage-preferences',
  templateUrl: '../email-preferences.component.html',
  styleUrls: ['../email-preferences.component.scss']
})
export class ManagePreferencesComponent extends EmailPreferencesComponent implements OnInit {
  ngOnInit() {
    this.route.snapshot.data = { ignoreRedirect: true };
    super.ngOnInit();
  }

  getUserPreferences$() {
    return this.route.queryParamMap.pipe(
      switchMap(qp => {
        const q_email = qp.get('email');
        const q_id = qp.get('id');
        const email = Utilities.isDefined(q_email);
        const id = Utilities.isDefined(q_id);

        if (!email && !id) {
          return throwError(
            `We aren't able to look up email preferences without an email and user ID.`
          );
        }
        return of(true);
      }),
      catchError(err => {
        // setTimeout to avoid test error:
        // Error: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked.
        // Previous value: 'ngIf: undefined'.
        // Current value: 'ngIf: We aren't able to look up email preferences without an email and user ID.'.
        setTimeout(() => {
          this.errorTitle = this.logic.ERROR_TITLE;
          this.error = err;
        });
        this.loading$.next(false);
        return null;
      }),
      filter(_ => _ === true),
      switchMap(authorized => this.getPreferencesByEmail$())
    );
  }

  getPreferencesByEmail$() {
    return this.getAllParams$().pipe(
      filter(qp => Utilities.isDefined(qp)),
      switchMap(qp => {
        const { id, email } = qp;
        return this.emailService.getPreferencesByMarketoId(id, email);
      })
    );
  }

  save() {
    return this.getAllParams$().pipe(
      take(1),
      switchMap(params => {
        const { id, email } = params;
        const preferences = this.logic.formToPreferences(this.preferencesForm);
        return this.emailService.updateInterestAreas(id, preferences, email);
      }),
      switchMap(result => {
        return this.confirm(result);
      })
    );
  }

  getAllParams$() {
    return this.route.queryParamMap.pipe(
      take(1),
      map(qp => {
        return { id: qp.get('id'), email: qp.get('email') };
      })
    );
  }

  getIsCsr$() {
    return of(false);
  }
}
