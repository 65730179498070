import { Subscription } from 'rxjs';
import { AbstractControl } from '@angular/forms';
import { trimEnd, endsWith, chain, first, last, orderBy, isUndefined, has } from 'lodash';
import { Constants } from '@shared/services/constants';
import { isNumber, ceil } from 'lodash';

export abstract class Utilities {
  /**
   * Equivalent to C# string.Empty
   */
  public static readonly stringEmpty: string = '';

  /**
   * Unsubscribe from an observable subscription at the end of its lifecycle
   * @param args Array of subscriptions to unsubscribe from
   */
  public static destroySubscriptions(...args: Subscription[]): void {
    args.forEach(s => {
      if (Utilities.isDefined(s) && (s[Constants.Observables.Closed] && !s.closed)) {
        s.unsubscribe();
      }
    });
  }

  /**
   * Get the plural term for a word based on a number of records
   * @param noun The word to pluralize in its singluar form, e.g. "article"
   * @param pluralTerm The character added to the end of the word to make it plural, e.g. "s"
   * @param count The number of records in the result set
   */
  public static pluralize(noun: string, pluralTerm: string, count: number) {
    // initialization
    noun = trimEnd(noun, ' Ss');
    chain(noun)
      .trimEnd(' Ss')
      .lowerCase()
      .value();
    const pluralTrimLength: number = endsWith(noun, 'ex') ? 2 : 1;
    const plural =
      pluralTerm === 's'
        ? noun
        : count !== 1
        ? noun.substring(0, noun.length - pluralTrimLength)
        : noun;
    // return
    return `${plural}${count === 1 ? '' : pluralTerm}`;
  }

  /**
   * Wrapper around lodash _.first(), get the first item in an array
   * @param array Array data source
   */
  public static first<T>(array: Array<T>): T {
    return first(array);
  }

  /**
   * Wrapper around lodash _.last(), get the first item in an array
   * @param array Array data source
   */
  public static last<T>(array: Array<T>): T {
    return last(array);
  }

  /**
   * Wrapper around lodash _.orderBy(), sort items in an array by any number of properties
   */
  public static orderBy<T>(
    array: Array<T>,
    iteratee?: string | Array<string>,
    orders?: string | Array<string>
  ): Array<T> {
    return orderBy(array, iteratee, orders);
  }

  /**
   * Check if value is undefined or null
   */
  public static isUndefinedOrNull(value: any): boolean {
    return isUndefined(value) || value === null;
  }

  /**
   * Check if value is defined
   */
  public static isDefined(value: any): boolean {
    return !(isUndefined(value) || value === null);
  }

  public static hasProperty(object: any, path: string[] | string): boolean {
    return has(object, path);
  }

  // function for dynamic sorting
  public static compareValues(key, order = 'asc') {
    return (a, b) => {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        // property doesn't exist on either object
        return 0;
      }

      const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
      const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return order === 'desc' ? comparison * -1 : comparison;
    };
  }

  // function for checking for required field
  public static hasRequiredField(abstractControl: AbstractControl): boolean {
    if (!abstractControl) {
      return false;
    }
    if (abstractControl.validator) {
      const validator = abstractControl.validator({} as AbstractControl);
      if (validator && validator.required) {
        return true;
      }
    }
    // tslint:disable: no-string-literal
    if (abstractControl['controls']) {
      for (const controlName in abstractControl['controls']) {
        if (abstractControl['controls'][controlName]) {
          if (Utilities.hasRequiredField(abstractControl['controls'][controlName])) {
            return true;
          }
        }
      }
    }
    return false;
    // tslint:disable-next-line: semicolon
  }

  public static validateNpi(npi: string): boolean {
    try {
      if (Utilities.validateNpiLength(npi)) {
        return Utilities.validateNpiCheckDigit(npi);
      }
      return false;
    } catch (ex) {
      console.error(ex);
      return false;
    }
  }

  public static validateNpiLength(npi: string): boolean {
    return npi.length === 10;
  }

  public static validateNpiCheckDigit(npi: string): boolean {
    const actualLast = parseInt(npi.substring(npi.length - 1));
    let small = npi
      .substring(0, npi.length - 1)
      .split('')
      .map((_, ix) => {
        const parsed = parseInt(_);
        if (!isNumber(parsed)) {
          throw new Error(`Invalid character at position ${ix}: ${_}. Expected digit 0-9`);
        }
        return parsed;
      })
      .map((_, ix) => {
        if (ix % 2 === 0) {
          return ('' + _ * 2)
            .split('')
            .map(n => parseInt(n))
            .reduce((a, b) => a + b);
        } else {
          return _;
        }
      })
      .reduce((a, b) => a + b);
    // TODO: once we start accepting international NPIs, this constant will need to be dynamic based on country
    small += Constants.UserProfile.NPIPrefixTotal_USA;
    const large = ceil(small, -1);
    const calculatedLast = large - small;
    return calculatedLast === actualLast;
  }

  public static arrayIsPopulated(value: any[]): boolean {
    return Utilities.isDefined(value) && Utilities.hasProperty(value, 'length') && value.length > 0;
  }
}
