import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Preference } from '@shared/models/data';
import { Constants } from '@shared/services/constants';
import { Utilities } from '@shared/services/utils';

@Component({
  selector: 'aaos-searchable-select',
  templateUrl: './searchable-select.component.html',
  styleUrls: ['./searchable-select.component.scss']
})
export class SearchableSelectComponent implements OnInit {

  @Input()
  formControlName: string;
  @Output()
  selectionChange = new EventEmitter<string>();
  @Input()
  formGroup: FormGroup;
  @Input()
  options: Preference[];
  originalOptions: Preference[];
  clearedOptions: Preference[];
  countryCodeString: string;

  value: string;
  constructor() { }

  ngOnInit() {
    this.originalOptions = this.options;
    this.clearedOptions = this.options;
    this.setCountryCode();
    this.value = Utilities.stringEmpty;
  }

  sendSelectionChanged(value: string) {
    this.selectionChange.emit(value);
  }

  /*
  * Handles the key down event with MatSelect.
  * Allows e.g. selecting with enter key, navigation with arrow keys, etc.
  */
  handleKeydown(event: KeyboardEvent) {
    if (event.keyCode === 32) {
      // do not propagate spaces to MatSelect, as this would select the currently active option
      event.stopPropagation();
    }
  }

  onInputChange(value) {
    const valueChanged = this.value !== value;
    if (valueChanged) {
      this.value = value;
      this.options = this.originalOptions.filter(_ => _.PreferenceDescription.toLowerCase().startsWith(value.toLowerCase()));
    } else if (!value) {
      this.options = this.originalOptions;
    }
  }
  setCountryCode() {
    if (this.formGroup != null ) {
      this.countryCodeString = this.formGroup.get(Constants.FormSubFieldNameConstants.countryCode).value;
    }

  }
  clear() {
    this.value = Utilities.stringEmpty;
    this.options = this.clearedOptions;
  }
  matSelectOpenStateChanged(open: boolean) {
    if (!open) {
      this.clear();
    }
  }
}
