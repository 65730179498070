import { UserProfileComponent } from './user-profile.component';
import { RouterModule, Route } from '@angular/router';
import { Routes } from '@angular/router';
import { RouteNames } from '../routing/route-utils';
import { NgModule } from '@angular/core';
import { LoggedInGuard } from '@shared/guards';
// tslint:disable-next-line: max-line-length
import { UserProfileRoutableSummaryComponent } from './user-profile-routable/user-profile-routable-summary/user-profile-routable-summary.component';
import { UserProfileRoutableEditComponent } from './user-profile-routable/user-profile-routable-edit/user-profile-routable-edit.component';
import { UserProfileRoutableComponent } from './user-profile-routable/user-profile-routable.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { UpdateUsernameComponent } from './update-username/update-username.component';

const edit: Route = {
  path: `${RouteNames.Dynamic}\\${RouteNames.Edit}`,
  component: UserProfileRoutableEditComponent
};

const editDynamic: Route = {
  path: RouteNames.Edit,
  component: UserProfileRoutableEditComponent
};

const home: Route = {
  canActivate: [LoggedInGuard],
  path: RouteNames.User,
  component: UserProfileComponent
};

const profileSummary: Route = {
  path: RouteNames.Root,
  component: UserProfileRoutableSummaryComponent
};

const password: Route = {
  path: RouteNames.Password,
  component: ResetPasswordComponent
};

const username: Route = {
  path: RouteNames.Username,
  component: UpdateUsernameComponent
};
const userProfile: Route = {
  canActivate: [LoggedInGuard],
  path: RouteNames.UserProfile,
  component: UserProfileRoutableComponent,
  children: [profileSummary, edit, editDynamic, password, username]
};


// const routes: Routes = [home, edit, userProfile];
export const routes: Routes = [home, edit, userProfile];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  providers: [LoggedInGuard],
  exports: [RouterModule]
})
export class UserProfileRoutingModule { }

export const routedComponents = [UserProfileComponent];
