import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpResponse
} from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { APP_CONFIG } from '@config/main';
import { LogLevel } from '@shared/models/enums';

@Injectable()
export class AMPInterceptor implements HttpInterceptor {
  constructor() { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.url.startsWith('http')) {
      // requests sent to the local application api or front end assets
      const headers = req.headers
        // prevent IE caching
        .set('Cache-Control', 'no-cache')
        .set('Pragma', 'no-cache')
        .set('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
        // JSON
        .set('Content-Type', 'application/json; charset=utf-8')
        // AMP API Key
        .set('ApiKey', 'b930e4ad3ef04b6c8a7b5e0c6761ab2d'); // TODO: remove this, it's hard-coded
      const authReq = req.clone({ headers });
      return next.handle(authReq);
    }
    return next.handle(req); // external requests

  }
}
