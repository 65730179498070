import { Component, OnDestroy, Input, OnInit, HostListener } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { Constants } from '@shared/services/constants';
import { ContinueOnTrackerService } from '@shared/services/trackers';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'aaos-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() isLoading: boolean;
  @Input() isProfilePage: boolean;
  @Input() isCsr$: Observable<boolean>;
  @Input() showEmailPreferencesLink: boolean;
  @Input() showGlobalNavigation: boolean;
  continueOn$: Observable<string>;
  private _destroy$: Subject<void> = new Subject<void>();

  constructor(private continueTracker: ContinueOnTrackerService) { }

  ngOnInit() {
    this.continueOn$ = this.continueTracker.continueOn$.pipe(takeUntil(this._destroy$));
  }

  ngOnDestroy() {
    this._destroy$.next();
  }
}
