import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserTrackerService } from '@shared/services/trackers';
import { UserProfileBaseComponent } from '../../user-profile-base.component';
import { Customer, ApiResponse } from '@shared/models/data';
import { ActivatedRoute, Router } from '@angular/router';
import { has } from 'lodash';
import { Constants } from '@shared/services/constants';
import { EmailPreferencesService, UserProfileService } from '@shared/services/api';
import { UserProfileEditLogicService } from '../../user-profile-edit/logic.service';
import { map, catchError, take, tap, takeUntil } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { of, BehaviorSubject } from 'rxjs';
import { FormFinishType } from '@shared/models/enums';
import { MatDialog } from '@angular/material';
import {
  UpdateProfileModalFailComponent,
  UpdateProfileModalSuccessComponent
} from '@shared/components';
import { ApplicationFlowParameterCacheService } from '@shared/services/application-flow/application-flow-parameter-cache.service';
import { AllowedRedirectUrlsService } from '@shared/services/allowed-redirect-urls.service';
import { AnnualMeetingRedirectService } from '@shared/services/api/annual-meeting-redirect.service';
import { GlobalNavigationService } from '@shared/services/api/global-nav-service';
import { SaveResult } from '@shared/models/contracts/saveResults';
import { FormFinishError } from '@shared/models/enums/form-finish';

@Component({
  selector: 'aaos-user-profile-routable-summary',
  templateUrl: './user-profile-routable-summary.component.html',
  styleUrls: ['./user-profile-routable-summary.component.scss']
})
export class UserProfileRoutableSummaryComponent extends UserProfileBaseComponent
  implements OnInit, OnDestroy {
  user: Customer;
  annualUpdate: boolean;
  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    tracker: UserTrackerService,
    route: ActivatedRoute,
    private router: Router,
    profileService: UserProfileService,
    private logicService: UserProfileEditLogicService,
    private dialog: MatDialog,
    flowParamCache: ApplicationFlowParameterCacheService,
    allowedUrlService: AllowedRedirectUrlsService,
    customerTokenService: AnnualMeetingRedirectService,
    private userProfileEditLogicService: UserProfileEditLogicService,
    globalNavigationService: GlobalNavigationService,
    emailPreferencesService: EmailPreferencesService) {
    super(route, flowParamCache, profileService, allowedUrlService, customerTokenService, globalNavigationService, emailPreferencesService);
    this.userTrackerService = tracker;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  ngOnInit() {
    super.ngOnInit();
    const $sub = this.user$.subscribe(x => {
      this.loading$.next(false);
      if ($sub !== null && $sub !== undefined) {
        $sub.unsubscribe();
      }
    });
    const params = this.route.snapshot.queryParams;
    if (this.applicationType) {
      this.annualUpdate = this.applicationType.toLowerCase() === Constants.ApplicationTypes.AnnualMeeting.toLowerCase();
    }
    // todo this is supporting the old way of passing in application type, when the annual flow is updated get rid of this if block
    if (has(params, Constants.QueryParams.AnnualUpdate)) {
      try {
        this.annualUpdate = JSON.parse(params[Constants.QueryParams.AnnualUpdate]);
      } catch (error) {
        return;
      }
    }
  }

  save() {
    this.loading$.next(true);
    const user = this.logicService.userToUserSnapshot(this.user);
    const response$ = this.profileService
      .postProfileDetailedWithCommunications(user, this.user.Communications, null)
      .pipe(
        map((responses: ApiResponse[]) => {
          const anyUnsuccessful = responses.some(_ => _.hasUnsuccessfulResponse());
          if (anyUnsuccessful) {
            let errorsList: FormFinishError[];
            errorsList = this.userProfileEditLogicService.parsePersonifyErrors(responses);
            return (new SaveResult(FormFinishType.Error, errorsList));
          }
          return new SaveResult(FormFinishType.Success, null);
        }),
        catchError((_: HttpErrorResponse) => {
          // tslint:disable-next-line: deprecation
          return of(new SaveResult(FormFinishType.Error, null));
        })
      );

    response$
      .pipe(
        tap(_ => {
          this.loading$.next(false);
          this.finishProfile(_);
        }),
        takeUntil(this._destroy$)
      )
      .subscribe();
  }

  finishProfile(saveResult: SaveResult) {
    if (saveResult.type === FormFinishType.Success) {
      this.annualUpdate = false;
      this.dialog.closeAll();
      this.router.navigate(['user-profile']);
    }
    const dialogRefComponent =
      saveResult.type === FormFinishType.Success
        ? UpdateProfileModalSuccessComponent
        : UpdateProfileModalFailComponent;
    const dialogRef = this.dialog.open(dialogRefComponent, {
      width: '400px',
      data: {
        errors: saveResult.errors
      }
    });
    dialogRef
      .afterOpened()
      .pipe(take(1))
      .subscribe(_ => { this.reloadPage().subscribe(); });
  }
}
