<div class="form-group" *ngIf="form" [formGroup]="form">
    <div class="title-deleteicon">
        <div *ngIf="isPhonePrimary() && !isBlankAdditionalItem()" class="deleteable-field-title-section">
            <aaos-form-field-title [editMode]="editMode" [field]="field"></aaos-form-field-title>
        </div>
        <div *ngIf="!isPhonePrimary() && !isBlankAdditionalItem()" class="deleteable-field-title-section">
            <div class="title title--xs">
                {{PhoneFieldName | titlecase}}
                <span *ngIf="field.ruleName === 'OptionalEditable'">(Optional)</span>
            </div>
        </div>
        <div *ngIf="(!isPhonePrimary() && editMode)" class="delete-button-wrapper">
            <div id="{{PhoneId}}" class="delete-file-button" (click)="deletePhone($event.target.id)"></div>
        </div>
    </div>

    <div *ngIf=" (editMode && !fieldIsDisabled)">
        <div class="form-group">
            <aaos-searchable-select [formControlName]="formControlName" [formGroup]="form" [options]="countriesOptions"
                (selectionChange)="countryCodeChanged($event)">
            </aaos-searchable-select>
            <div class="required-text" *ngIf="showRequiredErrorForFieldName('countryCode')">
                Country Code is required
            </div>
            <div class="required-text" *ngIf="showValidationError('countryCode')">
                Please enter a valid country code
            </div>
        </div>
        <div class="form-group">
            <input class="form-control phone-country-code-width" *ngIf="!isAreaCodeAvailable"
                [mask]="maskForCountryCode" [value]="phoneCountryCode" readonly="true" />
            <input id="{{PhoneId}}" class="form-control phone-number-width" placeholder="Phone Number"
                formControlName="phoneNumber" (input)="callAll($event.target)" [mask]="maskForPhoneNumber" [ngClass]="{
                    'phone-number-with-country-code-width': !isAreaCodeAvailable,
                    '.width--full': isAreaCodeAvailable
                  }" type="text" />
            <div class="required-text" *ngIf="showRequiredErrorForFieldName('phoneNumber')">
                Phone is required
            </div>
            <div class="required-text"
                *ngIf="showValidationError('phoneNumber') && !showErrorByName('phoneNumberMatchesToSameCustomer') && !showErrorByName('phoneNumberMatchesToAnotherAccount')">
                Please enter a valid Phone
            </div>
            <div class="required-text" *ngIf="showErrorByName('phoneNumberMatchesToSameCustomer')">
                Phone Number already exists.
            </div>
        </div>
        <div class="form-group">
            <input class="form-control .width--full" *ngIf="isPhoneTypeWork()" placeholder="EXT"
                formControlName="phoneExtension" [minlength]="minLength" [maxlength]="maxLength" type="text" />
        </div>
        <div class="form-group">
            <mat-select formControlName="phoneType" (selectionChange)="phoneTypeChanged($event.value)" disableRipple
                panelClass="mat-panel">
                <mat-option *ngFor="let type of phoneTypeOptions" [value]="type.PreferenceCode"
                    [disabled]="!type.Active">{{
            type.PreferenceDescription
        }}</mat-option>
            </mat-select>
            <div class="required-text" *ngIf="showRequiredErrorForFieldName('phoneType')">
                Phone Type is required
            </div>
            <div class="required-text" *ngIf="showValidationError('phoneType')">
                Please select a valid Phone Type
            </div>
        </div>
        <div class="form-check" (click)="flipDoNotCallFlag()">
            <input type="checkbox" id="doNotCallFlag" formControlName="doNotCallFlag" />
            <label [attr.for]="doNotCallFlag">Do not call</label>
            <span></span>
        </div>
        <div class="form-check" (click)="flipIncludeInDirectoryFlagForPhone()">
            <input type="checkbox" id="phoneIncludeInDirectoryFlag" formControlName="phoneIncludeInDirectoryFlag" />
            <label [attr.for]="phoneIncludeInDirectoryFlag">Include in directory</label>
            <span></span>
        </div>
    </div>
    <div *ngIf="!(editMode && !fieldIsDisabled) && !isBlankAdditionalItem()">
        <div class="form-group--address">
            <div class="italic">
                {{readonlyPhoneType}}:
            </div>
            <div *ngIf="readonlyPhoneNumber && isCountryCodeUsaOrCan">
                {{ readonlyPhoneNumber | mask: '(000) 000-0000 SSS 00000' }}
            </div>
            <div *ngIf="readonlyPhoneNumber && !isCountryCodeUsaOrCan">
                {{ readonlyPhoneNumber }}
            </div>
            <div *ngIf="readonlyDoNotCallFlag">
                <div class="check-icon text-gap"></div>
                Do not call
            </div>
            <div *ngIf="readonlyPhoneIncludeInDirectoryFlag">
                <div class="check-icon"></div>
                Included in directory
            </div>
        </div>
        <div class="required-text" *ngIf="showRequiredErrorForFieldName('phoneNumber')">
            Phone is required
        </div>
        <div class="required-text" *ngIf="showValidationError('phoneNumber')">
            Please enter a valid Phone
        </div>
    </div>
</div>