import { Component, OnInit, Input } from '@angular/core';
import { BaseFieldComponent } from '@shared/form-fields/baseFieldComponent';
import { Customer } from '@shared/models/data';

@Component({
  selector: 'aaos-master-customer-id-field',
  templateUrl: './master-customer-id.component.html',
  styleUrls: ['./master-customer-id.component.scss']
})
export class MasterCustomerIdComponent extends BaseFieldComponent implements OnInit {

  @Input()
  user: Customer;
  ngOnInit() {
    super.ngOnInit();
  }
  showRequiredError() {
    if (this.form && this.field) {
      const control = this.getFormControl();
      return control.hasError('required');
    }
  }
  private getFormControl() {
    return this.form.get(this.field.fieldId);
  }

  showValidationError() {
    if (this.form && this.field) {
      const control = this.form.get(this.field.fieldId);
      return !this.showRequiredError() && control.invalid;
    } else { return null; }
  }



}
