import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { BaseFieldComponent } from '@shared/form-fields/baseFieldComponent';
import { Subject } from 'rxjs';
import { Constants } from '@shared/services/constants';
import { UserProfileConstants } from '@shared/user-profile-constants';
import { CustomerAttachmentReadHandler } from './FileReadHandler';
import { Customer, CustomerAttachment, Preference } from '@shared/models/data';
import { Utilities } from '@shared/services/utils';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UserProfileService } from '@shared/services/api';
import { DownloadAttachment } from '@shared/models/data/DownloadAttachment';
import { tap } from 'rxjs/operators';
import { AttachmentData } from '@shared/models/data/AttachmentData';

@Component({
  selector: 'aaos-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.scss']
})
export class AttachmentsComponent extends BaseFieldComponent implements OnInit, OnDestroy {

  fileExtension: string;
  sessionStorageKeys = new Object();
  @Input()
  biographyAttachmentsSubject: Subject<Array<CustomerAttachment>>;
  @Input()
  user: Customer;
  attachments: CustomerAttachment[] = [];
  docCodes: Preference[];
  fileTypesAllowed = ['.docx', '.doc', '.application/msword',
    '.pdf', '.xlsx', '.xls', '.csv', '.application/vnd.ms-excel'];
  addingAttachment: boolean;
  currentlyEditingRelatedDocumentIds: string[] = [];
  newDocumentIds: string[] = [];
  constructor(private profileService: UserProfileService) { super(); }

  ngOnInit() {
    super.ngOnInit();
    this.attachments = this.user ? this.user.Attachments : null;
    const profileFormOptions = this.formOptions.filter(x => x.key === Constants.UserProfileFormOptions.RelatedDocumentTypes).pop();
    if (profileFormOptions) {
      this.docCodes = profileFormOptions.value;
    }
    // readonly pages do not use this
    if (this.refreshEditState$) {
      this.refreshEditState$.subscribe(x => this.resetView());
    }

  }
  resetView(): void {
    if (this.attachments) {
      this.attachments = this.attachments.filter(x => x.Action !== 'delete');
      this.attachments.forEach(x => {
        x.Action = null;
      });
    }
  }

  ngOnDestroy(): void {
    Object.keys(this.sessionStorageKeys).forEach(x => {
      sessionStorage.removeItem(this.sessionStorageKeys[x]);
    });
    this.sessionStorageKeys = null;
  }
  fileDropped(event) {
    if (event) {
      for (const file of event) {
        this.readFile(file);
      }
    }
  }
  private readFile(file: File) {
    const newDocumentId = (Math.round(Math.random() * 10000000)).toString();
    this.currentlyEditingRelatedDocumentIds.push(newDocumentId);
    this.newDocumentIds.push(newDocumentId);

    const fileReadHandler = new CustomerAttachmentReadHandler(this.fileTypesAllowed,
      UserProfileConstants.SessionStorageConstants.biographyAttachmentsPrefix);
    const errorAlertSub$ = fileReadHandler.errorAlertSubject.subscribe(x => {
      alert(x);
      // tslint:disable-next-line: no-use-before-declare
      readSub$.unsubscribe();
      errorAlertSub$.unsubscribe();
    });
    const readSub$ = fileReadHandler.fileReadSubject.subscribe(sessionStorageKey => {
      const newAttachment = new CustomerAttachment();
      newAttachment.Action = 'upload';
      newAttachment.FileName = file.name;
      newAttachment.sessionStorageKey = sessionStorageKey;
      newAttachment.IsAttachmentNew = true;
      newAttachment.CustomerRelatedDocumentId = newDocumentId;  // random number as placeholder for id
      this.attachments.push(newAttachment);
      this.sessionStorageKeys[newAttachment.CustomerRelatedDocumentId] = sessionStorageKey;

      readSub$.unsubscribe();
      errorAlertSub$.unsubscribe();
      this.biographyAttachmentsSubject.next(this.attachments.filter(x => !Utilities.isUndefinedOrNull(x.Action)));

      this.addingAttachment = false;
    });
    fileReadHandler.readFile(file);
    const formGroup = (this.form as FormGroup);
    formGroup.addControl(`relatedDocType-${newDocumentId}`,
      new FormControl('', { validators: Validators.required, updateOn: 'blur' }));
    let fileIsOfAllowedType = false;
    this.fileTypesAllowed.forEach(ext => {
      const extWithoutPeriod = ext.replace('.', '');
      if (file.type.match(extWithoutPeriod)) {
        fileIsOfAllowedType = true;
      }
    });
    if (fileIsOfAllowedType === false) {
      const errorFormGroup = this.form as FormGroup;
      errorFormGroup.removeControl(`relatedDocType-${newDocumentId}`);
    }
  }

  fileSelected(event) {
    if (event && event.target && event.target.files) {
      for (const file of event.target.files) {
        this.readFile(file);
      }
    }
  }
  deleteAttachment(documentId: string) {
    const key = this.sessionStorageKeys[documentId];
    if (key) {
      sessionStorage.removeItem(key);
      delete this.sessionStorageKeys[documentId];
    }
    const attachment = this.attachments.find(x => x.CustomerRelatedDocumentId === documentId);
    // if the attachment is in the list and exists on the server mark it for delete
    if (attachment && attachment.CustomerRelatedDocumentId) {
      attachment.Action = 'delete';
    }
    this.biographyAttachmentsSubject.next(this.attachments);
    const formGroup = (this.form as FormGroup);
    const controlId = `relatedDocType-${documentId}`;

    if (formGroup.controls[controlId]) {
      formGroup.removeControl(controlId);
    }
    if (attachment.IsAttachmentNew === true) {
      this.attachments = this.attachments.filter(x => x.CustomerRelatedDocumentId !== attachment.CustomerRelatedDocumentId);
    }
  }


  undoDeleteAttachment(documentId: string) {
    const attachment = this.attachments.find(x => x.CustomerRelatedDocumentId === documentId);
    // if the attachment is in the list and exists on the server mark it for delete
    if (attachment && attachment.CustomerRelatedDocumentId) {
      attachment.Action = null;
    }
  }
  addAttachment() {
    this.currentlyEditingRelatedDocumentIds = [];
    this.addingAttachment = true;
  }
  documentIsBeingEdited(documentId: string): boolean {
    return this.currentlyEditingRelatedDocumentIds.findIndex(_ => _ === documentId) >= 0;
  }
  downloadFile(id: string) {
    const attachment = this.attachments.find(x => x.CustomerRelatedDocumentId === id);
    const result = this.profileService.getAttachmentData(attachment.FileName, attachment.CustomerRelatedDocumentId).pipe(
      tap(_ => {
        this.buildDownloadLink(_);
      }),
    ).subscribe();
  }
  buildDownloadLink(fields: AttachmentData) {
    const link = document.createElement('a');
    link.href = fields.AttachmentDataBase64;
    link.download = fields.FileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

}
