import { Injectable } from '@angular/core';
import { AaosBaseService, LoggingService } from '@shared/services/api';
import { HttpClient } from '@angular/common/http';
import { Constants } from '@shared/services/constants';
import { UpdatePasswordDto } from '@shared/models/data/update-password-dto';
import { Observable, OperatorFunction, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ApiResponse } from '@shared/models/data';

@Injectable({
  providedIn: 'root'
})
export class UpdatePasswordService extends AaosBaseService {

  constructor(protected _h: HttpClient, protected _l: LoggingService) {
    super(_l, _h);
  }

  public updateUserPassword(oldPassword: string, newPassword: string, username: string): Observable<ApiResponse> {
    const updatePasswordDto = new UpdatePasswordDto();
    updatePasswordDto.NewPassword = newPassword;
    updatePasswordDto.OldPassword = oldPassword;
    updatePasswordDto.UserName = username;
    return this.http.put(`${Constants.ApiUrls.UpdateCustomerPassword}`, updatePasswordDto)
      .pipe(
        map((_: ApiResponse) => _)
      );
  }
}
