<div class="form-group" *ngIf="form" [formGroup]="form">
  <div class="title-deleteicon">
    <div *ngIf="isEmailPrimary() && !isBlankAdditionalItem()" class="deleteable-field-title-section">
      <aaos-form-field-title [editMode]="editMode" [field]="field"></aaos-form-field-title>
    </div>
    <div *ngIf="!isEmailPrimary() && !isBlankAdditionalItem()" class="deleteable-field-title-section">
      <div class="title title--xs">
        {{EmailAddressFieldName | titlecase}}
        <span *ngIf="field.ruleName === 'OptionalEditable'">(Optional)</span>
      </div>
    </div>

    <div *ngIf="(!isEmailPrimary() && editMode)" class="delete-button-wrapper">
      <div id="{{EmailFieldId}}" class="delete-file-button" (click)="deleteEmail($event.target.id)"></div>
    </div>
  </div>

  <div *ngIf="(editMode && !fieldIsDisabled)">
    <div class="form-group">
      <input id="{{EmailFieldId}}" class="form-control width--full" type="text" formControlName="emailAddress"
        (blur)="primaryEmailChanged()" (input)="isEmailDuplicate($event.target)"
        placement="top" />
    </div>
    <div class="required-text" *ngIf="showRequiredErrorForFieldName('emailAddress')">
      Email is required
    </div>
    <div class="required-text" *ngIf="primaryEmailChangeMessage">
      {{primaryEmailChangeMessage}}
    </div>
    <div class="required-text" *ngIf="showErrorByName('emailAdressMatchesToExisting')">
      Primary email already exists for another account.
    </div>
    <div class="required-text"
      *ngIf="showValidationError('emailAddress') && !showErrorByName('emailAdressMatchesToExisting') && !showErrorByName('emailAdressMatchesToSameCustomer')">
      Please enter a valid Email
    </div>
    <div class="required-text" *ngIf="showErrorByName('emailAdressMatchesToSameCustomer')">
      Email already exists for customer.
    </div>
    <div class="form-group p-t-10">
      <mat-select formControlName="emailType" (selectionChange)="emailTypeChanged($event.value)" disableRipple
        panelClass="mat-panel">
        <mat-option *ngFor="let type of emailTypeOptions" [value]="type.PreferenceCode" [disabled]="!type.Active">{{
  type.PreferenceDescription
}}</mat-option>
      </mat-select>

      <div class="required-text" *ngIf="showRequiredErrorForFieldName('emailType')">
        Email Address Type is required
      </div>
      <div class="required-text" *ngIf="showValidationError('emailType')">
        Please enter a valid Email Address Type
      </div>
    </div>
  </div>


  <div *ngIf="!(editMode && !fieldIsDisabled) && !isBlankAdditionalItem()">
    <div>{{form.get("emailAddress").value}}</div>

    <div class="required-text" *ngIf="showRequiredErrorForFieldName('emailAddress')">
      Email is required
    </div>
    <div class="required-text" *ngIf="showValidationError('emailAddress')">
      Please enter a valid Email
    </div>
  </div>
</div>