import { Utilities } from '@shared/services/utils';
import { BirthdayService } from '@shared/services/utils/birthday.service';
export class CustomerProfile {
    SubCustomerId: string;
    MemberExpirationDate: Date;
    SpecialtyArea: string;
    SpecialtyInterests: string;
    CustomerClassStatusCode: string;
    MasterCustomerId: number;
    UserName: string;
    LabelName: string;
    PrimaryEmailAddress: string;
    PrimaryPhone: string;
    PrimaryFormattedAddress: string;
    PrimaryAddressTypeCode: string;
    PrimaryPhoneLocationCode: string;
    CustomerClass: string;
    MemberGroup: string;
    CustomerClassCode: number;
    MemberGroupCode: string;
    ResidencyComplete: Date;
    FellowshipComplete: Date;
    ABOSYear: string; // this is a numeric value but the model and data storage is string
    RecertificationYear: string; // this is a numeric value but the model and data storage is string
    ElectionClass: string;
    BirthDate: Date;
    ProfilePictureUrl: string;
    Biography: string;
    ProfileImageId: number;
    IsCustomerActive: boolean;
    ProfileImageBase64String: string;
    Ethnicity: string;
    Gender: string;
    Identity: string;
    IdentityPronoun: string;
    PracticeSpecializationPrimary: string;
    PracticeSpecializationSecondary: string;
    PracticeType: string;
    PracticeSize: string;
    Npi: number;
    CompanyName: string;
    JobTitle: string;
    WorkStatus: string;
    MobileOptin: string;
    RetirementAge: number;
    static create(obj: any) {
        const result = obj as CustomerProfile;
        result.MemberExpirationDate = new Date(result.MemberExpirationDate);
        result.ResidencyComplete = new Date(result.ResidencyComplete);
        result.FellowshipComplete = new Date(result.FellowshipComplete);
        if (BirthdayService.isValid(result.BirthDate)) {
            // normalize birthday datetime for different timezones if they have a valid birthday
            result.BirthDate = BirthdayService.normalizeTimezone(result.BirthDate);
        } else {
            result.BirthDate = null;
        }
        if (Utilities.isDefined(result.ProfilePictureUrl) && result.ProfilePictureUrl !== '') {
            result.ProfilePictureUrl = result.ProfilePictureUrl + '?' + Date.now().toExponential();
            result.ProfilePictureUrl = result.ProfilePictureUrl.replace('http://', 'https://');
        }
        return result;
    }
}

