import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfilePromptComponent } from './profile-prompt.component';
import { ProfilePromptRoutingModule } from './routing.module';
import { SharedModule } from '../shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [ProfilePromptComponent],
  imports: [CommonModule, ProfilePromptRoutingModule, SharedModule, BrowserAnimationsModule],
  exports: [ProfilePromptComponent]
})
export class ProfilePromptModule {}
