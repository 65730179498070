import { Component, OnInit } from '@angular/core';
import { EmailPreferencesService, UserProfileService } from '@shared/services/api';
import { UserTrackerService } from '@shared/services/trackers';
import { UserProfileBaseComponent } from '../user-profile-base.component';
import { ApplicationFlowParameterCacheService } from '@shared/services/application-flow/application-flow-parameter-cache.service';
import { ActivatedRoute } from '@angular/router';
import { AllowedRedirectUrlsService } from '@shared/services/allowed-redirect-urls.service';
import { Subject } from 'rxjs';
import { AnnualMeetingRedirectService } from '@shared/services/api/annual-meeting-redirect.service';
import { GlobalNavigationService } from '@shared/services/api/global-nav-service';

@Component({
  selector: 'aaos-user-profile-routable',
  templateUrl: './user-profile-routable.component.html',
  styleUrls: ['./user-profile-routable.component.scss']
})
export class UserProfileRoutableComponent extends UserProfileBaseComponent implements OnInit {
  refreshEditState$ = new Subject<void>();
  constructor(
    profileService: UserProfileService,
    userTrackerService: UserTrackerService,
    route: ActivatedRoute,
    flowParamCache: ApplicationFlowParameterCacheService,
    allowedUrlService: AllowedRedirectUrlsService, customerTokenService: AnnualMeetingRedirectService,
    globalNavigationService: GlobalNavigationService,
    emailPreferencesService: EmailPreferencesService) {
    super(route, flowParamCache, profileService, allowedUrlService, customerTokenService, globalNavigationService, emailPreferencesService);
    this.userTrackerService = userTrackerService;
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
