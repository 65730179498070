import { Component, OnInit } from '@angular/core';
import { Customer } from '@shared/models/data';
import { EmailPreferencesService, UserProfileService } from '@shared/services/api';
import { catchError, take, delay, tap, switchMap, concatMap } from 'rxjs/operators';
import { Constants } from '@shared/services/constants';
import { FormFinishType } from '@shared/models/enums';
import { APP_CONFIG } from '@config/main';
import { MatDialog } from '@angular/material/dialog';
import {
  UpdateProfileModalSuccessComponent,
  UpdateProfileModalFailComponent
} from '@shared/components';
import { FormGroup } from '@angular/forms';
import { Observable, of, Subject } from 'rxjs';
import { Utilities } from '@shared/services/utils';
import { ApplicationFlowParameterCacheService } from '@shared/services/application-flow/application-flow-parameter-cache.service';
import { ActivatedRoute } from '@angular/router';
import { UserProfileBaseComponent } from './user-profile-base.component';
import { UserTrackerService } from '@shared/services/trackers';
import { AllowedRedirectUrlsService } from '@shared/services/allowed-redirect-urls.service';
import { AnnualMeetingRedirectService } from '@shared/services/api/annual-meeting-redirect.service';
import { GlobalNavigationService } from '@shared/services/api/global-nav-service';
import { SaveResult } from '@shared/models/contracts/saveResults';

@Component({
  selector: 'aaos-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent extends UserProfileBaseComponent implements OnInit {

  static readonly MAX_IMAGE_SIZE = 10485760;
  editMode: boolean;
  error: boolean;
  isRedirecting: boolean;
  spinnerContainerClass = 'spinner-container';
  fileExtension: string;
  profileImageDirty: boolean;
  profileImageDeleted: boolean;
  tmpProfilePictureUrl: string;
  refreshEditState$ = new Subject<void>();
  applicationType: string;
  showOnlyFields = false;

  constructor(
    profileService: UserProfileService,
    private dialog: MatDialog,
    route: ActivatedRoute,
    userTrackerService: UserTrackerService,
    flowParamCache: ApplicationFlowParameterCacheService,
    allowedUrlService: AllowedRedirectUrlsService,
    customerTokenService: AnnualMeetingRedirectService,
    globalNavigationService: GlobalNavigationService,
    emailPreferencesService: EmailPreferencesService) {
    super(route, flowParamCache, profileService, allowedUrlService, customerTokenService, globalNavigationService, emailPreferencesService);
    this.userTrackerService = userTrackerService;
    this.editMode = false;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.applicationType = this.flowParamCache.getApplicationType();
    if (this.applicationType === Constants.ApplicationTypes.AnnualMeeting ) {
      this.editMode = true;
    }

    if ( this.applicationType === Constants.ApplicationTypes.MAAP ) {
      this.showOnlyFields = true;
      this.editMode = true;
    }
    this.user$.subscribe();
  }

  toggleEditProfile() {
    this.editMode = !this.editMode;
  }

  setFile(event) {
    if (event.target.files !== undefined && event.target.files.length > 0) {
      const reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this);

      const allowed = ['jpeg', 'png', 'gif', 'jpg'];
      let found = false;
      allowed.forEach(ext => {
        if (event.target.files[0].type.match('image/' + ext)) {
          found = true;
          this.fileExtension = ext;
        }
      });

      if (found) {
        if (event.target.files[0].size > UserProfileComponent.MAX_IMAGE_SIZE) {
          alert('The maximum image size allowed is 10MB. Please try again with a smaller image.');
        } else {
          reader.readAsArrayBuffer(event.target.files[0]);
        }
      }
    }
  }

  deleteFile() {
    this.user.CustomerProfile.ProfilePictureUrl = null;
    this.user.CustomerProfile.ProfileImageBase64String = null;
    sessionStorage.setItem('tmpProfilePictureUrl', null);

    this.profileImageDeleted = true;
  }

  handleFinishProfile(finishType: SaveResult) {
    if (finishType.type === FormFinishType.Cancel) {
      this.reloadPage();
    }
    this.tmpProfilePictureUrl = undefined;

    const nextStep$ = finishType.type === FormFinishType.Success ? this.endSession() : of(null);
    nextStep$
      .pipe(
        switchMap(_ => {
          this.dialog.closeAll();
          if (Utilities.isUndefinedOrNull(finishType.type) || finishType.type === FormFinishType.Cancel) {
            return of(null);
          } else {
            const dialogRefComponent =
            finishType.type === FormFinishType.Success
                ? UpdateProfileModalSuccessComponent
                : UpdateProfileModalFailComponent;
            const dialogRef = this.dialog.open(dialogRefComponent, {
              width: '400px',
              data: {
                errors: finishType.errors
              }
            });
            return of(dialogRef.afterOpened().pipe(take(1)));
          }
        })).pipe()
      .subscribe(_ => { this.reloadPage().subscribe(); });
  }



  updateUrl() {
    this.tmpProfilePictureUrl = sessionStorage.getItem('tmpProfilePictureUrl');
  }

  _handleReaderLoaded(readerEvt) {
    let binary = '';
    const bytes = new Uint8Array(readerEvt.target.result);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    const base64String = btoa(binary);
    this.user.CustomerProfile.ProfileImageBase64String = base64String;
    this.tmpProfilePictureUrl =
      'data:image/' +
      this.fileExtension +
      ';base64,' +
      this.user.CustomerProfile.ProfileImageBase64String;

    sessionStorage.setItem('tmpProfilePictureUrl', this.tmpProfilePictureUrl);

    this.profileImageDirty = true;
  }
}
