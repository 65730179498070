export class PhoneNumberStructure {
    AvailableFlag: boolean;
    CountryCode: string;
    FieldName: string;
    Format: string;
    MaxLength: number;
    MinLength: number;
    Prefix: string;
    Suffix: string;
}
