import { Injectable } from '@angular/core';
import { Customer } from '@shared/models/data';
import { BehaviorSubject } from 'rxjs';
import { isEqual } from 'lodash';

@Injectable({ providedIn: 'root' })
export class UserTrackerService {
  private _userSubject: BehaviorSubject<Customer>;
  get user$() {
    return this._userSubject.asObservable();
  }

  constructor() {
    this._userSubject = new BehaviorSubject<Customer>(null);
  }

  setUser(user: Customer): void {
    if (isEqual(user, this._userSubject.value)) {
      return;
    }
    this._userSubject.next(user);
  }

  getUser(): Customer {
    return this._userSubject.value;
  }

  clearUser(): void {
    this._userSubject.next(null);
  }
}
