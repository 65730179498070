import {
  FormGroup,
  FormControl,
  Validators,
  AbstractControl,
  FormArray,
  ValidatorFn,
  ValidationErrors
} from '@angular/forms';
import { MemberGroups } from '../enums';
import { Utilities } from '@shared/services/utils';
import { BirthdayService } from '@shared/services/utils/birthday.service';

export class AMPFormFactory {
  private readonly _customValidators = {
    defaultPostalCodeValidation: Validators.pattern(new RegExp(/(^\d{5}$)|(^\d{5}-\d{4}$)/)),
    minSelectedCheckboxes(): ValidatorFn {
      const validator: ValidatorFn = (control: FormControl): { [key: string]: boolean } => {
        const snapshot = control.value;
        const keys = Object.keys(snapshot);
        if (!keys || keys.length === 0) {
          return null;
        }

        const anythingSelected = keys.some(_ => !!snapshot[_]);
        return anythingSelected ? null : { required: true };
      };
      return validator;
    },
    checkDateValidation(): ValidatorFn {
      const validator: ValidatorFn = (control: FormControl): ValidationErrors | null => {
        return BirthdayService.validate(control.value);
      };
      return validator;
    },
    npiValidation(): ValidatorFn {
      const validator: ValidatorFn = (control: FormControl): ValidationErrors | null => {
        if (control.value) {
          return Utilities.validateNpi(control.value.toString()) ? null : { invalidNpi: true };
        }
      };
      return validator;
    }
  };

  createForm(memberGroupCode: string) {
    switch (memberGroupCode) {
      case MemberGroups.Emeritus:
      case MemberGroups.Emeritus8AO:
      case MemberGroups.Emeritus8DO:
        return this._createEmeritusForm();
      case MemberGroups.Candidate:
      case MemberGroups.Candidate3AO:
      case MemberGroups.Candidate3DO:
      case MemberGroups.ActiveFellow:
        return this._createCandidateFellowForm();
      default:
        return this._createResidentForm();
    }
  }

  private _createCandidateFellowForm() {
    const form = this._createDefaultForm();
    const phoneNumber = form.get('phone.number') as FormControl;
    const phoneType = form.get('phone.type') as FormControl;
    const areasOfInterests = form.get('learningPreferences.areasOfInterests') as FormGroup;
    const practiceType = form.get('practice.practiceType') as FormControl;
    const npi = form.get('practice.npi') as FormControl;

    phoneNumber.setValidators([Validators.required]);
    phoneNumber.updateValueAndValidity();
    phoneType.setValidators(Validators.required);
    phoneType.updateValueAndValidity();
    practiceType.setValidators(Validators.required);
    practiceType.updateValueAndValidity();
    npi.setValidators([Validators.required, this._customValidators.npiValidation()]);
    npi.updateValueAndValidity();

    return form as FormGroup;
  }

  private _createResidentForm() {
    const form = this._createDefaultForm();
    return form as FormGroup;
  }

  private _createEmeritusForm() {
    const form = this._createDefaultForm();
    const phoneNumber = form.get('phone.number') as FormControl;
    const phoneType = form.get('phone.type') as FormControl;

    phoneNumber.setValidators([Validators.required]);
    phoneNumber.updateValueAndValidity();
    phoneType.setValidators(Validators.required);
    phoneType.updateValueAndValidity();

    return form as FormGroup;
  }

  private _createDefaultForm() {
    return new FormGroup({
      name: new FormGroup({
        prefix: new FormControl(''),
        suffix: new FormControl(''),
        credentials: new FormControl('')
      }),
      address: new FormGroup({
        organizationName: new FormControl(''),
        address1: new FormControl('', Validators.required),
        address2: new FormControl(''),
        address3: new FormControl(''),
        city: new FormControl('', Validators.required),
        state: new FormControl(''),
        postalcode: new FormControl('', [
          Validators.required,
          this._customValidators.defaultPostalCodeValidation
        ]),
        countryCode: new FormControl('', Validators.required),
        addressType: new FormControl('', Validators.required)
      }),
      email: new FormGroup({
        address: new FormControl('', [Validators.required, Validators.email]),
        type: new FormControl('', Validators.required)
      }),
      phone: new FormGroup({
        number: new FormControl('', Validators.required),
        type: new FormControl('', Validators.required),
        extension: new FormControl('')
      }),
      demographicInfo: new FormGroup({
        birthdate: new FormControl('', [
          Validators.required,
          this._customValidators.checkDateValidation()
        ]),
        gender: new FormControl('', Validators.required),
        identity: new FormControl('', Validators.required),
        identityPronoun: new FormControl('', Validators.required),
        ethnicity: new FormControl('', Validators.required)
      }),
      specialization: new FormGroup({
        primarySpecialization: new FormControl('', Validators.required),
        secondarySpecialization: new FormControl('')
      }),
      practice: new FormGroup({
        practiceType: new FormControl(''),
        npi: new FormControl('', this._customValidators.npiValidation())
      }),
      learningPreferences: new FormGroup({
        areasOfInterests: new FormGroup({}, this._customValidators.minSelectedCheckboxes()),
        otherAreasOfInterests: new FormGroup({})
      })
    });
  }
}
