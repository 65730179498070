import { EmailPreferencesComponent } from './email-preferences.component';
import { Route, Routes, RouterModule } from '@angular/router';
import { LoggedInGuard, ClearCookiesGuard, RedirectGuard } from '@shared/guards';
import { RouteNames } from '../routing/route-utils';
import { NgModule } from '@angular/core';
import { EmailPreferencesSearchComponent } from './search/search.component';
import { ManagePreferencesComponent } from './manage-preferences/manage-preferences.component';
import { EmailPreferencesUnsubscribeComponent } from './unsubscribe/unsubscribe.component';

const search: Route = {
  data: {
    checkCsr: true
  },
  path: RouteNames.UserAdministration,
  component: EmailPreferencesSearchComponent
};

const unsubscribe: Route = {
  canActivate: [ClearCookiesGuard],
  path: RouteNames.Unsubscribe,
  component: EmailPreferencesUnsubscribeComponent
};

const unsubscribeOnlyMarketo: Route = {
    canActivate: [ClearCookiesGuard],
    path: RouteNames.UnsubscribeOnlyMarketo,
    component: EmailPreferencesUnsubscribeComponent
};

const unsubscribeFromMarketo: Route = {
  canActivate: [ClearCookiesGuard],
  path: RouteNames.UnsubscribeFromMarketo,
  component: EmailPreferencesUnsubscribeComponent
};

const home: Route = {
  data: {
    redirectURI: 'https://communications.aaos.org/preference-center'
  },
  canActivate: [RedirectGuard],
  path: RouteNames.EmailPreferences,
  component: RedirectGuard
};

const manage: Route = {
  data: {
    redirectURI: 'https://communications.aaos.org/preference-center'
  },
  canActivate: [RedirectGuard],
  path: RouteNames.ManagePreferences,
  component: RedirectGuard
};

export const routes: Routes = [home, search, manage, unsubscribe, unsubscribeOnlyMarketo, unsubscribeFromMarketo];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  providers: [LoggedInGuard, ClearCookiesGuard, RedirectGuard],
  exports: [RouterModule]
})
export class EmailPreferencesRoutingModule {}
