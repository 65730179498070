import { FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { Customer } from '@shared/models/data';
import { UserProfileService } from '@shared/services/api';
import { switchMap, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FormConfirmgDialogComponent } from '@shared/components/form-confirm-dialog/form-confirm-dialog.component';
import { FormConfirmType, FormFinishType } from '@shared/models/enums';
import { of, Observable } from 'rxjs';
import { UpdateProfileModalSuccessComponent } from '@shared/components/modal-success/modal-success.component';
import { UpdateProfileModalFailComponent } from '@shared/components/modal-fail/modal-fail.component';
import { SaveResult } from '@shared/models/contracts/saveResults';

export abstract class UserLoginInformationPageBaseComponent {
    user: Customer;
    form: FormGroup;
    submitting: boolean;
    oldPassword = 'oldPassword';
    newPassword = 'newPassword';
    confirmNewPassword = 'confirmNewPassword';
    loading: boolean;

    constructor(
        private userProfileService: UserProfileService,
        private router: Router, private dialog: MatDialog) {
        this.loading = true;
    }

    loadUser(): Observable<Customer> {
        return Observable.create(observer => this.userProfileService.getProfileDetailedCommunications()
            .subscribe(
                _ => {
                    this.user = _;
                    this.loading = false;
                    observer.next(_);
                },
                error => {
                    alert('Error retrieving user account'); // todo better error message
                    this.loading = false;
                    observer.error();
                }
            ));
    }


    abstract buildForm();
    abstract save(): Observable<SaveResult>;

    openDialog(): void {
        if (!this.form.valid) {
            return;
        }
        const dialogRef = this.dialog.open(FormConfirmgDialogComponent, this.getModalConfig());
        dialogRef.componentInstance.actionTaken
            .pipe(
                switchMap((result: FormConfirmType) => {
                    switch (result) {
                        case FormConfirmType.Save:
                            dialogRef.componentInstance.beginSpinner();
                            return this.save();
                        case FormConfirmType.DontSave:
                            return of(new SaveResult(FormFinishType.Cancel, null));
                        default:
                            return of(null);
                    }
                }),
                take(1)
            ).subscribe(_ => {
                this.finishSaveAction(_);
            });
    }

    finishSaveAction(finishType: SaveResult) {
        this.dialog.closeAll();
        if (finishType.type === FormFinishType.Cancel) {
            return;
        }
        const dialogRefComponent =
            finishType.type === FormFinishType.Success
                ? UpdateProfileModalSuccessComponent
                : UpdateProfileModalFailComponent;
        const dialogRef = this.dialog.open(dialogRefComponent, {
            width: '400px',
            data: {
                errors: finishType.errors,
                autoCloseInterval: 4000
            }
        });
        if (finishType.type === FormFinishType.Success) {
            dialogRef.afterClosed().subscribe(() => this.closePage());
        }
        return dialogRef.afterOpened().pipe(take(1));
    }

    cancel() {
        this.closePage();
    }
    private closePage() {
        this.router.navigate(['/user']);
    }

    showRequiredError(fieldName: string) {
        const control = this.form.get(fieldName);
        return control && control.hasError('required');
    }

    getModalConfig(): MatDialogConfig<any> {
        return {
            panelClass: 'amp-dialog',
            disableClose: true,
            autoFocus: false
        } as MatDialogConfig;
    }
}
