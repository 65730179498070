import { CustomerProfile } from './CustomerProfile';
import { AddressDetail } from './AddressDetail';
import { DisclosureSummary } from './DisclosureSummary';
import { NameCredentials } from './NameCredentials';
import { Preference } from './preference';
import { CustomerCommunication } from './CustomerCommunication';
import { CustomerAttachment } from './CustomerAttachment';
import { CustomerEmergencyContact } from '@shared/models/data/CustomerEmergencyContact';
import { PracticeInformation } from '@shared/models/data';

export class Customer {
    CustomerProfile: CustomerProfile;
    NameCredentials: NameCredentials;
    AddressDetail: AddressDetail[];
    Preferences: Preference[];
    DisclosureSummary: DisclosureSummary;
    Communications: CustomerCommunication[];
    Attachments: CustomerAttachment[];
    EmergencyContacts: CustomerEmergencyContact[];
    PracticeInformation: PracticeInformation;
}


