import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { isEqual } from 'lodash';

@Injectable({ providedIn: 'root' })
export class ContinueOnTrackerService {
  private _continueOnLink: BehaviorSubject<string>;

  get continueOn$() {
    return this._continueOnLink.asObservable();
  }

  constructor() {
    this._continueOnLink = new BehaviorSubject<string>(null);
  }

  set(url: string): void {
    if (isEqual(url, this._continueOnLink.value)) {
      return;
    }
    this._continueOnLink.next(url);
  }

  clear(): void {
    this._continueOnLink.next(null);
  }
}
