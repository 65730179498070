<div *ngIf="user" [formGroup]="form">
  <aaos-header [showEmailPreferencesLink]="showEmailPreferencesLink" [isProfilePage]="true"></aaos-header>

  <div class="flexbox container">
    <div class="profile-breadcrumb">
      <span class="breadcrumb-text" routerLink="/user-profile">MY PROFILE</span>&ensp;/&ensp;CHANGE
      USERNAME
    </div>
    <div class="form-group reset-password-form">
      <div class="form-group">
        <div class="title title--lg flex-align-center">Change Username/Email</div>
        <div class="primary-email-message-container">
          <div class="confirm-text">
            For account security and recovery, we <b>require</b> you use your primary email address as your username.
          </div>
        </div>
        <div class="form-group">
          <div class="title title--xs">Current Username</div>
          <input type="text" formControlName="currentUserName" disabled readonly>
        </div>
        <div class="form-group" *ngIf=canSave>
          <div class="title title--xs">Update to Primary Email</div>
          <input type="text" formControlName="newUsername" disabled readonly>
        </div>


        <div class="image-upload btn--container btn-inline" *ngIf=canSave>
          <div class="btn btn--solid saveButton" [ngClass]="{
            'btn--red': form.valid,
            'btn--disabled': !form.valid || submitting
          }" (click)="openDialog()">
            UPDATE & CONTINUE
          </div>
          <button class="btn btn--outline btn--delete btn--cancel" (click)="cancel()">
            CANCEL
          </button>
        </div>
        <div *ngIf="!canSave">
          <div class="confirm-text">
            To update your username please update your primary email address on the profile edit page.
          </div>
          <div class="image-upload btn--container btn-inline" *ngIf="!canSave">
             <!--<button class="btn btn--solid saveButton btn--red" routerLink="/user-profile/edit"
              [queryParams]="{ goToField: 'primaryEmail' }">
              CHANGE PRIMARY EMAIL
            </button>--> 
            <button class="btn btn--outline btn--delete btn--cancel" (click)="cancel()">
              CANCEL
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <aaos-footer></aaos-footer>
</div>
<aaos-spinner [isLoading]="loading"></aaos-spinner>