import { Component, Inject, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { ApiResponse } from '@shared/models/data';
import { EmailPreferencesService } from '@shared/services/api';
import { Utilities } from '@shared/services/utils';
import { BehaviorSubject, of, throwError } from 'rxjs';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';
import { RouteNames } from '../../routing/route-utils';

@Component({
  selector: 'aaos-unsubscribe-confirm',
  templateUrl: './unsubscribe-confirm.component.html',
  styleUrls: ['./unsubscribe-confirm.component.scss']
})
export class UnsubscribeConfirmModalComponent {
  success: boolean;
  email: string;
  description: string;
  error: string;
  constructor(@Inject(MAT_DIALOG_DATA) data) {
    this.success = data.success;
    this.email = data.email;
    this.description = data.description;
    this.error = data.error;
  }
}

@Component({
  selector: 'aaos-unsubscribe-marketo-confirm',
  templateUrl: './unsubscribe-marketo-confirm.component.html',
  styleUrls: ['./unsubscribe-marketo-confirm.component.scss']
})
export class UnsubscribeMarketoConfirmModalComponent {
  success: boolean;
  email: string;
  description: string;
  error: string;
  contact: string;
  contactEmailLink: string;
  contactEmail: string;
  titleDescription: string;
  detailDescription: string;
  constructor(@Inject(MAT_DIALOG_DATA) data) {
    this.success = data.success;
    this.email = data.email;
    this.description = data.description;
    this.error = data.error;
    this.contact = data.contact;
    this.contactEmailLink = data.contactEmailLink;
    this.contactEmail = data.contactEmail;
    this.titleDescription = data.titleDescription;
    this.detailDescription = data.detailDescription;
  }
}

@Component({
  selector: 'aaos-unsubscribe',
  templateUrl: './unsubscribe.component.html',
  styleUrls: ['./unsubscribe.component.scss']
})
export class EmailPreferencesUnsubscribeComponent implements OnDestroy {
  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  queryParams: ParamMap;

  constructor(
    private route: ActivatedRoute,
    private service: EmailPreferencesService,
    private dialog: MatDialog
  ) {
    this.route.queryParamMap
      .pipe(
        filter(qp => Utilities.isDefined(qp)),
        take(1),
        switchMap((qp: ParamMap) => {
          this.queryParams = qp;
          const emailType = qp.get('emailtype');
          const email = qp.get('email');
          const id = qp.get('id');
          const path = this.route.snapshot.url[0].toString();
          if (path === RouteNames.UnsubscribeFromMarketo) {
            return this.service.unsubscribeFromMarketo(id, email);
          } else if (Utilities.isDefined(emailType) && path === RouteNames.UnsubscribeOnlyMarketo) {
            return this.service.unsubscribeByTypeOnlyMarketo(id, emailType, email);
          } else if (Utilities.isDefined(emailType)) {
            return this.service.unsubscribeByType(id, emailType, email);
          } else {
            return throwError(of({ message: 'Failed to unsubscribe, no email type present.' }));
          }
        }),
        tap((response: { result: ApiResponse; email: string; description: string; }) => {
          let data;
          if (response.result.hasUnsuccessfulResponse()) {
            const error = Utilities.arrayIsPopulated(response.result.unsuccessfulUpdateDetails)
              ? response.result.unsuccessfulUpdateDetails[0]
              : `Failed to unsubscribe from ${response.description}.`;
            data = {
              success: false,
              error
            };
          } else {
            const contact = this.queryParams.get('contact');
            const contactEmailLink = this.queryParams.get('contactemaillink');
            const contactEmail = this.queryParams.get('contactemail');
            const titleDescription = this.queryParams.get('titledescription');
            const detailDescription = this.queryParams.get('detaildescription');

            const success = response.result.unsuccessfulUpdate === 0;
            const { email, description } = response;
            data = {
              success,
              email,
              description,
              titleDescription,
              detailDescription,
              contact,
              contactEmailLink,
              contactEmail
            };
          }
          const path = this.route.snapshot.url[0].toString();
          if (path === RouteNames.UnsubscribeFromMarketo) {
            this.dialog.open(UnsubscribeMarketoConfirmModalComponent, {
              panelClass: 'amp-dialog',
              disableClose: true,
              autoFocus: false,
              data
            } as MatDialogConfig);
          } else {
            this.dialog.open(UnsubscribeConfirmModalComponent, {
              panelClass: 'amp-dialog',
              disableClose: true,
              autoFocus: false,
              data
            } as MatDialogConfig);
          }
          this.loading$.next(false);
        }),
        catchError((err: { message: string }) => {
          const data = {
            success: false,
            error: err.message
          };
          this.dialog.open(UnsubscribeConfirmModalComponent, {
            panelClass: 'amp-dialog',
            disableClose: true,
            autoFocus: false,
            data
          } as MatDialogConfig);
          this.loading$.next(false);

          return null;
        })
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.dialog.closeAll();
  }
}
