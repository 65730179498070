<nav class="navbar navbar-expand-md navbar-dark m-t-10">
  <button class="navbar-toggler hidden-sm-up" type="button" (click)="toggleMenu()" data-target="#navbarsDefault"
    aria-controls="navbarsDefault" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div [ngbCollapse]="isNavbarCollapsed" class="collapse navbar-collapse m-l-100" id="navbarsDefault">
    <ul class="navbar-nav mr-auto" *ngIf="globalNav" >
      <div *ngFor="let global of globalNav.PrimaryMenuAndSubmenu">
        <div *ngIf="(global.SubMenus !== null && global.SubMenus.SecondaryLinks.length > 0)">
          <li class="nav-item dropdown" ngbDropdown>
            <div class="nav-link dropdown-toggle" id="id01" ngbDropdownToggle>
              {{global.GlobalLink.Text}}</div>
            <div class="dropdown-menu" aria-labelledby="id01" ngbDropdownMenu>
              <div *ngFor="let subMenu of global.SubMenus.SecondaryLinks">
                <a class="dropdown-item" href="{{subMenu.Href}}" title="{{subMenu.Title}}">{{subMenu.Text}}</a>
              </div>
            </div>
          </li>
        </div>
        <div *ngIf="!(global.SubMenus !== null && global.SubMenus.SecondaryLinks.length > 0)">
          <li class="nav-item dropdown"><a class="nav-link" href={{global.GlobalLink.Href}}
              target={{global.GlobalLink.Target}}>
              {{global.GlobalLink.Text}} </a>
          </li>
        </div>
      </div>
    </ul>
  </div>
</nav>