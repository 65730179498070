import { Component, OnInit } from '@angular/core';
import { Customer, ApiResponse, CustomerCommunication } from '@shared/models/data';
import { UpdateUsernameService } from '@shared/services/update-username/update-username.service';
import { UserProfileService } from '@shared/services/api/user-profile.service';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UserLoginInformationPageBaseComponent } from '../user-login-information-page-base';
import { Observable } from 'rxjs';
import { FormFinishType } from '@shared/models/enums';
import { Constants } from '@shared/services/constants';
import { EmailPreferencesService } from '@shared/services/api';
import { SaveResult } from '@shared/models/contracts/saveResults';
import { FormFinishError } from '@shared/models/enums/form-finish';

@Component({
  selector: 'aaos-update-username',
  templateUrl: './update-username.component.html',
  styleUrls: ['./update-username.component.scss']
})
export class UpdateUsernameComponent extends UserLoginInformationPageBaseComponent implements OnInit {
  loading: boolean;
  form: FormGroup;
  submitting: boolean;
  newUsername = 'newUsername';
  currentUserName = 'currentUserName';
  email: CustomerCommunication;
  canSave: boolean = false;
  showEmailPreferencesLink: boolean = false;
  constructor(
    private updateUsernameService: UpdateUsernameService,
    userProfileService: UserProfileService,
    router: Router, dialog: MatDialog,
    private emailPreferencesService: EmailPreferencesService) {
    super(userProfileService, router, dialog);
    this.buildForm();
  }

  ngOnInit() {
    super.loadUser().subscribe(_ => {
      this.email = this.user.Communications.find(x => x.IsPrimary
        && x.CommunicationType.toLowerCase() === Constants.CommunicationType.email);
      const formControl = this.form.get(this.newUsername);
      formControl.setValue(this.email.EmailAddress);
      const currentUserNameControl = this.form.get(this.currentUserName);
      currentUserNameControl.setValue(this.user.CustomerProfile.UserName);
      this.canSave = this.email.EmailAddress !== this.user.CustomerProfile.UserName;
    });

    this.emailPreferencesService.canUserUpdateEmailPreferences().subscribe(result => {
      this.showEmailPreferencesLink = result;
    });
  }

  save(): Observable<SaveResult> {
    const newUsername = this.form.get(this.newUsername).value;
    return Observable.create(observer => {
      this.updateUsernameService.updateUsername(newUsername)
        .subscribe((apiResponse: ApiResponse) => {
          if (apiResponse && apiResponse.successful) {
            observer.next(new SaveResult(FormFinishType.Success, null));
          } else {
            observer.next(new SaveResult(FormFinishError.UsernameError, null));
          }
        }, error => {
          observer.next(new SaveResult(FormFinishError.UsernameError, null));
        });
    });
  }

  buildForm() {
    const newUsernamePasswordControl = new FormControl('', { validators: Validators.required });
    const currentUsernamePasswordControl = new FormControl('');
    this.form = new FormGroup({});
    this.form.addControl(this.newUsername, newUsernamePasswordControl);
    this.form.addControl(this.currentUserName, currentUsernamePasswordControl);
  }
}
