import { CustomerCommunication } from './CustomerCommunication';
import { Preference } from './preference';
import { CustomerAddressSnapshot } from '@shared/models/data/CustomerAddressSnapshot';
import { CustomerEmergencyContactSnapshot } from '@shared/models/data/CustomerEmergencyContactsSnapshot';
export class CustomerProfileSnapshot {


    constructor(id) {
        this.MasterCustomerId = id;
        this.Communications = [];
        this.Suffix = '';
        this.Credentials = '';
    }
    MasterCustomerId: string;
    SpecialtyArea: Preference[] = [];
    SpecialtyInterests: Preference[] = [];
    Biography: string;
    BirthDate: Date;
    BirthDateString: string;
    ProfileImageId: number;
    ProfileImageBase64String: string;
    Ethnicity: string;
    Gender: string;
    PracticeSpecializationPrimary: string;
    PracticeSpecializationSecondary: string;
    PracticeType: string;
    Npi: number;
    Communications: CustomerCommunication[] = [];
    Address: CustomerAddressSnapshot[] = [];
    EmergencyContact: CustomerEmergencyContactSnapshot[] = [];
    AddressId: number;
    AddressType: string;
    Address1: string;
    Address2: string;
    Address3: string;
    City: string;
    State: string;
    PostalCode: string;
    Country: string;
    CompanyName: string;
    JobTitle: string;
    IncludeInDirectoryFlag: boolean;
    IsNew: boolean;
    IsDeleted: boolean;
    Email: string;
    EmailType: string;
    Phone: string;
    Prefix: string;
    Suffix: string;
    Credentials: string;

    MobileOptin: string;

    WorkStatus: string;
    RetirementAge: number;
    BeginPracticeYear: number;
    LanguagesSpoken: string[] = [];
    AgeGroupsTreated: string[] = [];
    LevelOfSpecialization: string;

    ABOSRecertificationYear: string;
    ABOSYear: string;

    Identity: string;
    IdentityPronoun: string;

    PracticeSize: string;
}
