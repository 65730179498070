import { NgModule, APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AMPInterceptor } from '@shared/interceptors/http.interceptor';
import { NamePlateComponent } from './components/name-plate/name-plate.component';
import {
  SpecialtyFilterPipe,
  RequiredFieldPipe,
  EmailTopicFilterPipe,
  PreferenceValuePipe,
  CommunicationFilterPipe
} from '@shared/pipes';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatProgressSpinnerModule, MatDialogModule, MatCheckbox, MatCheckboxModule } from '@angular/material';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { HeaderComponent } from './components/header/header.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TooltipDialogComponent } from './components/tooltip-dialog/tooltip-dialog.component';
import { FooterComponent } from './components/footer/footer.component';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { FormConfirmgDialogComponent } from './components/form-confirm-dialog/form-confirm-dialog.component';
import { UpdateProfileModalSuccessComponent } from './components/modal-success/modal-success.component';
import { UpdateProfileModalFailComponent } from './components/modal-fail/modal-fail.component';
import { ImageUploaderComponent } from './components/image-uploader/image-uploader.component';
import { ProfileImageComponent } from './components/profile-image/profile-image.component';
import { DefaultErrorComponent } from './components/default-error/default-error.component';
import { FormFieldGroupComponent } from './form-fields/form-field-group/form-field-group.component';
import { TextFieldComponent } from './form-fields/text-field/text-field.component';
import { EmailFieldComponent } from './form-fields/email-field/email-field.component';
import { MatRadioModule, MatSelectModule } from '@angular/material';
import { PhoneFieldComponent } from './form-fields/phone-field/phone-field.component';
import { FormFieldTitleComponent } from './form-fields/form-field-title/form-field-title.component';
import { NgxMaskModule } from 'ngx-mask';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TextAreaFieldComponent } from './form-fields/text-area-field/text-area-field.component';
import { CheckBoxComponent } from './form-fields/check-box/check-box.component';
import { DropDownComponent } from './form-fields/drop-down/drop-down.component';
import { RadioButtonComponent } from './form-fields/radio-button/radio-button.component';
import { DateFieldComponent } from './form-fields/date-field/date-field.component';
import { AddressFieldComponent } from './form-fields/address-field/address-field.component';
import { WorkStatusFieldComponent } from './form-fields/work-status-field/work-status-field.component';
import { EmergencyContactFieldComponent } from './form-fields/emergency-contact-field/emergency-contact-field.component';
import { AttachmentsComponent } from './form-fields/attachments/attachments.component';
import { BiographyComponent } from './form-fields/biography/biography.component';
import { FileSelectorDirective } from './form-fields/attachments/file-selector.directive';
import { MasterCustomerIdComponent } from './form-fields/master-customer-id/master-customer-id.component';
import { SearchableSelectComponent } from './form-fields/searchable-select/searchable-select.component';
import { PhoneListComponent } from './form-fields/phone-list/phone-list.component';
import { EmailListComponent } from './form-fields/email-list/email-list.component';
import { AddressListComponent } from './form-fields/address-list/address-list.component';
import { EmergencyListComponent } from './form-fields/emergency-list/emergency-list.component';
import { WebMediaFieldComponent } from './form-fields/web-media-field/web-media-field.component';
import { WebMediaListComponent } from './form-fields/web-media-list/web-media-list.component';
import { GlobalNavComponent } from '../user-profile/global-nav/global-nav.component';
import { CellPhoneFieldComponent } from '@shared/form-fields/cell-phone-field/cell-phone-field.component';

const entryComponents = [
  FormConfirmgDialogComponent,
  UpdateProfileModalSuccessComponent,
  UpdateProfileModalFailComponent
];
const formFieldComponents = [
  FormFieldGroupComponent,
  TextFieldComponent,
  EmailFieldComponent,
  PhoneFieldComponent,
  FormFieldTitleComponent,
  TextAreaFieldComponent,
  CheckBoxComponent,
  DropDownComponent,
  MasterCustomerIdComponent,
  RadioButtonComponent,
  DateFieldComponent,
  AddressFieldComponent,
  WorkStatusFieldComponent,
  EmergencyContactFieldComponent,
  AttachmentsComponent,
  BiographyComponent,
  FileSelectorDirective,
  PhoneListComponent,
  GlobalNavComponent,
  CellPhoneFieldComponent
];

const components = [
  NamePlateComponent,
  SpinnerComponent,
  TooltipDialogComponent,
  HeaderComponent,
  FooterComponent,
  ImageUploaderComponent,
  ProfileImageComponent,
  DefaultErrorComponent,
  MasterCustomerIdComponent,
  SearchableSelectComponent,
  PhoneListComponent,
  EmailListComponent,
  AddressListComponent,
  EmergencyListComponent,
  WebMediaFieldComponent,
  WebMediaListComponent
];
const pipes = [
  SpecialtyFilterPipe,
  RequiredFieldPipe,
  EmailTopicFilterPipe,
  PreferenceValuePipe,
  CommunicationFilterPipe
];

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    MatDialogModule,
    RouterModule,
    MatIconModule,
    MatRadioModule,
    MatSelectModule,
    MatCheckboxModule,
    NgxMaskModule.forRoot(),
    NgbModule

  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AMPInterceptor,
      multi: true
    }
  ],
  declarations: [...components, ...entryComponents, ...pipes, ...formFieldComponents],
  entryComponents: [...entryComponents],
  exports: [...components, ...entryComponents, ...pipes, ...formFieldComponents]
})
export class SharedModule { }
