import { Injectable } from '@angular/core';
import { AaosBaseService } from './aaos-base.service';
import { LoggingService } from './logging.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { of, Observable, throwError } from 'rxjs';
import { EmailTopic, OptInInterestPreference, ApiResponse, CustomerSearchResult } from '@shared/models/data';
import { Constants } from '@shared/services/constants';
import { map, catchError } from 'rxjs/operators';
import { Utilities } from '@shared/services/utils';
import { upperFirst } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class EmailPreferencesService extends AaosBaseService {
  // BUG: workaround for https://github.com/angular/angular/pull/25033
  static ngInjectableDef = undefined;

  constructor(protected _h: HttpClient, protected _l: LoggingService) {
    super(_l, _h);
  }

  isAuthorizedCsr() {
    const qs = `${Constants.ApiParams.RedirectPath}=${window.location.pathname}`;
    const url = `${Constants.ApiUrls.IsAuthorizedCsr}?${qs}`;
    return this.http.get(url).pipe(
      map((result: { IsCsr: boolean }) => {
        return result.IsCsr;
      }),
      catchError((err, caught) => {
        return of(false);
      })
    );
  }

  getAllCategories(): Observable<{ categories: OptInInterestPreference[]; preferences: OptInInterestPreference[] }> {
    return this.http.get(`${Constants.ApiUrls.AllPreferences}`).pipe(
      map((result: string) => {
        const parsed: { Categories: OptInInterestPreference[]; Preferences: OptInInterestPreference[] } = JSON.parse(result);
        return { categories: parsed.Categories, preferences: parsed.Preferences };
      }),
      catchError(result => of(null))
    );
  }

  getCategoryByType(
    emailType: string
  ): Observable<{ categories: OptInInterestPreference[]; preferences: OptInInterestPreference[] }> {
    const upper = emailType.toUpperCase();
    return this.getAllCategories().pipe(
      map(_ => {
        const { categories, preferences } = _;
        const ix = categories.findIndex(c => c.PreferenceCode.toUpperCase() === upper);
        if (ix < 0) {
          return _;
        }
        const filteredCategories = [].concat(categories[ix]);
        const filteredPreferences: any = {};
        filteredPreferences[upper] = preferences[upper];
        return { categories: filteredCategories, preferences: filteredPreferences };
      }),
      catchError(result => of(null))
    );
  }

  getMarketoIdByEmail(email: string, lastName: string, isMember: boolean, firstName: string): Observable<CustomerSearchResult[]> {
    let url = `${Constants.ApiUrls.CustomerSearch}?${
      Constants.ApiParams.EmailAddress
      }=${email}`;
    if (Utilities.isDefined(lastName)) {
      url += `&${Constants.ApiParams.LastName}=${lastName}`;
    }
    if (Utilities.isDefined(firstName)) {
      url += `&${Constants.ApiParams.FirstName}=${firstName}`;
    }
    url += `&${Constants.ApiParams.AAOSUsersOnly}=${isMember}`;
    return this.http.get(url).pipe(
      catchError(err => {
        return throwError(err.error);
      }),
      map((result: CustomerSearchResult[]) => {
        return result;
      })
    );
  }

  getInterestAreas() {
    return this.http.get(Constants.ApiUrls.CustomerInterestAreas).pipe(
      map((result: string) => {
        const parsed = JSON.parse(result);
        return parsed;
      })
    );
  }

  getPreferencesByMarketoId(id: string, email: string) {
    const qsObj: any = {};
    qsObj[Constants.ApiParams.MarketoLeadId] = Utilities.isDefined(id) ? id : '';
    qsObj[Constants.ApiParams.MarketoEmail] = Utilities.isDefined(email) ? email : '';
    const qs = Object.keys(qsObj)
      .map(key => `${key}=${qsObj[key]}`)
      .join('&');
    return this.http.get(`${Constants.ApiUrls.CustomerOptInAreas}?${qs}`).pipe(
      catchError(result => {
        return of(null);
      }),
      map((result: string) => {
        const parsed = JSON.parse(result);
        return parsed;
      })
    );
  }

  updateInterestAreas(marketoId: string, optInAreas: any, email?: string) {
    let qs = `?${Constants.ApiParams.MarketoLeadId}=${marketoId}`;
    if (Utilities.isDefined(email)) {
      qs += `&${Constants.ApiParams.MarketoEmail}=${email}`;
    }
    const url = `${Constants.ApiUrls.UpdateInterestAreas}${qs}`;
    return this.http.post(url, optInAreas).pipe(
      map(result => {
        return result;
      })
    );
  }

  updateMemberInterestAreas(optInAreas: any, email?: string) {
    let qs = '';
    if (Utilities.isDefined(email)) {
      qs += `&${Constants.ApiParams.MarketoEmail}=${email}`;
    }
    const url = `${Constants.ApiUrls.UpdateMemberInterestAreas}${qs}`;
    return this.http.post(url, optInAreas).pipe(
      map(result => {
        return result;
      }),
      catchError(err => {
        return of(null);
      })
    );
  }

  canUserUpdateEmailPreferences(): Observable<boolean> {
    return this.http.get<boolean>(`${Constants.ApiUrls.CanUserUpdateEmailPreferences}`)
    .pipe(
      map(response => {
        return response;
      })
    );
  }

  unsubscribeByType(id: string, optInArea: string, email: string): any {
    const body = {
      [upperFirst(Constants.ApiParams.MarketoLeadId)]: id,
      [upperFirst(Constants.ApiParams.OptInArea)]: optInArea,
      [upperFirst(Constants.ApiParams.MarketoEmail)]: email
    };

    const url = `${Constants.ApiUrls.UnsubscribeByType}`;
    return this.http.post(url, body).pipe(
      map((response: { result: ApiResponse; email: string; description: string }) => {
        response.result = Object.assign(new ApiResponse(), response.result);
        return response;
      }),
      catchError((response: HttpErrorResponse) => {
        const message =
          response && response.error && response.error.error
            ? response.error.error
            : `Failed to unsubscribe from ${optInArea}`;
        return throwError({ message });
      })
    );
  }

  unsubscribeFromMarketo(id: string, email: string): any {
    const qsObj: any = {};
    qsObj[Constants.ApiParams.MarketoLeadId] = Utilities.isDefined(id) ? id : '';
    qsObj[Constants.ApiParams.MarketoEmail] = Utilities.isDefined(email) ? email : '';
    const qs = Object.keys(qsObj)
      .map(key => `${key}=${qsObj[key]}`)
      .join('&');
    return this.http.post(`${Constants.ApiUrls.UnsubscribeFromMarketo}?${qs}`, null).pipe(
      map((response: { result: ApiResponse; email: string; description: string }) => {
        response.result = Object.assign(new ApiResponse(), response.result);
        return response;
      }),
      catchError((response: HttpErrorResponse) => {
        const message =
          response && response.error && response.error.error
            ? response.error.error
            : `Failed to unsubscribe from marketo`;
        return throwError({ message });
      })
    );
  }

  unsubscribeByTypeOnlyMarketo(id: string, optInArea: string, email: string): any {
    const body = {
      [upperFirst(Constants.ApiParams.MarketoLeadId)]: id,
      [upperFirst(Constants.ApiParams.OptInArea)]: optInArea,
      [upperFirst(Constants.ApiParams.MarketoEmail)]: email
    };

    const url = `${Constants.ApiUrls.UnsubscribeByTypeOnlyMarketo}`;
    return this.http.post(url, body).pipe(
      map((response: { result: ApiResponse; email: string; description: string }) => {
        response.result = Object.assign(new ApiResponse(), response.result);
        return response;
      }),
      catchError((response: HttpErrorResponse) => {
        const message =
          response && response.error && response.error.error
            ? response.error.error
            : `Failed to unsubscribe from ${optInArea}`;
        return throwError({ message });
      })
    );
  }


  userIsMember(email: string): Observable<boolean> {
    let qs = '';
    if (Utilities.isDefined(email)) {
      qs += `?${Constants.ApiParams.EmailAddress}=${email}`;
    }
    const url = `${Constants.ApiUrls.UserIsMember}${qs}`;
    return this.http.get(url).pipe(
      map((response: { isMember: boolean }) => {
        return response.isMember;
      }),
      catchError((response: HttpErrorResponse) => {
        return of(false);
      })
    );
  }
}
