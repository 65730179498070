<div class="form-group" *ngIf="form" [formGroup]="form">
  <div class="title-deleteicon">
    <div class="deleteable-field-title-section" *ngIf="!isBlankAdditionalItem()">
      <aaos-form-field-title [editMode]="editMode" [field]="field"></aaos-form-field-title>
    </div>
    <div *ngIf="(editMode)" class="delete-button-wrapper">
      <div id="{{EmergencyFieldId}}" class="delete-file-button" (click)="deleteEmergencyContact($event.target.id)">
      </div>
    </div>
  </div>
  <aaos-text-field [form]="form" [editMode]="editMode" [field]="contactNameField">
  </aaos-text-field>
  <aaos-drop-down [form]="form" [editMode]="editMode" [field]="relationshipField" [formOptions]="formOptions">
  </aaos-drop-down>
  <aaos-text-field [form]="form" [editMode]="editMode" [field]="phoneNumberField">
  </aaos-text-field>
  <div *ngIf="(editMode)">
    <aaos-form-field-title [editMode]="editMode" [field]="phoneTypeField"></aaos-form-field-title>
    <mat-radio-group formControlName="cfd5c6eb-6ff7-4322-a867-d8312690bddc-phoneType" class="radio"
      [disabled]="disableRadioButton">
      <mat-radio-button *ngFor="let type of phoneTypeOptions" [value]="type.PreferenceCode"
        (change)="phoneTypeChanged($event.value)">
        {{ type.PreferenceDescription }}
      </mat-radio-button>
    </mat-radio-group>
  </div>
  <div *ngIf="!(editMode) && !isBlankAdditionalItem()">
    {{readOnlyPhoneType}}
  </div>
  <div *ngIf="(editMode && !fieldIsDisabled)">
    <aaos-form-field-title [editMode]="editMode" [field]="commentsField"></aaos-form-field-title>
    <textarea class="form-control width--50" type="text" formControlName="cfd5c6eb-6ff7-4322-a867-d8312690bddc-comments"
      placement="top"></textarea>
  </div>

</div>