import { NgModule, forwardRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserProfileComponent } from './user-profile.component';
import { UserProfileRoutingModule } from './routing.module';

import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from '@shared/shared.module';
import { ReactiveFormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatRadioModule, MatSelectModule } from '@angular/material';
import { TooltipDialogComponent } from '@shared/components/tooltip-dialog/tooltip-dialog.component';
import { NgxMaskModule } from 'ngx-mask';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UserProfileRoutableComponent } from './user-profile-routable/user-profile-routable.component';
import { UserProfileBaseComponent } from './user-profile-base.component';
import { RouterModule } from '@angular/router';
// tslint:disable-next-line: max-line-length
import { UserProfileRoutableSummaryComponent } from '../user-profile/user-profile-routable/user-profile-routable-summary/user-profile-routable-summary.component';
// tslint:disable-next-line: max-line-length
import { UserProfileRoutableEditComponent } from '../user-profile/user-profile-routable/user-profile-routable-edit/user-profile-routable-edit.component';
import { DynamicUserProfileEditComponent } from './dynamic-user-profile-edit/dynamic-user-profile-edit.component';
import { UserLoginInformationComponent } from './user-login-information/user-login-information.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { UpdateUsernameComponent } from './update-username/update-username.component';
import { PasswordCriteriaComponent } from './reset-password/password-criteria/password-criteria.component';
import { CandidateMemberModalComponent } from './candidate-member-modal/candidate-member-modal.component';
import { MembershipDroppedModalComponent } from './membership-dropped-modal/membership-dropped-modal.component';
import { NotificationModalComponent } from './notification-modal/notification-modal.component';

@NgModule({
  imports: [
    CommonModule,
    UserProfileRoutingModule,
    MatDialogModule,
    SharedModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatRadioModule,
    MatSelectModule,
    NgxMaskModule.forRoot(),
    NgbModule,
    RouterModule
  ],
  exports: [UserProfileComponent],
  // this is for accessing form values during unit testing
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UserProfileComponent),
      multi: true
    }
  ],
  declarations: [
    UserProfileComponent,
    UserProfileRoutableComponent,
    UserProfileRoutableSummaryComponent,
    UserProfileRoutableEditComponent,
    DynamicUserProfileEditComponent,
    UserLoginInformationComponent,
    ResetPasswordComponent,
    UpdateUsernameComponent,
    PasswordCriteriaComponent,
    MembershipDroppedModalComponent,
    CandidateMemberModalComponent,
    NotificationModalComponent
  ],

  entryComponents: [TooltipDialogComponent]
})
export class UserProfileModule { }
