﻿import { Injectable } from '@angular/core';
import { ValidatorFn, ValidationErrors, FormControl, AbstractControl, Validators, FormGroup } from '@angular/forms';
import { Utilities } from '@shared/services/utils';
import { FormField, FormFieldConstants, FormFieldRuleConstants, MemberGroupConstants } from '@shared/form-fields/models/form-field';
import { BaseMultipleOptionsFieldComponent } from '@shared/form-fields/BaseMultipleOptionsFieldComponent';
import { Constants } from '@shared/services/constants';
import { Preference } from '@shared/models/data';
import { BirthdayService } from '@shared/services/utils/birthday.service';

@Injectable({
    providedIn: 'root'
})
export class FormFieldValidatorsService {
    static allowedValuesPreferencesValidator(addressTypes: Preference[]): any {
        const validator: ValidatorFn = (control: FormControl): ValidationErrors | null => {
            if (control && control.value) {
                const match = addressTypes.filter(_ => control.value === _.PreferenceCode).length > 0;
                if (!match) {
                    return { required: true };
                }
            }
            return null;
        };
        return validator;
    }

    static getRetirementAgeValidator(AMPMemberGroupId: string): ValidatorFn {
        const memberGroupsWithRequiredRetireAge = [MemberGroupConstants.EmeritusId.toLowerCase()];
        const validator: ValidatorFn = (control: FormControl): ValidationErrors | null => {
            const AMPMemberGroupIdLower = AMPMemberGroupId ? AMPMemberGroupId.toLowerCase() : '';
            if (memberGroupsWithRequiredRetireAge.indexOf(AMPMemberGroupIdLower) >= 0) {
                if (!control.value) {
                    return { required: true };
                }
                const valueAsNumber = Number(control.value);
                if (isNaN(valueAsNumber) || valueAsNumber < 1) {
                    return { invalid: true };
                }
            }
            return null;
        };
        return validator;
    }
    static getZipCodeValidator(ruleId) {

        const validator: ValidatorFn = (control: FormControl): ValidationErrors | null => {
            if (control.value) {
                const zipCodeValidator = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
                const check = zipCodeValidator.test(control.value);
                return !check ? { pattern: true } : null;
            } else if (ruleId.toLowerCase() === FormFieldRuleConstants.OptionalEditableId.toLowerCase() && !control.value) {
                return null;
            } else {
                return null;
            }
        };
        return validator;

    }
    static yearNumberValidation(minYear: number): ValidatorFn {
        const validator: ValidatorFn = (control: FormControl): ValidationErrors | null => {
            if (control.value && control.value < minYear) {
                return { invalid: true };
            } else {
                return null;
            }
        };
        return validator;
    }

    constructor() { }

    showRequiredError(fieldName: string, field: FormField, form: AbstractControl) {
        if (form && field) {
            const control = form.get(fieldName);
            return control.hasError('required');
        } else { return false; }
    }
    showValidationError(fieldName: string, field: FormField, form: AbstractControl) {

        if (form && field) {
            const control = form.get(fieldName);
            const formHasRequiredError = this.showRequiredError(fieldName, field, form);
            return !formHasRequiredError && control.invalid;
        } else { return false; }

    }
    phoneNumberValidation(field: FormField): ValidatorFn {
        const validator: ValidatorFn = (control: FormControl): ValidationErrors | null => {
            if (control.value) {
                const nanpaValidation = /^([2-9][0-9][0-9])([2-9][0-9]{2})([0-9]{4})$/;
                const consecutiveOne = /^([2-9][0-9][0-9])([2-9][1][1])([0-9]{4})$/;
                const consecutive = /(\d)\1{9}/;
                const phoneNumber = control.value.toString().replace(/[^\d]/g, '');
                const check =
                    !nanpaValidation.test(phoneNumber) ||
                    consecutiveOne.test(phoneNumber) ||
                    consecutive.test(phoneNumber);
                return check ? { invalidPhoneNumber: true } : null;
            } else if (field.ruleId.toLowerCase() === FormFieldRuleConstants.OptionalEditableId.toLowerCase() && !control.value) {
                return null;
            } else {
                return null;
            }
        };
        return validator;
    }

    maxPhoneLengthValidator() {
        const validator: ValidatorFn = (control: FormControl): ValidationErrors | null => {
            if (control.value) {
                const phoneNumber = control.value.toString().replace(/[^\d]/g, '');
                const check = phoneNumber.length > 19;
                return check ? { invalidPhoneNumber: true } : null;
            }
            return null;
        };
        return validator;
    }

    requiredValidation(field: FormField): ValidatorFn {
        const validator: ValidatorFn = (control: FormControl): ValidationErrors | null => {
            if ((!control.value || control.value === Utilities.stringEmpty)
                && field.ruleId.toLowerCase() === FormFieldRuleConstants.RequiredEditableId.toLowerCase()) {
                return { required: true };
            }
            return null;
        };
        return validator;
    }

    maxOptionsLength(maxLength: number) {
        const validator: ValidatorFn = (control: FormControl): ValidationErrors | null => {
            if (control.value) {
                const value = BaseMultipleOptionsFieldComponent
                    .mapFromMultiSelectToCsv(control.value);
                const check = value.length > maxLength;
                return check ? { maxLength: true } : null;
            }
            return null;
        };
        return validator;
    }
    npiValidation(form: AbstractControl, field: FormField): ValidatorFn {
        const validator: ValidatorFn = (control: FormControl): ValidationErrors | null => {
            // always validate if they put in a value, only check required if user enters us address
            if (!Utilities.isUndefinedOrNull(control.value) && Utilities.stringEmpty !== control.value) {
                return Utilities.validateNpi(control.value.toString()) ? null : { invalidNpi: true };
            } else if (field.ruleId.toLowerCase() === FormFieldRuleConstants.RequiredEditableId.toLowerCase()) {
                return { required: true };
            }
            return null;
        };
        return validator;
    }
    getRuleBaseValidators(fieldRuleId: string, fieldTypeName: string): ValidatorFn[] {
        const validators = [];
        switch (fieldRuleId.toLowerCase()) {
            case FormFieldRuleConstants.RequiredEditableId.toLowerCase():
            case FormFieldRuleConstants.RequiredIfBlankId.toLowerCase():
                validators.push(Validators.required);
                break;
            default:
                break;
        }
        return validators;
    }
    checkDateValidation(): ValidatorFn {
      const validator: ValidatorFn = (control: FormControl): ValidationErrors | null => {
        return BirthdayService.validate(control.value);
      };
      return validator;
    }

    getFieldState(field: FormField) {
        return {
            value: null, // do not remove this line, if value is not set in the field state for some reason it ignores the disabled flag
            disabled: field.ruleId.toLowerCase() === FormFieldRuleConstants.DisplayOnlyId.toLowerCase()
        };
    }
    setErrorForEmailControlMatchesOtherAccount(form: AbstractControl) {
        form.setErrors({ emailAdressMatchesToAnotherAccount: true });
    }
    setErrorForEmailControlMatchesToSameCustomer(form: AbstractControl) {
        form.setErrors({ emailAdressMatchesToSameCustomer: true });
    }
    setErrorForPhoneControlMatchesAnotherAccount(form: AbstractControl) {
        form.setErrors({ phoneNumberMatchesToAnotherAccount: true });
    }
    setErrorForPhoneControlMatchesSameCustomer(form: AbstractControl) {
        form.setErrors({ phoneNumberMatchesToSameCustomer: true });
    }

    setErrorForEmailControl(form: AbstractControl) {
        form.setErrors({ emailAdressMatchesToExisting: true });
    }

    private getPrimaryAddressCountryControl(abstractControl: AbstractControl): AbstractControl {
        const countryCodeControl = null;
        if (abstractControl instanceof FormGroup) {
            const group = abstractControl as FormGroup;
            const keys = Object.keys(group.controls);
            // tslint:disable-next-line: prefer-for-of
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                if (key === FormFieldConstants.AddressId) {
                    return this.getPrimaryAddressControlFromAddressListGroup(group.controls[key]);
                } else if (group.controls[key] instanceof FormGroup) {
                    const value = this.getPrimaryAddressCountryControl(group.controls[key]); // recursive call for form groups
                    if (value) {
                        return value;
                    }
                }
            }
        }
        return null;
    }
    getPrimaryAddressControlFromAddressListGroup(abstractControl: AbstractControl): AbstractControl {
        const addressListFormGroup = abstractControl as FormGroup;
        const keys = Object.keys(addressListFormGroup.controls);
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            const addressFormGroupControl = addressListFormGroup.controls[key];
            if (addressFormGroupControl) {
                const addressFormGroup = addressFormGroupControl as FormGroup;
                const isPrimaryField = addressFormGroup.controls[Constants.AdditionalFieldConstants.primaryflag];
                if (isPrimaryField && isPrimaryField.value === true) {
                    const countryCodeControl = addressFormGroup.controls[Constants.FormSubFieldNameConstants.countryCode];
                    return countryCodeControl;
                }
            }
        }
        return null;
    }
}
