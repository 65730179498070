export class AddressField {
    ActiveFlag: boolean;
    LabelFlag: boolean;
    RequiredFlag: boolean;
    ScreenFlag: boolean;
    LineNumber: number;
    MaxLength: number;
    Position: number;
    Caption: string;
    CountryCode: string;
    Delimiter: string;
    FieldName: string;
}
