export class AddressDetail {
    CompanyName: string;
    Address1: string;
    Address2: string;
    Address3: string;
    City: string;
    State: string;
    Country: string;
    PostalCode: string;
    CountryCode: string;
    AddressTypeCode: string;
    AddressId: number;
    IsPrimaryAddress: boolean;
    BillToFlag: boolean;
    ShipToFlag: boolean;
    PrioritySequence: number;
    StatusCode: string;
    JobTitle: string;
    IncludeInDirectoryFlag: boolean;
}
