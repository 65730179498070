import { Injectable, Inject } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { DOCUMENT } from '@angular/platform-browser';
import { Utilities } from '@shared/services/utils';
import { Constants } from '@shared/services/constants';

@Injectable()
export class ClearCookiesGuard implements CanActivate {
  constructor(@Inject(DOCUMENT) private document: any) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const cookies = this._getCookies();
    if (Utilities.isDefined(cookies)) {
      cookies.forEach((cookie: string, ix: number) => {
        if (this._isAuthCookie(cookie)) {
          cookies[ix] = null;
        }
      });
    }
    return true;
  }

  private _isAuthCookie(cookie: string) {
    return (
      cookie
        .toLowerCase()
        .trim()
        .split('=')[0] === Constants.Cookies.Auth.toLowerCase()
    );
  }

  private _getCookies() {
    return this.document.cookie && this.document.cookie !== Utilities.stringEmpty
      ? document.cookie.split(';')
      : null;
  }
}
