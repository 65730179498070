import { Component, OnInit, ViewChild, ElementRef, OnDestroy, AfterViewInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { EmailPreferencesService } from '@shared/services/api';
import { Utilities } from '@shared/services/utils';
import {
  take,
  tap,
  switchMap,
  map,
  filter,
  debounceTime,
  takeUntil,
  catchError
} from 'rxjs/operators';
import { BehaviorSubject, fromEvent, Subject, merge, of, combineLatest } from 'rxjs';
import { MemberType } from './logic.service';
import { EmailPreferencesSearchLogicService } from './logic.service';
import { RouteNames } from '../../routing/route-utils';
import { LoggedInGuard } from '@shared/guards';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerSearchResult } from '@shared/models/data';
import { ApplicationTypesService } from '@shared/services/application-types/application-types.service';
import { ApplicationFlowParameterCacheService } from '@shared/services/application-flow/application-flow-parameter-cache.service';

@Component({
  selector: 'aaos-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class EmailPreferencesSearchComponent implements OnInit, OnDestroy {
  @ViewChild('emailInput') emailRef: ElementRef;
  @ViewChild('nameInput') nameRef: ElementRef;
  address = 'address';
  lastName = 'lastName';
  firstName = 'firstName';
  memberType = 'memberType';
  member = 'member';
  nonmember = 'nonmember';
  selectedApplication = 'selectedApplication';
  userRoute = '/user';
  defaultApplication = 'default';
  emailForm: FormGroup = new FormGroup({
    address: new FormControl(''),
    lastName: new FormControl('', [Validators.required]),
    memberType: new FormControl(this.member),
    firstName: new FormControl(''),
    selectedApplication: new FormControl('')
  });
  error: string;
  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  spinnerCaption: string;
  memberTypes: MemberType[];
  userNotFound: boolean;
  searchResults: CustomerSearchResult[] = [];
  emailPreferencesLink: string = RouteNames.EmailPreferences;
  userLink: string = RouteNames.User;
  authorized$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  private _destroy$: Subject<void> = new Subject<void>();
  applicationTypes: string[];
  displayedColumns: string[] =
    ['name',
      'masterCustomerId',
      'memberGroup',
      'city',
      'state',
      'country',
      'editEmailPreferences',
      'editProfile'
    ];

  constructor(
    private service: EmailPreferencesService,
    private logic: EmailPreferencesSearchLogicService,
    private auth: LoggedInGuard,
    private route: ActivatedRoute,
    private router: Router,
    private applicationTypesService: ApplicationTypesService,
    private flowParameterCache: ApplicationFlowParameterCacheService
  ) {
    this.memberTypes = [...this.logic.MEMBER_TYPES];
    this.flowParameterCache.clearApplicationType();
  }

  ngOnInit() {
    of(true)
      .pipe(
        switchMap(_ => {
          this.loading$.next(true);
          return this.auth.canActivate(this.route.snapshot, null);
        }),
        switchMap(_ => {
          return !!_ ? this.service.isAuthorizedCsr() : of(null);
        }),
        take(1),
        tap(result => {
          this.authorized$.next(!!result);
          this.loading$.next(false);
        })
      )
      .subscribe();

    this.applicationTypesService.getApplicationTypes().subscribe(_ => {
      this.applicationTypes = _;
    });

    this.emailForm.get(this.memberType).valueChanges.subscribe(_ => { this.updateFormValidation(_); });

  }
  updateFormValidation(memberTypeValue: any) {
    const emailAddressField = this.emailForm.get(this.address);
    if (memberTypeValue === this.nonmember) {
      emailAddressField.setValidators([Validators.required]);
    } else {
      emailAddressField.setValidators([]);
    }
    emailAddressField.updateValueAndValidity();
  }
  ngOnDestroy() {
    this._destroy$.next();
  }

  handleKeyUp(event: KeyboardEvent): void {
    of(event)
      .pipe(
        debounceTime(200),
        filter(evt => evt.keyCode === 13),
        take(1),
        switchMap(e => this.loading$.pipe(take(1))),
        map(loading => {
          if (this.emailForm.valid && !loading) {
            this.handleSearch();
          }
        })
      )
      .subscribe();
  }

  handleSearch() {
    if (!this.emailForm.valid) {
      return;
    }
    this.error = null;
    this.userNotFound = false;
    const address = this.emailForm.get(this.address).value;
    const lastName = this.emailForm.get(this.lastName).value;
    const firstName = this.emailForm.get(this.firstName).value;

    const isMember = this.emailForm.get(this.memberType).value === this.member;
    this.spinnerCaption = 'Looking up email address';
    this.loading$.next(true);
    this.flowParameterCache.clearApplicationType();
    this.service
      .getMarketoIdByEmail(address, lastName, isMember, firstName)
      .pipe(
        take(1),
        catchError(err => {
          this.searchResults = [];
          this.error = err.error;
          return of(null);
        }),
        tap((users: CustomerSearchResult[]) => {
          if (Utilities.isDefined(users) && users.length > 0) {
            this.searchResults = users;
          } else {
            this.userNotFound = true;
          }
          this.loading$.next(false);
        })
      )
      .subscribe();
  }
  goToUserProfile(customer: CustomerSearchResult) {
    const applicationType = this.emailForm.get(this.selectedApplication).value;
    this.router.navigate([this.userRoute], {
      queryParams: {
        impersonateMasterCustomerId: customer.masterCustomerId,
        'application-type': applicationType !== null ? applicationType : this.defaultApplication
      }
    });
  }
}
