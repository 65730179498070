import { Subscription } from 'rxjs';

abstract class ApiUrls {
  public static ImpersonateMasterCustomerId = 'impersonateMasterCustomerId';

  public static readonly CustomerOptInAreas: string = '/api/dataservice/customeroptininterestareas';
  public static readonly CustomerSearch: string = '/api/dataservice/customer/search';
  public static readonly UpdateProfileDetailed: string = '/api/dataservice/updateprofiledetailed';
  public static readonly CustomerProfileDetailed: string = '/api/dataservice/profiledetailed';
  public static readonly UpdatePreferencesAndAreas: string =
    '/api/dataservice/updatepreferencesandareas';
  public static readonly ProfileFormOptions: string = '/api/dataservice/profileformoptions';
  public static readonly GetStates: string = '/api/dataservice/getstates';
  public static readonly ProfileScore: string = '/api/dataservice/profilescore';
  public static readonly CustomerCommunications: string = '/api/dataservice/customercommunications';
  public static readonly GetAddressStructure: string = 'api/dataservice/getaddressstructure';
  public static readonly GetPhoneNumberStructure: string = 'api/dataservice/getphonenumberstructure';
  public static readonly UpdateCustomerCommunication: string =
    'api/dataservice/updatecustomercommunications';
  public static readonly CreateCustomerCommunication: string =
    'api/dataservice/createcustomercommunications';
  public static readonly RemoveCustomerCommunication: string =
    'api/dataservice/removecustomercommunications';
  public static readonly CreateCustomerAddress: string = 'api/dataservice/createcustomeraddress';
  public static readonly RemoveCustomerAddress: string = 'api/dataservice/removecustomeraddress';
  public static readonly UpdateCustomerAddress: string = 'api/dataservice/updatecustomeraddress';
  public static readonly CreateCustomerEmergencyContact: string = 'api/dataservice/createcustomeremergencycontacts';
  public static readonly UpdateCustomerEmergencyContact: string = 'api/dataservice/updatecustomeremergencycontacts';
  public static readonly RemoveCustomerEmergencyContact: string = 'api/dataservice/removecustomeremergencycontacts';
  public static readonly CustomerInterestAreas: string = 'api/dataservice/optininterestareas';
  public static readonly AllPreferences: string = 'api/dataservice/optinareadetails';
  public static readonly IsAuthorizedCsr: string = 'api/dataservice/authorizedformarketo';
  public static readonly UpdateInterestAreas: string =
    'api/dataservice/updatecustomeroptininterestareas';
  public static readonly UpdateInterestAreasOnlyMarketo: string =
    'api/dataservice/updatecustomeroptininterestareasonlymarketo';
  public static readonly UpdateMemberInterestAreas: string =
    'api/dataservice/updatemembercustomeroptininterestareas';
  public static readonly CanUserUpdateEmailPreferences: string =
    'api/dataservice/canuserupdateemailpreferences';
  public static readonly UnsubscribeByType: string = '/api/dataservice/unsubscribeoptinareabytype';
  public static readonly UnsubscribeByTypeOnlyMarketo: string = '/api/dataservice/unsubscribeoptinareabytypeonlymarketo';
  public static readonly UnsubscribeFromMarketo: string = '/api/dataservice/unsubscribefrommarketo';
  public static readonly UserIsMember: string = '/api/dataservice/ismember';
  public static readonly BuildPlaceholderImage: string = '/api/dataservice/buildplaceholderimage';
  public static readonly BiographyAttachment = 'api/dataservice/biography/attachment';
  public static readonly BiographyAttachmentDownload = 'api/dataservice/biography/attachment';
  public static readonly HasNonPaymentDues = 'api/dataservice/hasnonpaymentdues';
  public static readonly EncryptString = 'api/dataservice/encrypt';

  public static readonly MembershipFormConfiguration = '/api/membership/formconfiguration';
  public static readonly MAAPFormConfiguration = 'api/membership/formconfiguration/applicationTypeOnly';
  public static readonly AllowedRedirectUrls = '/api/allowedRedirectUrls';
  public static readonly UpdateCustomerPassword = '/api/customer/password';
  public static readonly UpdateUsername = '/api/customer/username';
  public static readonly AnnualMeetingRedirect = '/api/annualMeetingRedirect';
  public static readonly CommunicationInUse: string = '/api/dataservice/communicationinuse';
  public static readonly CustomerAddress: string = '/api/dataservice/customeraddress';
  public static readonly ApplicationTypes = '/api/membership/applicationTypes';
  public static readonly GlobalNavigation = 'https://aaoscdndev01.azureedge.net/aaosepi-globalnav/globalNav.json';
  public static readonly GlobalNavigationSetting = '/api/appSettings';
  public static readonly ModalSettings = '/api/appSettings/modalsettings';
}

abstract class ApiParams {
  public static readonly ApiKey: string = 'apikey';
  public static readonly CustomerId: string = 'customerId';
  public static readonly CountryCode: string = 'countryCode';
  public static readonly PersonifyCountryCode: string = 'personifyCountryCode';
  public static readonly RedirectPath: string = 'redirectPath';
  public static readonly CommunicationType: string = 'communicationType';
  public static readonly EmailAddress: string = 'emailAddress';
  public static readonly MarketoLeadId: string = 'marketoLeadId';
  public static readonly MarketoEmail: string = 'marketoEmail';
  public static readonly LastName: string = 'lastName';
  public static readonly OptInArea: string = 'optInArea';
  public static readonly AAOSUsersOnly: string = 'aaosUsersOnly';
  public static readonly ApplicationType: string = 'application-type';
  public static readonly DebugKey: string = 'debug-key';
  public static readonly HideHeaderFooter: string = 'hide-header-and-footer';
  public static readonly FirstName: string = 'firstName';
  public static readonly FileName: string = 'fileName';
  public static readonly CustomerRelatedDocumentId: string = 'customerRelatedDocumentId';
  public static readonly ValidCurrentDisclosure: string = 'showOnlyValidCurrentDisclosure';
}


abstract class Cookies {
  public static readonly Auth: string = '.AspNetCore.Cookies';
  public static readonly SsoAuthStatus: string = 'SsoAuthStatus';
}
abstract class KeyValues {
  public static readonly DebugKeyValue: string = 'RXPnK';
  public static readonly BaseValue: string = 'data:application;base64,';
}

abstract class CustomerMemberGroup {
  public static readonly Resident: string = 'Resident';
  public static readonly Candidate: string = 'Candidate';
  public static readonly ActiveFellow: string = 'Active Fellow';
  public static readonly Emeritus: string = 'Emeritus';
  public static readonly Member: string = 'AAOS Member';
  public static readonly NonMember: string = 'Not a Member';
}

abstract class DateFilterTypes {
  public static readonly Empty: string = '<Date Filter>';
  public static readonly None: string = 'None';
  public static readonly Day: string = 'Today';
  public static readonly Week: string = 'Last 7 Days';
  public static readonly Month: string = 'Last 30 Days';
  public static readonly BiMonth: string = 'Last 60 Days';
  public static readonly Quarter: string = 'Last 90 Days';
  public static readonly SemiAnnual: string = 'Last 6 Months';
  public static readonly Annual: string = 'Last Year';
}

abstract class EmailTopicTypes {
  public static readonly AnnualMeeting: string = 'annualMeeting';
  public static readonly Education: string = 'education';
  public static readonly JAAOSCommunications: string = 'jaaosCommunications';
  public static readonly Newslettters: string = 'newsletters';
  public static readonly GovernmentRelations: string = 'governmentRelations';
  public static readonly OnlineLearning: string = 'onlineLearning';
  public static readonly PracticeManagement: string = 'practiceManagement';
  public static readonly StateSociety: string = 'stateSociety';
}
abstract class FormSubFieldNameConstants {
  public static readonly emailAddress = 'emailAddress';
  public static readonly emailType = 'emailType';
  public static readonly countryCode = 'countryCode'; // E.g US, or YEM
  public static readonly countryCodeNumber = 'countryCodeName'; // eg 1 or 49
  public static readonly phoneNumber = 'phoneNumber';
  public static readonly phoneExtension = 'phoneExtension';
  public static readonly doNotCallFlag = 'doNotCallFlag';
  public static readonly mobileOptinFlag = 'mobileOptinFlag';
  public static readonly phoneIncludeInDirectoryFlag = 'phoneIncludeInDirectoryFlag';
  public static readonly addressIncludeInDirectoryFlag = 'addressIncludeInDirectoryFlag';
  public static readonly webIncludeInDirectoryFlag = 'webIncludeInDirectoryFlag';
  public static readonly phoneType = 'phoneType';
  public static readonly addressLine1 = 'address1';
  public static readonly addressLine2 = 'address2';
  public static readonly addressLine3 = 'address3';
  public static readonly city = 'city';
  public static readonly state = 'state';
  public static readonly postalCode = 'postalcode';
  public static readonly biographyText = 'biography-text';
  public static readonly biographyAttachments = 'biography-attachments';
  public static readonly addressType = 'addressType';
  public static readonly organization = 'organizationName';
  public static readonly jobTitle = 'jobTitle';
  public static readonly workStatusStatus = 'status';
  public static readonly retirementAge = 'retirementAge';
  public static readonly webSiteName = 'webSiteName';
  public static readonly webSiteAddress = 'webSiteAddress';
  public static readonly emergencyContactId = 'emergencyContactId';
  public static readonly contactName = 'contactName';
  public static readonly relationship = 'relationship';
  public static readonly comments = 'comments';


}
abstract class FieldName {
  public static readonly Address = 'Mailing Address';
  public static readonly Email = 'Email';
  public static readonly Phone = 'Phone';
  public static readonly Mobile = 'Cell Phone';
  public static readonly ContactName = 'Contact Name';
  public static readonly Relationship = 'Relationship';
  public static readonly PhoneNumber = 'Phone Number';
  public static readonly Comments = 'Comments';
  public static readonly PhoneType = 'Phone Type';
  public static readonly WebSiteName = 'Web Site Name';
  public static readonly WebSiteAddress = 'Address';
  public static readonly WebSocialMedia = 'Web/Social Links';
  public static readonly Primary = 'Primary';
}

abstract class BlockNames {
  public static readonly EmergencyContacts = 'Emergency Contacts';
}
abstract class AdditionalFieldConstants {
  public static readonly id = 'Id';
  public static readonly primaryflag = 'primaryflag';
  public static readonly newflag = 'newflag';
  public static readonly deletedflag = 'deletedflag';
  public static readonly Emergency = 'Emergency';
}
abstract class CommunicationTypeConstants {
  public static readonly phone = 'phone';
  public static readonly email = 'email';
}

abstract class CountryConstants {
  public static readonly USA = 'USA';
  public static readonly Canada = 'CAN';

}
abstract class Errors {
  public static readonly DefaultMessage: string = 'An error has occurred';
  public static NullResponse(expected: string, url: string) {
    return `Expected ${expected}, but received null response received from ${url}`;
  }
}

abstract class IframeParameters {
  public static readonly Theme: string = 'theme';
  public static readonly Flow: string = 'flow';
}

abstract class LocationTypes {
  public static readonly Work: string = 'WORK';
  public static readonly Home: string = 'HOME';
  public static readonly Mobile: string = 'MOBILE';
}

abstract class LogLevel {
  public static readonly Empty: string = '<Log Level>';
  public static readonly Trace: string = 'Trace';
  public static readonly Debug: string = 'Debug';
  public static readonly Info: string = 'Info';
  public static readonly Warn: string = 'Warn';
  public static readonly Error: string = 'Error';
  public static readonly Silent: string = 'Silent';
}

abstract class NonMemberDisabledTopics {
  public static readonly AdvocacyNow: string = 'Advocacy Now';
  public static readonly HeadlineNews: string = 'Headline News Now';
  public static readonly ResidentNewsletter: string = `Residents Newsletter`;
  public static readonly MemberAdvantageProgram: string = 'Member Advantage Programs';
}

abstract class Observables {
  public static readonly Closed: keyof Subscription = 'closed';
}

abstract class QueryParams {
  public static readonly RedirectUri: string = 'Redirect';
  public static readonly AnnualUpdate: string = 'AnnualUpdate';
}

abstract class Specialty {
  public static readonly General = 'GENERALORTHOSURGERY';
}

abstract class UserProfile {
  public static readonly Success: string = 'success';
  public static readonly Error: string = 'error';
  public static readonly SuccessCssClass: string = 'success';
  public static readonly ErrorCssClass: string = 'error';
  public static readonly Dismiss: string = 'Dismiss';
  public static readonly OnSuccessfulUpdate: string = 'Saved';
  public static readonly OnFailedUpdate: string = 'Failed to update';
  public static readonly SpecialtyAreaCode: string = 'subspecialty';
  public static readonly SpecialtyInterestsCode: string = 'areasofinterest';
  public static readonly DefaultCountry: string = 'USA';
  public static readonly NPIPrefix_USA: number = 80840;
  public static readonly NPIPrefixTotal_USA: number = 24;
}
abstract class ApplicationTypes {
  public static readonly Default = 'default';
  public static readonly AnnualMeeting = 'Annual Meeting';
  public static readonly OVT = 'OVT';
  public static readonly MAAP = 'MAAP';
}

export class UserProfileFormOptions {
  public static readonly Specialty: string = 'Specialty';
  public static readonly Subspecialty: string = 'Subspecialty';

  public static readonly AreasOfInterest: string = 'AreasOfInterest';
  public static readonly AreaOfInterest: string = 'AreaOfInterest'; // yes these two are different from one another


  public static readonly ApplicationCountries: string = 'ApplicationCountries';
  public static readonly ApplicationStates: string = 'ApplicationStates';
  public static readonly AddressTypes: string = 'AddressTypes';
  public static readonly Ethnicities: string = 'Ethnicities';
  public static readonly Genders: string = 'Genders';
  public static readonly Identities: string = 'Orientations';
  public static readonly IdentityPronouns: string = 'Pronouns';
  public static readonly PracticeTypes: string = 'PracticeTypes';
  public static readonly CommunicationTypes: string = 'CommunicationTypes';
  public static readonly CommunicationLocations: string = 'CommunicationLocations';
  public static readonly Prefixes: string = 'Prefixes';
  public static readonly Suffixes: string = 'Suffixes';
  public static readonly PracticeSpecializationsPrimary: string = 'PracticeSpecializationsPrimary';
  public static readonly PracticeSpecializationsSecondary: string =
    'PracticeSpecializationsSecondary';
  public static readonly PracticeSize: string = 'PracticeSize';
  public static readonly Credentials: string = 'Credentials';
  public static readonly AllCredentials: string = 'ActiveCredentials';
  public static readonly RelatedDocumentTypes: string = 'RelatedDocumentTypes';
  public static readonly RelationshipCodes: string = 'RelationshipCodes';
  public static readonly Languages: string = 'Languages';
  public static readonly SpecialtyDegrees: string = 'SpecialtyDegrees';
  public static readonly WorkStatuses: string = 'WorkStatuses';
  public static readonly AgeGroups: string = 'AgeGroups';
  public static readonly MembersWithNAOption: string[] = [
    '2DO',
    'A',
    'X',
    '2',
    'PA',
    '2I',
    'B',
    'B8',
    'H'
  ];
}

abstract class CommunicationType {
  public static readonly email: string = 'email';
  public static readonly phone: string = 'phone';
  public static readonly mobile: string = 'mobile';
  public static readonly web: string = 'web';
  public static readonly defaultCountryCode: string = 'USA';
  public static readonly canadaCode = 'CAN';
}

abstract class Warnings {
  public static readonly PrimaryEmailChangesUserName = 'By updating your email address you are also updating your username';

}

abstract class AddressStatusCodes {
  public static readonly Bad = 'BAD';
  public static readonly Good = 'GOOD';
}
abstract class Codes {
  public static readonly Work = 'WORK';
  public static readonly Home = 'HOME';
  public static readonly Home2 = 'HOME2';
  public static readonly Work2 = 'WORK2';

}

abstract class Credentials {
  public static readonly FAAOSCode = 'FAAOS';
}

abstract class ButtonText {
  public static readonly Submit = 'SUBMIT';
  public static readonly SaveAndContinue = 'SAVE & CONTINUE';
  public static readonly Save = 'SAVE';
}

export const Constants = Object.freeze({
  ApiUrls,
  ApiParams,
  Cookies,
  Credentials,
  CustomerMemberGroup,
  DateFilterTypes,
  EmailTopicTypes,
  Errors,
  IframeParameters,
  LocationTypes,
  LogLevel,
  NonMemberDisabledTopics,
  Observables,
  QueryParams,
  Specialty,
  UserProfile,
  UserProfileFormOptions,
  ApplicationTypes,
  CommunicationType,
  FormSubFieldNameConstants,
  AdditionalFieldConstants,
  Warnings,
  AddressStatusCodes,
  CountryConstants,
  Codes,
  FieldName,
  ButtonText,
  KeyValues,
  BlockNames
});
