import { OnInit, Input } from '@angular/core';

import { FormField, FormFieldRuleConstants, ReadonlyConstants, FormFieldConstants } from '@shared/form-fields/models/form-field';

import { AbstractControl } from '@angular/forms';
import { Preference, ProfileFormOption } from '@shared/models/data';
import { Subject } from 'rxjs';
import { Constants } from '@shared/services/constants';

export abstract class BaseFieldComponent implements OnInit {
    @Input()
    field: FormField;
    @Input()
    form: AbstractControl;
    @Input()
    formOptions: ProfileFormOption[] = [];
    @Input()
    editMode: boolean;
    @Input()
    refreshEditState$: Subject<void>;
    fieldIsDisabled: boolean;
    isReadonly: boolean;
    @Input()
    initialFocusField;
    public static getPrefixForFieldAndOption(fieldId: string, option: Preference) {
        return `{{${fieldId}}}{{${option.PreferenceCode}}}{{${option.PreferenceSubCode}}}`;
    }
    public static parsePrefixForFieldAndOption(s: string): any {
        const splitStrings = s.split('}}');

        return {
            fieldId: splitStrings[0].replace('{{', ''),
            PreferenceCode: splitStrings[1].replace('{{', ''),
            PreferenceSubCode: splitStrings[2].replace('{{', '')
        };
    }


    public static mapFromMultiSelectToPreference(formValue: string[], sourcePreferences: Preference[]): Preference[] {
        return formValue
            .map(value => {
                const substrings = value.split('}}');
                const preferenceCode = substrings[1].replace('{{', '');
                const preferenceSubCode = substrings[2].replace('{{', '');

                return sourcePreferences.find(
                    _ => _.PreferenceCode.toLowerCase() === preferenceCode.toLowerCase() && _.PreferenceSubCode === preferenceSubCode
                );
            })
            .filter(_ => !!_);
    }


    ngOnInit() {
        if (this.field != null) {
            this.fieldIsDisabled = this.field.ruleId.toLowerCase() === FormFieldRuleConstants.DisplayOnlyId.toLowerCase();
            if (this.field.ruleId.toLowerCase() === FormFieldRuleConstants.RequiredIfBlankId.toLowerCase()
                && this.fieldValueIsNotBlank()) {
                this.formGroupField.setValidators([]);
                this.isReadonly = true;
            }
            if (this.field.ruleId.toLocaleLowerCase() === FormFieldRuleConstants.ReadOnlyIfNotBlankId.toLocaleLowerCase()) {
                if (this.fieldValueIsNotBlank()) {
                    this.isReadonly = true;
                } else {
                    this.fieldIsDisabled = true;
                }
            }
        }
    }
    fieldValueIsNotBlank(): boolean {
        const formGroupField = this.formGroupField;
        return formGroupField
            && formGroupField.value
            && formGroupField.value !== '0'
            && formGroupField.valid;
    }
    showRequiredError() {
        if (this.form && this.field) {
            const control = this.formGroupField;
            if (control) {
                return control.hasError('required');
            }
        }
    }

    public get hideFieldInReadonly(): boolean {
        if (this.field) {
            return ReadonlyConstants.FieldsHiddenInReadonlyMode
                .findIndex(_ => _.toLowerCase() === this.field.fieldId.toLocaleLowerCase()) > -1;
        }
        return false;
    }

    public get formGroupField(): AbstractControl {
        if (this.form && this.field) {
            return this.form.get(this.field.fieldId);
        }
        return null;
    }
    createFormFieldForSubField(subFieldName: string): FormField {
            const field = new FormField();
            if (this.field != null) {
            field.fieldId = `${this.field.fieldId}-${subFieldName}`;
            field.fieldName = `${this.field.fieldName}`;
            field.fieldTypeId = `${this.field.fieldTypeId}-${subFieldName}`;
            field.ruleName = `${this.field.ruleName}`;
            field.ruleId = `${this.field.ruleId}`;
        }
            return field;
    }

    getPrefix(option: Preference): string {
        return BaseFieldComponent.getPrefixForFieldAndOption(this.field.fieldId, option);
    }

}
