import { Component, OnInit, Input } from '@angular/core';
import { BaseFieldComponent } from '@shared/form-fields/baseFieldComponent';
import { Customer } from '@shared/models/data';
import { FormGroup, FormControl, AbstractControl } from '@angular/forms';
import { FormFieldValidatorsService } from '@shared/form-fields/form-field-validators.service';
import { Constants } from '@shared/services/constants';
import { BaseFieldListComponent } from '@shared/form-fields/baseFieldListComponent';
import { Subject } from 'rxjs';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { FormFieldRuleConstants } from '@shared/form-fields/models/form-field';

@Component({
  selector: 'aaos-web-media-list',
  templateUrl: './web-media-list.component.html',
  styleUrls: ['./web-media-list.component.scss']
})
export class WebMediaListComponent extends BaseFieldListComponent implements OnInit {
  @Input()
  user: Customer;
  @Input()
  editMode: boolean;
  formGroupsForWebMedias: FormGroup[] = [];
  showAddWebButton: boolean;
  selectedWebSiteNames: string[];
   constructor(formFieldValidatorsService: FormFieldValidatorsService) {
    super(formFieldValidatorsService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.buildSelectedWebSiteNames();
  }
  buildSelectedWebSiteNames() {
    this.selectedWebSiteNames = [];
    this.formGroups.forEach(element => {
      if (element.get(Constants.FormSubFieldNameConstants.webSiteName).value != null) {
        this.selectedWebSiteNames.push(element.get(Constants.FormSubFieldNameConstants.webSiteName).value);
      }
    });
  }
   addNewWebMediaSocialLink() {
    const existingFormGroup = this.form as FormGroup;
    const existingFormControlsCountForWebMedia = Object.keys(existingFormGroup.controls).length;
    const isPrimary = existingFormControlsCountForWebMedia === 0;
    const uniqueKey =
      this.user.CustomerProfile.MasterCustomerId + '-' + existingFormControlsCountForWebMedia + '-' + Constants.CommunicationType.web;
    const newFormGroup = new FormGroup({});
    const validator = this.formFieldValidatorsService.getRuleBaseValidators
      (FormFieldRuleConstants.OptionalEditableId, FormFieldRuleConstants.OptionalEditableName);
    const fieldState = this.formFieldValidatorsService.getFieldState(this.field);
    newFormGroup.addControl(Constants.AdditionalFieldConstants.id, new FormControl());
    newFormGroup.get(Constants.AdditionalFieldConstants.id).setValue(uniqueKey);
    newFormGroup.addControl(`${Constants.FormSubFieldNameConstants.webSiteName}`,
      new FormControl(fieldState, { validators: validator, updateOn: 'blur' }));
    newFormGroup.addControl(`${Constants.FormSubFieldNameConstants.webSiteAddress}`,
      new FormControl(fieldState, { validators: validator, updateOn: 'blur' }));
    newFormGroup.addControl(`${Constants.FormSubFieldNameConstants.webIncludeInDirectoryFlag}`,
      new FormControl(fieldState));
    newFormGroup.addControl(Constants.AdditionalFieldConstants.id, new FormControl());
    newFormGroup.get(Constants.AdditionalFieldConstants.id).setValue(uniqueKey);
    newFormGroup.addControl(Constants.AdditionalFieldConstants.newflag, new FormControl());
    newFormGroup.get(Constants.AdditionalFieldConstants.newflag).setValue(true);
    newFormGroup.addControl(Constants.AdditionalFieldConstants.primaryflag, new FormControl());
    newFormGroup.get(Constants.AdditionalFieldConstants.primaryflag).setValue(isPrimary);
    newFormGroup.addControl(Constants.AdditionalFieldConstants.deletedflag, new FormControl());
    newFormGroup.get(Constants.AdditionalFieldConstants.deletedflag).setValue(false);
    existingFormGroup.addControl(uniqueKey, newFormGroup);
    this.formGroups.push(newFormGroup);
    this.buildSelectedWebSiteNames();
    this.webSiteNameSubject.next(this.selectedWebSiteNames);
    this.setShowAddButton();
  }
  delete(id: string) {
    const formGroup = this.form as FormGroup;
    const deletedFromGroup = formGroup.get(id) as FormGroup;
    const deletedFlagControl = deletedFromGroup.get(Constants.AdditionalFieldConstants.deletedflag);
    const newFlagControl = deletedFromGroup.get(Constants.AdditionalFieldConstants.newflag);
    deletedFlagControl.setValue(true);
    if (newFlagControl.value === true) {
        formGroup.removeControl(id);
    } else {
        // remove validators for delete form which should no
        // longer be validated because it is only being used to track the deleted address
        Object.keys(deletedFromGroup.controls).forEach(_ => {
            const control = deletedFromGroup.controls[_];
            control.setValidators([]);
            control.updateValueAndValidity();
        });
    }
    const formToUpdateValidity = deletedFromGroup as AbstractControl;
    formToUpdateValidity.updateValueAndValidity();
    this.formGroups = [];
    for (const key of Object.keys(formGroup.controls)) {
        const formControl = formGroup.get(key) as FormGroup;
        const deletedFormControl = formControl.get(Constants.AdditionalFieldConstants.deletedflag);
        const deletedValue = deletedFormControl.value;
        if (key !== id && deletedValue === false) {
            this.formGroups.push(formControl);
        }
    }
    if (id.includes(Constants.CommunicationType.web)) {
        this.buildSelectedWebSiteNames();
        this.webSiteNameSubject.next(this.selectedWebSiteNames);
    }
    this.setShowAddButton();
}
}
