<aaos-header [isProfilePage]="true" [showEmailPreferencesLink]="showEmailPreferencesLink"
  *ngIf="showHeaderAndFooter && user"></aaos-header>
<div class="flexbox container userProfile">
    <div class="profile-breadcrumb" *ngIf="showBreadCrumb">
        <span class="breadcrumb-text" routerLink="/user-profile">MY PROFILE</span>&ensp;/&ensp;EDIT
        PROFILE
    </div>
    <aaos-dynamic-user-profile-edit [userTrackerService]="userTrackerService" [isChildComponent]="true"
                                    (finished)="finishProfile($event)" [editMode]="true" [refreshEditState$]="refreshEditState$">
    </aaos-dynamic-user-profile-edit>
</div>
<aaos-footer *ngIf="showHeaderAndFooter && user"></aaos-footer>
