<div class="form-group" *ngIf="form" [formGroup]="form">
  <div class="title-deleteicon">
    <div *ngIf="isWebMediaPrimary()" class="deleteable-field-title-section">
      <aaos-form-field-title [editMode]="editMode" [field]="field"></aaos-form-field-title>
    </div>
    <div *ngIf="!isWebMediaPrimary() && !isBlankAdditionalItem()" class="deleteable-field-title-section">
      <div class="title title--xs">
        {{WebMediaFieldName | titlecase}}
        <span *ngIf="field.ruleName === 'OptionalEditable'">(Optional)</span>
      </div>
    </div>
    <div *ngIf="(editMode)" class="delete-button-wrapper">
      <div id="{{WebSocialFieldId}}" class="delete-file-button" (click)="deleteWebSocial($event.target.id)">
      </div>
    </div>
  </div>
  <div class="form-group" [formGroup]="form">

    <div *ngIf="(editMode && !fieldIsDisabled)">
      <div class="title title--xs">
        {{webSiteFieldName}}
      </div>
      <mat-select formControlName="webSiteName"
        (selectionChange)="webSiteNameChanged($event.value)" disableRipple panelClass="mat-panel">
        <mat-option *ngFor="let option of webSiteNameOptions" [value]="option.PreferenceCode" [disabled]="!option.Active">{{
          option.PreferenceDescription
        }}</mat-option>
      </mat-select>
    </div>
  </div>
  <div *ngIf="!(editMode && !fieldIsDisabled) && !isBlankAdditionalItem()">
    <aaos-form-field-title [editMode]="editMode" [field]="webSiteNameField"></aaos-form-field-title>
    <p *ngIf="readonlyWebSiteNameValue">{{readonlyWebSiteNameValue | titlecase}}</p>
    <p *ngIf="!readonlyWebSiteNameValue" class="blank-text">(Blank)</p>
  </div>
  <aaos-text-field *ngIf="!isBlankAdditionalItem()"
    [form]="form" [editMode]="editMode" [field]="webSiteLinkField" [fullwidth]="true">
  </aaos-text-field>

  <div *ngIf="editMode" class="form-check" (click)="flipIncludeInDirectoryFlagForWeb()">
    <input type="checkbox" id="webIncludeInDirectoryFlag"
      formControlName="webIncludeInDirectoryFlag" />
    <label attr.for="webIncludeInDirectoryFlag">Include in directory</label>
    <span></span>
  </div>
  <div *ngIf="!(editMode && !fieldIsDisabled) && !isBlankAdditionalItem()">
    <div *ngIf="readonlyWebIncludeInDirectoryFlag">
        <div class="check-icon text-gap"></div>
        Included in directory 
    </div>
  </div>

</div>