import { Injectable } from '@angular/core';
import {
    Customer, CustomerCommunication,
    CustomerProfileSnapshot, ProfileFormOption,
    Preference, CustomerAttachment, CustomerEmergencyContact, AddressDetail
} from '@shared/models/data';
import { FormGroup, AbstractControl } from '@angular/forms';
import { FormFieldConstants } from '@shared/form-fields/models/form-field';
import { Constants } from '@shared/services/constants/constants';
import { FormBuilderService } from '../../user-profile/dynamic-user-profile-edit/form-builder.service';
import { BaseFieldComponent } from '@shared/form-fields/baseFieldComponent';
import { Utilities } from '@shared/services/utils';
import { BaseMultipleOptionsFieldComponent } from '@shared/form-fields/BaseMultipleOptionsFieldComponent';
import { CustomerAddressSnapshot } from '@shared/models/data/CustomerAddressSnapshot';
import { CustomerEmergencyContactSnapshot } from '@shared/models/data/CustomerEmergencyContactsSnapshot';
import { BirthdayService } from '@shared/services/utils/birthday.service';
import { UserProfileFormOptions } from '@shared/services/constants/constants';


@Injectable({
    providedIn: 'root'
})
export class MembershipFormUserMappingService {

    mapAttachmentTypesFromForm(form: FormGroup, attachments: CustomerAttachment[]) {
        const biographyAttachmentsControl = this.getFormControl(FormFieldConstants.BiographyId, form);
        if (biographyAttachmentsControl) {
            const biographyFormGroup = biographyAttachmentsControl as FormGroup;

            attachments.forEach(x => {
                const attachmentRelatedDocCodeControl = biographyFormGroup.controls[`relatedDocType-${x.CustomerRelatedDocumentId}`];
                if (attachmentRelatedDocCodeControl) {
                    x.RelatedDocCode = attachmentRelatedDocCodeControl.value;
                }
            });
        }
    }
    // recursively search all fields to prepopulate
    public populateFormGroup(abstractControl: AbstractControl, user: Customer, profileFormOptions: ProfileFormOption[]) {

        if (abstractControl instanceof FormGroup) {
            const group = abstractControl as FormGroup;
            Object.keys(group.controls).forEach(key => {
                if (key === FormFieldConstants.AddressId) {
                    this.populateAddress(key, group.controls[key], user);
                } else if (key === FormFieldConstants.EmailAddressId) {
                    this.populateEmails(key, group, user);
                } else if (key === FormFieldConstants.TelephoneId) {
                    this.populateMutiplePhone(key, group, user);
                } else if (key === FormFieldConstants.MobileId) {
                    this.populateCellPhone(key, group, user);
                } else if (key === FormFieldConstants.BiographyId) {
                    this.populateBiography(key, group.controls[key], user);
                } else if (key === FormFieldConstants.WebSocialMediaLinksId) {
                    this.populateWebSocialMediaLinks(key, group, user);
                } else if (key === FormFieldConstants.WorkStatusId) {
                    this.populateWorkStatus(key, group.controls[key], user);
                } else if (key === FormFieldConstants.EmergencyContactInformationId) {
                    this.populateEmergencyContacts(key, group, user);
                } else if (group.controls[key] instanceof FormGroup) {
                    this.populateFormGroup(group.controls[key], user, profileFormOptions); // recursive call for form groups
                } else {
                    this.populateField(key, group, user, profileFormOptions);
                }
            });
        }
        return;
    }

    public maapFormToUserSnapshot(form: FormGroup, user: Customer, profileFormOptions: ProfileFormOption[]) {
        const userSnapshot: CustomerProfileSnapshot = new CustomerProfileSnapshot(
            user.CustomerProfile.MasterCustomerId
        );
        this.mapPhotoFields(userSnapshot, user);
        this.mapCellPhoneFields(form, userSnapshot);
        this.mapPhoneFields(form, userSnapshot);
        this.customMapMaapFields(form, user, profileFormOptions, userSnapshot);
        this.customerToUserSnapshot(user, userSnapshot);
        return userSnapshot;
    }

    private customMapMaapFields(
        form: FormGroup,
        user: Customer,
        profileFormOptions: ProfileFormOption[],
        userSnapshot: CustomerProfileSnapshot) {

        const dateOfBirthControl = this.getFormControl(FormFieldConstants.DOBDateId, form);
        let _birthDay = user.CustomerProfile.BirthDate;
        if (dateOfBirthControl && dateOfBirthControl.value) {
            _birthDay = BirthdayService.convertToBirthdaySnapshot(user.CustomerProfile.BirthDate, dateOfBirthControl.value);
            userSnapshot.BirthDateString = dateOfBirthControl.value;
        }
        userSnapshot.BirthDate = _birthDay;

        const genderControl = this.getFormControl(FormFieldConstants.GenderId, form);
        userSnapshot.Gender = user.CustomerProfile.Gender;
        if (genderControl && genderControl.value !== userSnapshot.Gender) {
            userSnapshot.Gender = genderControl.value;
        }

        const identityControl = this.getFormControl(FormFieldConstants.IdentityId, form);
        userSnapshot.Identity = user.CustomerProfile.Identity;
        if (identityControl && identityControl.value !== userSnapshot.Identity) {
            userSnapshot.Identity = identityControl.value;
        }

        const identityPronounControl = this.getFormControl(FormFieldConstants.IdentityPronounId, form);
        userSnapshot.IdentityPronoun = user.CustomerProfile.IdentityPronoun;
        if (identityPronounControl && identityPronounControl.value !== userSnapshot.IdentityPronoun) {
            userSnapshot.IdentityPronoun = identityPronounControl.value;
        }

        const ethnicityControl = this.getFormControl(FormFieldConstants.RaceEthnicityId, form);
        userSnapshot.Ethnicity = user.CustomerProfile.Ethnicity;
        if (ethnicityControl && ethnicityControl.value !== userSnapshot.Ethnicity) {
            userSnapshot.Ethnicity = ethnicityControl.value;
        }

        const prefixControl = this.getFormControl(FormFieldConstants.NamePrefixId, form);
        userSnapshot.Prefix = user.NameCredentials.NamePrefix;
        if (prefixControl && prefixControl.value !== userSnapshot.Prefix) {
            userSnapshot.Prefix = prefixControl.value;
        }

        const suffixControl = this.getFormControl(FormFieldConstants.NameSuffixId, form);
        userSnapshot.Suffix = user.NameCredentials.NameSuffix;
        if (suffixControl && suffixControl.value !== userSnapshot.Suffix) {
            userSnapshot.Suffix = suffixControl.value;
        }

        const credentialsControl = this.getFormControl(FormFieldConstants.NameCredentialsId, form);
        userSnapshot.Credentials = user.NameCredentials.Credentials;
        if (credentialsControl && credentialsControl.value !== userSnapshot.Credentials) {
            userSnapshot.Credentials =
                BaseMultipleOptionsFieldComponent.mapFromMultiSelectToCsv(credentialsControl.value);
        }
        userSnapshot.Credentials = this.orderCredentials(userSnapshot.Credentials, profileFormOptions);
    }

    public mapAddressDetailstoAddressSnapshots(userSnapshot: CustomerProfileSnapshot, addressDetails: AddressDetail[]) {
        const addressSnapshots = Array<CustomerAddressSnapshot>();

        if (AddressDetail != null) {
            userSnapshot.AddressId = addressDetails[0].AddressId;
            userSnapshot.AddressType = addressDetails[0].AddressTypeCode;
            userSnapshot.Address1 = addressDetails[0].Address1;
            userSnapshot.Address2 = addressDetails[0].Address2;
            userSnapshot.Address3 = addressDetails[0].Address3;
            userSnapshot.City = addressDetails[0].City;
            userSnapshot.State = addressDetails[0].State;
            userSnapshot.PostalCode = addressDetails[0].PostalCode;
            userSnapshot.Country = addressDetails[0].Country;
            userSnapshot.CompanyName = addressDetails[0].CompanyName;
            userSnapshot.JobTitle = addressDetails[0].JobTitle;
            userSnapshot.IncludeInDirectoryFlag = addressDetails[0].IncludeInDirectoryFlag;
            userSnapshot.IsNew = false;
            userSnapshot.IsDeleted = false;
        }
        addressDetails.forEach(addressdetail => {
            const addressSnapshot: CustomerAddressSnapshot = {
                AddressId: addressdetail.AddressId,
                AddressType: addressdetail.AddressTypeCode,
                Address1: addressdetail.Address1,
                Address2: addressdetail.Address2,
                Address3: addressdetail.Address3,
                City: addressdetail.City,
                State: addressdetail.State,
                PostalCode: addressdetail.PostalCode,
                Country: addressdetail.Country,
                CompanyName: addressdetail.CompanyName,
                JobTitle: addressdetail.JobTitle,
                IncludeInDirectoryFlag: addressdetail.IncludeInDirectoryFlag,
                IsNew: false,
                IsDeleted: false,
                IsPrimary: addressdetail.IsPrimaryAddress
            };
            addressSnapshots.push(addressSnapshot);
        });
        userSnapshot.Address = addressSnapshots;
        return addressSnapshots;
    }

    public mapEmergencyContactsToSnapshot(emergencyContacts: CustomerEmergencyContact[]) {
        const contactsSnapshots = Array<CustomerEmergencyContactSnapshot>();
        emergencyContacts.forEach(contact => {
            const contactSnapshot: CustomerEmergencyContactSnapshot = {
                EmergencyContactId: contact.EmergencyContactId,
                PhoneNumber: contact.PhoneNumber,
                PhoneType: contact.PhoneType,
                Comments: contact.Comments,
                Relationship: contact.Relationship,
                ContactName: contact.ContactName,
                IsNew: false,
                IsDeleted: false,
            };
            contactsSnapshots.push(contactSnapshot);
        });
        return contactsSnapshots;
    }

    public customerToUserSnapshot(user: Customer, userSnapshot: CustomerProfileSnapshot) {
        userSnapshot.Biography = user.CustomerProfile.Biography;
        userSnapshot.PracticeSpecializationPrimary = user.CustomerProfile.PracticeSpecializationPrimary;
        userSnapshot.PracticeSpecializationSecondary = user.CustomerProfile.PracticeSpecializationSecondary;
        userSnapshot.Npi = user.CustomerProfile.Npi;
        userSnapshot.Address = this.mapAddressDetailstoAddressSnapshots(userSnapshot, user.AddressDetail);
        userSnapshot.EmergencyContact = this.mapEmergencyContactsToSnapshot(user.EmergencyContacts);
        userSnapshot.Email = user.CustomerProfile.PrimaryEmailAddress;
        userSnapshot.PracticeType = user.CustomerProfile.PracticeType;
        userSnapshot.PracticeSize = user.CustomerProfile.PracticeSize;
        userSnapshot.WorkStatus = user.CustomerProfile.WorkStatus;
        userSnapshot.RetirementAge = user.CustomerProfile.RetirementAge;
        userSnapshot.BeginPracticeYear = user.PracticeInformation.PracticeBeginDate;
        userSnapshot.LanguagesSpoken = user.PracticeInformation.Language.split(',');
        userSnapshot.AgeGroupsTreated = user.PracticeInformation.AgeGroupTreated.split(',');
        userSnapshot.LevelOfSpecialization = user.PracticeInformation.SpecialtyDegree;
        userSnapshot.ABOSRecertificationYear = user.CustomerProfile.RecertificationYear;
        userSnapshot.ABOSYear = user.CustomerProfile.ABOSYear;

    }

    public formToUserSnapshot(form: FormGroup, user: Customer, profileFormOptions: ProfileFormOption[]) {
        const userSnapshot: CustomerProfileSnapshot = new CustomerProfileSnapshot(
            user.CustomerProfile.MasterCustomerId
        );
        this.mapAddressFields(userSnapshot, form, user, profileFormOptions);
        this.mapEmailAddressFields(userSnapshot, form, user);
        this.mapPhotoFields(userSnapshot, user);
        this.mapPhoneFields(form, userSnapshot);
        this.mapCellPhoneFields(form, userSnapshot);
        this.mapPersonalInformation(form, user, userSnapshot, profileFormOptions);
        this.mapInterestFields(form, user, userSnapshot, profileFormOptions);
        this.mapPracticeFields(form, user, userSnapshot);
        this.mapCertificationsFields(form, user, userSnapshot);
        this.mapEmergencyContactFields(userSnapshot, form, user);
        this.mapWebSocialMediaFields(userSnapshot, form, user);
        return userSnapshot;
    }

    public checkPartiallyFilledOutBlocks(form: FormGroup, userSnapshot: CustomerProfileSnapshot, personifyUser: Customer) {
        const missingBlockNames = new Array<string>();
        if (this.isPartiallyFilledOutSocialMedia(form, userSnapshot, personifyUser)) {
            missingBlockNames.push(Constants.FieldName.WebSocialMedia);
        }
        if (this.isPartiallyFilledOutEmergencyContacts(form, userSnapshot, personifyUser)) {
            missingBlockNames.push(Constants.BlockNames.EmergencyContacts);
        }

        return missingBlockNames;
    }

    isPartiallyFilledOutSocialMedia(form: FormGroup, user: CustomerProfileSnapshot, personifyUser: Customer) {
        const webSocialMediaFormGroup = this.getFormControl(FormFieldConstants.WebSocialMediaLinksId, form) as FormGroup;
        if (!webSocialMediaFormGroup) {
            return false;
        }
        let isPartial = false;
        for (const key of Object.keys(webSocialMediaFormGroup.controls)) {
            const formControl = webSocialMediaFormGroup.get(key) as FormGroup;
            const webSiteNameControl =
                formControl.get(`${Constants.FormSubFieldNameConstants.webSiteName}`);
            const webSiteLinkControl =
                formControl.get(`${Constants.FormSubFieldNameConstants.webSiteAddress}`);
            if (!webSiteNameControl.value && !webSiteLinkControl.value) {
                continue;
            }
            if (user.Communications.some(x => {
                if (((webSiteNameControl.value && (x.LocationCode === webSiteNameControl.value))
                    && (webSiteLinkControl.value && (x.SocialMediaLink === webSiteLinkControl.value)))) {
                    return true;
                }

            }) || personifyUser.Communications.some(x => {
                if (((webSiteNameControl.value && (x.LocationCode === webSiteNameControl.value))
                    && (webSiteLinkControl.value && (x.SocialMediaLink === webSiteLinkControl.value)))) {
                    return true;
                }

            })) {
                isPartial = false;
            } else {
                isPartial = true;
            }
        }
        return isPartial;
    }

    isPartiallyFilledOutEmergencyContacts(form: FormGroup, user: CustomerProfileSnapshot, personifyfUser: Customer) {
        const emergencyContactsFormGroup = this.getFormControl(FormFieldConstants.EmergencyContactInformationId, form) as FormGroup;
        if (!emergencyContactsFormGroup) {
            return false;
        }
        let isPartial = false;
        for (const key of Object.keys(emergencyContactsFormGroup.controls)) {
            const formControl = emergencyContactsFormGroup.get(key) as FormGroup;
            const contactNameControl =
                formControl.get(
                    `${FormFieldConstants.EmergencyContactInformationId}-${Constants.FormSubFieldNameConstants.contactName}`);
            const relationshipControl =
                formControl.get(
                    `${FormFieldConstants.EmergencyContactInformationId}-${Constants.FormSubFieldNameConstants.relationship}`);
            const phoneNumberControl =
                formControl.get(
                    `${FormFieldConstants.EmergencyContactInformationId}-${Constants.FormSubFieldNameConstants.phoneNumber}`);
            if (!contactNameControl.value && !relationshipControl.value && !phoneNumberControl.value) {
                continue;
            }
            if (user.EmergencyContact.some(x => {
                if (((contactNameControl.value && (x.ContactName === contactNameControl.value))
                    && (phoneNumberControl.value && (x.PhoneNumber === phoneNumberControl.value)
                        && (relationshipControl.value && (x.Relationship === relationshipControl.value))))) {
                    return true;
                }
            }) || personifyfUser.EmergencyContacts.some(x => {
                if (((contactNameControl.value && (x.ContactName === contactNameControl.value))
                    && (phoneNumberControl.value && (x.PhoneNumber === phoneNumberControl.value)
                        && (relationshipControl.value && (x.Relationship === relationshipControl.value))))) {
                    return true;
                }
            })) {
                isPartial = false;
            } else {
                isPartial = true;
            }
        }
        return isPartial;
    }

    populateBiography(key: string, control: AbstractControl, user: Customer) {
        const formGroup = control as FormGroup;
        const textControl = formGroup.get(FormBuilderService.getSubfieldIdById(key, Constants.FormSubFieldNameConstants.biographyText));
        if (textControl) {
            textControl.setValue(user.CustomerProfile.Biography);
        }
    }

    populateWorkStatus(key: string, control: AbstractControl, user: Customer) {
        const formGroup = control as FormGroup;
        const workStatusControl = formGroup
            .get(FormBuilderService.getSubfieldIdById(key, Constants.FormSubFieldNameConstants.workStatusStatus));
        if (workStatusControl) {
            workStatusControl.setValue(user.CustomerProfile.WorkStatus);
        }

        const retirementAgeControl = formGroup
            .get(FormBuilderService.getSubfieldIdById(key, Constants.FormSubFieldNameConstants.retirementAge));
        if (retirementAgeControl) {
            retirementAgeControl.setValue(user.CustomerProfile.RetirementAge);
        }

    }

    private populateEmails(key: string, formGroup: FormGroup, user: Customer) {
        const userCommunicationsForEmail =
            user.Communications.filter(x => x.CommunicationType.toLowerCase() === Constants.CommunicationType.email);
        for (let i = 0; i < userCommunicationsForEmail.length; i++) {
            const uniqueKey = user.CustomerProfile.MasterCustomerId +
                '-' + i + '-' + userCommunicationsForEmail[i].CommunicationType.toLowerCase();
            const emailFormGroupControl = formGroup.controls[key];
            const formControl = emailFormGroupControl.get(uniqueKey);
            const emailAddressControl = formControl.get(Constants.FormSubFieldNameConstants.emailAddress);
            const emailTypeControl = formControl.get(Constants.FormSubFieldNameConstants.emailType);
            emailAddressControl.setValue(userCommunicationsForEmail[i].EmailAddress);
            emailTypeControl.setValue(userCommunicationsForEmail[i].LocationCode);

        }
    }


    private populateMutiplePhone(key: string, formGroup: FormGroup, user: Customer) {
        const userCommunicationsForPhone =
            user.Communications.filter(x => x.CommunicationType.toLowerCase() === Constants.CommunicationType.phone);
        for (let i = 0; i < userCommunicationsForPhone.length; i++) {
            const uniqueKey = user.CustomerProfile.MasterCustomerId + '-' + i + '-' +
                userCommunicationsForPhone[i].CommunicationType.toLowerCase();

            const phoneFormGroupControl = formGroup.controls[key];
            const formControl = phoneFormGroupControl.get(uniqueKey);
            const countryControl = formControl.get(Constants.FormSubFieldNameConstants.countryCode);
            const numberControl = formControl.get(Constants.FormSubFieldNameConstants.phoneNumber);
            const extensionNumberControl = formControl.get(Constants.FormSubFieldNameConstants.phoneExtension);
            const phoneTypeControl = formControl.get(Constants.FormSubFieldNameConstants.phoneType);
            const donotcallFlagControl = formControl.get(Constants.FormSubFieldNameConstants.doNotCallFlag);
            const phoneIncludeInDirectoryFlagControl = formControl.get(Constants.FormSubFieldNameConstants.phoneIncludeInDirectoryFlag);
            countryControl.setValue(userCommunicationsForPhone[i].CountryCode);
            numberControl.setValue(userCommunicationsForPhone[i].PhoneAreaCode + userCommunicationsForPhone[i].PhoneNumber);
            phoneTypeControl.setValue(userCommunicationsForPhone[i].LocationCode);
            extensionNumberControl.setValue(userCommunicationsForPhone[i].PhoneExtension);
            donotcallFlagControl.setValue(userCommunicationsForPhone[i].DoNotCallFlag);
            phoneIncludeInDirectoryFlagControl.setValue(userCommunicationsForPhone[i].IncludeInDirectoryFlag);
        }
    }

    private populateCellPhone(key: string, formGroup: FormGroup, user: Customer) {
        const userCommunicationsForCellPhone = user.Communications.filter(x =>
            x.CommunicationType.toLowerCase() === Constants.CommunicationType.mobile.toLowerCase() &&
            x.LocationCode.toLowerCase() === 'personal')[0];
        if (userCommunicationsForCellPhone) {
            const formControl = formGroup.controls[key];
            const countryControl = formControl.get(Constants.FormSubFieldNameConstants.countryCode);
            const numberControl = formControl.get(Constants.FormSubFieldNameConstants.phoneNumber);
            const mobileOptinControl = formControl.get(Constants.FormSubFieldNameConstants.mobileOptinFlag);
            const phoneTypeControl = formControl.get(Constants.FormSubFieldNameConstants.phoneType);
            countryControl.setValue(userCommunicationsForCellPhone.CountryCode);
            numberControl.setValue(userCommunicationsForCellPhone.PhoneAreaCode + userCommunicationsForCellPhone.PhoneNumber);
            phoneTypeControl.setValue(userCommunicationsForCellPhone.LocationCode);
            if (user.CustomerProfile.MobileOptin) {
                mobileOptinControl.setValue(user.CustomerProfile.MobileOptin);
            }
        }
    }

    populateEmergencyContacts(key: string, formGroup: FormGroup, user: Customer) {

        for (let i = 0; i < user.EmergencyContacts.length; i++) {
            const emergencyContact = user.EmergencyContacts[i];
            const uniqueKey = user.CustomerProfile.MasterCustomerId + '-' + i + '-' + Constants.AdditionalFieldConstants.Emergency;
            const emergencyContactFormGroupControl = formGroup.controls[key];
            const formControl = emergencyContactFormGroupControl.get(uniqueKey);
            const emergencyContactIdControl =
                formControl.get(
                    `${FormFieldConstants.EmergencyContactInformationId}-${Constants.FormSubFieldNameConstants.emergencyContactId}`
                );
            const contactNameControl =
                formControl.get(
                    `${FormFieldConstants.EmergencyContactInformationId}-${Constants.FormSubFieldNameConstants.contactName}`
                );
            const relationshipControl =
                formControl.get(
                    `${FormFieldConstants.EmergencyContactInformationId}-${Constants.FormSubFieldNameConstants.relationship}`
                );
            const phoneNumberControl =
                formControl.get(
                    `${FormFieldConstants.EmergencyContactInformationId}-${Constants.FormSubFieldNameConstants.phoneNumber}`
                );
            const commentsControl =
                formControl.get(
                    `${FormFieldConstants.EmergencyContactInformationId}-${Constants.FormSubFieldNameConstants.comments}`
                );
            const phoneTypeControl =
                formControl.get(
                    `${FormFieldConstants.EmergencyContactInformationId}-${Constants.FormSubFieldNameConstants.phoneType}`
                );
            contactNameControl.setValue(emergencyContact.ContactName);
            relationshipControl.setValue(emergencyContact.Relationship);
            phoneNumberControl.setValue(emergencyContact.PhoneNumber);
            commentsControl.setValue(emergencyContact.Comments);
            phoneTypeControl.setValue(emergencyContact.PhoneType);
            emergencyContactIdControl.setValue(emergencyContact.EmergencyContactId);
        }
    }
    populateWebSocialMediaLinks(key: string, formGroup: FormGroup, user: Customer) {
        const webMediaSocialLinks = user.Communications.filter(x => x.CommunicationType.toLocaleLowerCase()
            === Constants.CommunicationType.web);
        const isAnyMediaPrimary = webMediaSocialLinks.filter(x => x.IsPrimary).length > 0;
        for (let i = 0; i < webMediaSocialLinks.length; i++) {
            const webMedia = webMediaSocialLinks[i];
            const uniqueKey = user.CustomerProfile.MasterCustomerId + '-' + i + '-' + Constants.CommunicationType.web;
            const webMediaFormGroupControl = formGroup.controls[key];
            const formControl = webMediaFormGroupControl.get(uniqueKey);
            const webSiteNameControl =
                formControl.get(`${Constants.FormSubFieldNameConstants.webSiteName}`);
            const webSiteLinkControl =
                formControl.get(`${Constants.FormSubFieldNameConstants.webSiteAddress}`);
            const webIncludeInDirectoryFlagControl =
                formControl.get(`${Constants.FormSubFieldNameConstants.webIncludeInDirectoryFlag}`);
            if (!isAnyMediaPrimary && i === 0) {
                formControl.get(Constants.AdditionalFieldConstants.primaryflag).setValue(true);
            } else {
                formControl.get(Constants.AdditionalFieldConstants.primaryflag).setValue(webMediaSocialLinks[i].IsPrimary);
            }
            webSiteNameControl.setValue(webMedia.LocationCode);
            webSiteLinkControl.setValue(webMedia.SocialMediaLink);
            webIncludeInDirectoryFlagControl.setValue(webMedia.IncludeInDirectoryFlag);
        }

    }

    private populateAddress(key: string, group: AbstractControl, user: Customer) {
        // handled by the address control dynamically
    }
    // todo(Jackson) clean this up so it doesn't have so much repeated code key value pairs
    private populateField(key: string, group: FormGroup, user: Customer, profileFormOptions: ProfileFormOption[]) {
        const control = group.controls[key];
        switch (key.toLowerCase()) {
            case FormFieldConstants.CustomerNumberId.toLowerCase():
                control.setValue(user.CustomerProfile.MasterCustomerId);
                break;
            case FormFieldConstants.NamePrefixId.toLowerCase():
                control.setValue(user.NameCredentials.NamePrefix);
                break;
            case FormFieldConstants.NameSuffixId.toLowerCase():
                control.setValue(user.NameCredentials.NameSuffix);
                break;
            case FormFieldConstants.NameCredentialsId.toLowerCase():
                this.setCheckBoxValueFromCsv(key, control, Constants.UserProfileFormOptions.Credentials,
                    user.NameCredentials.Credentials, profileFormOptions);
                break;
            case FormFieldConstants.FirstNameId.toLowerCase():
                control.setValue(user.NameCredentials.FirstName);
                break;
            case FormFieldConstants.LastNameId.toLowerCase():
                control.setValue(user.NameCredentials.LastName);
                break;
            case FormFieldConstants.MiddleNameId.toLowerCase():
                control.setValue(user.NameCredentials.MiddleName);
                break;
            case FormFieldConstants.PrimaryPracticeSpecializationId.toLowerCase():
                control.setValue(user.CustomerProfile.PracticeSpecializationPrimary);
                break;
            case FormFieldConstants.SecondaryPracticeSpecializationId.toLowerCase():
                control.setValue(user.CustomerProfile.PracticeSpecializationSecondary);
                break;
            case FormFieldConstants.JobTitleId.toLowerCase():
                control.setValue(user.CustomerProfile.JobTitle);
                break;
            case FormFieldConstants.DOBDateId.toLowerCase():
                const dob = user.CustomerProfile.BirthDate;
                if (dob) {
                    control.setValue(BirthdayService.formatToControlString(dob));
                }
                break;
            case FormFieldConstants.GenderId.toLowerCase():
                control.setValue(user.CustomerProfile.Gender);
                break;
            case FormFieldConstants.IdentityId.toLowerCase():
                control.setValue(user.CustomerProfile.Identity);
                break;
            case FormFieldConstants.IdentityPronounId.toLowerCase():
                control.setValue(user.CustomerProfile.IdentityPronoun);
                break;
            case FormFieldConstants.RaceEthnicityId.toLowerCase():
                control.setValue(user.CustomerProfile.Ethnicity);
                break;
            case FormFieldConstants.WebSocialMediaLinksId.toLowerCase():
                // todo
                break;
            case FormFieldConstants.NPId.toLowerCase():
                if (user.CustomerProfile.Npi > 0) {
                    control.setValue(user.CustomerProfile.Npi); // if the value is 0 leave the text box blank
                }
                break;
            case FormFieldConstants.ABOSDiplomateId.toLowerCase():
                control.setValue(user.CustomerProfile.ABOSYear);
                break;
            case FormFieldConstants.ABOSRecertificationYear.toLowerCase():
                control.setValue(user.CustomerProfile.RecertificationYear);
                break;
            case FormFieldConstants.BeginPracticeYearId.toLowerCase():
                control.setValue(user.PracticeInformation.PracticeBeginDate.toString());
                break;
            case FormFieldConstants.AreasofInterestId.toLowerCase():
                this.setCheckBoxValueFromPreference(key, control, user, Constants.UserProfileFormOptions.Subspecialty);
                break;
            case FormFieldConstants.OtherAreaofInterest.toLowerCase():
                this.setCheckBoxValueFromPreference(key, control, user, Constants.UserProfileFormOptions.AreasOfInterest);
                break;
            case FormFieldConstants.PracticeEmploymentSettingId.toLowerCase():
                control.setValue(user.CustomerProfile.PracticeType);
                break;
            case FormFieldConstants.RaceEthnicityId.toLowerCase():
                control.setValue(user.CustomerProfile.Ethnicity);
                break;
            case FormFieldConstants.CurrentDisclosrueSummaryId.toLowerCase():
                control.setValue(user.DisclosureSummary.SummarySubmittedDateString);
                break;
            case FormFieldConstants.MembershipExpirationDateId.toLowerCase():
                const memberExpirationDate = user.CustomerProfile.MemberExpirationDate;
                if (memberExpirationDate) {
                    control.setValue(BirthdayService.formatToControlString(memberExpirationDate));
                }
                break;
            case FormFieldConstants.LanguagesSpokenId.toLowerCase():
                this.setCheckBoxValueFromCsv(key, control, Constants.UserProfileFormOptions.Languages,
                    user.PracticeInformation.Language, profileFormOptions);
                break;
            case FormFieldConstants.LevelofSpecializationId.toLowerCase():
                control.setValue(user.PracticeInformation.SpecialtyDegree);
                break;
            case FormFieldConstants.AgeGroupsTreatedId.toLowerCase():
                this.setCheckBoxValueFromCsv(key, control, Constants.UserProfileFormOptions.AgeGroups,
                    user.PracticeInformation.AgeGroupTreated, profileFormOptions);
                break;
            case FormFieldConstants.PracticeSizeId.toLowerCase():
                control.setValue(user.CustomerProfile.PracticeSize);
                break;
                
            default:
                break;
        }
    }
    setCheckBoxValueFromPreference(formFieldId: string, control: AbstractControl, user: Customer, preferenceCode: string) {
        const matchingPreferences = user.Preferences.filter(x => x.PreferenceCode.toLowerCase() === preferenceCode.toLowerCase());
        const values = new Array<string>();
        matchingPreferences.forEach(x => {
            values.push(BaseFieldComponent.getPrefixForFieldAndOption(formFieldId, x));
        });
        control.setValue(values);
    }
    setCheckBoxValueFromCsv(
        formFieldId: string, control: AbstractControl,
        preferenceCode: string, value: string, profileFormOptions: ProfileFormOption[]) {

        const matchingPreferenceCodePair = profileFormOptions.find(x => x.key.toLowerCase() === preferenceCode.toLowerCase());
        if (!matchingPreferenceCodePair) {
            return;
        }

        const preferenceList = matchingPreferenceCodePair.value;

        if (value) {
            const controlValues = new Array<string>();
            const valuesArray = value.split(',');



            preferenceList.forEach(preference => {
                if (valuesArray.find(y => y.toLowerCase().trim() === preference.PreferenceCode.toLowerCase().trim())) {
                    controlValues.push(BaseFieldComponent.getPrefixForFieldAndOption(formFieldId, preference));
                }
            });

            const faaosValue = valuesArray.find(x => x.toLowerCase().trim() === Constants.Credentials.FAAOSCode.toLowerCase());
            if (faaosValue) {
                const faaosCredentialPreference = new Preference();
                faaosCredentialPreference.Active = false;
                faaosCredentialPreference.PreferenceCode = Constants.Credentials.FAAOSCode;
                faaosCredentialPreference.PreferenceDescription = Constants.Credentials.FAAOSCode;
                preferenceList.push(faaosCredentialPreference);
                controlValues.push(BaseFieldComponent.getPrefixForFieldAndOption(formFieldId, faaosCredentialPreference));
            }
            control.setValue(controlValues);
        }
    }


    private mapPracticeFields(form: FormGroup, user: Customer, userSnapshot: CustomerProfileSnapshot) {

        const primarySpecializationField = this.getFormControl(FormFieldConstants.PrimaryPracticeSpecializationId, form);
        userSnapshot.PracticeSpecializationPrimary = user.CustomerProfile.PracticeSpecializationPrimary;
        if (primarySpecializationField && primarySpecializationField.value !== userSnapshot.PracticeSpecializationPrimary) {
            userSnapshot.PracticeSpecializationPrimary = primarySpecializationField.value;
        }

        const secondarySpecializationField = this.getFormControl(FormFieldConstants.SecondaryPracticeSpecializationId, form);
        userSnapshot.PracticeSpecializationSecondary = user.CustomerProfile.PracticeSpecializationSecondary;
        if (secondarySpecializationField && secondarySpecializationField.value !== userSnapshot.PracticeSpecializationSecondary) {
            userSnapshot.PracticeSpecializationSecondary = secondarySpecializationField.value;
        }

        const practiceTypeField = this.getFormControl(FormFieldConstants.PracticeEmploymentSettingId, form);
        userSnapshot.PracticeType = user.CustomerProfile.PracticeType;
        if (practiceTypeField && practiceTypeField.value !== userSnapshot.PracticeType) {
            userSnapshot.PracticeType = practiceTypeField.value;
        }

        const practiceSizeControl = this.getFormControl(FormFieldConstants.PracticeSizeId, form);
        userSnapshot.PracticeSize = user.CustomerProfile.PracticeSize;
        if (practiceSizeControl && practiceSizeControl.value !== userSnapshot.PracticeSize) {
            userSnapshot.PracticeSize = practiceSizeControl.value;
        }

        const workStatusFieldKey = FormBuilderService.getSubfieldIdById(FormFieldConstants.WorkStatusId,
            Constants.FormSubFieldNameConstants.workStatusStatus);
        const workStatusField = this.getFormControl(workStatusFieldKey, form);
        userSnapshot.WorkStatus = user.CustomerProfile.WorkStatus;
        if (workStatusField && workStatusField.value !== userSnapshot.WorkStatus) {
            userSnapshot.WorkStatus = workStatusField.value;
        }

        const retirementAgeKey = FormBuilderService.getSubfieldIdById(FormFieldConstants.WorkStatusId,
            Constants.FormSubFieldNameConstants.retirementAge);
        const retirementAgeControl = this.getFormControl(retirementAgeKey, form);
        userSnapshot.RetirementAge = user.CustomerProfile.RetirementAge;
        if (retirementAgeControl && retirementAgeControl.value !== userSnapshot.RetirementAge) {
            userSnapshot.RetirementAge = retirementAgeControl.value;
        }

        const beginPracticeYearControl = this.getFormControl(FormFieldConstants.BeginPracticeYearId, form);
        userSnapshot.BeginPracticeYear = user.PracticeInformation.PracticeBeginDate;
        if (beginPracticeYearControl && beginPracticeYearControl.value !== userSnapshot.RetirementAge) {
            userSnapshot.BeginPracticeYear = beginPracticeYearControl.value;
        }
        const languagesSpokenControl = this.getFormControl(FormFieldConstants.LanguagesSpokenId, form);
        userSnapshot.LanguagesSpoken = user.PracticeInformation.Language.split(',');
        if (languagesSpokenControl && languagesSpokenControl.value !== userSnapshot.LanguagesSpoken) {
            userSnapshot.LanguagesSpoken = BaseMultipleOptionsFieldComponent.mapFromMultiSelectToList(languagesSpokenControl.value);
        }

        const ageGroupsTreatedControl = this.getFormControl(FormFieldConstants.AgeGroupsTreatedId, form);
        userSnapshot.AgeGroupsTreated = user.PracticeInformation.AgeGroupTreated.split(',');
        if (ageGroupsTreatedControl && ageGroupsTreatedControl.value !== userSnapshot.AgeGroupsTreated) {
            userSnapshot.AgeGroupsTreated = BaseMultipleOptionsFieldComponent.mapFromMultiSelectToList(ageGroupsTreatedControl.value);
        }

        const levelOfSpecializationControl = this.getFormControl(FormFieldConstants.LevelofSpecializationId, form);
        userSnapshot.LevelOfSpecialization = user.PracticeInformation.SpecialtyDegree;
        if (levelOfSpecializationControl && levelOfSpecializationControl.value !== userSnapshot.LevelOfSpecialization) {
            userSnapshot.LevelOfSpecialization = levelOfSpecializationControl.value;
        }

    }

    mapCertificationsFields(form: FormGroup, user: Customer, userSnapshot: CustomerProfileSnapshot) {
        const recertificationField = this.getFormControl(FormFieldConstants.ABOSRecertificationYear, form);
        userSnapshot.ABOSRecertificationYear = user.CustomerProfile.RecertificationYear;
        if (recertificationField && recertificationField.value !== userSnapshot.PracticeType) {
            userSnapshot.ABOSRecertificationYear = recertificationField.value;
        }

        const abosYearField = this.getFormControl(FormFieldConstants.ABOSDiplomateId, form);
        userSnapshot.ABOSYear = user.CustomerProfile.ABOSYear;
        if (abosYearField && abosYearField.value !== userSnapshot.ABOSYear) {
            userSnapshot.ABOSYear = abosYearField.value;
        }
    }

    private mapInterestFields(
        form: FormGroup,
        user: Customer,
        userSnapshot: CustomerProfileSnapshot,
        profileFormOptions: ProfileFormOption[]) {

        const areaOfInterestOptions = profileFormOptions.find(x => x.key === Constants.UserProfileFormOptions.Specialty);
        const specialtyAreaField = this.getFormControl(FormFieldConstants.AreasofInterestId, form);
        if (specialtyAreaField && specialtyAreaField.value) {
            userSnapshot.SpecialtyArea =
                BaseFieldComponent.mapFromMultiSelectToPreference(specialtyAreaField.value, areaOfInterestOptions.value);
        }
        const OtherAreaofInterestOptions = profileFormOptions.find(x => x.key === Constants.UserProfileFormOptions.AreaOfInterest);
        const specialtyInterestsField = this.getFormControl(FormFieldConstants.OtherAreaofInterest, form);
        if (specialtyInterestsField && specialtyInterestsField.value) {
            userSnapshot.SpecialtyInterests = BaseFieldComponent.mapFromMultiSelectToPreference(
                specialtyInterestsField.value, OtherAreaofInterestOptions.value);
        }
    }

    private mapPersonalInformation(
        form: FormGroup,
        user: Customer,
        userSnapshot: CustomerProfileSnapshot,
        profileFormOption: ProfileFormOption[]) {

        const dateOfBirthControl = this.getFormControl(FormFieldConstants.DOBDateId, form);
        let _birthDay = user.CustomerProfile.BirthDate;
        if (dateOfBirthControl && dateOfBirthControl.value) {
            _birthDay = BirthdayService.convertToBirthdaySnapshot(user.CustomerProfile.BirthDate, dateOfBirthControl.value);
            userSnapshot.BirthDateString = dateOfBirthControl.value;
        }
        userSnapshot.BirthDate = _birthDay;

        const genderControl = this.getFormControl(FormFieldConstants.GenderId, form);
        userSnapshot.Gender = user.CustomerProfile.Gender;
        if (genderControl && genderControl.value !== userSnapshot.Gender) {
            userSnapshot.Gender = genderControl.value;
        }

        const identityControl = this.getFormControl(FormFieldConstants.IdentityId, form);
        userSnapshot.Identity = user.CustomerProfile.Identity;
        if (identityControl && identityControl.value !== userSnapshot.Identity) {
            userSnapshot.Identity = identityControl.value;
        }

        const identityPronounsControl = this.getFormControl(FormFieldConstants.IdentityPronounId, form);
        userSnapshot.IdentityPronoun = user.CustomerProfile.IdentityPronoun;
        if (identityPronounsControl && identityPronounsControl.value !== userSnapshot.IdentityPronoun) {
            userSnapshot.IdentityPronoun = identityPronounsControl.value;
        }

        const ethnicityControl = this.getFormControl(FormFieldConstants.RaceEthnicityId, form);
        userSnapshot.Ethnicity = user.CustomerProfile.Ethnicity;
        if (ethnicityControl && ethnicityControl.value !== userSnapshot.Ethnicity) {
            userSnapshot.Ethnicity = ethnicityControl.value;
        }

        const biographyFormGroup = this.getFormControl(FormFieldConstants.BiographyId, form);
        if (biographyFormGroup) {
            const biographyControl = biographyFormGroup.get(
                FormBuilderService.getSubfieldIdById(FormFieldConstants.BiographyId, Constants.FormSubFieldNameConstants.biographyText));
            userSnapshot.Biography = user.CustomerProfile.Biography;
            if (biographyControl && biographyControl.value !== userSnapshot.Biography) {
                userSnapshot.Biography = biographyControl.value;
            }
        }
        const prefixControl = this.getFormControl(FormFieldConstants.NamePrefixId, form);
        userSnapshot.Prefix = user.NameCredentials.NamePrefix;
        if (prefixControl && prefixControl.value !== userSnapshot.Prefix) {
            userSnapshot.Prefix = prefixControl.value;
        }

        const suffixControl = this.getFormControl(FormFieldConstants.NameSuffixId, form);
        userSnapshot.Suffix = user.NameCredentials.NameSuffix;
        if (suffixControl && suffixControl.value !== userSnapshot.Suffix) {
            userSnapshot.Suffix = suffixControl.value;
        }

        const credentialsControl = this.getFormControl(FormFieldConstants.NameCredentialsId, form);
        userSnapshot.Credentials = user.NameCredentials.Credentials;
        if (credentialsControl && credentialsControl.value !== userSnapshot.Credentials) {
            userSnapshot.Credentials =
                BaseMultipleOptionsFieldComponent.mapFromMultiSelectToCsv(credentialsControl.value);
        }
        userSnapshot.Credentials = this.orderCredentials(userSnapshot.Credentials, profileFormOption);

        const npiControl = this.getFormControl(FormFieldConstants.NPId, form);
        userSnapshot.Npi = user.CustomerProfile.Npi;
        if (npiControl && npiControl.value !== userSnapshot.Npi) {
            if (Utilities.isUndefinedOrNull(npiControl.value) || npiControl.value === Utilities.stringEmpty) {
                userSnapshot.Npi = 0;
            } else {
                userSnapshot.Npi = npiControl.value;
            }
        }
    }

    private mapPhoneFields(form: FormGroup, userSnapshot: CustomerProfileSnapshot) {
        const phoneFormGroup = this.getFormControl(FormFieldConstants.TelephoneId, form) as FormGroup;
        if (!phoneFormGroup) {
            return;
        }
        for (const key of Object.keys(phoneFormGroup.controls)) {
            const formControl = phoneFormGroup.get(key) as FormGroup;
            const phoneNumberField = formControl.get(Constants.FormSubFieldNameConstants.phoneNumber);
            const phoneExtensionField = formControl.get(Constants.FormSubFieldNameConstants.phoneExtension);
            const phoneTypeField = formControl.get(Constants.FormSubFieldNameConstants.phoneType);
            const phoneCountryField = formControl.get(Constants.FormSubFieldNameConstants.countryCode);
            const phoneCountryCodeField = formControl.get(Constants.FormSubFieldNameConstants.countryCodeNumber);
            const donotCallFlagField = formControl.get(Constants.FormSubFieldNameConstants.doNotCallFlag);
            const phoneIncludeInDirectoryFlagField = formControl.get(Constants.FormSubFieldNameConstants.phoneIncludeInDirectoryFlag);
            const isPhoneDeleted = formControl.get(Constants.AdditionalFieldConstants.deletedflag).value;
            const isNew = formControl.get(Constants.AdditionalFieldConstants.newflag).value;
            const isPrimary = formControl.get(Constants.AdditionalFieldConstants.primaryflag).value;

            // tslint:disable-next-line: max-line-length
            const updatePhone = phoneNumberField.dirty || phoneTypeField.dirty || phoneCountryField.dirty || donotCallFlagField.dirty || phoneExtensionField.dirty || phoneIncludeInDirectoryFlagField.dirty || isPhoneDeleted || isNew;

            if (updatePhone) {
                const _phone = phoneNumberField.value.replace(/\D/g, '');
                const isCountryCodeCanOrUS = (phoneCountryField.value === Constants.CommunicationType.defaultCountryCode
                    || phoneCountryField.value === Constants.CommunicationType.canadaCode);
                const _phoneNumber = isCountryCodeCanOrUS
                    ? _phone.substring(3, _phone) : _phone;
                const _areaCode = isCountryCodeCanOrUS
                    ? _phone.substring(0, 3) : '';
                const phone = new CustomerCommunication();
                phone.MasterCustomerId = userSnapshot.MasterCustomerId;
                phone.CommunicationType = 'phone';
                phone.CountryCode = phoneCountryField.value;
                phone.PhoneCountryCode = phoneCountryCodeField.value;
                phone.PhoneExtension = phoneExtensionField.value;
                phone.PhoneNumber = _phoneNumber;
                phone.PhoneAreaCode = _areaCode;
                phone.IsPrimary = isPrimary;
                if (Utilities.isUndefinedOrNull(donotCallFlagField.value)) {
                    phone.DoNotCallFlag = false;
                } else {
                    phone.DoNotCallFlag = donotCallFlagField.value;
                }
                if (Utilities.isUndefinedOrNull(phoneIncludeInDirectoryFlagField.value)) {
                    phone.IncludeInDirectoryFlag = false;
                } else {
                    phone.IncludeInDirectoryFlag = phoneIncludeInDirectoryFlagField.value;
                }
                phone.LocationCode = phoneTypeField.value;
                phone.LocationCodeUpdated = phoneTypeField.dirty;
                phone.IsDeletedFlag = isPhoneDeleted;
                phone.IsNewFlag = isNew;
                userSnapshot.Communications.push(phone);
            }

        }
    }

    private mapCellPhoneFields(form: FormGroup, userSnapshot: CustomerProfileSnapshot) {
        const formControl = this.getFormControl(FormFieldConstants.MobileId, form) as FormGroup;
        if (!formControl) {
            return;
        }
        const mobileOptinControl = formControl.get(Constants.FormSubFieldNameConstants.mobileOptinFlag);
        const phoneNumberField = formControl.get(Constants.FormSubFieldNameConstants.phoneNumber);
        const phoneTypeField = formControl.get(Constants.FormSubFieldNameConstants.phoneType);
        const phoneCountryField = formControl.get(Constants.FormSubFieldNameConstants.countryCode);
        const phoneCountryCodeField = formControl.get(Constants.FormSubFieldNameConstants.countryCodeNumber);
        const isNew = formControl.get(Constants.AdditionalFieldConstants.newflag).value;

        // tslint:disable-next-line: max-line-length
        const updatePhone = phoneNumberField.dirty || phoneTypeField.dirty || phoneCountryField.dirty;

        if (updatePhone) {
            const _phone = phoneNumberField.value.replace(/\D/g, '');
            const isCountryCodeCanOrUS = (phoneCountryField.value === Constants.CommunicationType.defaultCountryCode
                || phoneCountryField.value === Constants.CommunicationType.canadaCode);
            const _phoneNumber = isCountryCodeCanOrUS
                ? _phone.substring(3, _phone) : _phone;
            const _areaCode = isCountryCodeCanOrUS
                ? _phone.substring(0, 3) : '';

            const shouldDelete = _phoneNumber === null || _phoneNumber === ''
                || phoneCountryField.value === null || phoneCountryField.value === '';

            const phone = new CustomerCommunication();
            phone.MasterCustomerId = userSnapshot.MasterCustomerId;
            phone.CommunicationType = 'mobile';
            phone.CountryCode = phoneCountryField.value;
            phone.PhoneCountryCode = phoneCountryCodeField.value;
            phone.PhoneExtension = null;
            phone.PhoneNumber = _phoneNumber;
            phone.PhoneAreaCode = _areaCode;
            phone.IsPrimary = true;
            phone.DoNotCallFlag = false;
            phone.IncludeInDirectoryFlag = false;
            phone.LocationCode = phoneTypeField.value;
            phone.LocationCodeUpdated = phoneTypeField.dirty;
            phone.IsDeletedFlag = shouldDelete;
            phone.IsNewFlag = isNew;
            userSnapshot.Communications.push(phone);
        }
        if (!phoneNumberField || !phoneNumberField.value) {
            userSnapshot.MobileOptin = 'NO';
        } else {
            userSnapshot.MobileOptin = mobileOptinControl.value;
        }
    }

    private mapPhotoFields(userSnapshot: CustomerProfileSnapshot, user: Customer) {
        userSnapshot.ProfileImageBase64String = user.CustomerProfile.ProfileImageBase64String;
        userSnapshot.ProfileImageId = user.CustomerProfile.ProfileImageId;
    }

    mapEmailAddressFields(userSnapshot: CustomerProfileSnapshot, form: FormGroup, user: Customer) {
        const emailFormGroup = this.getFormControl(FormFieldConstants.EmailAddressId, form) as FormGroup;
        if (!emailFormGroup) {
            return;
        }
        for (const key of Object.keys(emailFormGroup.controls)) {
            const formControl = emailFormGroup.get(key) as FormGroup;
            const emailAddressTypeField = formControl.get(Constants.FormSubFieldNameConstants.emailType);
            const emailAddressField = formControl.get(Constants.FormSubFieldNameConstants.emailAddress);
            const isEmailDeleted = formControl.get(Constants.AdditionalFieldConstants.deletedflag).value;
            const currentEmail =
                user.Communications.find(x => x.CommunicationType.toLowerCase() === Constants.CommunicationType.email && x.IsPrimary);
            const isNew = formControl.get(Constants.AdditionalFieldConstants.newflag).value;
            const isPrimary = formControl.get(Constants.AdditionalFieldConstants.primaryflag).value;
            userSnapshot.Email = emailAddressField.value;
            if (emailAddressTypeField.dirty || emailAddressField.dirty || !currentEmail || isEmailDeleted || isNew) {
                const emailCommunication = {
                    MasterCustomerId: userSnapshot.MasterCustomerId,
                    CountryCode: userSnapshot.Country,
                    CommunicationType: Constants.CommunicationType.email,
                    EmailAddress: emailAddressField.value,
                    IsPrimary: isPrimary,
                    LocationCode: emailAddressTypeField.value,
                    LocationCodeUpdated: emailAddressTypeField.dirty,
                    IsDeletedFlag: isEmailDeleted,
                    IsNewFlag: isNew
                } as CustomerCommunication;
                userSnapshot.Communications.push(emailCommunication);
            }
        }
    }
    mapEmergencyContactFields(userSnapshot: CustomerProfileSnapshot, form: FormGroup, user: Customer) {
        const emergencyContactFormGroup = this.getFormControl(FormFieldConstants.EmergencyContactInformationId, form) as FormGroup;
        if (!emergencyContactFormGroup) {
            return;
        }
        for (const key of Object.keys(emergencyContactFormGroup.controls)) {
            const formControl = emergencyContactFormGroup.get(key) as FormGroup;
            const contactNameControl =
                formControl.get(
                    `${FormFieldConstants.EmergencyContactInformationId}-${Constants.FormSubFieldNameConstants.contactName}`);
            const relationshipControl =
                formControl.get(
                    `${FormFieldConstants.EmergencyContactInformationId}-${Constants.FormSubFieldNameConstants.relationship}`);
            const phoneNumberControl =
                formControl.get(
                    `${FormFieldConstants.EmergencyContactInformationId}-${Constants.FormSubFieldNameConstants.phoneNumber}`);
            const phoneTypeControl =
                formControl.get(
                    `${FormFieldConstants.EmergencyContactInformationId}-${Constants.FormSubFieldNameConstants.phoneType}`);
            const commentsControl =
                formControl.get(
                    `${FormFieldConstants.EmergencyContactInformationId}-${Constants.FormSubFieldNameConstants.comments}`);
            const emergencyContactIdControl =
                formControl.get(
                    `${FormFieldConstants.EmergencyContactInformationId}-${Constants.FormSubFieldNameConstants.emergencyContactId}`);
            const isEmergencyContactDeleted =
                formControl.get(Constants.AdditionalFieldConstants.deletedflag).value;
            const isNew = formControl.get(Constants.AdditionalFieldConstants.newflag).value;
            if ((contactNameControl.dirty || relationshipControl.dirty || phoneTypeControl.dirty ||
                phoneNumberControl.dirty || commentsControl.dirty || isNew || isEmergencyContactDeleted)
                && (phoneNumberControl.value && contactNameControl.value && relationshipControl.value)) {
                const emergencyContact = {

                    Comments: commentsControl.value,
                    ContactName: contactNameControl.value,
                    PhoneNumber: phoneNumberControl.value,
                    PhoneType: phoneTypeControl.value,
                    Relationship: relationshipControl.value,
                    IsDeleted: isEmergencyContactDeleted,
                    IsNew: isNew,
                    EmergencyContactId: emergencyContactIdControl.value

                } as CustomerEmergencyContactSnapshot;
                userSnapshot.EmergencyContact.push(emergencyContact);
            }
        }
    }
    mapWebSocialMediaFields(userSnapshot: CustomerProfileSnapshot, form: FormGroup, user: Customer) {
        const webSocialMediaFormGroup = this.getFormControl(FormFieldConstants.WebSocialMediaLinksId, form) as FormGroup;
        if (!webSocialMediaFormGroup) {
            return;
        }
        for (const key of Object.keys(webSocialMediaFormGroup.controls)) {
            const formControl = webSocialMediaFormGroup.get(key) as FormGroup;
            const webSiteNameControl =
                formControl.get(`${Constants.FormSubFieldNameConstants.webSiteName}`);
            const webSiteLinkControl =
                formControl.get(`${Constants.FormSubFieldNameConstants.webSiteAddress}`);
            const webIncludeInDirectoryFlagControl =
                formControl.get(`${Constants.FormSubFieldNameConstants.webIncludeInDirectoryFlag}`);
            const isWebSocialLinkDeleted = formControl.get(Constants.AdditionalFieldConstants.deletedflag).value;
            const isNew = formControl.get(Constants.AdditionalFieldConstants.newflag).value;
            const isPrimary = formControl.get(Constants.AdditionalFieldConstants.primaryflag).value;
            if ((webSiteLinkControl.dirty || webSiteNameControl.dirty || isNew ||
                isWebSocialLinkDeleted || webIncludeInDirectoryFlagControl.dirty) && (
                    webSiteNameControl.value && webSiteLinkControl.value)) {
                const webSocialMedia = {
                    SocialMediaLink: webSiteLinkControl.value,
                    LocationCode: webSiteNameControl.value,
                    MasterCustomerId: userSnapshot.MasterCustomerId,
                    CommunicationType: Constants.CommunicationType.web,
                    LocationCodeUpdated: webSiteNameControl.dirty,
                    IsDeletedFlag: isWebSocialLinkDeleted,
                    IsNewFlag: isNew,
                    IsPrimary: isPrimary,
                    IncludeInDirectoryFlag:
                        Utilities.isUndefinedOrNull(webIncludeInDirectoryFlagControl.value) ?
                            false : webIncludeInDirectoryFlagControl.value,
                } as CustomerCommunication;
                userSnapshot.Communications.push(webSocialMedia);
            }
        }
    }

    private mapAddressFields(
        userSnapshot: CustomerProfileSnapshot, form: AbstractControl,
        user: Customer, profileFormOptions: ProfileFormOption[]) {
        const addressFormGroup = this.getFormControl(FormFieldConstants.AddressId, form) as FormGroup;
        const addressIncludeInDirectoryFlagField = addressFormGroup.get(Constants.FormSubFieldNameConstants.addressIncludeInDirectoryFlag);
        for (const key of Object.keys(addressFormGroup.controls)) {
            const formControl = addressFormGroup.get(key) as FormGroup;
            const isPrimary = formControl.get(Constants.AdditionalFieldConstants.primaryflag).value;
            const addressLine1 = formControl.get(Constants.FormSubFieldNameConstants.addressLine1);
            const addressLine2 = formControl.get(Constants.FormSubFieldNameConstants.addressLine2);
            const addressLine3 = formControl.get(Constants.FormSubFieldNameConstants.addressLine3);
            const city = formControl.get(Constants.FormSubFieldNameConstants.city);
            const state = formControl.get(Constants.FormSubFieldNameConstants.state);
            const postalCode = formControl.get(Constants.FormSubFieldNameConstants.postalCode);
            const addressType = formControl.get(Constants.FormSubFieldNameConstants.addressType);
            const addressIncludeInDirectoryFlag = formControl.get(Constants.FormSubFieldNameConstants.addressIncludeInDirectoryFlag);
            const jobTitle = formControl.get(Constants.FormSubFieldNameConstants.jobTitle);
            const company = formControl.get(Constants.FormSubFieldNameConstants.organization);
            const isAddressDeleted = formControl.get(Constants.AdditionalFieldConstants.deletedflag).value;
            const countryCode = formControl.get(Constants.FormSubFieldNameConstants.countryCode);
            const addressId = formControl.get(Constants.AdditionalFieldConstants.id);
            const isNew = formControl.get(Constants.AdditionalFieldConstants.newflag).value;
            if (isPrimary) {
                userSnapshot.AddressId = addressId.value;
                userSnapshot.Address1 = addressLine1.value;
                userSnapshot.Address2 = addressLine2.value;
                if (addressLine3 !== null) {
                    userSnapshot.Address3 = addressLine3.value;
                }
                userSnapshot.AddressType = addressType.value;
                if (state !== null) {
                    userSnapshot.State = state.value;
                }
                if (postalCode !== null) {
                    userSnapshot.PostalCode = postalCode.value;
                }
                if (this.IsAddressTypeWork(userSnapshot.AddressType)) {
                    userSnapshot.CompanyName = company.value;
                    userSnapshot.JobTitle = jobTitle.value;
                } else {
                    userSnapshot.JobTitle = '';
                    userSnapshot.CompanyName = '';
                }
                userSnapshot.IncludeInDirectoryFlag = addressIncludeInDirectoryFlag.value;
                userSnapshot.City = city.value;
                userSnapshot.IsNew = isNew;
                userSnapshot.IsDeleted = isAddressDeleted;
                const applicationCountryOptions =
                    profileFormOptions.find(x => x.key === Constants.UserProfileFormOptions.ApplicationCountries);
                const country = applicationCountryOptions.value.find(x => x.PreferenceCode === countryCode.value);
                userSnapshot.Country = country.PreferenceDescription;
            } else if (addressLine1.dirty || (addressLine2 != null && addressLine2.dirty) || (addressLine3 != null && addressLine3.dirty) ||
                jobTitle.dirty || addressType.dirty || company.dirty ||
                addressType.dirty || addressIncludeInDirectoryFlag.dirty ||
                isNew || isAddressDeleted || (city != null && city.dirty) ||
                (postalCode != null && postalCode.dirty) || (state != null && state.dirty)) {
                const applicationCountryOptions =
                    profileFormOptions.find(x => x.key === Constants.UserProfileFormOptions.ApplicationCountries);
                const country = applicationCountryOptions.value.find(x => x.PreferenceCode === countryCode.value);
                const address = {
                    Address1: addressLine1.value,
                    Address2: addressLine2 === null ? Utilities.stringEmpty :
                        addressLine2 !== null && addressLine2.value === undefined ? Utilities.stringEmpty : addressLine2.value,
                    Address3: addressLine3 === null ? Utilities.stringEmpty :
                        addressLine3 !== null && addressLine3.value === undefined ? Utilities.stringEmpty : addressLine3.value,
                    AddressType: addressType.value,
                    AddressId: addressId.value,
                    PostalCode: postalCode !== null ? postalCode.value : Utilities.stringEmpty,
                    JobTitle: this.IsAddressTypeWork(addressType.value) ? jobTitle.value : Utilities.stringEmpty,
                    CompanyName: this.IsAddressTypeWork(addressType.value) ? company.value : Utilities.stringEmpty,
                    City: city !== null ? city.value : Utilities.stringEmpty,
                    State: state !== null ? state.value : Utilities.stringEmpty,
                    Country: country.PreferenceDescription,
                    IncludeInDirectoryFlag: addressIncludeInDirectoryFlag.value,
                    IsNew: isNew,
                    IsDeleted: isAddressDeleted
                } as CustomerAddressSnapshot;
                userSnapshot.Address.push(address);
            }
        }
    }

    private orderCredentials(userCredentials: string, profileFormOptions: ProfileFormOption[]) {
        const _credentials = userCredentials.split(',');
        const orderedCreds: string[] = new Array();
        const allCredentials = profileFormOptions.find(x => x.key === UserProfileFormOptions.AllCredentials);

        allCredentials.value.sort((a, b) => {
            return a.SortBy - b.SortBy;
        });
        allCredentials.value.forEach(allCred => _credentials.forEach(selectedCred => {
            if (selectedCred.toLowerCase() === allCred.PreferenceCode.toLowerCase()) {
                orderedCreds.push(selectedCred);
            }
        }));
        const newOrderedCreds = orderedCreds.join(',');
        return newOrderedCreds;
    }
    private IsAddressTypeWork(addressType: string): boolean {
        if (addressType === Constants.Codes.Work || addressType === Constants.Codes.Work2) {
            return true;
        } else {
            return false;
        }
    }

    // formName = form-root is a default value that will never be matched,
    // allows the top level call to pass in the root and not have it be returned back
    private getFormControl(id: string, formGroup: AbstractControl, formName: string = 'form-root'): AbstractControl {
        let control: AbstractControl = null;
        if (formName === id) {
            return formGroup;
        }
        if (formGroup instanceof FormGroup) {
            const group = formGroup as FormGroup;
            for (const key of Object.keys(group.controls)) {
                if (key === id) {
                    return group.controls[key];
                }
                control = this.getFormControl(id, group.controls[key], key);
                if (control) {
                    return control;
                }
            }
        }
        return null;
    }
}
