import { Injectable } from '@angular/core';
import { Constants } from '@shared/services/constants';
import { ActivatedRoute, ActivatedRouteSnapshot, ParamMap } from '@angular/router';
import { Utilities } from '@shared/services/utils';

@Injectable({
  providedIn: 'root'
})
export class ApplicationFlowParameterCacheService {

  constructor() {

  }

  setFromQueryString(route: ActivatedRouteSnapshot) {
    const routeSnapshot = route.queryParamMap;
    this.setApplicationType(routeSnapshot);
    this.setHideHeaderFooter(routeSnapshot);
    this.setRedirectUrl(routeSnapshot);
    this.setDebugFlag(routeSnapshot);
  }
  clear() {
    this.clearApplicationType();
    this.clearRedirectUrl();
    this.clearHideHeaderFooter();
  }

  private clearRedirectUrl() {
    sessionStorage.removeItem(Constants.ApiParams.RedirectPath);
  }

  public clearApplicationType() {
    sessionStorage.removeItem(Constants.ApiParams.ApplicationType);
  }
  public clearHideHeaderFooter() {
    sessionStorage.removeItem(Constants.ApiParams.HideHeaderFooter);
  }

  public setApplicationType(paramMap: ParamMap) {
    this.setSessionStorageItem(Constants.ApiParams.ApplicationType, paramMap);
  }
  public getApplicationType() {
    return sessionStorage.getItem(Constants.ApiParams.ApplicationType);
  }
  public setDebugFlag(paramMap: ParamMap) {
    this.setSessionStorageItem(Constants.ApiParams.DebugKey, paramMap);
  }
  public getDebugFlag() {
    return sessionStorage.getItem(Constants.ApiParams.DebugKey);
  }

  public setRedirectUrl(paramMap: ParamMap) {
    this.setSessionStorageItem(Constants.QueryParams.RedirectUri, paramMap, Constants.ApiParams.RedirectPath);
    this.setSessionStorageItem(Constants.ApiParams.RedirectPath, paramMap);
  }

  public setHideHeaderFooter(paramMap: ParamMap) {
    this.setSessionStorageItem(Constants.ApiParams.HideHeaderFooter, paramMap);
  }

  public getHideHeaderFooter(): boolean {
    const hideHeaderFooter = sessionStorage.getItem(Constants.ApiParams.HideHeaderFooter);
    if (Utilities.isUndefinedOrNull(hideHeaderFooter)) {
      return false;
    }
    return hideHeaderFooter.toLowerCase() === 'true';
  }
  private setSessionStorageItem(constantName: string, paramMap: ParamMap, overrideSessionStorageName: string = null) {
    const item = paramMap.get(constantName);
    if (item) {
      if (overrideSessionStorageName) {
        sessionStorage.setItem(overrideSessionStorageName, item);
      } else {
        sessionStorage.setItem(constantName, item);
      }
    }
  }

  public getRedirectUrl() {
    if (this.getApplicationType() === Constants.ApplicationTypes.OVT) {
      return null;
    }
    return sessionStorage.getItem(Constants.ApiParams.RedirectPath);
  }
}
