import { Component, OnInit, Input } from '@angular/core';
import { Customer } from '@shared/models/data';

@Component({
  selector: 'aaos-profile-image',
  templateUrl: './profile-image.component.html',
  styleUrls: ['./profile-image.component.scss']
})
export class ProfileImageComponent implements OnInit {
  @Input() user: Customer;
  @Input() tmpProfilePictureUrl: string;
  constructor() { }

  ngOnInit() { }
}
