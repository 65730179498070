<div class="confirm-modal">
  <div *ngIf="email">
    <div class="title title--xl">Unsubscribed From {{ description }}</div>
    <p class="title title--xs">
      {{ email }} has been removed from the AAOS email list for {{ description }}.
    </p>
    <p>
      AAOS strives to deliver important and relevant information in a timely manner to keep you
      connected to the latest orthopaedic and AAOS news, product and meeting updates, and more. To
      manage your AAOS email subscriptions, please visit
      <a
        [queryParams]="{ emailtype: '' }"
        queryParamsHandling="merge"
        [routerLink]="['/manage-preferences']"
        >My Account Email Preferences</a
      >.
    </p>
    <p>
      For additional assistance, please contact Customer Service at
      <a href="tel:1-847-823-7186">1-847-823-7186</a> or
      <a href="mailto:customerservice@aaos.org">customerservice@aaos.org</a>. For more information
      on your data privacy rights, see the AAOS Privacy Policy or contact the
      <a href="mailto:privacy@aaos.org">AAOS Data Privacy Officer</a> at
      <a href="mailto:privacy@aaos.org">privacy@aaos.org</a>.
    </p>
  </div>
  <div *ngIf="!success && !email">
    <div class="title title--xl">An error occurred while updating your email preferences</div>
    <p *ngIf="error">{{ error }}</p>
    <p>
      For additional assistance, please contact Customer Service at
      <a href="tel:1-847-823-7186">1-847-823-7186</a> or
      <a href="mailto:customerservice@aaos.org">customerservice@aaos.org</a>.
    </p>
  </div>
</div>
