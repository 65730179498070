import { Component, OnInit, Input } from '@angular/core';
import { FormField, FormFieldRuleConstants, FormFieldConstants, FieldTypeConstants } from '@shared/form-fields/models/form-field';
import { FormGroup } from '@angular/forms';
import { BaseFieldComponent } from '@shared/form-fields/baseFieldComponent';
import { Customer } from '@shared/models/data';

@Component({
  selector: 'aaos-text-field',
  templateUrl: './text-field.component.html',
  styleUrls: ['./text-field.component.scss']
})
export class TextFieldComponent extends BaseFieldComponent implements OnInit {
  @Input()
  fullwidth: boolean;
  isOptional: boolean;
  formControlName: string;
  toolTipText: string = '';
  @Input()
  placeholder: string = '';
  @Input()
  mask: string = '';

  ngOnInit() {
    super.ngOnInit();
    if (this.field != null) {
      if (this.field.placeholderText && this.field.placeholderText.length > 0) {
        this.placeholder = this.field.placeholderText;
      }
    }
  }

  showValidationError() {
    if (this.form && this.field) {
      const control = this.form.get(this.field.fieldId);
      return !this.showRequiredError() && control.invalid;
    } else { return null; }
  }
}


