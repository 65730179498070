<ng-container *ngIf="showValidationError(); then invalid; else valid">
</ng-container>
<ng-template #invalid>
  <div class="validation validation-not-met">
    <i class="material-icons">error_outline</i>
    <span class="text"> {{message}}</span>
  </div>
</ng-template>
<ng-template #valid>
  <div class="validation validation-met">
    <i class="material-icons">done</i>
    <span class=" text"> {{message}}</span>
  </div>
</ng-template>