<aaos-header [isProfilePage]="true" *ngIf="user" [showEmailPreferencesLink]="showEmailPreferencesLink"></aaos-header>
<div class="flexbox container" *ngIf="user">
    <div *ngIf="user">
        <aaos-notification-modal [user]="user"></aaos-notification-modal>
        <div class="profile-header">
            <div class="profile-header-text">
                <div *ngIf="annualUpdate" class="annual-update-container">
                    <div class="title title--md">Confirm Profile Information</div>
                    <div class="confirm-text">
                        Please confirm that the information below is accurate and up to date.
                    </div>
                    <div class="confirm-link" (click)="save()">Everything is correct</div>
                </div>
                <div class="my-profile-text">
                    <div class="title title--xlg">My Profile</div>
                    <div class="profile-text">
                        AAOS strives to deliver important, relevant, and timely information to keep you
                        connected to the latest orthopaedic and AAOS news, product and meeting updates, and
                        more.
                    </div>
                    <aaos-user-login-information [user]="user" [showButtons]="!impersonatedMasterCustomerId">
                    </aaos-user-login-information>
                    <div class="btn--container">
                        <button class="btn btn--solid btn--red" routerLink="edit">
                            Edit Profile
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <aaos-dynamic-user-profile-edit [editMode]="false" [userTrackerService]="userTrackerService"
                                        [isChildComponent]="true" (finished)="handleFinishProfile($event)">
        </aaos-dynamic-user-profile-edit>
    </div>
</div>
<aaos-footer *ngIf="user"></aaos-footer>
<aaos-spinner [isLoading]="!user" [isLoading$]="loading$"></aaos-spinner>