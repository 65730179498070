import { OnInit, OnDestroy, Input } from '@angular/core';
import { Customer } from '@shared/models/data';
import { UserTrackerService } from '@shared/services/trackers';
import { Subject, Observable, of } from 'rxjs';
import { filter, tap, takeUntil, take, catchError } from 'rxjs/operators';
import { Constants } from '@shared/services/constants';
import { ActivatedRoute } from '@angular/router';
import { ApplicationFlowParameterCacheService } from '@shared/services/application-flow/application-flow-parameter-cache.service';
import { EmailPreferencesService, UserProfileService } from '@shared/services/api';
import { APP_CONFIG } from '@config/main';
import { AllowedRedirectUrlsService } from '@shared/services/allowed-redirect-urls.service';
import { AnnualMeetingRedirectService } from '@shared/services/api/annual-meeting-redirect.service';
import { GlobalNavigationService } from '@shared/services/api/global-nav-service';

export abstract class UserProfileBaseComponent implements OnDestroy, OnInit {
  @Input()
  isChildComponent: boolean;
  isRedirecting: boolean;
  error: boolean;
  protected _destroy$: Subject<void> = new Subject<void>();
  protected user$: Observable<Customer>;
  showHeaderAndFooter = false;
  enableDebug = false;
  @Input()
  showGlobalNavigation: boolean;
  public user: Customer;
  flowParamCache: ApplicationFlowParameterCacheService;
  @Input()
  userTrackerService: UserTrackerService;
  applicationType: string;
  public showBreadCrumb: boolean = true;
  public showCancelButton: boolean = true;
  public submitButtonText = Constants.ButtonText.Submit;
  public showEmailPreferencesLink: boolean = false;
  allowedUrls: Array<string>;
  @Input()
  refreshEditState$: Subject<void>;
  impersonatedMasterCustomerId: string;
  initialFocusField: string;
  checkingCompletion: boolean = false;
  constructor(
    protected route: ActivatedRoute,
    flowParamCache: ApplicationFlowParameterCacheService,
    protected profileService: UserProfileService,
    private allowedUrlService: AllowedRedirectUrlsService,
    private annualMeetingRedirectService: AnnualMeetingRedirectService,
    public globalNavigationService: GlobalNavigationService,
    private emailPreferencesService: EmailPreferencesService) {
    this.flowParamCache = flowParamCache;
  }

  ngOnInit(): void {
    this.impersonatedMasterCustomerId = this.route.snapshot.queryParamMap.get(Constants.ApiUrls.ImpersonateMasterCustomerId);
    this.initialFocusField = this.route.snapshot.queryParamMap.get('goToField');
    this.applicationType = this.flowParamCache.getApplicationType();
    if (this.applicationType === Constants.ApplicationTypes.AnnualMeeting ||
      this.applicationType === Constants.ApplicationTypes.MAAP) {
      this.showBreadCrumb = false;
      this.showCancelButton = false;
      this.submitButtonText = Constants.ButtonText.SaveAndContinue;

      if (this.applicationType === Constants.ApplicationTypes.AnnualMeeting) {
        this.checkingCompletion = true;
        //if user profile was last updated within the last year, then redirect back to CDS
        this.profileService.getProfileScore().subscribe(x => {
          let currentDate = new Date();
          
          let days = 500; //essentially always ask for profile unless we're told different
          if (x.LastConfirmationDate != null && x.LastConfirmationDate != undefined) {
            let profileDate = new Date(x.LastConfirmationDate);
            days = Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) - Date.UTC(profileDate.getFullYear(), profileDate.getMonth(), profileDate.getDate())) / (1000 * 60 * 60 * 24));
          }

          if (days <= 365) {
            this.annualMeetingRedirectService.getRedirectUrl().subscribe((_: any) => {
              this.flowParamCache.clear();
              window.location.replace(`${_.url}`);
            });
          } else {
            this.checkingCompletion = false;
          }
        });
      }
    } else {
      this.emailPreferencesService.canUserUpdateEmailPreferences().subscribe(result => {
        this.showEmailPreferencesLink = result;
      });
    }

    this.showHeaderAndFooter = !this.flowParamCache.getHideHeaderFooter();
    this.enableDebug = this.flowParamCache.getDebugFlag() === Constants.KeyValues.DebugKeyValue;
    if (this.userTrackerService != null) {
      this.user$ = this.userTrackerService.user$.pipe(
        filter(user => !!user),
        tap((_: Customer) => {
          this.user = _;
        }),
        takeUntil(this._destroy$)
      );
    }

    this.allowedUrlService.getAllowedUrls().subscribe(x => this.allowedUrls = x);
    if (!this.isChildComponent) {
      const userSubscription = this.getProfileDetailed(this.impersonatedMasterCustomerId)
        .subscribe(_ => { userSubscription.unsubscribe(); });
    } else {
      this.user = this.userTrackerService.getUser(); // just in case the user has already returned before the child component loads
    }

    this.setApplicationType();
    this.setRedirectUrl();
    this.getGlobalNavSetting();
  }

  private setRedirectUrl() {
    this.flowParamCache.setRedirectUrl(this.route.snapshot.paramMap);
  }

  private setApplicationType() {
    this.flowParamCache.setApplicationType(this.route.snapshot.paramMap);
    this.applicationType = this.flowParamCache.getApplicationType();
  }

  getProfileDetailed(impersonatedMasterCustomerId: string) {
    return this.profileService.getProfileDetailedCommunications(impersonatedMasterCustomerId).pipe(
      take(1),
      tap((_: Customer) => {
        this.userTrackerService.setUser(_);
        if (this.refreshEditState$) {
          this.refreshEditState$.next();
        }
      }),
      catchError(_ => {
        if (_.status === 401) {
          // user isn't authenticated - let's auth them
          const pathParam = Constants.ApiParams.RedirectPath;
          const pathName = window.location.pathname;
          APP_CONFIG.subscribe(config => {
            window.location.href = `${config.authRedirectUrl}?${pathParam}=${pathName}`;
          });
          this.isRedirecting = true;
        } else {
          this.error = true;
        }
        this.userTrackerService.clearUser();
        return _;
      })
    );
  }

  ngOnDestroy() {
    this._destroy$.next();
  }

  public endSession() {
    const redirectUrl = this.flowParamCache.getRedirectUrl();
    const applicationType = this.flowParamCache.getApplicationType();
    if (applicationType && applicationType.toLowerCase() === Constants.ApplicationTypes.AnnualMeeting.toLowerCase()) {
      return Observable.create(observer => {
        this.annualMeetingRedirectService.getRedirectUrl()
          .subscribe((_: any) => {
            this.flowParamCache.clear();
            window.location.replace(`${_.url}`);
          });
      });
    }
    if (redirectUrl) {
      this.flowParamCache.clear();
      window.location.replace(redirectUrl);
      return of(null);
    } else {
      return of(null);
    }
  }
  checkUrlIsAllowed(redirectUrl: string): boolean {
    try {

      const rx = /([a-z0-9]+\.)*[a-z0-9]+\.[a-z]+/g;
      const arr = rx.exec(redirectUrl);
      const url = arr[0];
      return this.allowedUrls.find(x => x === url) !== undefined;

    } catch (ex) {
      return false;
    }
  }

  reloadPage() {

    setInterval(() => { location.reload(); }, 2000);
    return of(null);
  }
  showGlobalNavBar(_: any) {
    this.showGlobalNavigation = this.applicationType !== Constants.ApplicationTypes.Default &&
      this.applicationType !== Constants.ApplicationTypes.OVT && _.globalnavSetting.toLowerCase() === 'true';
  }
  getGlobalNavSetting() {
    return this.globalNavigationService.getGlobalNavigationSetting().subscribe(_ => {
      this.showGlobalNavBar(_);
    });
  }
}
