﻿export class NotificationModal {
    active: boolean;
    memberTypes: string;
    title: string;
    text: string;
    buttonActive: boolean;
    buttonText: string;
    buttonLink: string;
    custom: boolean;
}
