export class PracticeInformation {
    public MasterCustomerId: string;
    public Subspecialty: string;
    public Areasofinterest: string;
    public UserDefinedProgramSubspecialty: string;
    public AgeGroupTreated: string;
    public PracticeType: string;
    public Language: string;
    public SpecialtyDegree: string;
    public PracticeSetting: string;
    public PracticeBeginDate: number;
    public PracticeSize: string;
}
