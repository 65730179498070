<div class="form-group" *ngIf="formGroup" [formGroup]="formGroup">
  <mat-select class="search-select" [formControlName]="formControlName" (selectionChange)="sendSelectionChanged($event.value)" disableRipple
    disableOptionCentering panelClass="mat-panel" (openedChange)="matSelectOpenStateChanged($event)">
    <input class="search-input dummy-input-for-spacing" >
    <div class="search-inner">

      <input class="search-input" (input)="onInputChange($event.target.value)" placeholder="Search..." [value]="value"
        (keydown)="handleKeydown($event)"/>

      <i class="material-icons clear-search" (click)="clear()">
        clear
      </i>
    </div>

    <div class="required-text" *ngIf="options?.length === 0">
      No countries found
    </div>

    <mat-option *ngFor="let option of options" [value]="option.PreferenceCode">
      {{option.PreferenceDescription}}</mat-option>
  </mat-select>
</div>