import { Component, OnInit, Input, AfterViewInit, Renderer } from '@angular/core';
import { TextFieldComponent } from '@shared/form-fields/text-field/text-field.component';
import { FormField } from '@shared/form-fields/models/form-field';
import { FormGroup, AbstractControl, FormControl } from '@angular/forms';
import { BaseFieldComponent } from '@shared/form-fields/baseFieldComponent';
import { Preference } from '@shared/models/data';
import { Constants } from '@shared/services/constants';
import { Subject, Subscription, BehaviorSubject } from 'rxjs';
import { PrimaryEmailChangedActionService } from '@shared/form-fields/primary-email-changed-action.service';
import { EmailListComponent } from '@shared/form-fields/email-list/email-list.component';
import { Utilities } from '@shared/services/utils';
import { FormFieldValidatorsService } from '@shared/form-fields/form-field-validators.service';
import { UserProfileService } from '@shared/services/api';
import { tap } from 'rxjs/operators';
import { FieldOptionsMapperService } from '@shared/services/membershipForm/field-options-mapper.service';

@Component({
  selector: 'aaos-email-field',
  templateUrl: './email-field.component.html',
  styleUrls: ['./email-field.component.scss']
})
export class EmailFieldComponent extends BaseFieldComponent implements OnInit, AfterViewInit {

  @Input()
  primaryEmailChangedActionService: PrimaryEmailChangedActionService;
  primaryEmailChangeMessage: string;
  isCommunicationInUse: boolean;
  refreshSubscription: Subscription;
  EmailFieldId: string;
  @Input()
  showDeleteIcon: boolean;
  @Input()
  listIndex: number;
  @Input()
  emailTypeSubject: Subject<string[]>;
  @Input()
  selectedEmailTypes: string[] = [];
  EmailAddressFieldName: string;
  emailTypeOptions: Preference[] = [];
  emailTypeObject: BehaviorSubject<object> = new BehaviorSubject<object>(null);
  constructor(
    private profileService: UserProfileService,
    private formFieldValidatorService: FormFieldValidatorsService,
    private emailListComponent: EmailListComponent,
    private renderer: Renderer,
    private fieldOptionsMapperService: FieldOptionsMapperService,
  ) { super(); }
  ngOnInit() {
    super.ngOnInit();
    // readonly views do not do this
    if (this.refreshEditState$) {
      this.refreshSubscription = this.refreshEditState$.subscribe(() => { this.primaryEmailChanged(); });
    }
    if (this.form != null) {
      this.EmailFieldId = this.form.get('Id').value;
    }
    this.buildEmailTypeOptions(this.selectedEmailTypes);
    this.setEmailAddressFieldName();
    if (this.emailTypeSubject != null) {
      this.emailTypeSubject.subscribe(_ => { this.buildEmailTypeOptions(_); });
    }

  }

  ngAfterViewInit(): void {
    if (this.isEmailPrimary() && this.initialFocusField === 'primaryEmail') {
      const input = document.getElementById(this.EmailFieldId);
      this.renderer.invokeElementMethod(input, 'focus');
    }
  }
  primaryEmailChanged() {
    if (!this.primaryEmailChangedActionService) {
      return;
    }

    const control = this.form.get(Constants.FormSubFieldNameConstants.emailAddress);
    const action = this.primaryEmailChangedActionService.emailChanged(control.value);
    if (action.action === 'displayMessage') {
      this.primaryEmailChangeMessage = action.message;
    } else {
      this.primaryEmailChangeMessage = '';
    }
  }
  showRequiredErrorForFieldName(fieldName: string) {
    if (this.form && this.field) {
      const control = this.form.get(fieldName);
      return control.hasError('required');
    }
  }
  showValidationError(fieldName: string) {
    if (this.form && this.field) {
      const control = this.form.get(fieldName);
      const hasRequiredError = this.showRequiredErrorForFieldName(fieldName);
      return !hasRequiredError && control.invalid;
    }
  }
  isEmailValid(emailAddress: string) {
    const emailAddressValidator =
      // tslint:disable-next-line: max-line-length
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailAddressValidator.test(emailAddress);

  }
  isEmailDuplicate(event: any) {
    if (event.value === '') {
      return false;
    }
    if (event.value !== '' && this.isEmailValid(event.value) &&
      this.form.get(Constants.AdditionalFieldConstants.primaryflag).value === true) {
      const result = this.profileService.IsCommunicationInUse(event.value).pipe(
        tap(_ => {
          const fields = _;
          this.isCommunicationInUse = fields.IsCommunicationInUse;
          if (this.isCommunicationInUse) {
            this.formFieldValidatorService.setErrorForEmailControl(this.form.get(Constants.FormSubFieldNameConstants.emailAddress));
          }
        }),
      )
        .subscribe();
    }
    if (this.emailListComponent.isEmailDuplicateForCustomer(event)) {
      this.formFieldValidatorService.
        setErrorForEmailControlMatchesToSameCustomer(this.form.get(Constants.FormSubFieldNameConstants.emailAddress));
    }

  }
  deleteEmail(id: string) {
    this.emailListComponent.delete(id);
  }
  isEmailPrimary() {
    if (this.form != null) {
      return this.form.get(Constants.AdditionalFieldConstants.primaryflag).value === true;
    }
  }
  setEmailAddressFieldName() {
    if (!this.isEmailPrimary()) {
      if (this.form != null) {
        let emailType = this.form.get(Constants.FormSubFieldNameConstants.emailType).value;
        if (emailType === Constants.Codes.Work2) {
          emailType = Constants.Codes.Work;
        }
        if (emailType === Constants.Codes.Home2) {
          emailType = Constants.Codes.Home;
        }
        if (emailType === null) {
          this.EmailAddressFieldName = Constants.FieldName.Email;
        } else {
          this.EmailAddressFieldName = emailType + ' ' + Constants.FieldName.Email;
        }
      }
    } else {
      this.EmailAddressFieldName = Utilities.stringEmpty;
    }
  }
  showErrorByName(name: string) {
    if (this.form && this.field) {
      return this.form.get(Constants.FormSubFieldNameConstants.emailAddress).hasError(name);
    }
  }
  uncheckEmailType(_: any) {
    if (_.value.Id === this.EmailFieldId) {
      return;
    }
    const formGroup = this.form as FormGroup;
    const emailTypeControl = formGroup.get(Constants.FormSubFieldNameConstants.emailType);
    const emailTypeValue = emailTypeControl.value;
    if (_.value.emailType === emailTypeValue && _.value.Id !== this.EmailFieldId) {
      emailTypeControl.setValue(Utilities.stringEmpty);
      this.setEmailAddressFieldName();
    }

  }
  isBlankAdditionalItem() {
    const control = this.form.get(Constants.FormSubFieldNameConstants.emailAddress);
    return !this.editMode && control.value === '' && this.listIndex > 0;
  }
  buildEmailTypeOptions(_: string[]) {
    const emailTypes = this.fieldOptionsMapperService.getEmailTypePreferences();
    if (this.form != null) {
      const currentemailType = this.form.get(`${Constants.FormSubFieldNameConstants.emailType}`).value;
      _.forEach(selectedEmailType => {
        emailTypes.forEach(element => {
          if ((element.PreferenceCode === selectedEmailType) &&
            ((currentemailType !== null && currentemailType.toString() !== selectedEmailType) || currentemailType == null)) {
            element.Active = false;
          }
        });
      });
    }

    this.emailTypeOptions = emailTypes;
  }
  emailTypeChanged(emailType: string) {
    const emailTypeControl = this.form.get(`${Constants.FormSubFieldNameConstants.emailType}`);
    const previouslySelectedValue = emailTypeControl.value;
    emailTypeControl.setValue(emailType);
    this.emailListComponent.buildSelectedEmailTypes();
    if (previouslySelectedValue !== null) {
      const index = this.selectedEmailTypes.indexOf(previouslySelectedValue.toString());
      this.selectedEmailTypes.splice(index);
    }
    this.selectedEmailTypes.push(emailType);
    this.emailTypeSubject.next(this.selectedEmailTypes);
    this.setEmailAddressFieldName();
  }

}
