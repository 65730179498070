import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Component, Output, EventEmitter, Inject } from '@angular/core';
import { ProfileSection } from '@shared/models/enums';

@Component({
  selector: 'aaos-tooltip-dialog',
  templateUrl: './tooltip-dialog.component.html',
  styleUrls: ['./tooltip-dialog.component.scss']
})
export class TooltipDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<TooltipDialogComponent>
  ) { }
}
