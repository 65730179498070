import { NgModule } from '@angular/core';
import { RouterModule, Route, Routes } from '@angular/router';
import { RouteNames } from './route-utils';
import { PageNotFoundComponent } from '../page-not-found/page-not-found.component';
import { UserProfileModule } from '../user-profile/user-profile.module';
import { LoggedInGuard, ClearCookiesGuard } from '@shared/guards';
import { ProfilePromptModule } from '../profile-prompt/profile-prompt.module';
import { EmailPreferencesModule } from '../email-preferences/email-preferences.module';

const updateProfile: Route = {
  canActivate: [LoggedInGuard],
  path: RouteNames.UpdateProfile,
  loadChildren: 'app/profile-prompt/profile-prompt.module#ProfilePromptModule'
};
const emailPreferences: Route = {
  canActivate: [LoggedInGuard],
  path: RouteNames.EmailPreferences,
  loadChildren: 'app/email-preferences/email-preferences.module#EmailPreferencesModule'
};
const managePreferences: Route = {
  path: RouteNames.ManagePreferences,
  loadChildren: 'app/email-preferences/email-preferences.module#EmailPreferencesModule'
};
const user: Route = {
  canActivate: [LoggedInGuard],
  path: RouteNames.User,
  loadChildren: 'app/user-profile/user-profile.module#UserProfileModule'
};
const userProfile: Route = {
  canActivate: [LoggedInGuard],
  path: RouteNames.UserProfile,
  loadChildren: 'app/user-profile/user-profile.module#UserProfileModule'
};
const main: Route = {
  canActivate: [LoggedInGuard],
  path: RouteNames.Root,
  redirectTo: RouteNames.UserProfile,
  pathMatch: 'full'
};
const root: Route = {
  path: RouteNames.Root,
  children: [
    main,
    user,
    userProfile,
    updateProfile,
    emailPreferences,
    managePreferences
  ]
};
const pageNotFound: Route = {
  path: RouteNames.CatchAll,
  component: PageNotFoundComponent
};
const routes: Routes = [root, pageNotFound];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    EmailPreferencesModule,
    UserProfileModule,
    ProfilePromptModule
  ],
  providers: [LoggedInGuard, ClearCookiesGuard],
  exports: [RouterModule]
})
export class MainRoutingModule { }
