import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ApiResponse } from '@shared/models/data';
import { AaosBaseService, LoggingService } from '@shared/services/api';
import { HttpClient } from '@angular/common/http';
import { UpdateUsernameDto } from '@shared/models/data/UpdateUsernameDto';
import { Constants } from '@shared/services/constants';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UpdateUsernameService extends AaosBaseService {
  constructor(_h: HttpClient, private _l: LoggingService) {
    super(_l, _h);
  }

  public updateUsername(newUsername: string): Observable<ApiResponse> {
    const dto = new UpdateUsernameDto();
    dto.newUsername = newUsername;
    return this.http.put(`${Constants.ApiUrls.UpdateUsername}`, dto)
      .pipe(
        map((_: ApiResponse) => _),
        catchError(() => of(new ApiResponse()))
      );
  }
}
