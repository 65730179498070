import { Injectable } from '@angular/core';
import { AppModule } from '../app.module';
import { RouteParamAction } from '@shared/models/contracts';
import { Constants } from '@shared/services/constants';
import { RouteParamActionType } from '@shared/models/enums';

@Injectable({
  providedIn: 'root'
})
export class RootLogicService {
  getUrlParamListeners(): RouteParamAction[] {
    return [
      {
        key: Constants.IframeParameters.Theme,
        action: RouteParamActionType.AddClass
      } as RouteParamAction
    ];
  }
}
