import { Component, OnInit, Input } from '@angular/core';
import { BaseFieldComponent } from '@shared/form-fields/baseFieldComponent';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { Customer } from '@shared/models/data';
import { Constants } from '@shared/services/constants';
import { FormFieldValidatorsService } from '@shared/form-fields/form-field-validators.service';
import { PrimaryEmailChangedActionService } from '@shared/form-fields/primary-email-changed-action.service';
import { Subscription, Subject } from 'rxjs';
import { BaseFieldListComponent } from '@shared/form-fields/baseFieldListComponent';

@Component({
  selector: 'aaos-email-list',
  templateUrl: './email-list.component.html',
  styleUrls: ['./email-list.component.scss']
})
export class EmailListComponent extends BaseFieldListComponent implements OnInit {
  @Input()
  user: Customer;
  formGroupsForEmail: FormGroup[] = [];
  @Input()
  primaryEmailChangedActionService: PrimaryEmailChangedActionService;
  primaryEmailChangeMessage: string;
  refreshSubscription: Subscription;
  @Input()
  editMode: boolean;
  selectedEmailTypes: string[];
  showAddEmailButton: boolean;
  constructor(formFieldValidatorsService: FormFieldValidatorsService) {
    super(formFieldValidatorsService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.buildSelectedEmailTypes();
    this.checkUserForPrimaryEmail();
  }
  buildSelectedEmailTypes() {
    this.selectedEmailTypes = [];
    this.formGroups.forEach(element => {
      if (element.get(Constants.FormSubFieldNameConstants.emailType).value != null) {
        this.selectedEmailTypes.push(element.get(Constants.FormSubFieldNameConstants.emailType).value);
      }
    });
  }
  isEmailDuplicateForCustomer(event: any) {
    const formGroup = this.form as FormGroup;
    for (const key of Object.keys(formGroup.controls)) {
      const formControl = formGroup.get(key) as FormGroup;
      const email = formControl.get(Constants.FormSubFieldNameConstants.emailAddress).value;
      const deleted = formControl.get(Constants.AdditionalFieldConstants.deletedflag).value;
      if (event.value === email && event.id !== key && deleted === false) {
        return true;
      }

    }
    return false;
  }
  addNewEmail() {
    const existingFormGroup = this.form as FormGroup;
    const existingFormControlsCountForEmail = Object.keys(existingFormGroup.controls).length;
    const isPrimary = existingFormControlsCountForEmail === 0;
    const uniqueKey =
      this.user.CustomerProfile.MasterCustomerId + '-' + existingFormControlsCountForEmail + '-' + Constants.CommunicationType.email;
    const newFormGroup = new FormGroup({});
    const validator = this.formFieldValidatorsService.getRuleBaseValidators(this.field.ruleId, this.field.fieldTypeName);
    // tslint:disable-next-line: max-line-length
    validator.push(Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/));
    const typeValidator = this.formFieldValidatorsService.getRuleBaseValidators(this.field.ruleId, this.field.fieldTypeName);
    const fieldState = this.formFieldValidatorsService.getFieldState(this.field);
    newFormGroup.addControl(Constants.FormSubFieldNameConstants.emailAddress,
      new FormControl(fieldState, { validators: validator, updateOn: 'blur' }));
    newFormGroup.addControl(Constants.FormSubFieldNameConstants.emailType, new FormControl(fieldState, typeValidator));
    newFormGroup.addControl(Constants.AdditionalFieldConstants.id, new FormControl());
    newFormGroup.get(Constants.AdditionalFieldConstants.id).setValue(uniqueKey);
    newFormGroup.addControl(Constants.AdditionalFieldConstants.primaryflag, new FormControl(fieldState));
    newFormGroup.get(Constants.AdditionalFieldConstants.primaryflag).setValue(isPrimary);
    newFormGroup.addControl(Constants.AdditionalFieldConstants.newflag, new FormControl(fieldState));
    newFormGroup.get(Constants.AdditionalFieldConstants.newflag).setValue(true);
    newFormGroup.addControl(Constants.AdditionalFieldConstants.deletedflag, new FormControl(fieldState));
    newFormGroup.get(Constants.AdditionalFieldConstants.deletedflag).setValue(false);
    existingFormGroup.addControl(uniqueKey, newFormGroup);
    this.formGroups.push(newFormGroup);
    this.buildSelectedEmailTypes();
    this.emailTypeSubject.next(this.selectedEmailTypes);
    this.setShowAddButton();
  }
  delete(id: string) {
    const formGroup = this.form as FormGroup;
    const deletedFromGroup = formGroup.get(id) as FormGroup;
    const deletedFlagControl = deletedFromGroup.get(Constants.AdditionalFieldConstants.deletedflag);
    const newFlagControl = deletedFromGroup.get(Constants.AdditionalFieldConstants.newflag);
    deletedFlagControl.setValue(true);
    if (newFlagControl.value === true) {
      formGroup.removeControl(id);
    } else {
      // remove validators for delete form which should no
      // longer be validated because it is only being used to track the deleted address
      Object.keys(deletedFromGroup.controls).forEach(_ => {
        const control = deletedFromGroup.controls[_];
        control.setValidators([]);
        control.updateValueAndValidity();
      });
    }
    const formToUpdateValidity = deletedFromGroup as AbstractControl;
    formToUpdateValidity.updateValueAndValidity();
    this.formGroups = [];
    for (const key of Object.keys(formGroup.controls)) {
      const formControl = formGroup.get(key) as FormGroup;
      const deletedFormControl = formControl.get(Constants.AdditionalFieldConstants.deletedflag);
      const deletedValue = deletedFormControl.value;
      if (key !== id && deletedValue === false) {
        this.formGroups.push(formControl);
      }
    }
    if (id.includes(Constants.CommunicationType.email)) {
      this.buildSelectedEmailTypes();
      this.emailTypeSubject.next(this.selectedEmailTypes);
    }
    this.setShowAddButton();
    }

  checkUserForPrimaryEmail() {
      if (this.user.CustomerProfile.PrimaryEmailAddress === '') {
          this.addNewEmail();
      }
  }

}
