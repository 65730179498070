<div class="form-group" *ngIf="form" [formGroup]="form">
  <aaos-form-field-title [editMode]="editMode" [field]="field"></aaos-form-field-title>
  <input *ngIf="(editMode)" class="form-control width--50" [mask]="mask" [placeholder]="placeholder" type="text"
    [formControlName]="field.fieldId" placement="top" [readonly]="isReadonly" />

  <div *ngIf="!(editMode)">
      <div>{{getYear()}}</div>
  </div>
  <div class="required-text" *ngIf="showRequiredError()">
    {{field.fieldName}} is required
  </div>
  <div class="required-text" *ngIf="showValidationError()">
    Please enter a valid {{field.fieldName}}
  </div>
</div>