import { FormFieldConstants } from '@shared/form-fields/models/form-field';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { BaseFieldComponent } from '@shared/form-fields/baseFieldComponent';
import { FieldOptionsMapperService } from '@shared/services/membershipForm/field-options-mapper.service';
import { Utilities } from '@shared/services/utils';
import { DependentFieldSelectedValuesMessage } from './DependentFieldSelectedValuesMessage';
import { DependentFieldRule } from './DependentFieldRule';
import { RuleResponse } from './RuleResponse';
import { Customer } from '@shared/models/data';
export class AreaOfInterestDependentFieldRule extends DependentFieldRule {
    areasOfInterestRegistered: boolean;
    otherAreasOfInterestRegistered: boolean;
    areasInterestSelectedValues: Array<string> = [];
    otherAreasInterestSelectedValues: Array<string> = [];
    selectedValueChangedSubscription: Subscription;
    constructor(private fieldOptionMapper: FieldOptionsMapperService) {
        super();
        this.selectedValueChangedSubscription = this.selectedValuesUpdated$.subscribe(_ => { this.selectedValuesChanged(_); });
    }
    selectedValuesChanged(message: DependentFieldSelectedValuesMessage) {
        if (message) {
            const messageToSend = this.setSelectedValues(message.formGroup);
            this.allowedValuesUpdated$.next(messageToSend.message);
        }
    }
    setSelectedValues(formGroup: FormGroup, user?: Customer): RuleResponse {
        const areaOfInterestFieldValueObjects = [];
        const otherInterestFieldValueObjects = [];
        const areaOfInterestField = formGroup.get(FormFieldConstants.AreasofInterestId);
        const otherAreaOfInterestField = formGroup.get(FormFieldConstants.OtherAreaofInterest);
        const areaOfInterestValue = areaOfInterestField.value as Array<string>;
        const otherAreaOfInterestValue = otherAreaOfInterestField.value as Array<string>;
        const allAreaOfInterestOptions =
            this.fieldOptionMapper.getOptionsForField(FormFieldConstants.AreasofInterestId, this.formOptions, user);
        const allOtherAreaOfInterestOptions =
            this.fieldOptionMapper.getOptionsForField(FormFieldConstants.OtherAreaofInterest, this.formOptions);
        const areaOfInterestOptions = [...allAreaOfInterestOptions];
        const otherAreaOfInterestOptions = [...allOtherAreaOfInterestOptions];
        areaOfInterestField.value.forEach(_ => {
            areaOfInterestFieldValueObjects.push(BaseFieldComponent.parsePrefixForFieldAndOption(_));
        });
        otherAreaOfInterestField.value.forEach(_ => {
            otherInterestFieldValueObjects.push(BaseFieldComponent.parsePrefixForFieldAndOption(_));
        });
        areaOfInterestFieldValueObjects.forEach(_ => {
            const valueIndex = otherInterestFieldValueObjects.findIndex(x => x && x.PreferenceSubCode === _.PreferenceSubCode);
            if (valueIndex > -1) {
                delete otherAreaOfInterestValue[valueIndex];
                delete otherInterestFieldValueObjects[valueIndex];
            }
            const otherValueIndex = otherAreaOfInterestOptions.findIndex(x => x && x.PreferenceSubCode === _.PreferenceSubCode);
            if (otherValueIndex > -1) {
                delete otherAreaOfInterestOptions[otherValueIndex];
            }
        });
        const response = new RuleResponse();
        response.message.allowedValues[FormFieldConstants.AreasofInterestId]
            = areaOfInterestOptions.filter(_ => !Utilities.isUndefinedOrNull(_));
        response.message.values[FormFieldConstants.AreasofInterestId]
            = areaOfInterestValue.filter(_ => !Utilities.isUndefinedOrNull(_));
        response.message.allowedValues[FormFieldConstants.OtherAreaofInterest]
            = otherAreaOfInterestOptions.filter(_ => !Utilities.isUndefinedOrNull(_));
        response.message.values[FormFieldConstants.OtherAreaofInterest]
            = otherAreaOfInterestValue.filter(_ => !Utilities.isUndefinedOrNull(_));
        return response;
    }
    registerFieldForAllowedValues(formGroup: FormGroup, user?: Customer): RuleResponse {
        const response = this.setSelectedValues(formGroup, user);
        response.allowedValuesUpdated$ = this.allowedValuesUpdated$;
        response.selectedValuesUpdated$ = this.selectedValuesUpdated$;
        return response;
    }
}
