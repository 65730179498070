import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Flow } from '@shared/models/data';
import { AaosBaseService } from './aaos-base.service';
import { LoggingService } from './logging.service';

@Injectable({
  providedIn: 'root'
})
export class FlowService extends AaosBaseService {
  // BUG: workaround for https://github.com/angular/angular/pull/25033
  static ngInjectableDef = undefined;

  rootUrl: string;
  constructor(_h: HttpClient, private _l: LoggingService) {
    super(_l, _h);
    this.rootUrl = './assets/flows';
  }

  getFlowByName(name: string) {
    return this.http.get(`${this.rootUrl}/${name}.json`).pipe(
      map((_: Flow) => {
        return _.flows;
      }),
      catchError(this.handleError)
    );
  }

  handleError(err: HttpErrorResponse) {
    const thrown = super.handleError(err);
    return thrown;
  }
}
