import { Pipe, PipeTransform } from '@angular/core';
import { Utilities } from '@shared/services/utils/';
import { AbstractControl } from '@angular/forms';

@Pipe({
  name: 'hasRequiredField'
})
export class RequiredFieldPipe implements PipeTransform {
  transform(control: AbstractControl): boolean {
    return Utilities.hasRequiredField(control);
  }
}
