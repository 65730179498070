import { Component, OnInit } from '@angular/core';
import { UserTrackerService } from '@shared/services/trackers';
import { UserProfileBaseComponent } from '../../user-profile-base.component';
import { Customer } from '@shared/models/data';
import { EmailPreferencesService, UserProfileService } from '@shared/services/api';
import { MatDialog } from '@angular/material';
import { take } from 'rxjs/operators';
import { FormFinishType } from '@shared/models/enums';
import {
  UpdateProfileModalSuccessComponent,
  UpdateProfileModalFailComponent
} from '@shared/components';
import { Router, ActivatedRoute } from '@angular/router';
import { ApplicationFlowParameterCacheService } from '@shared/services/application-flow/application-flow-parameter-cache.service';
import { AllowedRedirectUrlsService } from '@shared/services/allowed-redirect-urls.service';
import { Subject } from 'rxjs';
import { AnnualMeetingRedirectService } from '@shared/services/api/annual-meeting-redirect.service';
import { GlobalNavigationService } from '@shared/services/api/global-nav-service';
import { SaveResult } from '@shared/models/contracts/saveResults';

@Component({
  selector: 'aaos-user-profile-routable-edit',
  templateUrl: './user-profile-routable-edit.component.html',
  styleUrls: ['./user-profile-routable-edit.component.scss']
})
export class UserProfileRoutableEditComponent extends UserProfileBaseComponent
  implements OnInit {
  refreshEditState$ = new Subject<void>();

  constructor(
    tracker: UserTrackerService,
    route: ActivatedRoute,
    private router: Router,
    profileService: UserProfileService,
    private dialog: MatDialog,
    flowParamCache: ApplicationFlowParameterCacheService,
    allowedUrlService: AllowedRedirectUrlsService,
    customerTokenService: AnnualMeetingRedirectService,
    globalNavigationService: GlobalNavigationService,
    emailPreferencesService: EmailPreferencesService) {
    super(route, flowParamCache, profileService, allowedUrlService, customerTokenService, globalNavigationService, emailPreferencesService);
    this.userTrackerService = tracker;
    this.isChildComponent = true;
  }

  ngOnInit() {
    super.ngOnInit();
  }

  finishProfile(saveResult: SaveResult) {
    if (saveResult.type === FormFinishType.Cancel || saveResult.type === FormFinishType.Success) {
      this.dialog.closeAll();
      this.router.navigate(['user-profile']);
    }

    if (saveResult.type !== FormFinishType.Cancel) {
      const dialogRefComponent =
      saveResult.type === FormFinishType.Success
          ? UpdateProfileModalSuccessComponent
          : UpdateProfileModalFailComponent;
      const dialogRef = this.dialog.open(dialogRefComponent, {
        width: '400px',
        data: {
          errors: saveResult.errors
        }
      });
      dialogRef
        .afterOpened()
        .pipe(take(1))
        .subscribe(_ => { this.reloadPage().subscribe(); });
    }
  }
}
