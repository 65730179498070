import { Injectable } from '@angular/core';
import { PrimaryEmailChangedActionService, PrimaryEmailChangedAction } from '@shared/form-fields/primary-email-changed-action.service';
import { Constants } from '@shared/services/constants';

@Injectable({
  providedIn: 'root'
})
export class UserProfilePrimaryEmailUpdatedActionService extends PrimaryEmailChangedActionService {
  originalUsername: string;
  setOriginalValues(originalEmail: string, originalUsername: string) {
    this.originalEmail = originalEmail;
    this.originalUsername = originalUsername;
  }
  emailChanged(email: string): PrimaryEmailChangedAction {
    const action = new PrimaryEmailChangedAction();
    action.action = 'none';
    if (this.originalEmail === this.originalUsername && this.originalEmail !== email) {
      action.action = 'displayMessage';
      action.message = Constants.Warnings.PrimaryEmailChangesUserName;
    }
    return action;
  }
}
