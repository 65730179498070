export class CustomerCommunication {
    CountryCode: string;
    MasterCustomerId: string;
    CommunicationType: 'email' | 'phone' | 'mobile';
    EmailAddress: string;
    PhoneNumber: string;
    SocialMediaLink: string;
    PhoneAreaCode: string;
    PhoneCountryCode: string;
    PhoneExtension: string;
    IsPrimary: boolean;
    LocationCode: string;
    LocationCodeUpdated: boolean;
    DoNotCallFlag: boolean;
    IncludeInDirectoryFlag: boolean;
    IsDeletedFlag: boolean;
    IsNewFlag: boolean;
}

