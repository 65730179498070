import { Pipe, PipeTransform } from '@angular/core';
import { CustomerCommunication } from '@shared/models/data';
import { Constants } from '@shared/services/constants';

@Pipe({
  name: 'aaosCommunicationFilter'
})
export class CommunicationFilterPipe implements PipeTransform {
  transform(
    communications: CustomerCommunication[],
    type: 'phone' | 'email'
  ): CustomerCommunication {
    if (!communications || !type) {
      return null;
    }

    const communication = communications.find(
      _ => (_.CommunicationType || '').toLowerCase() === type
    );

    return communication;
  }
}
