import { Component, OnInit, Input } from '@angular/core';
import { BaseFieldComponent } from '@shared/form-fields/baseFieldComponent';
import { FormField, FormFieldRuleConstants, FormFieldConstants } from '@shared/form-fields/models/form-field';
import { Constants } from '@shared/services/constants';
import { WebMediaListComponent } from '@shared/form-fields/web-media-list/web-media-list.component';
import { FieldOptionsMapperService } from '@shared/services/membershipForm/field-options-mapper.service';
import { Preference } from '@shared/models/data';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { min } from 'rxjs/operators';

@Component({
  selector: 'aaos-web-media-field',
  templateUrl: './web-media-field.component.html',
  styleUrls: ['./web-media-field.component.scss']
})
export class WebMediaFieldComponent extends BaseFieldComponent implements OnInit {
  webSiteNameField: FormField = new FormField();
  webSiteLinkField: FormField = new FormField();
  webIncludeInDirectoryField: FormField = new FormField();
  WebSocialFieldId: string;
  @Input()
  showDeleteIcon: boolean;
  @Input()
  selectedWebSiteNames: string[] = [];
  @Input()
  webSiteNameSubject: Subject<string[]>;
  @Input()
  listIndex: number;
  WebMediaFieldName: string;
  webSiteNameOptions: Preference[] = [];
  readonlyWebIncludeInDirectoryFlag: boolean;
  readonlyWebSiteNameValue: boolean;
  webSiteFieldName: string;

  constructor(private webSocialListComponent: WebMediaListComponent, private fieldOptionsMapperService: FieldOptionsMapperService) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.webSiteLinkField = this.createFormFieldForSubField(Constants.FormSubFieldNameConstants.webSiteAddress);
    this.webSiteLinkField = this.assignOptionalEditableRuleForSubField(this.webSiteLinkField, Constants.FieldName.WebSiteAddress,
      Constants.FormSubFieldNameConstants.webSiteAddress);
    this.webSiteNameField = this.createFormFieldForSubField(Constants.FormSubFieldNameConstants.webSiteName);
    this.webSiteNameField = this.assignOptionalEditableRuleForSubField(this.webSiteNameField, Constants.FieldName.WebSiteName,
      Constants.FormSubFieldNameConstants.webSiteName);
    if (this.form != null) {
      this.WebSocialFieldId = this.form.get(Constants.AdditionalFieldConstants.id).value;
    }

    this.setWebMediaFieldName();
    this.buildSiteNameOptions(this.selectedWebSiteNames);
    if (this.form != null) {
      const webIncludeInDirectoryFlag =
      this.form.get(`${Constants.FormSubFieldNameConstants.webIncludeInDirectoryFlag}`);
      if (webIncludeInDirectoryFlag) {
        this.readonlyWebIncludeInDirectoryFlag = webIncludeInDirectoryFlag.value;
      }
      const webSiteNameControl = this.form.get(`${Constants.FormSubFieldNameConstants.webSiteName}`);
      if (webSiteNameControl) {
        this.readonlyWebSiteNameValue = webSiteNameControl.value;
      }
    }
    this.webSiteFieldName = Constants.FieldName.WebSocialMedia;
    if (this.webSiteNameSubject != null) {
      this.webSiteNameSubject.subscribe(_ => { this.buildSiteNameOptions(_); });
    }

  }
  deleteWebSocial(id: string) {
    this.webSocialListComponent.delete(id);
  }
  assignOptionalEditableRuleForSubField(formField: FormField, fieldName: string, fieldId: string): FormField {
    formField.ruleId = FormFieldRuleConstants.OptionalEditableId;
    formField.ruleName = FormFieldRuleConstants.OptionalEditableName;
    formField.fieldName = fieldName;
    formField.fieldId = fieldId;
    return formField;
  }
  isWebMediaPrimary() {
    if (this.form != null) {
      return this.form.get(Constants.AdditionalFieldConstants.primaryflag).value === true;
    }
  }
  setWebMediaFieldName() {
    if (!this.isWebMediaPrimary() && this.form != null) {
      const webSiteName = this.form.get(`${Constants.FormSubFieldNameConstants.webSiteName}`).value;
      if (webSiteName === null) {
        this.WebMediaFieldName = Constants.FieldName.WebSocialMedia;
      } else {
        this.WebMediaFieldName = Constants.FieldName.WebSocialMedia + '-' + webSiteName;
      }
    }
  }
  webSiteNameChanged(webSiteName: string) {
    const webSiteNameControl = this.form.get(`${Constants.FormSubFieldNameConstants.webSiteName}`);
    const previouslySelectedValue = webSiteNameControl.value;
    webSiteNameControl.setValue(webSiteName);
    this.webSocialListComponent.buildSelectedWebSiteNames();
    if (previouslySelectedValue !== null) {
      const index = this.selectedWebSiteNames.indexOf(previouslySelectedValue.toString());
      this.selectedWebSiteNames.splice(index, 1);
    }
    this.selectedWebSiteNames.push(webSiteName);
    this.webSiteNameSubject.next(this.selectedWebSiteNames);
    this.setWebMediaFieldName();
  }
  showRequiredError() {
    if (this.form && this.field) {
      const control = this.form.get(`${Constants.FormSubFieldNameConstants.webSiteName}`);
      return control.hasError('required');
    } else { return false; }
  }
  flipIncludeInDirectoryFlagForWeb() {
    const webIncludeInDirectoryFlagControl =
      this.form.get(`${Constants.FormSubFieldNameConstants.webIncludeInDirectoryFlag}`);
    if (webIncludeInDirectoryFlagControl) {
      webIncludeInDirectoryFlagControl.setValue(!(webIncludeInDirectoryFlagControl.value as boolean));
      webIncludeInDirectoryFlagControl.markAsDirty();
    }
  }
  buildSiteNameOptions(_: string[]) {
    const siteNames = this.fieldOptionsMapperService.getwebSiteNamePreference();
    if (this.form != null) {
      const currentSiteName = this.form.get(`${Constants.FormSubFieldNameConstants.webSiteName}`).value;
      _.forEach(selectedWebSite => {
        siteNames.forEach(element => {
          if ((element.PreferenceCode === selectedWebSite) &&
            ((currentSiteName !== null && currentSiteName.toString() !== selectedWebSite) || currentSiteName == null)) {
            element.Active = false;
          }
        });
      });
    }
    this.webSiteNameOptions = siteNames;
  }
  isBlankAdditionalItem() {
    return !this.editMode && !this.readonlyWebSiteNameValue && this.listIndex > 0;
  }

}
