import { Component, OnInit, Input } from '@angular/core';
import { BaseFieldComponent } from '@shared/form-fields/baseFieldComponent';
import { Subscription, Observable, Subject, forkJoin } from 'rxjs';
import { Customer, AddressField, Preference, AddressDetail } from '@shared/models/data';
import { Constants } from '@shared/services/constants';
import { FormControl, Validators, FormGroup, AbstractControl } from '@angular/forms';
import { FormFieldValidatorsService } from '@shared/form-fields/form-field-validators.service';
import { FormFieldRuleConstants } from '@shared/form-fields/models/form-field';
import { UserProfileService } from '@shared/services/api';
import { FieldOptionsMapperService } from '@shared/services/membershipForm/field-options-mapper.service';
import { AddressOptions } from '@shared/services/membershipForm/AddressOptions';
import { tap, takeUntil, map } from 'rxjs/operators';
import { BaseFieldListComponent } from '@shared/form-fields/baseFieldListComponent';


@Component({
  selector: 'aaos-address-list',
  templateUrl: './address-list.component.html',
  styleUrls: ['./address-list.component.scss']
})
export class AddressListComponent extends BaseFieldListComponent implements OnInit {
  _destroy$: Subject<any> = new Subject();
  @Input()
  refreshSubscription: Subscription;
  @Input()
  user: Customer;
  @Input()
  editMode: boolean;
  showDeleteIcon: boolean;
  addressOptions: AddressOptions;
  previousForm: AbstractControl;
  formGroupAddresses: FormGroup[] = [];
  formGroupAddress1: AbstractControl;
  states: Preference[] = [];
  addressStructure: AddressField[] = [];
  selectedCountryCode: string;
  formGroup: FormGroup;
  showAddAddressButton: boolean;
  disableAddButtonSubject: Subject<string> = new Subject<string>();
  disableAddButton: boolean;
  selectedAddressTypes: string[];
  countriesOptions: Preference[] = [];
  formModified: boolean;
  constructor(
    private profileService: UserProfileService,
    private fieldOptionsMapperService: FieldOptionsMapperService,
    private formFieldService: FormFieldValidatorsService) {
    super(formFieldService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.setUpFormForAddress();
    this.setShowAddAddressButton();
    this.buildSelectedAddressTypes();
  }
  buildSelectedAddressTypes() {
    this.selectedAddressTypes = [];
    if (this.user != null) {
      this.user.AddressDetail.forEach(addressDetail => {
        if (addressDetail.AddressTypeCode != null) {
          this.selectedAddressTypes.push(addressDetail.AddressTypeCode);
        }
      });
    }
  }
  addNewAddress() {
    const addressDetail = new AddressDetail();
    addressDetail.CountryCode = Constants.CountryConstants.USA;
    addressDetail.AddressId = this.user.AddressDetail.length + 1;
    addressDetail.IsPrimaryAddress = false;
    addressDetail.AddressTypeCode = null;
    const formGroup = this.form as FormGroup;
    const addressControl = new FormGroup({});
    formGroup.addControl(addressDetail.AddressId.toString(), addressControl);
    this.user.AddressDetail.push(addressDetail);
    this.buildSelectedAddressTypes();
    this.addressTypeSubject.next(this.selectedAddressTypes);
    this.setShowAddAddressButton();

  }
  setUpFormForAddress() {
    if (this.user != null) {
      this.user.AddressDetail.forEach(address => {
        const formGroup = this.form as FormGroup;
        const addressControl = new FormGroup({});
        formGroup.addControl(address.AddressId.toString(), addressControl);
      });
    }
  }
  deleteAddress(id: string) {
    const formGroup = this.form as FormGroup;
    const deletedFromGroup = formGroup.get(id) as FormGroup;
    const deletedFlagControl = deletedFromGroup.get(Constants.AdditionalFieldConstants.deletedflag);
    const newFlagControl = deletedFromGroup.get(Constants.AdditionalFieldConstants.newflag);
    deletedFlagControl.setValue(true);
    if (newFlagControl.value === true) {
      formGroup.removeControl(id);
    } else {
      // remove validators for delete form which should no longer be validated because it is only being used to track the deleted address
      Object.keys(deletedFromGroup.controls).forEach(_ => {
        const control = deletedFromGroup.controls[_];
        control.setValidators([]);
        control.updateValueAndValidity();
      });
    }
    const formToUpdateValidity = deletedFromGroup as AbstractControl;
    formToUpdateValidity.updateValueAndValidity();
    const addressDetails = this.user.AddressDetail;
    this.user.AddressDetail = [];
    for (const addressDetail of addressDetails) {
      if (addressDetail.AddressId.toString() !== id) {
        this.user.AddressDetail.push(addressDetail);
      }
    }
    this.buildSelectedAddressTypes();
    this.addressTypeSubject.next(this.selectedAddressTypes);

    this.setShowAddAddressButton();
  }
  setShowAddAddressButton() {
    if (this.user != null) {
      this.showAddAddressButton = this.user.AddressDetail != null && this.user.AddressDetail.length < 3;
    }
  }
}
