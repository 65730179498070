import { Injectable } from '@angular/core';
import { FormField, FieldTypeConstants, FormFieldConstants } from '@shared/form-fields/models/form-field';
import { FormGroup } from '@angular/forms';
import { Customer, ProfileFormOption } from '@shared/models/data';
import { FieldOptionsMapperService } from '@shared/services/membershipForm/field-options-mapper.service';
import { DependentFieldRule } from './DependentFieldRule';
import { RuleResponse } from './RuleResponse';
import { AreaOfInterestDependentFieldRule } from './AreaOfInterestDependentFieldRule';

@Injectable({
  providedIn: 'root'
})
export class DependentFieldAllowedValueService {
  randomNumber: number;
  rules: Array<DependentFieldRule> = new Array<DependentFieldRule>();

  constructor(private fieldOptionsMapper: FieldOptionsMapperService) {
    this.randomNumber = Math.random();
  }
  public setFieldsForGroup(groupFields: Array<FormField>, formGroup: FormGroup, formOptions: ProfileFormOption[]) {
    this.setupAreaOfInterestRule(groupFields, formGroup, formOptions);
  }
  setupAreaOfInterestRule(groupFields: Array<FormField>, formGroup: FormGroup, formOptions: ProfileFormOption[]) {
    const areaOfInterestsField = groupFields.find(x => x.fieldId.toLowerCase() === FormFieldConstants.AreasofInterestId);
    const otherAreaOfInterestsField = groupFields.find(x => x.fieldId.toLowerCase() === FormFieldConstants.OtherAreaofInterest);
    if (areaOfInterestsField && otherAreaOfInterestsField) {

      const areaOfInterestRule = new AreaOfInterestDependentFieldRule(this.fieldOptionsMapper);
      areaOfInterestRule.formOptions = formOptions;
      // put the rule in the dictionary for both field id for later lookup
      this.rules[FormFieldConstants.AreasofInterestId] = areaOfInterestRule;
      this.rules[FormFieldConstants.OtherAreaofInterest] = areaOfInterestRule;

    }
  }

  public registerField(fieldId: string, formGroup: FormGroup, user?: Customer): RuleResponse {
    const rule = this.rules[fieldId];
    if (rule) {
      return rule.registerFieldForAllowedValues(formGroup, user);
    }
    return null;
  }
}
