import { Component, Input } from '@angular/core';
import { NameCredentials } from '@shared/models/data';

@Component({
  selector: 'aaos-name-plate',
  templateUrl: './name-plate.component.html',
  styleUrls: ['./name-plate.component.scss']
})
export class NamePlateComponent {
  @Input() nameCredentials: NameCredentials;
  constructor() {}
}
