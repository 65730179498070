import { Component, OnInit } from '@angular/core';
import { FormGroup,  FormControl, Validators, ValidatorFn, ValidationErrors } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { UpdatePasswordService } from '@shared/services/update-password/update-password.service';
import { Customer, ApiResponse } from '@shared/models/data';
import { EmailPreferencesService, UserProfileService } from '@shared/services/api';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserLoginInformationPageBaseComponent } from '../user-login-information-page-base';
import { SaveResult } from '@shared/models/contracts/saveResults';
import { FormFinishError, FormFinishType } from '@shared/models/enums/form-finish';

@Component({
  selector: 'aaos-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent extends UserLoginInformationPageBaseComponent implements OnInit {
  user: Customer;
  form: FormGroup;
  submitting: boolean;
  oldPassword = 'oldPassword';
  newPassword = 'newPassword';
  confirmNewPassword = 'confirmNewPassword';
  loading: boolean;
  passwordLengthTextMessage = '8-14 characters';
  containsNumberMessage = '1 number';
  containsLowerCaseMessage = '1 lower case';
  containsUpperCaseMessage = '1 upper case';
  containsSpecialCharacter = '1 special character';
  showEmailPreferencesLink: boolean = false;


  // regex first pass ^(?=.{8,12}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).*$
  constructor(
    private updatePasswordService: UpdatePasswordService,
    userProfileService: UserProfileService,
    router: Router, dialog: MatDialog,
    private emailPreferencesService: EmailPreferencesService) {
    super(userProfileService, router, dialog);
    this.buildForm();
  }

  ngOnInit() {
    super.loadUser().subscribe();

    this.emailPreferencesService.canUserUpdateEmailPreferences().subscribe(result => {
      this.showEmailPreferencesLink = result;
    });
  }

  buildForm() {
    const oldPasswordControl = new FormControl('', { validators: Validators.required });
    const passwordValidators = this.getPasswordValidators();
    const newPasswordControl = new FormControl('', { validators: passwordValidators });
    const confirmPasswordControl = new FormControl('', { validators: Validators.required });
    this.form = new FormGroup({});
    this.form.addControl(this.oldPassword, oldPasswordControl);
    this.form.addControl(this.newPassword, newPasswordControl);
    this.form.addControl(this.confirmNewPassword, confirmPasswordControl);
    const validator = this.resetPasswordFormValidator();
    this.form.setValidators(validator);
  }
  getPasswordValidators(): ValidatorFn[] {
    const validators = [];
    validators.push(Validators.required);
    validators.push(this.createLengthValidator());
    validators.push(this.createNamedPatternValidator('containsNumber', '[0-9]'));
    validators.push(this.createNamedPatternValidator('containsLowerCase', '[a-z]'));
    validators.push(this.createNamedPatternValidator('containsUpperCase', '[A-Z]'));
    validators.push(this.createNamedPatternValidator('containsSpecialChars', '[!@#\$%\^&\*]'));

    return validators;
  }
  createLengthValidator(): any {
    const validator: ValidatorFn = (control: FormControl): ValidationErrors | null => {
      const error = { length: true };
      if (control && control.value) {
        const length = control.value.length;
        if (length < 8 || length > 14) {
          return error;
        }
        return null;
      }
      return error;
    };
    return validator;
  }
  createNamedPatternValidator(validatorName: string, pattern: string): ValidatorFn {
    const validator: ValidatorFn = (control: FormControl): ValidationErrors | null => {
      const regExp = new RegExp(pattern);
      const result = regExp.test(control.value);
      if (!result) {
        const error = {};
        error[validatorName] = true;
        return error;
      }
      return null;
    };
    return validator;
  }


  save(): Observable<SaveResult> {
    const newPassword = this.form.get(this.newPassword).value;
    const oldPassword = this.form.get(this.oldPassword).value;
    return Observable.create(observer => {
      this.updatePasswordService.updateUserPassword(oldPassword, newPassword, this.user.CustomerProfile.UserName)
        .subscribe((apiResponse: ApiResponse) => {
          if (apiResponse && apiResponse.successful) {
            observer.next(new SaveResult(FormFinishType.Success, null));
          } else {
            observer.next(new SaveResult(FormFinishError.PasswordError, null));
          }
        }, error => {
          if (error && error.error && error.error.unsuccessfulUpdateDetails
            && error.error.unsuccessfulUpdateDetails.find(_ => _.toLowerCase() === 'Password Incorrect'.toLowerCase())) {
            observer.next(new SaveResult(FormFinishError.OldPasswordIncorrectError, null));
          } else {
            observer.next(new SaveResult(FormFinishError.PasswordError, null));
          }
        });
    });
  }

  showRequiredError(fieldName: string) {
    return this.showValidationError(fieldName, 'required');
  }

  showValidationError(fieldName: string, errorName: string) {
    const control = this.form.get(fieldName);
    return control && control.hasError(errorName);
  }

  getModalConfig(): MatDialogConfig<any> {
    return {
      panelClass: 'amp-dialog',
      disableClose: true,
      autoFocus: false
    } as MatDialogConfig;
  }
  resetPasswordFormValidator(): ValidatorFn {
    const validator: ValidatorFn = (control: FormControl): ValidationErrors | null => {
      if (control.value) {
        const newPasswordControl = control.get(this.newPassword);
        const confirmNewPasswordControl = control.get(this.confirmNewPassword);
        if (newPasswordControl.value && confirmNewPasswordControl.value
          && newPasswordControl.value !== confirmNewPasswordControl.value) {
          return { passwordMismatch: true };
        }
      }
      return null;
    };
    return validator;
  }
}
