<div class="form-group" *ngIf="form" [formGroup]="form">

    <div [id]="field.fieldId" *ngIf="(editMode && !fieldIsDisabled)">
        <aaos-form-field-title [editMode]="editMode" [field]="field"></aaos-form-field-title>
        <mat-select [formControlName]="field.fieldId" disableRipple panelClass="mat-panel">
            <mat-option *ngFor="let option of options" [value]="option.PreferenceCode">{{ option.PreferenceDescription }}
            </mat-option>
        </mat-select>
        <div class="required-text" *ngIf="showRequiredError()">
            {{field.fieldName}} is required
        </div>
    </div>
    <div *ngIf="!(editMode && !fieldIsDisabled) && !hideFieldInReadonly">
        <aaos-form-field-title [editMode]="editMode" [field]="field"></aaos-form-field-title>
        <div *ngFor="let selectedValue of readOnlyInitialValues">
            <p *ngIf="readOnlyInitialValues.length>0 ">{{ selectedValue }}</p>
        </div>
        <p *ngIf="readOnlyInitialValues.length==0" class="blank-text">(Blank)</p>
        <div class="required-text" *ngIf="showRequiredError()">
            {{ requiredText }} is required
        </div>
    </div>
</div>