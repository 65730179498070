import { Subject } from 'rxjs';
import { ProfileFormOption } from '@shared/models/data';
import { DependentFieldSelectedValuesMessage } from './DependentFieldSelectedValuesMessage';
import { DependentFieldAllowedValuesUpdatedMessage } from './DependentFieldAllowedValuesUpdatedMessage';
import { RuleResponse } from './RuleResponse';
export abstract class DependentFieldRule {
    allowedValuesUpdated$: Subject<DependentFieldAllowedValuesUpdatedMessage> = new Subject<DependentFieldAllowedValuesUpdatedMessage>();
    selectedValuesUpdated$: Subject<DependentFieldSelectedValuesMessage> = new Subject<DependentFieldSelectedValuesMessage>();
    formOptions: ProfileFormOption[];
    abstract registerFieldForAllowedValues(selectedValues: any): RuleResponse;
}
