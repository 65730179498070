import { Injectable, OnDestroy } from '@angular/core';
import {
  FormGroup,
  FormControl,
  AbstractControl
} from '@angular/forms';
import {
  Customer,
  Preference,
  CustomerProfileSnapshot,
  AddressField,
  ProfileFormOption,
  CustomerCommunication,
  ApiResponse
} from '@shared/models/data';
import { Constants } from '@shared/services/constants';
import { Utilities } from '@shared/services/utils';
import { MatDialogConfig } from '@angular/material';
import { UserProfileService } from '@shared/services/api';
import { Subject } from 'rxjs';
import { BirthdayService } from '@shared/services/utils/birthday.service';
import { FormFinishError } from '@shared/models/enums/form-finish';

@Injectable({
  providedIn: 'root'
})
export class UserProfileEditLogicService implements OnDestroy {
  states: Preference[];
  countries: Preference[];
  specialtyAreas: Preference[];
  specialtyInterests: Preference[];
  addressTypes: Preference[];
  ethnicities: Preference[];
  genders: Preference[];
  identities: Preference[];
  identityPronouns: Preference[];
  practiceTypes: Preference[];
  communicationTypes: Preference[];
  communicationLocations: Preference[];
  prefixes: Preference[];
  suffixes: Preference[];
  practiceSpecializationPrimary: Preference[];
  practiceSpecializationSecondary: Preference[];
  addressStructure: AddressField[];
  credentials: Preference[];
  practiceSize: Preference[];

  private _destroy$: Subject<void> = new Subject<void>();

  private readonly _customValidators = {
    phoneNumberValidation: (control: AbstractControl): { [key: string]: boolean } => {
      if (control.value) {
        const nanpaValidation = /^([2-9][0-9][0-9])([2-9][0-9]{2})([0-9]{4})$/;
        const consecutiveOne = /^([2-9][0-9][0-9])([2-9][1][1])([0-9]{4})$/;
        const consecutive = /(\d)\1{9}/;
        const phoneNumber = control.value.toString().replace(/[^\d]/g, '');
        const check =
          !nanpaValidation.test(phoneNumber) ||
          consecutiveOne.test(phoneNumber) ||
          consecutive.test(phoneNumber);
        return check ? { invalidPhoneNumber: true } : null;
      }
    }
  };

  constructor(private profileService: UserProfileService) { }

  ngOnDestroy() {
    this._destroy$.next();
  }

  userToFormSnapshot(form: FormGroup, user: Customer): any {
    const currentState: any = form.value;
    currentState.name.prefix = user.NameCredentials.NamePrefix;
    currentState.name.suffix = user.NameCredentials.NameSuffix;
    const _credentials = user.NameCredentials.Credentials.split(', ');
    currentState.name.credentials = _credentials;
    // currentState.address.organizationName = user.AddressDetail.CompanyName;
    // currentState.address.address1 = user.AddressDetail.Address1;
    // currentState.address.address2 = user.AddressDetail.Address2;
    // currentState.address.address3 = user.AddressDetail.Address3;
    // currentState.address.city = user.AddressDetail.City;
    // currentState.address.state = user.AddressDetail.State;
    // currentState.address.postalcode = user.AddressDetail.PostalCode;
    // currentState.address.countryCode = user.AddressDetail.CountryCode;
    // currentState.address.addressType = user.AddressDetail.AddressTypeCode;
    currentState.email.address = user.CustomerProfile.PrimaryEmailAddress;
    const _emailType = user.Communications.find(_ => _.CommunicationType.toLowerCase() === 'email');
    currentState.email.type = _emailType ? _emailType.LocationCode : null;
    if (user.CustomerProfile.PrimaryPhone.includes('EXT')) {
      const indexOfExtension = user.CustomerProfile.PrimaryPhone.indexOf('EXT');
      currentState.phone.number = user.CustomerProfile.PrimaryPhone.substring(
        0,
        indexOfExtension - 1
      );
      currentState.phone.extension = user.CustomerProfile.PrimaryPhone.substring(
        indexOfExtension + 4,
        user.CustomerProfile.PrimaryPhone.length
      );
    } else {
      currentState.phone.number = user.CustomerProfile.PrimaryPhone;
    }
    const _phoneType = user.Communications.find(_ => _.CommunicationType.toLowerCase() === 'phone');
    currentState.phone.type = _phoneType ? _phoneType.LocationCode : null;
    const _birthdate = BirthdayService.isValid(user.CustomerProfile.BirthDate)
      ? BirthdayService.formatToControlString(user.CustomerProfile.BirthDate)
      : null;
    currentState.demographicInfo.birthdate = _birthdate;
    currentState.demographicInfo.gender = user.CustomerProfile.Gender;
    currentState.demographicInfo.identity = user.CustomerProfile.Identity;
    currentState.demographicInfo.identityPronouns = user.CustomerProfile.IdentityPronoun;
    currentState.demographicInfo.ethnicity = user.CustomerProfile.Ethnicity;
    currentState.practice.practiceType = user.CustomerProfile.PracticeType;
    currentState.practice.npi = user.CustomerProfile.Npi === 0 ? null : user.CustomerProfile.Npi;
    currentState.specialization.primarySpecialization =
      user.CustomerProfile.PracticeSpecializationPrimary;
    currentState.specialization.secondarySpecialization =
      user.CustomerProfile.PracticeSpecializationSecondary;
    currentState.specialization.PracticeSize = user.CustomerProfile.PracticeSize;
    const interests = user.Preferences.filter(
      _ => _.PreferenceCode.toLowerCase() === Constants.UserProfile.SpecialtyAreaCode
    ).map(_ => _.PreferenceDescription);
    this.specialtyAreas.forEach(interest => {
      const checked = interests.find(
        _ => _.toLowerCase() === interest.PreferenceDescription.toLowerCase()
      );
      (form.get('learningPreferences.areasOfInterests') as FormGroup).addControl(
        interest.PreferenceDescription,
        new FormControl(!!checked)
      );
    });

    const otherInterests = user.Preferences.filter(
      _ => _.PreferenceCode.toLowerCase() === Constants.UserProfile.SpecialtyInterestsCode
    ).map(_ => _.PreferenceDescription);
    this.specialtyInterests.forEach(interest => {
      const checked = otherInterests.find(
        _ => _.toLowerCase() === interest.PreferenceDescription.toLowerCase()
      );
      (form.get('learningPreferences.otherAreasOfInterests') as FormGroup).addControl(
        interest.PreferenceDescription,
        new FormControl(!!checked)
      );
    });
    return currentState;
  }

  formToUserSnapshot(form: FormGroup, user: Customer) {
    const currentState: any = form.value;
    const userSnapshot: CustomerProfileSnapshot = new CustomerProfileSnapshot(
      user.CustomerProfile.MasterCustomerId
    );
    userSnapshot.AddressType = currentState.address.addressType;
    // userSnapshot.AddressId = user.AddressDetail.AddressId;
    userSnapshot.ProfileImageBase64String = user.CustomerProfile.ProfileImageBase64String;
    userSnapshot.ProfileImageId = user.CustomerProfile.ProfileImageId;
    if (currentState.address.addressType === 'WORK') {
      userSnapshot.CompanyName = currentState.address.organizationName;
    }
    userSnapshot.Address1 = currentState.address.address1;
    userSnapshot.Address2 = currentState.address.address2;
    userSnapshot.Address3 = currentState.address.address3;
    userSnapshot.City = currentState.address.city;
    userSnapshot.State = currentState.address.state;
    userSnapshot.PostalCode = currentState.address.postalcode;
    userSnapshot.Country = this.countries.find(
      _ => _.PreferenceCode === currentState.address.countryCode
    ).PreferenceDescription;
    userSnapshot.Email = currentState.email.address;
    userSnapshot.Phone = currentState.phone.number;

    const updateEmail = form.get('email.type').dirty || form.get('email.address').dirty;
    if (updateEmail) {
      const email = {
        MasterCustomerId: userSnapshot.MasterCustomerId,
        CountryCode: currentState.address.countryCode,
        CommunicationType: 'email',
        EmailAddress: currentState.email.address,
        IsPrimary: true,
        LocationCode: currentState.email.type,
        LocationCodeUpdated: form.get('email.type').dirty
      } as CustomerCommunication;
      userSnapshot.Communications.push(email);
    }
    const updatePhone =
      form.get('phone.number').dirty ||
      form.get('phone.extension').dirty ||
      form.get('phone.type').dirty;
    if (updatePhone) {
      const _phone = currentState.phone.number.replace(/\D/g, '');
      const _phoneNumber =
        currentState.address.countryCode === 'USA' ? _phone.substring(3, _phone) : _phone;
      const _areaCode = currentState.address.countryCode === 'USA' ? _phone.substring(0, 3) : '';
      const phone = {
        MasterCustomerId: userSnapshot.MasterCustomerId,
        CommunicationType: 'phone',
        CountryCode: currentState.address.countryCode,
        PhoneCountryCode: '',
        PhoneExtension: currentState.phone.extension,
        PhoneNumber: _phoneNumber,
        PhoneAreaCode: _areaCode,
        IsPrimary: true,
        LocationCode: currentState.phone.type,
        LocationCodeUpdated: form.get('phone.type').dirty
      } as CustomerCommunication;
      userSnapshot.Communications.push(phone);
    }
    userSnapshot.SpecialtyArea = this.specialtiesToPreferences(
      currentState.learningPreferences.areasOfInterests,
      this.specialtyAreas
    );
    userSnapshot.SpecialtyInterests = this.specialtiesToPreferences(
      currentState.learningPreferences.otherAreasOfInterests,
      this.specialtyInterests
    );
    const _birthdate = BirthdayService.convertToBirthdaySnapshot(user.CustomerProfile.BirthDate, currentState.demographicInfo.birthdate);
    userSnapshot.BirthDate = _birthdate;
    userSnapshot.BirthDateString = currentState.demographicInfo.birthdate.toString();
    userSnapshot.Gender = currentState.demographicInfo.gender;
    userSnapshot.Identity = currentState.demographicInfo.identity;
    userSnapshot.IdentityPronoun = currentState.demographicInfo.identityPronoun;
    userSnapshot.Ethnicity = currentState.demographicInfo.ethnicity;
    userSnapshot.PracticeSpecializationPrimary = currentState.specialization.primarySpecialization;
    userSnapshot.PracticeSpecializationSecondary =
      currentState.specialization.secondarySpecialization;
    userSnapshot.PracticeType = currentState.practice.practiceType;
    userSnapshot.PracticeSize = currentState.practice.practiceSize;
    userSnapshot.Biography = '';
    userSnapshot.Npi = currentState.practice.npi ? currentState.practice.npi : 0;
    userSnapshot.Prefix = user.NameCredentials.NamePrefix;
    userSnapshot.Suffix = currentState.name.suffix;
    userSnapshot.Credentials = currentState.name.credentials.join(', ');
    return userSnapshot;
  }

  // todo move this to new service for mapping and out of this logic service
  userToUserSnapshot(user: Customer) {
    const userSnapshot: CustomerProfileSnapshot = new CustomerProfileSnapshot(
      user.CustomerProfile.MasterCustomerId
    );
    // userSnapshot.AddressType = user.AddressDetail.AddressTypeCode;
    // userSnapshot.AddressId = user.AddressDetail.AddressId;
    // userSnapshot.ProfileImageBase64String = user.CustomerProfile.ProfileImageBase64String;
    // userSnapshot.ProfileImageId = user.CustomerProfile.ProfileImageId;
    // userSnapshot.CompanyName = user.CustomerProfile.CompanyName;
    // userSnapshot.Address1 = user.AddressDetail.Address1;
    // userSnapshot.Address2 = user.AddressDetail.Address2;
    // userSnapshot.Address3 = user.AddressDetail.Address3;
    // userSnapshot.City = user.AddressDetail.City;
    // userSnapshot.State = user.AddressDetail.State;
    // userSnapshot.PostalCode = user.AddressDetail.PostalCode;
    // userSnapshot.Country = this.countries.find(
    //   _ => _.PreferenceCode === user.AddressDetail.CountryCode
    // ).PreferenceDescription;
    userSnapshot.Email = user.CustomerProfile.PrimaryEmailAddress;
    userSnapshot.Phone = user.CustomerProfile.PrimaryPhone;
    userSnapshot.SpecialtyArea = user.Preferences.filter(
      _ => _.PreferenceCode.toLowerCase() === Constants.UserProfile.SpecialtyAreaCode
    ).map(_ => _);
    userSnapshot.SpecialtyInterests = user.Preferences.filter(
      _ => _.PreferenceCode.toLowerCase() === Constants.UserProfile.SpecialtyInterestsCode
    ).map(_ => _);
    userSnapshot.BirthDate = user.CustomerProfile.BirthDate;
    userSnapshot.Gender = user.CustomerProfile.Gender;
    userSnapshot.Identity = user.CustomerProfile.Identity;
    userSnapshot.IdentityPronoun = user.CustomerProfile.IdentityPronoun;
    userSnapshot.Ethnicity = user.CustomerProfile.Ethnicity;
    userSnapshot.PracticeSpecializationPrimary = user.CustomerProfile.PracticeSpecializationPrimary;
    userSnapshot.PracticeSpecializationSecondary =
      user.CustomerProfile.PracticeSpecializationSecondary;
    userSnapshot.PracticeType = user.CustomerProfile.PracticeType;
    userSnapshot.PracticeSize = user.CustomerProfile.PracticeSize;
    userSnapshot.Biography = '';
    userSnapshot.Npi = user.CustomerProfile.Npi;
    userSnapshot.Prefix = user.NameCredentials.NamePrefix;
    userSnapshot.Suffix = user.NameCredentials.NameSuffix;
    userSnapshot.Credentials = user.NameCredentials.Credentials;

    return userSnapshot;
  }

  patchValueToProfileForm(profileForm: FormGroup, snapshot: any, user: Customer) {
    profileForm.patchValue({
      address: snapshot.address,
      demographicInfo: snapshot.demographicInfo,
      email: snapshot.email,
      name: snapshot.name,
      phone: snapshot.phone,
      practice: snapshot.practice,
      learningPreferences: snapshot.learningPreferences
    });
    profileForm
      .get('specialization.primarySpecialization')
      .patchValue(snapshot.specialization.primarySpecialization);
    profileForm
      .get('specialization.secondarySpecialization')
      .patchValue(snapshot.specialization.secondarySpecialization);
    return profileForm;
  }

  getModalConfig() {
    return {
      panelClass: 'amp-dialog',
      disableClose: true,
      autoFocus: false
    } as MatDialogConfig;
  }

  specialtiesToPreferences(formPreferences: Preference[], sourcePreferences: Preference[]) {
    const keys = Object.keys(formPreferences)
      .map(key => {
        if (!formPreferences[key]) {
          return null;
        }

        return sourcePreferences.find(
          _ => _.PreferenceDescription.toLowerCase() === key.toLowerCase()
        );
      })
      .filter(_ => !!_);
    return keys;
  }

  parsePersonifyErrors(responses: ApiResponse[]): FormFinishError[] {
    const errorList = new Array<FormFinishError>();
    responses.forEach(response => {
      if (this.isPostalCodeError(response)) {
        errorList.push(FormFinishError.PostalCodeError);
      }
      if (this.isCityError(response)) {
        errorList.push(FormFinishError.CityError);
      }
      if (this.isEmailError(response)) {
        errorList.push(FormFinishError.EmailError);
      }
      if (this.isPrimaryEmailExistsError(response)) {
        errorList.push(FormFinishError.PrimaryEmailExistsError);
      }
    });
    return errorList;
  }

  isEmailError(response: ApiResponse) {
    return response.unsuccessfulUpdateDetails &&
      response.unsuccessfulUpdateDetails.some(x => x.search('Not a valid email') >= 0);
  }

  isPrimaryEmailExistsError(response: ApiResponse) {
    return response.unsuccessfulUpdateDetails &&
      response.unsuccessfulUpdateDetails.some(x => x.search('Primary email already exists for another customer') >= 0);
  }

  isPostalCodeError(response: ApiResponse) {
    return response.unsuccessfulUpdateDetails &&
      response.unsuccessfulUpdateDetails.some(
        x => x.search('The value is not valid for PostalCode') >= 0
      );
  }

  isCityError(response: ApiResponse) {
    return response.unsuccessfulUpdateDetails &&
      response.unsuccessfulUpdateDetails.some(x => x.search('The value is not valid for City') >= 0);
  }

  private _getOption(options: ProfileFormOption[], key: string) {
    if (!options) {
      return null;
    }

    const found = options.find(option => option.key === key);
    return Utilities.isDefined(found) ? found.value : null;
  }

}
