import { Injectable } from '@angular/core';
import { AaosBaseService, LoggingService } from '@shared/services/api';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Constants } from '@shared/services/constants';
import { take, tap, map, catchError } from 'rxjs/operators';
import { Utilities } from '@shared/services/utils';

@Injectable({
  providedIn: 'root'
})
export class AllowedRedirectUrlsService extends AaosBaseService {
  // BUG: workaround for https://github.com/angular/angular/pull/25033
  static ngInjectableDef = undefined;

  constructor(protected _h: HttpClient, protected _l: LoggingService) {
    super(_l, _h);
  }

  /**
   * getAllowedUrls
   */
  public getAllowedUrls(): Observable<Array<string>> {
    return this.http.get(Constants.ApiUrls.AllowedRedirectUrls).pipe(
      catchError(_ => this.handleError(_)),
      take(1),
      map((_: string[]) => {
        return _;
      }));
  }
}


