import { Directive, HostListener, OnInit, OnDestroy } from '@angular/core';
import { interval, Subscription } from 'rxjs';

interface Size {
  height: number;
  width: number;
}

@Directive({
  selector: '[aaosSizeBroadcast]'
})
// detect/send logic based on https://www.dyn-web.com/tutorials/iframes/postmessage/height/
export class SizeBroadcastDirective implements OnInit, OnDestroy {
  private lastSize: Size;
  private interval: Subscription;

  ngOnInit(): void {
    // resize isn't always firing when we want it to, so every 200ms we recheck the size - it should be cheap enough to be fine
    this.interval = interval(200).subscribe(() => {
      this.sendUpdatedSize();
    });
  }
  ngOnDestroy(): void {
    // clean up our subscription when we're not used any
    if (this.interval) {
      this.interval.unsubscribe();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.sendUpdatedSize();
  }

  @HostListener('window:load', ['$event'])
  onLoad(): void {
    this.sendUpdatedSize();
  }

  sendUpdatedSize(): void {
    const newSize = this.getCurrentSize();
    if (
      !this.lastSize ||
      newSize.height !== this.lastSize.height ||
      newSize.width !== this.lastSize.width
    ) {
      if (window.parent) {
        window.parent.postMessage(
          JSON.stringify({
            type: 'contentSizeChanged',
            frameName: window.name || '',
            height: newSize.height,
            width: newSize.width
          }),
          '*'
        );
        this.lastSize = newSize;
      }
    }
  }

  getCurrentSize(): Size {
    const body = document.body;
    const html = document.documentElement;
    const height = Math.max(body.offsetHeight, html.offsetHeight);
    const width = Math.max(body.offsetWidth, html.offsetWidth);
    return { height, width };
  }
}
