import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { RouteParamAction } from '@shared/models/contracts';
import { RootLogicService } from './logic.service';
import { DOCUMENT } from '@angular/platform-browser';
import { Utilities } from '@shared/services/utils';
import { Router, NavigationEnd } from '@angular/router';
import { UserTrackerService } from '@shared/services/trackers';
// declare ga as a function to set and sent the events
type CallbackFunctionVariadicAnyReturn = (...args: any[]) => any;
declare let ga: CallbackFunctionVariadicAnyReturn;


@Component({
  selector: 'aaos-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RootComponent {
  listeningFor: RouteParamAction[];

    constructor(@Inject(DOCUMENT) private document: any, private logic: RootLogicService, public router: Router) {
        this.listeningFor = this.logic.getUrlParamListeners();
        window.onbeforeunload = e => {
        if (Utilities.isDefined(this.document.cookie) && this.document.cookie !== '') {
            this.document.cookie = '';
        }};
      // subscribe to router events and send page views to Google Analytics
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                let mcid = 'Anonymous';
                const cookieSplit = document.cookie.split(';');
                if (cookieSplit.some((item) => item.trim().indexOf('mcid=') === 0)) {
                    mcid = cookieSplit.find(row => row.trim().startsWith('mcid')).split('=')[1];
                    ga('set', 'userId', mcid);
                }

                ga('set', 'page', event.urlAfterRedirects);
                ga('set', 'dimension2', mcid);
                ga('send', 'pageview');
            }
        });
        }
}
