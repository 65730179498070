import { Utilities } from '@shared/services/utils';
import { Disclosure } from './Disclosure';
export class DisclosureSummary {
    LastDisclosureDate: Date;
    HasDisclosure: boolean;
    Summary: string;
    SummarySubmittedDateString: string;
    get SummaryFormatted() {
        return this._summaryFormatted;
    }
    private _summaryFormatted: Disclosure[];
    private _delimiter = '|';
    private _submittedOn = 'Submitted on: ';
    constructor(disclosureDate: Date | string, hasDisclosure: boolean, summary: string) {
        if (typeof disclosureDate === 'string') {
            disclosureDate = new Date(disclosureDate);
        }
        this.LastDisclosureDate = disclosureDate;
        this.HasDisclosure = hasDisclosure;
        this.Summary = summary;
        if (this.Summary) { this.SummarySubmittedDateString = this.Summary.split('|')[0]; }
        this.formatSummary();
    }
    static create(source: any) {
        let result = source as DisclosureSummary;
        result = new DisclosureSummary(result.LastDisclosureDate, result.HasDisclosure, result.Summary);
        return result;
    }
    formatSummary() {
        if (Utilities.isUndefinedOrNull(this.Summary)) {
            return;
        }
        const split: string[] = this.Summary.split('|');
        // get rid of _submittedOn constants if it exists
        if (this.Summary.indexOf(this._submittedOn) > -1) {
            split.shift();
        }
        this._summaryFormatted = split
            .filter(_ => !!_)
            .map(_ => {
                const disclosure = new Disclosure();
                const splitD = _.split(':');
                disclosure.DisclosureBy = splitD[0];
                disclosure.DisclosureFor = splitD[1];
                return disclosure;
            });
    }
}
