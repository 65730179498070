import { RouterModule, Route } from '@angular/router';
import { Routes } from '@angular/router';
import { RouteNames } from '../routing/route-utils';
import { NgModule } from '@angular/core';
import { LoggedInGuard } from '@shared/guards';
import { ProfilePromptComponent } from './profile-prompt.component';

const home: Route = {
  canActivate: [LoggedInGuard],
  path: RouteNames.UpdateProfile,
  component: ProfilePromptComponent
};

const routes: Routes = [home];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  providers: [LoggedInGuard],
  exports: [RouterModule]
})
export class ProfilePromptRoutingModule {}

export const routedComponents = [ProfilePromptComponent];
