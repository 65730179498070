import { Component, OnInit, Input } from '@angular/core';
import { FormField, FormFieldRuleConstants } from '@shared/form-fields/models/form-field';

@Component({
  selector: 'aaos-form-field-title',
  templateUrl: './form-field-title.component.html',
  styleUrls: ['./form-field-title.component.scss']
})
export class FormFieldTitleComponent {
  @Input()
  field: FormField;
  @Input()
  editMode: FormField;
  optionalRuleId = FormFieldRuleConstants.OptionalEditableId.toLowerCase();
  constructor() { }
}
