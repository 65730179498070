<div class="form-group" *ngIf="form" [formGroup]="form">
  <aaos-form-field-title [editMode]="editMode" [field]="field"></aaos-form-field-title>
  <div *ngIf="(editMode && !fieldIsDisabled)">
    <textarea [id]="field.fieldName" class="form-control width--50" type="text" [formControlName]="field.fieldId"
      placement="top"></textarea>
  </div>
  <div *ngIf="!(editMode && !fieldIsDisabled)">
    <div *ngIf="form.get(field.fieldId).value">{{form.get(field.fieldId).value}}</div>

    <div *ngIf="!form.get(field.fieldId).value" class="blank-text">(Blank)
    </div>
  </div>

  <div class="required-text" *ngIf="showRequiredError()">
    {{field.fieldName}} is required
  </div>
  <div class="required-text" *ngIf="showValidationError()">
    Please enter a valid {{field.fieldName}}
  </div>