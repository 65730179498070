<div mat-dialog-content>
  <div class="title title-lg confirm-dialog-text">Profile Update Error!</div>
  <p *ngIf="!data.errors">Failed to update your profile. Please try again later.</p>
  <ng-container *ngFor="let error of data.errors">
    <p *ngIf="error === 'postalcode'">
      You have entered an invalid postal code. Please try again with a valid postal code.
    </p>
    <p *ngIf="error === 'email'">
      You have entered an invalid email. Please try again with a valid email.
    </p>
    <p *ngIf="error === 'city'">
      You have entered an invalid city. Please try again with a valid city.
    </p>
    <p *ngIf="error === 'oldPasswordIncorrectError'">
      Unable to authenticate with current password, please check your password and try again.
    </p>
    <p *ngIf="error === 'primaryEmailExits'">
      The primary email entered already exists with another account. Please try again with another email.
    </p>
  </ng-container>
</div>
`