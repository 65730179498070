import { Sample } from './sample';
import { Flow } from './flow';
import { ServerError } from './error';
import { Log } from './log';
import { ApiResponse } from './api-response';
import { AMPFormFactory } from './amp-form-factory';
import { ProfileScore } from './profile-score';
import { CustomerSearchResult } from './CustomerSearchResult';
import { Customer } from '@shared/models/data/customer';
import { CustomerAttachment } from '@shared/models/data/CustomerAttachment';
import { CustomerCommunication } from '@shared/models/data/CustomerCommunication';
import { AddressDetail } from '@shared/models/data/AddressDetail';
import { AddressField } from '@shared/models/data/AddressField';
import { CustomerProfile } from '@shared/models/data/CustomerProfile';
import { CustomerProfileSnapshot } from '@shared/models/data/CustomerProfileSnapshot';
import { Disclosure } from '@shared/models/data/Disclosure';
import { DisclosureSummary } from '@shared/models/data/DisclosureSummary';

import { ProfileFormOption } from '@shared/models/data/ProfileFormOption';
import { Preference } from '@shared/models/data/preference';
import { OptInInterestPreference } from '@shared/models/data/OptInInterestPreference';
import { PhoneStructure } from '@shared/models/data/PhoneStructure';
import { EmailTopic } from '@shared/models/data/EmailTopic';
import { NameCredentials } from '@shared/models/data/NameCredentials';
import { PhoneNumberStructure } from '@shared/models/data/PhoneNumberStructure';
import { CustomerEmergencyContact } from '@shared/models/data/CustomerEmergencyContact';
import { PracticeInformation } from '@shared/models/data/PracticeInformation';

// data contracts between server and client
export {
    Sample,
    Flow,
    ServerError,
    Log,
    ApiResponse,
    AMPFormFactory,
    ProfileScore,
    CustomerSearchResult
};
export {
    Customer,
    AddressDetail,
    AddressField,
    CustomerAttachment,
    CustomerCommunication,
    CustomerProfile,
    CustomerProfileSnapshot,
    Disclosure,
    DisclosureSummary,
    EmailTopic,
    NameCredentials,
    PhoneNumberStructure,
    PhoneStructure,
    Preference,
    CustomerEmergencyContact,
    OptInInterestPreference,
    ProfileFormOption,
    PracticeInformation
};
