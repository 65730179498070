<div class="form-group" *ngIf="form" [formGroup]="form">
    <aaos-form-field-title [editMode]="editMode" [field]="field"></aaos-form-field-title>
    <div *ngIf="(editMode && !fieldIsDisabled)" class="instructions">
        Upload a resume, recommendation letter, or any other attachments to keep in your profile.
    </div>

    <div class="attachmentsList">
        <div class="files-list" *ngFor="let attachment of attachments;index as i">
            <div>
                <div class="file-name-wrapper">
                    <div *ngIf="(editMode && !fieldIsDisabled)" class="file-name file-name-top-padding"
                        [ngClass]="{'strike-through' : attachment.Action === 'delete' && attachment.IsAttachmentNew === undefined}">
                        <div class="attachment-icon"></div>
                        {{ attachment.FileName }}
                    </div>
                    <div *ngIf="!(editMode && !fieldIsDisabled) && attachment.IsAttachmentNew === undefined"
                        class="file-name file-name-top-padding">
                        <div class="attachment-icon"></div>
                        {{ attachment.FileName }}
                    </div>
                    <div *ngIf="(editMode && !fieldIsDisabled)" class="button-wrapper">

                        <div *ngIf="attachment.Action !== 'delete'" id="'fileDeleteButton' + i"
                            class="file-button delete-file-button"
                            (click)="deleteAttachment(attachment.CustomerRelatedDocumentId)">
                        </div>
                        <div *ngIf=" attachment.Action === 'delete' && attachment.IsAttachmentNew=== undefined"
                            id="'undoFileButton' + i" class="file-button undo-file-button"
                            (click)="undoDeleteAttachment(attachment.CustomerRelatedDocumentId)">
                        </div>
                    </div>
                    <div *ngIf="!(editMode && !fieldIsDisabled) && attachment.IsAttachmentNew=== undefined ">
                        <a  target="_new" class="download-file-button"
                        (click)="downloadFile(attachment.CustomerRelatedDocumentId)"
                            [ngClass]="attachment.IsAttachmentNew === true ? 'disable-download':''"></a>
                    </div>
                    <div *ngIf="(editMode && !fieldIsDisabled)">
                        <a class="download-file-button"
                       (click)="downloadFile(attachment.CustomerRelatedDocumentId)"
                            [ngClass]="attachment.IsAttachmentNew === true ? 'disable-download':''"></a>
                    </div>
                </div>

                <div
                    *ngIf="(editMode && !fieldIsDisabled) && attachment.Action === 'upload' && documentIsBeingEdited(attachment.CustomerRelatedDocumentId)">
                    <div class="document-type-wrapper">
                        <mat-select [formControlName]="'relatedDocType-'+attachment.CustomerRelatedDocumentId"
                            disableRipple disableOptionCentering panelClass="mat-panel">
                            <mat-option *ngFor="let docCode of docCodes" [value]="docCode.PreferenceCode">
                                {{docCode.PreferenceDescription}}</mat-option>
                        </mat-select>
                    </div>
                    <div class="required-text"
                        *ngIf="!form.get('relatedDocType-'+attachment.CustomerRelatedDocumentId).valid">
                        Attachment Type is required.
                    </div>
                </div>

            </div>
        </div>
        <div *ngIf="!editMode && (!attachments ||attachments.length === 0)" class="blank-text">(Blank)</div>
    </div>
    <button (click)="addAttachment()" [ngClass]="{'btn--disabled': !form.valid}"
        *ngIf="editMode && !addingAttachment && !fieldIsDisabled" class="btn btn--outline">Add Attachment</button>
    <div *ngIf="(editMode && addingAttachment && !fieldIsDisabled)" class="file-upload-box" aaosFileSelector
        (fileDropped)="fileDropped($event)">
        <div class="file-upload-box-content align-text-center">
            <img class="file-icon" src="/assets/images/drag-and-drop-file.svg">
            <div class="title title--lg align-text-center">
                Drag Files Here
            </div>
            <div class="align-text-center">
                xlsx, xls, docx, pdf
            </div>
            <div class="btn--container btn--inline image-upload">
                <label for="attachment-upload">
                    <div class="btn btn--solid btn--red fill-width">
                        Select File
                    </div>
                </label>
                <input id="attachment-upload" type="file" [accept]="fileTypesAllowed" (change)="fileSelected($event)"
                    #fileInput>
            </div>
        </div>
    </div>
</div>