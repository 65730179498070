import { Pipe, PipeTransform } from '@angular/core';
import { EmailTopic } from '@shared/models/data';

@Pipe({
  name: 'aaosEmailTopicFilter'
})
export class EmailTopicFilterPipe implements PipeTransform {
  transform(items: EmailTopic[], emailType: string): EmailTopic[] {
    if (!items || !emailType) {
      return items;
    }

    return items.filter(_ => (_.Parent || '').toLowerCase() === emailType.toLowerCase());
  }
}
