import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { EmailPreferencesService, UserProfileService } from '@shared/services/api';
import { UserProfileBaseComponent } from '../user-profile-base.component';
import { ActivatedRoute } from '@angular/router';
import { UserTrackerService } from '@shared/services/trackers';
import { ApplicationFlowParameterCacheService } from '@shared/services/application-flow/application-flow-parameter-cache.service';
import { AllowedRedirectUrlsService } from '@shared/services/allowed-redirect-urls.service';
import { AnnualMeetingRedirectService } from '@shared/services/api/annual-meeting-redirect.service';
import { GlobalNavigation } from '@shared/models/data/GlobalNavigation';
import { tap, takeUntil } from 'rxjs/operators';
import { Constants } from '@shared/services/constants';
import { GlobalNavigationService } from '@shared/services/api/global-nav-service';

@Component({
  selector: 'aaos-global-nav',
  templateUrl: './global-nav.component.html',
  styleUrls: ['./global-nav.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GlobalNavComponent extends UserProfileBaseComponent implements OnInit {
  globalNav: GlobalNavigation;
  html: string;
  isNavbarCollapsed: boolean;
  isApplicationTypeDefault: boolean;
  constructor(
    profileService: UserProfileService,
    private globalNavService: GlobalNavigationService,
    route: ActivatedRoute,
    flowParamCache: ApplicationFlowParameterCacheService,
    allowedUrlService: AllowedRedirectUrlsService,
    customerTokenService: AnnualMeetingRedirectService,
    globalNavigationService: GlobalNavigationService,
    emailPreferencesService: EmailPreferencesService) {
    super(route, flowParamCache, profileService, allowedUrlService, customerTokenService, globalNavigationService, emailPreferencesService);
  }

  ngOnInit() {
    this.isNavbarCollapsed = true;
    this.globalNavService.getGlobalNavigation().pipe(tap(_ => {
      this.globalNav = _;
    }), takeUntil(this._destroy$)).subscribe();

  }
  toggleMenu() {
    this.isNavbarCollapsed = !this.isNavbarCollapsed;
  }
}
