<div class="form-group" *ngIf="form" [formGroup]="form">

  <div [id]="field.fieldId" *ngIf="(editMode && !fieldIsDisabled)">
    <aaos-form-field-title [editMode]="editMode" [field]="field"></aaos-form-field-title>
    <mat-select multiple [formControlName]="field.fieldId" class="width--full"
      (selectionChange)="selectionChanged($event)">
      <mat-option *ngFor="let option of options;" [value]="getPrefix(option)" [disabled]="!option.Active">
        {{ option.PreferenceDescription }}
      </mat-option>
      <span></span>
    </mat-select>
    <div class="required-text" *ngIf="formGroupField.hasError('maxLength')">
      You have selected too many options, please remove an option from your selection.
    </div>
    <div class="required-text" *ngIf="showRequiredError()">
      {{field.fieldName}} is required
    </div>
  </div>

  <div *ngIf="!(editMode && !fieldIsDisabled) && !hideFieldInReadonly">
    <aaos-form-field-title [editMode]="editMode" [field]="field"></aaos-form-field-title>
    <div *ngFor="let selectedValue of readOnlyInitialValues">
      <p *ngIf="readOnlyInitialValues.length>0 ">{{ selectedValue }}</p>

    </div>
    <p *ngIf="readOnlyInitialValues.length===0" class="blank-text">(Blank)</p>
  </div>
</div>