import { FormField } from './form-field';

export class FormFieldGroup {
    public name: string;
    public formFields: FormField[];
    public sortOrder: number;
    public toolTipText: string;
    public toolTipTextDetail: string;
    public id: string;
}
