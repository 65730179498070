import { Injectable } from '@angular/core';
import { AaosBaseService, LoggingService } from '@shared/services/api';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Utilities } from '@shared/services/utils';
import { Constants } from '@shared/services/constants';
import { FormField } from '@shared/form-fields/models/form-field';
import { Observable, Subject } from 'rxjs';
import { MembershipFormFieldResponse } from '@shared/services/api/membership-form-field-response';

@Injectable({
  providedIn: 'root'
})
export class UserProfileFormConfigurationService extends AaosBaseService {


  static ngInjectableDef = undefined;

  constructor(protected _h: HttpClient, protected _l: LoggingService) {
    super(_l, _h);
  }

  getMembershipConfiguration(
    applicationType: string = 'default',
    impersonatedMasterCustomerId = null): Observable<MembershipFormFieldResponse> {
    const headers = this.getImpersonationHeader(impersonatedMasterCustomerId);
    const options = { headers };
    let baseUrl;

    if ( applicationType === Constants.ApplicationTypes.MAAP ) {
      baseUrl = `${Constants.ApiUrls.MAAPFormConfiguration}`;
    } else {
      baseUrl = `${Constants.ApiUrls.MembershipFormConfiguration}`;
    }

    return this.http.get(`${baseUrl}/${applicationType}`, options)
      .pipe(
        map((_: any) => {
          if (!Utilities.isUndefinedOrNull(_.error)) {
            const error = _.error;
            throw new Error(error);
          }
          return _;
        }),
        catchError(_ => this.handleError(_)),
        map((_: MembershipFormFieldResponse) => {
          return _;
        })
      );
  }
}


