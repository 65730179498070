import { Component, OnInit } from '@angular/core';
import { TextFieldComponent } from '@shared/form-fields/text-field/text-field.component';
import { Utilities } from '@shared/services/utils';
import { BirthdayService } from '@shared/services/utils/birthday.service';

@Component({
  selector: 'aaos-date-field',
  templateUrl: './date-field.component.html',
  styleUrls: ['./date-field.component.scss']
})
export class DateFieldComponent extends TextFieldComponent implements OnInit {

  ngOnInit() {
    super.ngOnInit();
    if (this.form != null) {
      const fieldIsValid = this.form.valid;
      const fieldHasValue = !Utilities.isUndefinedOrNull(this.formGroupField.value);
      if (!fieldIsValid || !fieldHasValue) {
        this.mask = '00/00/0000';
        this.placeholder = 'MM/DD/YYYY';
        this.formGroupField.enable();
        this.isReadonly = false;
      } else {
        this.formGroupField.disable();
        this.isReadonly = true;
      }
    }
  }

  getYear() {
    const dobYear = BirthdayService.getYear(this.formGroupField.value);
    return dobYear;
  }
}
