<aaos-header *ngIf="showHeaderAndFooter && user && !error && !isRedirecting && !checkingCompletion"
             [showEmailPreferencesLink]="showEmailPreferencesLink" [isProfilePage]="true"
             [showGlobalNavigation]="showGlobalNavigation"></aaos-header>
<div class="flexbox container userProfile">
    <div *ngIf="user">
        <aaos-notification-modal [user]="user"></aaos-notification-modal>
    </div>
    <div *ngIf="editMode && showBreadCrumb" class="profile-breadcrumb">
        <span class="breadcrumb-text" (click)="toggleEditProfile()">MY PROFILE</span>&ensp;/&ensp;EDIT
        PROFILE
    </div>
    <div *ngIf="user && !error && !isRedirecting && !checkingCompletion">
        <div class="profile-header">
            <div class="profile-header-text">
                <div *ngIf="!editMode " class="my-profile-text">
                    <div class="title title--xlg">My Profile</div>
                    <div class="profile-text">
                        AAOS strives to deliver important, relevant, and timely information to keep you
                        connected to the latest orthopaedic and AAOS news, product and meeting updates, and
                        more.
                    </div>
                </div>
                <div *ngIf="editMode && !showOnlyFields" class="edit-profile-text">
                    <span class="title title--xlg">Edit Profile</span>
                </div>
            </div>
            <div *ngIf="!editMode">
                <aaos-user-login-information [user]="user" [showButtons]="!impersonatedMasterCustomerId">
                </aaos-user-login-information>
                <div class="btn--container spacing">
                    <button class="btn btn--solid btn--red" (click)="toggleEditProfile()">
                        Edit Profile
                    </button>
                </div>
            </div>
        </div>
        <div>
            <aaos-dynamic-user-profile-edit [editMode]="editMode" [userTrackerService]="userTrackerService" [enableDebug]="enableDebug"
                                            [isChildComponent]="true" (finished)="handleFinishProfile($event)" [refreshEditState$]="refreshEditState$">
            </aaos-dynamic-user-profile-edit>
        </div>
    </div>
    <aaos-spinner [isLoading]="(!user && !error && !isRedirecting) || checkingCompletion"></aaos-spinner>
    <div *ngIf="error">
        <div>
            An error occurred trying to fetch the user profile. Please try again in a few moments, or
            contact the System Administrator for more information.
        </div>
    </div>
    <div *ngIf="isRedirecting">
        <div>Redirecting you to authenticate, please wait...</div>
    </div>
</div>
<aaos-footer *ngIf="showHeaderAndFooter && user && !error && !isRedirecting && !checkingCompletion"></aaos-footer>