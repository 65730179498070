import { Injectable } from '@angular/core';
import { FormField, FormFieldRuleConstants, FormFieldConstants, FieldTypeConstants } from '@shared/form-fields/models/form-field';
import { FormFieldGroup } from '@shared/form-fields/models/form-field-group';
import { FormGroup, FormControl, Validators, AbstractControl, FormBuilder, ValidatorFn, ValidationErrors } from '@angular/forms';
import { FormFieldValidatorsService } from '@shared/form-fields/form-field-validators.service';
import { Constants } from '@shared/services/constants';
import { Utilities } from '@shared/services/utils';
import { Customer } from '@shared/models/data';


export class FormConfiguration {
  groups: FormFieldGroup[];
  formGroup: FormGroup;
}


@Injectable({
  providedIn: 'root'
})
export class FormBuilderService {

  addressControl: AbstractControl;
  constructor(private formBuilder: FormBuilder, private formFieldValidatorsService: FormFieldValidatorsService) { }

  public static getFieldState(field: FormField) {
    return {
      value: null, // do not remove this line, if value is not set in the field state for some reason it ignores the disabled flag
      disabled: field.ruleId.toLowerCase() === FormFieldRuleConstants.DisplayOnlyId.toLowerCase()
    };
  }

  public static getSubfieldIdByField(fieldId: FormField, subFieldName: string) {
    return `${fieldId.fieldId}-${subFieldName}`;
  }
  public static getSubfieldIdById(fieldId: string, subFieldName: string) {
    return `${fieldId}-${subFieldName}`;
  }

  setUpForm(data: Array<FormField>, user: Customer): FormConfiguration {
    const configuration = new FormConfiguration();
    const formGroup = new FormGroup({});
    const newformFieldGroups = new Array<FormFieldGroup>();
    data = data
      .sort((n1, n2) => n1.fieldSortOrder - n2.fieldSortOrder)
      .filter(x => x.ruleId.toLowerCase() !== FormFieldRuleConstants.HiddenId.toLowerCase());
    for (const field of data) {
      let childFormGroup = new FormGroup({});
      const existingGroup = newformFieldGroups.filter(x => x.id === field.groupId).pop();
      if (existingGroup) {
        existingGroup.formFields.push(field);
        childFormGroup = formGroup.get(field.groupName) as FormGroup;
      } else {
        const newFormFieldGroup = new FormFieldGroup();
        newFormFieldGroup.id = field.groupId;
        newFormFieldGroup.toolTipText = field.groupToolTipText;
        newFormFieldGroup.toolTipTextDetail = field.groupToolTipTextDetail;
        newFormFieldGroup.name = field.groupName;
        newFormFieldGroup.sortOrder = field.groupSortOrder;
        newFormFieldGroup.formFields = new Array<FormField>();
        newFormFieldGroup.formFields.push(field);
        newformFieldGroups.push(newFormFieldGroup);
      }
      const newControl = this.buildControl(field, user, formGroup);

      childFormGroup.addControl(field.fieldId, newControl);
      formGroup.addControl(field.groupName, childFormGroup);
    }
    configuration.groups = newformFieldGroups.sort((n1, n2) => n1.sortOrder - n2.sortOrder);
    configuration.formGroup = formGroup;
    return configuration;
  }
  buildControl(field: FormField, user: Customer, form: FormGroup): AbstractControl {
    const lowerCaseFieldTypeId = field.fieldTypeId.toLowerCase();
    switch (lowerCaseFieldTypeId) {
      case FieldTypeConstants.TextBoxId.toLowerCase():
      case FieldTypeConstants.TextAreaName.toLowerCase():
      case FieldTypeConstants.DropDownId.toLowerCase():
      case FieldTypeConstants.RadioButtonId.toLowerCase():
        return this.buildBasicControl(field, form);
      case FieldTypeConstants.CheckBoxId.toLowerCase():
        return this.buildCheckboxControl(field);
      case FieldTypeConstants.DateId.toLowerCase():
        return this.buildDateFormControl(field);
      case FieldTypeConstants.EmailId.toLowerCase():
        return this.buildEmailFormGroup(field, user);
      case FieldTypeConstants.AddressId.toLowerCase():
        return this.buildAddressControl(field);
      case FieldTypeConstants.PhoneId.toLowerCase():
        return this.buildPhoneFormGroup(field, user);
      case FieldTypeConstants.MobileId.toLowerCase():
        return this.buildCellPhoneControl(field, user);
      case FieldTypeConstants.WorkStatusId.toLowerCase():
        return this.buildWorkStatusForm(field);
      case FieldTypeConstants.EmergencyContactId.toLowerCase():
        return this.buildEmergencyContactForm(field, user);
      case FieldTypeConstants.BiographyId.toLowerCase():
        return this.buildBiographyField(field);
      case FieldTypeConstants.WebSocialLinkId.toLowerCase():
        return this.buildWebSocialLinkForm(field, user);
      default:
        return new FormControl('');
    }
  }

  buildBiographyField(field: FormField): AbstractControl {
    const biographyValidator = this.formFieldValidatorsService.getRuleBaseValidators(field.ruleId, field.fieldTypeName);
    const textState = this.formFieldValidatorsService.getFieldState(field);
    const attachmentsState = this.formFieldValidatorsService.getFieldState(field);

    const formGroup = new FormGroup({});
    formGroup.addControl(`${field.fieldId}-${Constants.FormSubFieldNameConstants.biographyText}`,
      new FormControl(textState, { validators: biographyValidator }));
    formGroup.addControl(`${field.fieldId}-${Constants.FormSubFieldNameConstants.biographyAttachments}`,
      new FormControl(attachmentsState)); // attachments are optional or disabled
    return formGroup;
  }

  buildEmergencyContactForm(field: FormField, user: Customer): AbstractControl {
    const listFormGroup = new FormGroup({});
    const emergencyContacts = user.EmergencyContacts;
    for (let i = 0; i < emergencyContacts.length; i++) {
      const uniqueKey = user.CustomerProfile.MasterCustomerId + '-' + i + '-' + Constants.AdditionalFieldConstants.Emergency;
      const formGroup = new FormGroup({});
      const contactNameValidator = this.formFieldValidatorsService.getRuleBaseValidators(FormFieldRuleConstants.OptionalEditableId,
        field.fieldTypeName);
      const relationshipValidator = this.formFieldValidatorsService.getRuleBaseValidators(FormFieldRuleConstants.OptionalEditableId,
        field.fieldTypeName);
      const phoneNumberValidator = this.formFieldValidatorsService.getRuleBaseValidators(FormFieldRuleConstants.OptionalEditableId,
        field.fieldTypeName);
      const phoneTypeValidator = this.formFieldValidatorsService.getRuleBaseValidators(FormFieldRuleConstants.OptionalEditableId,
        field.fieldTypeName);
      const commentsValidator = this.formFieldValidatorsService.getRuleBaseValidators(FormFieldRuleConstants.OptionalEditableId,
        field.fieldTypeName);
      const contactNameFieldState = this.formFieldValidatorsService.getFieldState(field);
      const relationshipFieldState = this.formFieldValidatorsService.getFieldState(field);
      const phoneNumberFieldState = this.formFieldValidatorsService.getFieldState(field);
      const phoneTypeFieldState = this.formFieldValidatorsService.getFieldState(field);
      const commentsFieldState = this.formFieldValidatorsService.getFieldState(field);
      formGroup.addControl(`${field.fieldId}-${Constants.FormSubFieldNameConstants.emergencyContactId}`,
        new FormControl());
      formGroup.addControl(`${field.fieldId}-${Constants.FormSubFieldNameConstants.contactName}`,
        new FormControl(contactNameFieldState, { validators: contactNameValidator }));
      formGroup.addControl(`${field.fieldId}-${Constants.FormSubFieldNameConstants.relationship}`,
        new FormControl(relationshipFieldState, { validators: relationshipValidator }));
      formGroup.addControl(`${field.fieldId}-${Constants.FormSubFieldNameConstants.phoneNumber}`,
        new FormControl(phoneNumberFieldState, { validators: phoneNumberValidator }));
      formGroup.addControl(`${field.fieldId}-${Constants.FormSubFieldNameConstants.comments}`,
        new FormControl(commentsFieldState, { validators: commentsValidator }));
      formGroup.addControl(`${field.fieldId}-${Constants.FormSubFieldNameConstants.phoneType}`,
        new FormControl(phoneTypeFieldState, { validators: phoneTypeValidator }));
      formGroup.addControl(Constants.AdditionalFieldConstants.id, new FormControl());
      formGroup.get(Constants.AdditionalFieldConstants.id).setValue(uniqueKey);
      formGroup.addControl(Constants.AdditionalFieldConstants.newflag, new FormControl());
      formGroup.get(Constants.AdditionalFieldConstants.newflag).setValue(false);
      formGroup.addControl(Constants.AdditionalFieldConstants.deletedflag, new FormControl());
      formGroup.get(Constants.AdditionalFieldConstants.deletedflag).setValue(false);
      listFormGroup.addControl(uniqueKey, formGroup);
    }
    return listFormGroup;
  }
  buildWebSocialLinkForm(field: FormField, user: Customer): AbstractControl {
    const listFormGroup = new FormGroup({});
    const socialLinks = user.Communications.filter(x => x.CommunicationType.toLowerCase() === Constants.CommunicationType.web);
    const isAnyMediaPrimary = socialLinks.filter(x => x.IsPrimary).length > 0;
    for (let i = 0; i < socialLinks.length; i++) {
      const uniqueKey = user.CustomerProfile.MasterCustomerId + '-' + i + '-' + Constants.CommunicationType.web;
      const formGroup = new FormGroup({});
      const webSiteNameValidator = this.formFieldValidatorsService.getRuleBaseValidators(FormFieldRuleConstants.OptionalEditableId,
        field.fieldTypeName);
      const webSiteAddressValidator = this.formFieldValidatorsService.getRuleBaseValidators(FormFieldRuleConstants.OptionalEditableId,
        field.fieldTypeName);
      const webIncludeInDircetoryValidator = this.formFieldValidatorsService.getRuleBaseValidators(field.ruleId, field.fieldTypeName);

      const fieldState = this.formFieldValidatorsService.getFieldState(field);

      formGroup.addControl(`${Constants.FormSubFieldNameConstants.webSiteName}`,
        new FormControl(fieldState, { validators: webSiteNameValidator, updateOn: 'blur' }));
      formGroup.addControl(`${Constants.FormSubFieldNameConstants.webSiteAddress}`,
        new FormControl(fieldState, { validators: webSiteAddressValidator, updateOn: 'blur' }));
      formGroup.addControl(`${Constants.FormSubFieldNameConstants.webIncludeInDirectoryFlag}`,
        new FormControl(fieldState, { validators: webIncludeInDircetoryValidator, updateOn: 'blur' }));
      formGroup.addControl(Constants.AdditionalFieldConstants.id, new FormControl());
      formGroup.get(Constants.AdditionalFieldConstants.id).setValue(uniqueKey);
      formGroup.addControl(Constants.AdditionalFieldConstants.newflag, new FormControl());
      formGroup.get(Constants.AdditionalFieldConstants.newflag).setValue(false);
      formGroup.addControl(Constants.AdditionalFieldConstants.deletedflag, new FormControl());
      formGroup.get(Constants.AdditionalFieldConstants.deletedflag).setValue(false);
      formGroup.addControl(Constants.AdditionalFieldConstants.primaryflag, new FormControl(fieldState));
      if (!isAnyMediaPrimary && i === 0) {
        formGroup.get(Constants.AdditionalFieldConstants.primaryflag).setValue(true);
      } else {
        formGroup.get(Constants.AdditionalFieldConstants.primaryflag).setValue(socialLinks[i].IsPrimary);
      }
      listFormGroup.addControl(uniqueKey, formGroup);
    }
    return listFormGroup;
  }
  buildWorkStatusForm(field: FormField): AbstractControl {
    const validator = this.formFieldValidatorsService.getRuleBaseValidators(field.ruleId, field.fieldTypeName);
    const fieldState = this.formFieldValidatorsService.getFieldState(field);
    const retirementAgeFormField = new FormControl();
    const formGroup = new FormGroup({});
    formGroup.addControl(`${field.fieldId}-${Constants.FormSubFieldNameConstants.workStatusStatus}`,
      new FormControl(fieldState, { validators: validator }));
    formGroup.addControl(`${field.fieldId}-${Constants.FormSubFieldNameConstants.retirementAge}`,
      retirementAgeFormField);
    return formGroup;
  }
  buildAddressControl(field: FormField): AbstractControl {
    // due to the complexity of this control, it builds up its own form group
    return  new FormGroup({});
  }
  buildDateFormControl(field: FormField): AbstractControl {
    const validator = this.formFieldValidatorsService.getRuleBaseValidators(field.ruleId, field.fieldTypeName);
    validator.push(this.formFieldValidatorsService.checkDateValidation());
    const fieldState = this.formFieldValidatorsService.getFieldState(field);
    return new FormControl(fieldState, { validators: validator, updateOn: 'blur' });
  }
  buildBasicControl(field: FormField, form: FormGroup) {
    const validator = this.formFieldValidatorsService.getRuleBaseValidators(field.ruleId, field.fieldTypeName);
    const fieldState = this.formFieldValidatorsService.getFieldState(field);
    const validatorOptions = this.getFieldSpecificValidatorOptions(field, validator, form);
    return new FormControl(fieldState, validatorOptions);
  }
  getFieldSpecificValidatorOptions(field: FormField, validator: ValidatorFn[], form: FormGroup): any {
    if (field.fieldId === FormFieldConstants.NPId) {
      const npiValidator = this.formFieldValidatorsService.npiValidation(form, field);
      return { validators: npiValidator };
    }
    if (field.fieldId.toLowerCase() === FormFieldConstants.ABOSRecertificationYear.toLowerCase() ||
      field.fieldId.toLowerCase() === FormFieldConstants.ABOSDiplomateId.toLowerCase()) {
      validator.push(FormFieldValidatorsService.yearNumberValidation(1));
      return { validators: validator };
    }
    return { validators: validator, updateOn: 'blur' };
  }

  buildCheckboxControl(field: FormField): AbstractControl {

    const validator = this.formFieldValidatorsService.getRuleBaseValidators(field.ruleId, field.fieldTypeName);

    if (field.fieldId === FormFieldConstants.NameCredentialsId) {
      const maxLengthValidator = this.formFieldValidatorsService
        .maxOptionsLength(FormFieldConstants.NameCredentialsMaxLength);
      validator.push(maxLengthValidator);
    }
    const fieldState = this.formFieldValidatorsService.getFieldState(field);
    return new FormControl(fieldState, { validators: validator, updateOn: 'blur' });
  }

  private buildEmailFormGroup(field: FormField, user: Customer) {
    const listFormGroup = new FormGroup({});
    const userCommunicationsForEmail =
      user.Communications.filter(x => x.CommunicationType.toLowerCase() === Constants.CommunicationType.email);
    for (let i = 0; i < userCommunicationsForEmail.length; i++) {
      const typeValidator = this.formFieldValidatorsService.getRuleBaseValidators(field.ruleId, field.fieldTypeName);
      const uniqueKey =
        user.CustomerProfile.MasterCustomerId + '-' + i + '-' + userCommunicationsForEmail[i].CommunicationType.toLowerCase();
      const formGroup = new FormGroup({});
      const fieldState = this.formFieldValidatorsService.getFieldState(field);
      const validator = this.formFieldValidatorsService.getRuleBaseValidators(field.ruleId, field.fieldTypeName);
      // tslint:disable-next-line: max-line-length
      validator.push(Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/));
      formGroup.addControl(Constants.FormSubFieldNameConstants.emailAddress,
        new FormControl(fieldState, { validators: validator }));
      formGroup.addControl(Constants.FormSubFieldNameConstants.emailType, new FormControl(fieldState, typeValidator));
      formGroup.addControl(Constants.AdditionalFieldConstants.id, new FormControl());
      formGroup.get(Constants.AdditionalFieldConstants.id).setValue(uniqueKey);
      formGroup.addControl(Constants.AdditionalFieldConstants.newflag, new FormControl(fieldState));
      formGroup.get(Constants.AdditionalFieldConstants.newflag).setValue(false);
      formGroup.addControl(Constants.AdditionalFieldConstants.primaryflag, new FormControl(fieldState));
      formGroup.get(Constants.AdditionalFieldConstants.primaryflag).setValue(userCommunicationsForEmail[i].IsPrimary);
      formGroup.addControl(Constants.AdditionalFieldConstants.deletedflag, new FormControl(fieldState));
      formGroup.get(Constants.AdditionalFieldConstants.deletedflag).setValue(false);
      listFormGroup.addControl(uniqueKey, formGroup);
    }
    return listFormGroup;
  }

  private buildPhoneFormGroup(field: FormField, user: Customer) {

    const listFormGroup = new FormGroup({});
    const userCommunicationsForPhone =
      user.Communications.filter(x =>
        x.CommunicationType.toLowerCase() === Constants.CommunicationType.phone);
    if (userCommunicationsForPhone.length === 0 &&
          field.ruleId === FormFieldRuleConstants.RequiredEditableId.toLowerCase()) {
          const uniqueKey = user.CustomerProfile.MasterCustomerId + '-' + 0 + '-' + 'phone';
          this.CreatePhoneObject(field, listFormGroup, uniqueKey, true, true);
    } else {
    for (let i = 0; i < userCommunicationsForPhone.length; i++) {
      const uniqueKey =
        user.CustomerProfile.MasterCustomerId + '-' + i + '-' + userCommunicationsForPhone[i].CommunicationType.toLowerCase();
      this.CreatePhoneObject(field, listFormGroup, uniqueKey, false, userCommunicationsForPhone[i].IsPrimary);
    }
  }
    return listFormGroup;
  }

  private CreatePhoneObject(field: FormField, listFormGroup: FormGroup, key: string, newFlag: boolean, primaryFlag: boolean) {

    const formGroup = new FormGroup({});
    const countryCodeValidator = this.formFieldValidatorsService.getRuleBaseValidators(field.ruleId, field.fieldTypeName);
    const validator = this.formFieldValidatorsService.getRuleBaseValidators(field.ruleId, field.fieldTypeName);
    const phoneTypeValidator = this.formFieldValidatorsService.getRuleBaseValidators(field.ruleId, field.fieldTypeName);
    const fieldState = this.formFieldValidatorsService.getFieldState(field);
    formGroup.addControl(Constants.FormSubFieldNameConstants.countryCode,
      new FormControl(fieldState, { validators: countryCodeValidator, updateOn: 'blur' }));
    formGroup.addControl(Constants.FormSubFieldNameConstants.phoneNumber,
      new FormControl(fieldState, { validators: validator }));
    formGroup.addControl(Constants.FormSubFieldNameConstants.phoneType,
      new FormControl(fieldState, { validators: phoneTypeValidator, updateOn: 'blur' }));
    formGroup.addControl(Constants.FormSubFieldNameConstants.phoneExtension,
      new FormControl(fieldState, { updateOn: 'blur' }));
    formGroup.addControl(Constants.FormSubFieldNameConstants.doNotCallFlag,
      new FormControl(fieldState));
    formGroup.addControl(Constants.FormSubFieldNameConstants.phoneIncludeInDirectoryFlag,
      new FormControl(fieldState));
    formGroup.addControl(Constants.FormSubFieldNameConstants.countryCodeNumber,
      new FormControl(fieldState));
    formGroup.addControl(Constants.AdditionalFieldConstants.newflag,
      new FormControl(fieldState));
    formGroup.get(Constants.AdditionalFieldConstants.newflag).setValue(newFlag);
    formGroup.addControl(Constants.AdditionalFieldConstants.primaryflag, new FormControl(fieldState));
    formGroup.get(Constants.AdditionalFieldConstants.primaryflag).setValue(primaryFlag);
    formGroup.addControl(Constants.AdditionalFieldConstants.id, new FormControl());
    formGroup.get(Constants.AdditionalFieldConstants.id).setValue(key);
    formGroup.addControl(Constants.AdditionalFieldConstants.deletedflag, new FormControl(fieldState));
    formGroup.get(Constants.AdditionalFieldConstants.deletedflag).setValue(false);
    listFormGroup.addControl(key, formGroup);
  }

  private buildCellPhoneControl(field: FormField, user: Customer) {
    const formGroup = new FormGroup({});
    const uniqueKey = user.CustomerProfile.MasterCustomerId + '-' + Constants.CommunicationType.mobile.toLowerCase();
    const countryCodeValidator = this.formFieldValidatorsService.getRuleBaseValidators(field.ruleId, field.fieldTypeName);
    const validator = this.formFieldValidatorsService.getRuleBaseValidators(field.ruleId, field.fieldTypeName);
    const fieldState = this.formFieldValidatorsService.getFieldState(field);
    formGroup.addControl(Constants.FormSubFieldNameConstants.countryCode,
      new FormControl(fieldState, { validators: countryCodeValidator, updateOn: 'blur' }));
    formGroup.addControl(Constants.FormSubFieldNameConstants.phoneNumber,
      new FormControl(fieldState, { validators: validator }));
    formGroup.addControl(Constants.FormSubFieldNameConstants.countryCodeNumber, new FormControl(fieldState));
    formGroup.addControl(Constants.AdditionalFieldConstants.id, new FormControl());
    formGroup.get(Constants.AdditionalFieldConstants.id).setValue(uniqueKey);
    formGroup.addControl(Constants.FormSubFieldNameConstants.phoneType, new FormControl());
    formGroup.addControl(Constants.AdditionalFieldConstants.newflag, new FormControl(fieldState));
    formGroup.get(Constants.AdditionalFieldConstants.newflag).setValue(false);
    formGroup.addControl(Constants.FormSubFieldNameConstants.mobileOptinFlag,
      new FormControl(fieldState));

    return formGroup;
  }
}
