import { Injectable } from '@angular/core';
import { ProfileCompleteness } from '@shared/models/enums';
import { Utilities } from '@shared/services/utils';
import { ProfileScore } from '@shared/models/data';

@Injectable({
  providedIn: 'root'
})
export class ProfilePromptLogicService {
  shouldRedirect(score: ProfileScore, redirectUri: string): any {
    return !score.HasBadPrimaryAddress && (score.CompletenessType === 'Ignore' ||
      (score.CompletenessType === 'Complete' && Utilities.isDefined(redirectUri))
    );
  }
  promptTitleText(profileScore: ProfileScore) {
    const promptType = profileScore.CompletenessType;
    if (promptType === 'Annual') {
      return `It's been a year since your last profile update`;
    } else if (promptType === 'Incomplete') {
      return `Your profile is ${this.formatScore(profileScore.Completeness)} complete.`;
    } else if (promptType === 'Ignore' || promptType === 'Complete') {
      if (profileScore.HasBadPrimaryAddress) {
        return 'Your Primary Mailing Address is undeliverable.';
      } else if (promptType === 'Complete') {
        return 'Your profile is up to date.';
      }
    }
    return '';
  }

  promptText(score: ProfileScore) {
    const promptType = score.CompletenessType;
    if (promptType === 'Annual' || promptType === 'Incomplete') {
      return `Stay connected by keeping your profile up to date.`;
    } else if (score.HasBadPrimaryAddress) {
      return 'Please update your primary address.';
    } else if (promptType === 'Complete') {
      return 'Your profile is up to date.';
    } else {
      return '';
    }
  }

  primaryBtnText(promptType: ProfileCompleteness) {
    switch (promptType) {
      case 'Annual':
        return `Confirm my profile`;
      case 'Incomplete':
        return `Complete my profile`;
      case 'Complete':
      default:
        return `Update my profile`;
    }
  }

  secondaryBtnText() {
    return `Maybe later`;
  }

  formatScore(score: number) {
    return Utilities.isDefined(score) ? `${Math.round(score)}%` : `${NaN}%`;
  }
}
