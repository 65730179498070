import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatDialogModule, MatSlideToggleModule,
  MatRadioModule, MatSelectModule,
  MatPaginatorModule, MatTableModule
} from '@angular/material';
import { SharedModule } from '@shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { EmailPreferencesComponent } from './email-preferences.component';
import { EmailPreferencesSearchComponent } from './search/search.component';
import { EmailPreferencesRoutingModule } from './routing.module';
import { ManagePreferencesComponent } from './manage-preferences/manage-preferences.component';
import {
  EmailPreferencesUnsubscribeComponent,
  UnsubscribeConfirmModalComponent,
  UnsubscribeMarketoConfirmModalComponent
} from './unsubscribe/unsubscribe.component';

const components = [
  EmailPreferencesComponent,
  EmailPreferencesSearchComponent,
  ManagePreferencesComponent,
  EmailPreferencesUnsubscribeComponent,
  UnsubscribeConfirmModalComponent,
  UnsubscribeMarketoConfirmModalComponent
];
@NgModule({
  imports: [
    CommonModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatSelectModule,
    MatTableModule,
    MatPaginatorModule,
    SharedModule,
    ReactiveFormsModule,
    RouterModule,
    EmailPreferencesRoutingModule,
    MatRadioModule
  ],
  exports: [
    EmailPreferencesComponent,
    ManagePreferencesComponent,
    EmailPreferencesUnsubscribeComponent
  ],
  entryComponents: [ UnsubscribeConfirmModalComponent, UnsubscribeMarketoConfirmModalComponent ],
  declarations: [...components]
})
export class EmailPreferencesModule { }
