import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'aaos-password-criteria',
  templateUrl: './password-criteria.component.html',
  styleUrls: ['./password-criteria.component.scss']
})
export class PasswordCriteriaComponent implements OnInit {

  @Input()
  fieldName: string;
  @Input()
  errorName: string;
  @Input()
  message: string;
  @Input()
  form: FormGroup;

  constructor() { }

  ngOnInit() {
  }
  showValidationError() {
    if (!this.form) { return false; }
    const control = this.form.get(this.fieldName);
    return control && control.hasError(this.errorName);
  }
}
