<div class="form-group web-spacing" *ngIf="form" [formGroup]="form">
  <div *ngIf="!hasFormGroups()">
    <aaos-form-field-title [editMode]="editMode" [field]="field"></aaos-form-field-title>
  </div>

  <div class="form-group" [formGroup]="form">
    <div *ngFor="let formGroupsForWebMedia of formGroups; let i = index">
      <aaos-web-media-field [field]="field" [formOptions]="formOptions" [form]="formGroupsForWebMedia" [listIndex]="i"
      [selectedWebSiteNames]="selectedWebSiteNames" [webSiteNameSubject]= "webSiteNameSubject" [editMode]="editMode" [refreshEditState$]="refreshEditState$">
      </aaos-web-media-field>
    </div>
    <div *ngIf="(editMode) && showAddButton === true">
      <button (click)="addNewWebMediaSocialLink()" class="btn btn--outline" [ngClass] ="{'btn--disabled': !form.valid}">Add Web
        media</button>
    </div>
    <div *ngIf="!(editMode && !fieldIsDisabled) && !hideFieldInReadonly">
      <p *ngIf="formGroups.length==0" class="blank-text">(Blank)</p>
    </div>
  </div>