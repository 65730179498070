import { Constants } from '@shared/services/constants';

export enum MemberGroups {
    Resident = '2',
    Resident2DO = '2DO',
    Candidate = '3',
    Candidate3AO = '3AO',
    Candidate3DO = '3DO',
    Applicant = '4',
    Applicant4AO = '4AO',
    Applicant4DO = '4DO',
    ActiveFellow = '7',
    Associate7AO = '7AO',
    Associate7DO = '7DO',
    Emeritus = '8',
    Emeritus8AO = '8AO',
    Emeritus8DO = '8DO',
    NonMember = 'X',
    NonMemberOrthoSurgeon = '6'
}
