import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Customer } from '@shared/models/data';
import { Constants } from '@shared/services/constants';
import { FormGroup, FormControl, AbstractControl } from '@angular/forms';
import { FormFieldValidatorsService } from '@shared/form-fields/form-field-validators.service';
import { BaseFieldListComponent } from '@shared/form-fields/baseFieldListComponent';
import { Utilities } from '@shared/services/utils';
import { Subject, Subscription, BehaviorSubject } from 'rxjs';


@Component({
  selector: 'aaos-phone-list',
  templateUrl: './phone-list.component.html',
  styleUrls: ['./phone-list.component.scss']
})
export class PhoneListComponent extends BaseFieldListComponent implements OnInit {
  @Input()
  user: Customer;
  @Input()
  editMode: boolean;
  selectedPhoneTypes: string[];
  constructor(public formFieldValidatorsService: FormFieldValidatorsService) {
    super(formFieldValidatorsService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.buildSelectedPhoneTypes();
  }
  buildSelectedPhoneTypes() {
    this.selectedPhoneTypes = [];
    this.formGroups.forEach(element => {
      if (element.get(Constants.FormSubFieldNameConstants.phoneType).value != null) {
        this.selectedPhoneTypes.push(element.get(Constants.FormSubFieldNameConstants.phoneType).value);
      }
    });
  }
  isPhoneDuplicateForCustomer(event: any, isCountryCodeUsaOrCan: boolean) {
    const formGroup = this.form as FormGroup;
    for (const key of Object.keys(formGroup.controls)) {
      const formControl = formGroup.get(key) as FormGroup;
      const phone = formControl.get(Constants.FormSubFieldNameConstants.phoneNumber).value;
      let phoneNumber = '';
      phoneNumber = this.buildPhoneNumberBasedOnCountryCode(event.value, isCountryCodeUsaOrCan);
      if (phoneNumber === phone && event.id !== key) {
        return true;
      }

    }
    return false;
  }
  buildPhoneNumberBasedOnCountryCode(value: any, isCountryCodeUsaOrCan: boolean): string {
    const _phone = value.replace(/\D/g, '');
    const _phoneNumber = isCountryCodeUsaOrCan
      ? _phone.substring(3, _phone) : _phone;
    const _areaCode = isCountryCodeUsaOrCan
      ? _phone.substring(0, 3) : '';
    let phoneNumber = '';
    if (isCountryCodeUsaOrCan) {
      phoneNumber = _areaCode + _phoneNumber;
      return phoneNumber;
    } else {
      phoneNumber = value;
      return phoneNumber;
    }
  }
  addNewPhone() {
    const existingFormGroup = this.form as FormGroup;
    const existingFormControlsCountForPhone = Object.keys(existingFormGroup.controls).length;
    const isPrimary = existingFormControlsCountForPhone === 0;
    const uniqueKey = this.user.CustomerProfile.MasterCustomerId + '-' + existingFormControlsCountForPhone + '-' + 'phone';
    const newFormGroup = new FormGroup({});
    const countryCodeValidator = this.formFieldValidatorsService.getRuleBaseValidators(this.field.ruleId, this.field.fieldTypeName);
    const validator = this.formFieldValidatorsService.getRuleBaseValidators(this.field.ruleId, this.field.fieldTypeName);
    const phoneTypeValidator = this.formFieldValidatorsService.getRuleBaseValidators(this.field.ruleId, this.field.fieldTypeName);
    const fieldState = this.formFieldValidatorsService.getFieldState(this.field);

    newFormGroup.addControl(Constants.FormSubFieldNameConstants.countryCode,
      new FormControl(fieldState, { validators: countryCodeValidator, updateOn: 'blur' }));
    newFormGroup.addControl(Constants.FormSubFieldNameConstants.phoneNumber,
      new FormControl(fieldState, { validators: validator, updateOn: 'blur' }));
    newFormGroup.addControl(Constants.FormSubFieldNameConstants.phoneType,
      new FormControl(fieldState, { validators: phoneTypeValidator, updateOn: 'blur' }));
    newFormGroup.addControl(Constants.FormSubFieldNameConstants.phoneExtension,
      new FormControl(fieldState, { updateOn: 'blur' }));
    newFormGroup.addControl(Constants.FormSubFieldNameConstants.doNotCallFlag,
      new FormControl(fieldState));
    newFormGroup.addControl(Constants.FormSubFieldNameConstants.phoneIncludeInDirectoryFlag,
      new FormControl(fieldState));
    newFormGroup.addControl(Constants.FormSubFieldNameConstants.countryCodeNumber,
      new FormControl(fieldState));
    newFormGroup.addControl(Constants.AdditionalFieldConstants.id, new FormControl());
    newFormGroup.get(Constants.AdditionalFieldConstants.id).setValue(uniqueKey);
    newFormGroup.addControl(Constants.AdditionalFieldConstants.primaryflag, new FormControl(fieldState));
    newFormGroup.get(Constants.AdditionalFieldConstants.primaryflag).setValue(isPrimary);
    newFormGroup.addControl(Constants.AdditionalFieldConstants.deletedflag, new FormControl(fieldState));
    newFormGroup.get(Constants.AdditionalFieldConstants.deletedflag).setValue(false);
    newFormGroup.addControl(Constants.AdditionalFieldConstants.newflag, new FormControl(fieldState));
    newFormGroup.get(Constants.AdditionalFieldConstants.newflag).setValue(true);
    existingFormGroup.addControl(uniqueKey, newFormGroup);
    this.formGroups.push(newFormGroup);
    this.buildSelectedPhoneTypes();
    this.phoneTypeSubject.next(this.selectedPhoneTypes);
    this.setShowAddButton();
  }
  delete(id: string) {
    const formGroup = this.form as FormGroup;
    const deletedFromGroup = formGroup.get(id) as FormGroup;
    const deletedFlagControl = deletedFromGroup.get(Constants.AdditionalFieldConstants.deletedflag);
    const newFlagControl = deletedFromGroup.get(Constants.AdditionalFieldConstants.newflag);
    deletedFlagControl.setValue(true);
    if (newFlagControl.value === true) {
      formGroup.removeControl(id);
    } else {
      // remove validators for delete form which should no
      // longer be validated because it is only being used to track the deleted address
      Object.keys(deletedFromGroup.controls).forEach(_ => {
        const control = deletedFromGroup.controls[_];
        control.setValidators([]);
        control.updateValueAndValidity();
      });
    }
    const formToUpdateValidity = deletedFromGroup as AbstractControl;
    formToUpdateValidity.updateValueAndValidity();
    this.formGroups = [];
    for (const key of Object.keys(formGroup.controls)) {
      const formControl = formGroup.get(key) as FormGroup;
      const deletedFormControl = formControl.get(Constants.AdditionalFieldConstants.deletedflag);
      const deletedValue = deletedFormControl.value;
      if (key !== id && deletedValue === false) {
        this.formGroups.push(formControl);
      }
    }
    if (id.includes(Constants.CommunicationType.phone)) {
      this.buildSelectedPhoneTypes();
      this.phoneTypeSubject.next(this.selectedPhoneTypes);
    }
    this.setShowAddButton();
  }
}

