<div class="form-group" [formGroup]="formGroup">
    <div class="title-deleteicon">
        <div *ngIf="isAddressPrimary()" class="deleteable-field-title-section">
            <aaos-form-field-title [editMode]="editMode" [field]="field"></aaos-form-field-title>
        </div>
        <div *ngIf="!isAddressPrimary() && !isBlankAdditionalItem()" class="deleteable-field-title-section">
            <div class="title title--xs">
                {{AddressFieldName | titlecase}}
            </div>
        </div>
        <div *ngIf="(!isAddressPrimary() && editMode)" class="delete-button-wrapper">
            <div id="{{AddressId}}" class="delete-file-button" (click)="deleteAddress($event.target.id)"></div>
        </div>
    </div>
    <div *ngIf="(editMode && !fieldIsDisabled)">
        <div class="form-group">
            <mat-select formControlName="addressType" (selectionChange)="addressTypeChanged($event.value)" disableRipple
                        panelClass="mat-panel">
                <mat-option *ngFor="let type of addressTypesOptions" [value]="type.PreferenceCode" [disabled]="!type.Active">
                    {{
                  type.PreferenceDescription
                    }}
                </mat-option>
            </mat-select>
            <div class="required-text" *ngIf="showAddressTypeMessage()">
                Address Type is required
            </div>
        </div>


        <aaos-searchable-select [formControlName]="formControlName" [formGroup]="formGroup" [options]="countriesOptions"
            (selectionChange)="resetAddressStructure($event)">

        </aaos-searchable-select>
        <div class="form-group"
            *ngIf="(formGroup.get('addressType')?.value === 'WORK' ||formGroup.get('addressType')?.value === 'WORK2' )">
            <input class="form-control" placeholder="Organization" type="text" formControlName="organizationName" />
            <div class="required-text" *ngIf="!IsOrganizationValid()">
                Organization is required
            </div>
        </div>


        <div class="form-group"
            *ngIf="(formGroup.get('addressType')?.value === 'WORK' ||formGroup.get('addressType')?.value === 'WORK2')">
            <input class="form-control" placeholder="Job Title" type="text" formControlName="jobTitle" />

            <div class="required-text" *ngIf="!IsJobTitleValid()">
                Job Title is required
            </div>
        </div>


        <div class="form-group" *ngFor="let addressField of addressStructure">
            <input *ngIf="(addressField.FieldName !== 'State' || states.length === 0)" class="form-control"
                [ngClass]="{ 'width--50': addressField.FieldName === 'PostalCode' }"
                placeholder="{{ addressField.Caption }}" type="text"
                formControlName="{{ addressField.FieldName | lowercase }}" required="{{ addressField.RequiredFlag }}"
                maxlength="{{ addressField.MaxLength }}" />

            <div *ngIf="addressField.FieldName === 'State' && states.length !== 0">
                <div *ngIf="states.length !== 0" ngbDropdown #dropdown="ngbDropdown">
                    <mat-select formControlName="state" disableRipple disableOptionCentering panelClass="mat-panel">
                        <mat-option *ngFor="let state of states" [value]="state.PreferenceCode">
                            {{
                  state.PreferenceDescription
                            }}
                        </mat-option>
                    </mat-select>
                </div>
            </div>
            <div class="required-text" *ngIf="
              formGroup              
                .get(addressField.FieldName | lowercase)
                .hasError('required')
            ">
                {{ addressField.Caption }} is required
            </div>
            <div class="required-text" *ngIf="
                addressField.FieldName === 'PostalCode'&& 
                formGroup
                .get('postalcode')
                .hasError('pattern')
            ">
                Please enter a valid postal code
            </div>
        </div>

        <div class="form-check" (click)="flipIncludeInDirectoryFlagForAddress()">
            <input type="checkbox" id="addressIncludeInDirectoryFlag" formControlName="addressIncludeInDirectoryFlag" />
            <label [attr.for]="addressIncludeInDirectoryFlag">Include in directory</label>
            <span></span>
        </div>
    </div>

    <div *ngIf="!(editMode && !fieldIsDisabled) && !isBlankAdditionalItem()">
        <div class="form-group form-group--address" *ngIf="address.Address1">
            <div class="italic">
                {{ address.AddressTypeCode | aaosPreferenceValue: addressTypesOptions:'other' }}:
            </div>
            <p *ngIf="address.CompanyName && (address.AddressTypeCode === 'WORK' || address.AddressTypeCode === 'WORK2')">
                {{ address.CompanyName }}
            </p>
            <p *ngIf="address.JobTitle && (address.AddressTypeCode === 'WORK' || address.AddressTypeCode === 'WORK2')">
                {{ address.JobTitle }}
            </p>
            <p>{{ address.Address1 }}</p>
            <p>{{ address.Address2 }}</p>
            <p *ngIf="address.CountryCode !== 'USA'">{{ address.Address3 }}</p>
            <p>
                {{ address.City }}, {{ address.State }}
                {{ address.PostalCode }}
            </p>
            <p>{{ address.Country }}</p>
            <div *ngIf="address.IncludeInDirectoryFlag">
                <div class="check-icon text-gap"></div>
                Included in directory 
            </div>
            <div class="required-text" *ngIf="address.StatusCode !== 'GOOD'">
                Your Primary Mailing Address is undeliverable.
                 <br>Please update. 
            </div>
        </div>
        <div *ngIf="!address.Address1" class="blank-text">
            (Blank) <br />
            <span class="required-text">
                Mailing Address is required</span>
        </div>
    </div>
</div>