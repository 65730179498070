import { Injectable, Inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  Router,
  CanActivateChild
} from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { Utilities } from '@shared/services/utils';
import { Constants } from '@shared/services/constants';
import { APP_CONFIG } from '@config/main';
import { filter, take, map } from 'rxjs/operators';
import { of, combineLatest } from 'rxjs';
import { EmailPreferencesService } from '@shared/services/api';
import { ApplicationFlowParameterCacheService } from '@shared/services/application-flow/application-flow-parameter-cache.service';

@Injectable()
export class LoggedInGuard implements CanActivate, CanActivateChild {
  constructor(
    @Inject(DOCUMENT) private document: any,
    private router: Router,
    private emailService: EmailPreferencesService,
    private flowCache: ApplicationFlowParameterCacheService
  ) { }

  canActivateChild(r: ActivatedRouteSnapshot, s: RouterStateSnapshot) {
    return this.canActivate(r, s);
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let loggedIn = false;
    const cookies = this._getCookies();
    if (Utilities.isDefined(cookies)) {
      const found = cookies.find(
        _ =>
          _.toLowerCase()
            .trim()
            .split('=')[0] === 'mcidweb'
      );
      loggedIn = Utilities.isDefined(found);
    }

    this.flowCache.setFromQueryString(route);

    if (!loggedIn && !route.data.ignoreRedirect) {
      const pathParam = Constants.ApiParams.RedirectPath;
      let pathName = window.location.pathname;
      if (state != null && state.url != null) {
        pathName = state.url;
      }

      APP_CONFIG.subscribe(config => {
        const queryString = `${pathParam}=` + encodeURIComponent(`${pathName}`);
        const path = `${config.authRedirectUrl}?${queryString}`;
        window.location.href = path;
      });
    }

    return of(loggedIn);
  }

  private _getCookies() {
    return this.document.cookie && this.document.cookie !== Utilities.stringEmpty
      ? document.cookie.split(';')
      : null;
  }
}
