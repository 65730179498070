import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LogLevel } from '@shared/models/enums';
import { EnvironmentTypes } from '@shared/models/enums';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Utilities } from '@shared/services/utils';

export class AppConfig {
  readonly apiEndpoint: string;
  readonly environmentType: EnvironmentTypes;
  readonly baseUrl: string;
  readonly logLevel: LogLevel;
  readonly apiKey: string;
  readonly dataServiceUrl: string;
  readonly ampApiKey: string;
  readonly authRedirectUrl: string;
  readonly maapOrigin: string;
}

const _APP_CONFIG: BehaviorSubject<AppConfig> = new BehaviorSubject<AppConfig>(null);

export let APP_CONFIG: Observable<AppConfig> = _APP_CONFIG.pipe(
  filter(_ => Utilities.isDefined(_))
);

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
  constructor(private http: HttpClient) {}

  load() {
    return this.http.get('/assets/config/config.json').subscribe((envResponse: any) => {
      const config = new AppConfig();
      const descoped: any = envResponse.ng;
      descoped.environmentType = EnvironmentTypes[descoped.environmentType];
      descoped.logLevel = LogLevel[descoped.logLevel];
      _APP_CONFIG.next(Object.assign(config, descoped));
      return true;
    });
  }
}
