<div class="profile-section">
  <div class="form-group">
    <div class="title title--lg flex-align-center"
      *ngIf="group && group.name && group.name !== 'Photo' && (!allFieldsHiddenInReadonly || editMode)">
      {{group?.name}}
      <a *ngIf="group && group.toolTipText && !showTooltipUnderneathHeader && (showToolTipOnLandingPage(group.name) || editMode)"
        class="btn btn--round btn--blue info-icon" (click)="openTooltipDialog()" tooltipClass="amp-tooltip"
        placement="top" [ngbTooltip]="group.toolTipText">i</a>
    </div>
    <div class="profile-text" *ngIf="group && group.toolTipText && showTooltipUnderneathHeader && (showToolTipOnLandingPage(group.name) || editMode)">
      {{ group.toolTipTextDetail }}
    </div>
    <div *ngFor="let field of group?.formFields">
      <div [ngSwitch]="field.fieldTypeName" *ngIf="field.ruleName !== 'Hidden'">
        <aaos-text-field *ngSwitchCase="'text-box'" [form]="form" [field]="field" [formOptions]="formOptions"
          [editMode]="editMode" [refreshEditState$]="refreshEditState$">
        </aaos-text-field>
        <aaos-master-customer-id-field *ngSwitchCase="'master-customer-id'" [form]="form" [field]="field"
          [formOptions]="formOptions" [user]="user">
        </aaos-master-customer-id-field>

        <aaos-date-field *ngSwitchCase="'date'" [form]="form" [field]="field" [formOptions]="formOptions"
          [editMode]="editMode" [refreshEditState$]="refreshEditState$">
        </aaos-date-field>

        <aaos-text-area-field *ngSwitchCase="'text-area'" [form]="form" [field]="field" [formOptions]="formOptions"
          [editMode]="editMode" [refreshEditState$]="refreshEditState$">
        </aaos-text-area-field>

        <aaos-drop-down *ngSwitchCase="'drop-down'" [form]="form" [field]="field" [formOptions]="formOptions"
          [editMode]="editMode" [refreshEditState$]="refreshEditState$" [user]="user">
        </aaos-drop-down>

        <aaos-radio-button *ngSwitchCase="'radio-button'" [form]="form" [field]="field" [formOptions]="formOptions"
          [editMode]="editMode" [refreshEditState$]="refreshEditState$">
        </aaos-radio-button>

        <aaos-email-list *ngSwitchCase="'email'" [form]="form.get(field.fieldId)" [field]="field" [user]="user"
          [formOptions]="formOptions" [primaryEmailChangedActionService]="primaryEmailChangedActionService"
          [editMode]="editMode" [refreshEditState$]="refreshEditState$" [initialFocusField]="initialFocusField">
        </aaos-email-list>

        <aaos-phone-list *ngSwitchCase="'phone'" [form]="form.get(field.fieldId)" [field]="field"
          [formOptions]="formOptions" [user]="user" [formGroup]="form" [editMode]="editMode"
          [refreshEditState$]="refreshEditState$">
        </aaos-phone-list>

        <aaos-cell-phone-field *ngSwitchCase="'mobile'" [form]="form.get(field.fieldId)" [field]="field"
          [formOptions]="formOptions" [user]="user" [formGroup]="form" [editMode]="editMode"
          [refreshEditState$]="refreshEditState$" [covidOnly]="true">
        </aaos-cell-phone-field>

        <aaos-web-media-list *ngSwitchCase="'web-social'" [form]="form.get(field.fieldId)" [field]="field"
          [formOptions]="formOptions" [editMode]="editMode" [refreshEditState$]="refreshEditState$" [user]="user">
        </aaos-web-media-list>

        <aaos-check-box *ngSwitchCase="'check-box'" [form]="form" [field]="field" [formOptions]="formOptions"
          [editMode]="editMode" [refreshEditState$]="refreshEditState$" [user]="user">
        </aaos-check-box>

        <aaos-address-list *ngSwitchCase="'address'" [form]="form.get(field.fieldId)" [field]="field"
          [formOptions]="formOptions" [user]="user" [editMode]="editMode" [refreshEditState$]="refreshEditState$">
        </aaos-address-list>
      
        <aaos-image-uploader [maapApplication] = "maapApplication" *ngSwitchCase="'photo'" [user]="user" [saveSubject]="saveSubject"
          [saveButtonText]="submitButtonText" [parentForm]="form.parent" [editMode]="editMode">
        </aaos-image-uploader>

        <aaos-work-status-field *ngSwitchCase="'work-status'" [form]="form.get(field.fieldId)" [field]="field"
          [formOptions]="formOptions" [editMode]="editMode" [refreshEditState$]="refreshEditState$"
          [AMPMemberGroupId]="AMPMemberGroupId">
        </aaos-work-status-field>

        <aaos-emergency-list *ngSwitchCase="'emergency-contact'" [form]="form.get(field.fieldId)" [field]="field"
          [formOptions]="formOptions" [editMode]="editMode" [refreshEditState$]="refreshEditState$" [user]="user">
        </aaos-emergency-list>

        <aaos-biography *ngSwitchCase="'biography'" [form]="form.get(field.fieldId)" [field]="field"  [enableDebug]="enableDebug"
          [formOptions]="formOptions" [biographyAttachmentsSubject]="biographyAttachmentsSubject" [user]="user"
          [editMode]="editMode" [refreshEditState$]="refreshEditState$">
        </aaos-biography>

        <div *ngSwitchDefault>Field Type not implemented for {{field.fieldName}} - type: {{field.fieldTypeName}}</div>
      </div>
      <div class="clearFloat"></div>
    </div>
  </div>
</div>