<div class="form-group" *ngIf="form" [formGroup]="form">
    <div *ngIf="user.AddressDetail.length === 0">
        <aaos-form-field-title [editMode]="editMode" [field]="field"></aaos-form-field-title>
      </div>
    <div *ngIf="user">
    <div *ngFor="let address of user.AddressDetail; let i = index">
      <aaos-address-field [field]="field" [formOptions]="formOptions" [form]="form" [editMode]="editMode" [listIndex]="i"
      [selectedAddressTypes]="selectedAddressTypes" [addressTypeSubject]="addressTypeSubject"
        [showDeleteIcon]="showDeleteIcon" [refreshEditState$]="refreshEditState$" [user]="user" [address]="address">
      </aaos-address-field>
    </div>
  </div>
  <div *ngIf="(editMode) && showAddAddressButton === true">
    <button (click)="addNewAddress()" class="btn btn--outline" [ngClass] ="{'btn--disabled': !form.valid}">Add Address</button>
  </div>
</div>