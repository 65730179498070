<footer class="footer">
  <div class="container flex-row flex-align-center footer-container">
    <div class="col-6 col-sm-6 col-md-3  col-lg-2 col-xl-2">
      <img src="/assets/images/aaos-two-line-logo.svg" class="logo--footer" alt="White AAOS Logo" />
    </div>
    <div class="copyrights col-12 col-sm-12 col-md-9 col-lg-10 col-xl-10">
      &copy; 1995-2021 by the American Academy of Orthopaedic Surgeons. “All Rights Reserved.” This
      website and its contents may not be reproduced in whole or in part without written permission.
      “American Academy of Orthopaedic Surgeons” and its associated seal and “American Association
      of Orthopaedic Surgeons” and its logo are all registered U.S. trademarks and may not be used
      without written permission.
    </div>
  </div>
</footer>