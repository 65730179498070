<div class="form-group phone-spacing" *ngIf="form && show" [formGroup]="form">
    <aaos-form-field-title [editMode]="editMode" [field]="field"></aaos-form-field-title>

    <div class="form-group">
        <div class="form-group">
            <div *ngIf=" (editMode && !fieldIsDisabled)">
                <!--<div class="profile-text" *ngIf="this.covidOnly">
                    <p>
                        A cell phone number is required for Annual Meeting, live courses, and other live events for COVID-19 contact tracing. 
                        If you have any questions, please contact <a href="mailto:meeting@aaos.org">meeting@aaos.org</a>.
                    </p>
                </div>-->
                <div class="form-group">
                    <aaos-searchable-select [formControlName]="formControlName" [formGroup]="form"
                        [options]="countriesOptions" (selectionChange)="countryCodeChanged($event)">
                    </aaos-searchable-select>
                    <div class="required-text" *ngIf="showRequiredErrorForFieldName('countryCode')">
                        Country Code is required
                    </div>
                    <div class="required-text" *ngIf="showValidationError('countryCode')">
                        Please enter a valid country code
                    </div>
                </div>
                <div class="form-group">
                    <input class="form-control phone-country-code-width" *ngIf="!isAreaCodeAvailable"
                        [mask]="maskForCountryCode" [value]="phoneCountryCode" readonly="true" />
                    <input id="{{MobileId}}" class="form-control phone-number-width" placeholder="Phone Number"
                        formControlName="phoneNumber" (input)="callAll($event.target)" [mask]="maskForPhoneNumber"
                        [ngClass]="{
                            'phone-number-with-country-code-width': !isAreaCodeAvailable,
                            '.width--full': isAreaCodeAvailable
                        }" type="text" />
                    <div class="required-text" *ngIf="showRequiredErrorForFieldName('phoneNumber')">
                        Phone is required
                    </div>
                    <div class="required-text" *ngIf="showValidationError('phoneNumber')">
                        Please enter a valid Phone
                    </div>
                </div>
                <div class="form-check" (click)="flipChecked()">
                    <input type="checkbox" id="mobileOptin" [checked]="mobileOptin" formControlName="mobileOptinFlag" />
                    <label [attr.for]="mobileOptin">Allow Communication</label>
                    <span></span>
                </div>
                <div>
                    <p>
                        *By selecting this box, you allow the American Academy of Orthopaedic Surgeons (AAOS) to contact
                        you (via voicemail, text message, or call) with relevant information regarding your Membership,
                        products and events. This agreement is not a condition of any purchase, and is subject to the
                        AAOS <a href="http://www.aaos.org/privacy">Privacy Policy</a> and <a
                            href="https://www.aaos.org/about/bylaws-policies/organizational-policies/website-disclaimer/terms-of-use/">Terms
                            of Use</a>. Standard fees and rates may apply for text messages and
                        calls. You may opt out at any time by deselecting this box, by contacting <a
                            href="https://www.aaos.org/about/contact-the-aaos/customer-and-member-services-team/">AAOS
                            Customer Service</a>
                        at 800-626-6726, or replying STOP to text message communications.
                    </p>
                </div>
            </div>
            <div *ngIf="!(editMode && !fieldIsDisabled)">
                <div class="form-group--address">
                    <div *ngIf="readonlyPhoneNumber && isCountryCodeUsaOrCan">
                        {{ readonlyPhoneNumber | mask: '(000) 000-0000 SSS 00000' }}
                    </div>
                    <div *ngIf="readonlyPhoneNumber && !isCountryCodeUsaOrCan">
                        {{ readonlyPhoneNumber }}
                    </div>
                </div>
                <div *ngIf="mobileOptin">
                    <div class="check-icon text-gap"></div>
                    Allow Communication
                </div>
                <div class="required-text" *ngIf="showRequiredErrorForFieldName('phoneNumber')">
                    Phone is required
                </div>
                <div class="required-text" *ngIf="showValidationError('phoneNumber')">
                    Please enter a valid Phone
                </div>
            </div>
        </div>
    </div>
</div>