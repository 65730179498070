import { Injectable } from '@angular/core';
import { Constants } from '@shared/services/constants';

export type MemberTypeCode = 'member' | 'nonmember';

export interface MemberType {
  code: MemberTypeCode;
  description: string;
}

@Injectable({ providedIn: 'root' })
export class EmailPreferencesSearchLogicService {
  public readonly MEMBER_TYPES: MemberType[] = [
    { code: 'member', description: Constants.CustomerMemberGroup.Member },
    { code: 'nonmember', description: Constants.CustomerMemberGroup.NonMember }
  ];
}
