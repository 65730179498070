import { Component, OnInit, Input } from '@angular/core';
import { BaseFieldComponent } from '@shared/form-fields/baseFieldComponent';
import { FormField, FormFieldRuleConstants, FormFieldConstants } from '@shared/form-fields/models/form-field';
import { Preference } from '@shared/models/data';
import { Constants } from '@shared/services/constants';
import { FieldOptionsMapperService } from '@shared/services/membershipForm/field-options-mapper.service';
import { EmergencyListComponent } from '@shared/form-fields/emergency-list/emergency-list.component';

@Component({
  selector: 'aaos-emergency-contact-field',
  templateUrl: './emergency-contact-field.component.html',
  styleUrls: ['./emergency-contact-field.component.scss']
})
export class EmergencyContactFieldComponent extends BaseFieldComponent implements OnInit {
  contactNameField: FormField = new FormField();
  relationshipField: FormField = new FormField();
  phoneNumberField: FormField = new FormField();
  phoneTypeField: FormField = new FormField();
  relationshipCodes: Preference[] = [];
  commentsField: FormField = new FormField();
  EmergencyFieldId: string;
  readOnlyPhoneType: string;
  @Input()
  showDeleteIcon: boolean;
  @Input()
  listIndex: number;
  phoneTypeOptions: Preference[] = [
    { PreferenceCode: 'HOME', PreferenceDescription: 'Home', PreferenceSubCode: '', Active: null, SortBy: 0 },
    { PreferenceCode: 'WORK', PreferenceDescription: 'Work', PreferenceSubCode: '', Active: null, SortBy: 0 },
    { PreferenceCode: 'ASST', PreferenceDescription: 'Assistant', PreferenceSubCode: '', Active: null, SortBy: 0 },
    { PreferenceCode: 'CELL', PreferenceDescription: 'Cell', PreferenceSubCode: '', Active: null, SortBy: 0 }];

  constructor(
    private fieldOptionsMapperService: FieldOptionsMapperService,
    private emergencyListComponent: EmergencyListComponent) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.relationshipCodes = this.fieldOptionsMapperService.getOptionsForEmergencyContacts(this.formOptions);
    this.contactNameField = this.createFormFieldForSubField(Constants.FormSubFieldNameConstants.contactName);
    this.contactNameField = this.assignOptionalEditableForSubField(this.contactNameField, Constants.FieldName.ContactName);
    this.relationshipField = this.createFormFieldForSubField(Constants.FormSubFieldNameConstants.relationship);
    this.relationshipField = this.assignOptionalEditableForSubField(this.relationshipField, Constants.FieldName.Relationship);
    this.phoneNumberField = this.createFormFieldForSubField(Constants.FormSubFieldNameConstants.phoneNumber);
    this.phoneNumberField = this.assignOptionalEditableForSubField(this.phoneNumberField, Constants.FieldName.PhoneNumber);
    this.commentsField = this.createFormFieldForSubField(Constants.FormSubFieldNameConstants.comments);
    this.commentsField = this.assignOptionalEditableForSubFieldWithOptionalText(this.commentsField, Constants.FieldName.Comments);
    this.phoneTypeField = this.createFormFieldForSubField(Constants.FormSubFieldNameConstants.phoneType);
    this.phoneTypeField = this.assignOptionalEditableForSubFieldWithOptionalText(this.phoneTypeField, Constants.FieldName.PhoneType);
    if (this.form != null) {
      this.EmergencyFieldId = this.form.get(Constants.AdditionalFieldConstants.id).value;
      const phoneType =
        this.form.get(`${FormFieldConstants.EmergencyContactInformationId}-${Constants.FormSubFieldNameConstants.emergencyContactId}`);
      if (phoneType) {
        const phoneTypePreference = this.phoneTypeOptions.find(x => x.PreferenceCode === phoneType.value);
        if (phoneTypePreference) {
          this.readOnlyPhoneType = phoneTypePreference.PreferenceDescription;
        }
      }
    }
  }
  deleteEmergencyContact(id: string) {
    this.emergencyListComponent.deleteEmergencyContact(id);
  }
  assignRequiredEditableRuleForSubField(formField: FormField, fieldName: string): FormField {
    formField.ruleId = FormFieldRuleConstants.RequiredEditableId;
    formField.ruleName = FormFieldRuleConstants.RequiredEditableIdName;
    formField.fieldName = fieldName;
    return formField;
  }

  assignOptionalEditableForSubField(formField: FormField, fieldName: string): FormField {
    formField.ruleId = FormFieldRuleConstants.OptionalEditableId;
    formField.ruleName = FormFieldRuleConstants.OptionalEditableName;
    formField.fieldName = fieldName;
    return formField;
  }

  assignOptionalEditableForSubFieldWithOptionalText(formField: FormField, fieldName: string): FormField {
    formField.ruleId = FormFieldRuleConstants.OptionalEditableId.toLowerCase();
    formField.ruleName = FormFieldRuleConstants.OptionalEditableName;
    formField.fieldName = fieldName;
    return formField;
  }

  isBlankAdditionalItem() {
    return !this.editMode && this.contactNameField.value === '' && this.listIndex > 0;
  }
}
