﻿import * as moment from 'moment';

export class BirthdayService {

  public static normalizeTimezone(birthDate: Date): Date {
    // get offset of timezone to cancel out
    const timezoneOffset = new Date().getTimezoneOffset();

    // split the incoming db string
    const splitOnT = birthDate.toString().split('T');
    if (splitOnT !== null && splitOnT.length === 2) {
      // create a date object from just the Date component
      const d = new Date(splitOnT[0].replace(/-/g, '\/'));

      // parse into moment date
      const dobRetrievedUtc = moment(d, 'YYYY-MM-DD').clone().utc();

      // nullify the timezone to move the day back in line
      let dobRetrievedShifted = dobRetrievedUtc.clone().add(timezoneOffset, 'minutes').toDate();
      const dobRetrievedShiftedString = dobRetrievedShifted.toString();

      // dobRetrievedShifted should be the accurate day with hours zeroed out and timezone at this point
      // this is a special check for an anomaly with the Auckland timezone reporting as +12 but offset of 780
      // if the hour was overshot or undershot, move over
      const regEx1 = new RegExp(/(23:00:00)/);
      const regExOff1 = regEx1.test(dobRetrievedShiftedString);
      const regEx2 = new RegExp(/(01:00:00)/);
      const regExOff2 = regEx2.test(dobRetrievedShiftedString);
      if (regExOff1) {
        dobRetrievedShifted = dobRetrievedUtc.clone().add(timezoneOffset + 60, 'minutes').toDate();
      } else if (regExOff2) {
        dobRetrievedShifted = dobRetrievedUtc.clone().add(timezoneOffset - 60, 'minutes').toDate();
      }

      return dobRetrievedShifted;
    }

    return moment(birthDate).toDate();
  }

  public static getYear(birthdayString: string): string {
    if (birthdayString === null || birthdayString === undefined || birthdayString === '') {
      return '';
    }
    const regEx = new RegExp(/((0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/([12]\d{3}))/);
    const regExDate = regEx.test(birthdayString);
    if (regExDate) {
      const split = birthdayString.toString().split('/');
      if (split !== null && split.length === 3) {
        return split[2];
      }
    }

    return moment(birthdayString).toDate().getFullYear().toString();
  }

  public static validate(controlValue: any): any {
    const birthdate = moment(controlValue, 'MM/DD/YYYY');
    const maxBirthdate = moment().subtract(100, 'years');
    const minBirthdate = moment().subtract(20, 'years');
    if (!birthdate.isValid() || birthdate < maxBirthdate || birthdate > minBirthdate) {
      return { invalidBirthdate: true };
    }
    return null;
  }

  public static isValid(birthdate: Date): boolean {
    const _birthdate = moment(birthdate);
    const maxBirthdate = moment().subtract(100, 'years');
    const minBirthdate = moment().subtract(20, 'years');
    if (_birthdate < maxBirthdate || _birthdate > minBirthdate || !_birthdate.isValid()) {
      return false;
    }
    return true;
  }

  public static convertToBirthdaySnapshot(birthdate: Date, dateOfBirthControlValue: string): Date {
    if (dateOfBirthControlValue && BirthdayService.isValid(moment(dateOfBirthControlValue, 'MMDDYYYY').toDate())) {
      return moment(dateOfBirthControlValue, 'MMDDYYYY').toDate();
    }
    return moment(birthdate).toDate();
  }

  public static convertNullBirthday(birthdate: Date): Date {
    // Convert 01/01/0001 birthdays to null
    if (birthdate) {
      const nullBirthdate = moment('01/01/0001', 'MM/DD/YYYY');
      const invalidBirthdate = moment(birthdate, 'MM/DD/YYYY').isSameOrBefore(
        nullBirthdate,
        'day'
      );
      return invalidBirthdate ? null : birthdate;
    }
    return birthdate;
  }

  public static formatToControlString(birthdate: Date): string {
    const result = moment(birthdate).format('MM/DD/YYYY');
    return result;
  }
}

