import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Component, Output, EventEmitter, Inject } from '@angular/core';
import { FormConfirmType } from '@shared/models/enums';

@Component({
  selector: 'aaos-form-confirm-dialog',
  templateUrl: './form-confirm-dialog.component.html',
  styleUrls: ['./form-confirm-dialog.component.scss']
})
export class FormConfirmgDialogComponent {
  readonly typeSave: FormConfirmType = FormConfirmType.Save;
  readonly typeDontSave: FormConfirmType = FormConfirmType.DontSave;
  readonly typeCancel: FormConfirmType = FormConfirmType.Cancel;
  loading: boolean;
  public showPrimaryEmailChangedMessage: boolean;

  @Output()
  actionTaken: EventEmitter<FormConfirmType> = new EventEmitter<FormConfirmType>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Array<string>,
    public dialogRef: MatDialogRef<FormConfirmgDialogComponent>) { }

  save() {
    this.actionTaken.next(this.typeSave);
  }

  dontSave() {
    this.actionTaken.next(this.typeDontSave);
  }

  cancel() {
    this.actionTaken.next(this.typeCancel);
  }

  beginSpinner() {
    this.loading = true;
  }
}
