import { Customer, Preference } from '@shared/models/data';
import { FieldOptionsMapperService } from '@shared/services/membershipForm/field-options-mapper.service';
import { BaseFieldComponent } from './baseFieldComponent';
import { OnInit, Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
@Injectable()
export class BaseMultipleOptionsFieldComponent extends BaseFieldComponent implements OnInit {
    options: Preference[] = [];
    readOnlyInitialValues: Array<string>;
    refreshStateSubscription: Subscription;
    user: Customer;

    public static mapFromMultiSelectToCsv(value: Array<string>): string {
        return BaseMultipleOptionsFieldComponent.mapFromMultiSelectToList(value).join(',');
    }
    public static mapFromMultiSelectToList(value: Array<string>): string[] {
        if (!value) {
            return [];
        }
        const rawStrings = (value);
        const preferenceCodes = new Array<string>();
        rawStrings.forEach(x => {
            const substrings = x.split('}}');
            const preferenceCode = substrings[1].replace('{{', '');
            preferenceCodes.push(preferenceCode);
        });
        return preferenceCodes;
    }

    constructor(public formFieldsOptionsMapper: FieldOptionsMapperService) { super(); }
    ngOnInit() {
        super.ngOnInit();
        if (this.refreshEditState$) {
            this.refreshStateSubscription = this.refreshEditState$.subscribe(() => { this.updateReadonlyValue(); });
        }
        this.options = this.field ? this.formFieldsOptionsMapper.getOptionsForField(this.field.fieldId, this.formOptions, this.user) : null;
        this.readOnlyInitialValues = [];
        this.updateReadonlyValue();
    }

    setUser(user: Customer) {
        this.user = user;
    }

    updateReadonlyValue() {
        this.readOnlyInitialValues = [];
        const formField = this.formGroupField;
        if (!formField || !this.options) {
            return;
        }
        if (formField.value instanceof Array) {
            formField.value.forEach(x => {
                const substrings = x.split('}}');
                const preferenceCode = substrings[1].replace('{{', '');
                let preferenceSubCode = substrings[2].replace('{{', '');
                if (preferenceSubCode === 'null') {
                    preferenceSubCode = null;
                }

                const selectedPreference = this.options.find(_ => _.PreferenceCode === preferenceCode
                    && _.PreferenceSubCode === preferenceSubCode
                );
                if (selectedPreference) {
                    this.readOnlyInitialValues.push(selectedPreference.PreferenceDescription);
                }
            });
        } else {
            const value = formField.value;
            const selectedPreference = this.options.find(x => x.PreferenceCode === value);
            if (selectedPreference) {
                this.readOnlyInitialValues.push(selectedPreference.PreferenceDescription);
            }
        }
    }
}
