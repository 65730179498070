import { Component, OnInit, Input } from '@angular/core';
import { BaseFieldComponent } from '@shared/form-fields/baseFieldComponent';
import { Customer, AddressDetail } from '@shared/models/data';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { FormFieldValidatorsService } from '@shared/form-fields/form-field-validators.service';
import { Constants } from '@shared/services/constants';
import { FormFieldRuleConstants } from '@shared/form-fields/models/form-field';
import { Subject } from 'rxjs';


export abstract class BaseFieldListComponent extends BaseFieldComponent implements OnInit {
    @Input()
    user: Customer;
    @Input()
    editMode: boolean;
    formGroups: FormGroup[] = [];
    showAddButton: boolean;
    webSiteNameSubject: Subject<string[]> = new Subject<string[]>();
    phoneTypeSubject: Subject<string[]> = new Subject<string[]>();
    emailTypeSubject: Subject<string[]> = new Subject<string[]>();
    addressTypeSubject: Subject<string[]> = new Subject<string[]>();
    selectedSiteNames: string[] = [];
    constructor(public formFieldValidatorsService: FormFieldValidatorsService) {
        super();
    }

    ngOnInit() {
        super.ngOnInit();
        const formGroup = this.form as FormGroup;
        if (formGroup != null) {
            for (const key of Object.keys(formGroup.controls)) {
                const formControl = formGroup.get(key) as FormGroup;
                this.formGroups.push(formControl);
            }
            this.setShowAddButton();
        }
    }

    addNewAddress() {
        const addressDetail = new AddressDetail();
        addressDetail.CountryCode = Constants.CountryConstants.USA;
        addressDetail.AddressId = this.user.AddressDetail.length + 1;
        addressDetail.IsPrimaryAddress = false;
        const formGroup = this.form as FormGroup;
        const addressControl = new FormGroup({});
        formGroup.addControl(addressDetail.AddressId.toString(), addressControl);
        this.user.AddressDetail.push(addressDetail);
        this.setShowAddButton();

    }
    delete(id: string) {
        const formGroup = this.form as FormGroup;
        const deletedFromGroup = formGroup.get(id) as FormGroup;
        const deletedFlagControl = deletedFromGroup.get(Constants.AdditionalFieldConstants.deletedflag);
        const newFlagControl = deletedFromGroup.get(Constants.AdditionalFieldConstants.newflag);
        deletedFlagControl.setValue(true);
        if (newFlagControl.value === true) {
            formGroup.removeControl(id);
        } else {
            // remove validators for delete form which should no
            // longer be validated because it is only being used to track the deleted address
            Object.keys(deletedFromGroup.controls).forEach(_ => {
                const control = deletedFromGroup.controls[_];
                control.setValidators([]);
                control.updateValueAndValidity();
            });
        }
        const formToUpdateValidity = deletedFromGroup as AbstractControl;
        formToUpdateValidity.updateValueAndValidity();
        this.formGroups = [];
        for (const key of Object.keys(formGroup.controls)) {
            const formControl = formGroup.get(key) as FormGroup;
            const deletedFormControl = formControl.get(Constants.AdditionalFieldConstants.deletedflag);
            const deletedValue = deletedFormControl.value;
            if (key !== id && deletedValue === false) {
                this.formGroups.push(formControl);
            }
        }
        this.setShowAddButton();
    }
    hasFormGroups() {
        return this.formGroups.length !== 0;
    }
    setShowAddButton() {
        this.showAddButton = this.formGroups !== null && this.formGroups.length < 3;
    }
}
