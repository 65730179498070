import { Component, OnInit, Input } from '@angular/core';
import { BaseFieldComponent } from '@shared/form-fields/baseFieldComponent';
import { AddressField, Preference, Customer, AddressDetail } from '@shared/models/data';
import { UserProfileService } from '@shared/services/api';
import { tap, takeUntil } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';
import { FormGroup, FormControl, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { FieldOptionsMapperService } from '@shared/services/membershipForm/field-options-mapper.service';
import { AddressOptions } from '@shared/services/membershipForm/AddressOptions';
import { Constants } from '@shared/services/constants';
import { stringify } from '@angular/compiler/src/util';
import { FormFieldConstants, FormFieldRuleConstants } from '@shared/form-fields/models/form-field';
import { FormFieldValidatorsService } from '@shared/form-fields/form-field-validators.service';
import { AddressListComponent } from '@shared/form-fields/address-list/address-list.component';
import { Utilities } from '@shared/services/utils';



@Component({
  selector: 'aaos-address-field',
  templateUrl: './address-field.component.html',
  styleUrls: ['./address-field.component.scss']
})
export class AddressFieldComponent extends BaseFieldComponent implements OnInit {
  _destroy$: Subject<any> = new Subject();
  addressOptions: AddressOptions;
  @Input()
  user: Customer;
  @Input()
  address: AddressDetail;
  @Input()
  showDeleteIcon: boolean;
  @Input()
  listIndex: number;
  @Input()
  selectedAddressTypes: string[] = [];
  @Input()
  addressTypeSubject: Subject<string[]>;
  previousForm: AbstractControl;
  constructor(
    private profileService: UserProfileService,
    private fieldOptionsMapperService: FieldOptionsMapperService,
    private addressListComponent: AddressListComponent) {
    super();
  }
  selectedCountryCode: string;
  states: Preference[] = [];
  addressStructure: AddressField[] = [];
  addressTypesOptions: Preference[] = [];

  countriesOptions: Preference[] = [];
  formModified: boolean;
  formGroup: FormGroup;
  AddressId: string;
  AddressHeader: string;
  AddressFieldName: string;
  formControlName: string;
  ngOnInit() {
    super.ngOnInit();

    this.formGroup = this.form.get(this.address.AddressId.toString()) as FormGroup;
    this.replaceForm(this.getBaseForm());
    this.formModified = false;
    this.addressOptions = this.fieldOptionsMapperService.getOptionsForAddressField(this.formOptions);
    this.countriesOptions = this.addressOptions.applicationCountries;
    this.resetAddressStructure(this.address.CountryCode);
    this.AddressId = this.address.AddressId.toString();
    this.setAddressFieldName();
    this.buildAddressTypeOptions(this.selectedAddressTypes);
    this.formControlName = Constants.FormSubFieldNameConstants.countryCode.toString();
    this.addressTypeSubject.subscribe(_ => { this.buildAddressTypeOptions(_); });
  }

  getStates(countryCode: string): Observable<Preference[]> {
    return this.profileService.getStates(countryCode);
  }

  private replaceForm(formGroup: FormGroup) {
    const addressId = this.address.AddressId.toString();
    const parent = (this.form as FormGroup);
    parent.removeControl(addressId);
    this.previousForm = this.formGroup;
    this.formGroup = formGroup;
    this.formGroup.valueChanges.subscribe(x => this.formModified = true);
    parent.addControl(addressId, formGroup);
  }

  private getFieldState() {
    return {
      disabled: this.field.ruleId.toLowerCase() === FormFieldRuleConstants.DisplayOnlyId.toLowerCase(),
      value: null
    };
  }

  getCountryCodeState() {
    return {
      disabled: this.field.ruleId.toLowerCase() === FormFieldRuleConstants.DisplayOnlyId.toLowerCase(),
      value: this.selectedCountryCode
    };
  }
  getBaseForm(): FormGroup {
    const validator = [];
    const formGroup = new FormGroup({});
    validator.push(Validators.required);
    validator.push(FormFieldValidatorsService.allowedValuesPreferencesValidator
      (this.fieldOptionsMapperService.getAddressTypePreferences()));
    const addressTypeField = new FormControl(this.getFieldState(), { validators: validator });
    addressTypeField.valueChanges.subscribe(_ => {
      this.setWorkTypeFields(true);
    });
    formGroup.addControl(Constants.FormSubFieldNameConstants.addressType, addressTypeField);
    formGroup.addControl(Constants.FormSubFieldNameConstants.organization, new FormControl(this.getFieldState()));
    formGroup.addControl(Constants.FormSubFieldNameConstants.jobTitle, new FormControl(this.getFieldState()));
    formGroup.addControl(Constants.FormSubFieldNameConstants.countryCode, new FormControl(this.getCountryCodeState()));
    formGroup.addControl(Constants.FormSubFieldNameConstants.addressIncludeInDirectoryFlag, new FormControl(this.getFieldState()));

    formGroup.addControl(Constants.AdditionalFieldConstants.id, new FormControl());
    formGroup.get(Constants.AdditionalFieldConstants.id).setValue(this.address.AddressId);
    formGroup.addControl(Constants.AdditionalFieldConstants.primaryflag, new FormControl());
    formGroup.get(Constants.AdditionalFieldConstants.primaryflag).setValue(this.address.IsPrimaryAddress);
    formGroup.addControl(Constants.AdditionalFieldConstants.newflag, new FormControl());
    formGroup.get(Constants.AdditionalFieldConstants.newflag).setValue(this.user.AddressDetail.length === this.address.AddressId ?
      true : false);
    formGroup.addControl(Constants.AdditionalFieldConstants.deletedflag, new FormControl());
    formGroup.get(Constants.AdditionalFieldConstants.deletedflag).setValue(false);
    return formGroup;
  }

  setAddressStructure(addressFields: AddressField[], countryCode: string) {
    this.addressStructure = addressFields;
    const formGroup = this.getBaseForm();
    addressFields.forEach(addressField => {
      const validators = [];
      if (this.field.ruleId.toLowerCase() === FormFieldRuleConstants.RequiredEditableId.toLowerCase() && addressField.RequiredFlag) {
        validators.push(Validators.required);
      }
      if (addressField.FieldName.toLowerCase() === Constants.FormSubFieldNameConstants.postalCode.toLowerCase()
        && this.selectedCountryCode === Constants.CountryConstants.USA) {
        validators.push(FormFieldValidatorsService.getZipCodeValidator(this.field.ruleId));
      } else if (addressField.FieldName.toLowerCase() === Constants.FormSubFieldNameConstants.state.toLowerCase()) {
        this.getStates(countryCode).subscribe(x => { this.states = x; });
      }

      const state = this.getFieldState();
      const control = new FormControl(state, { validators });

      formGroup.addControl(addressField.FieldName.toLocaleLowerCase(), control);
    });
    this.setWorkTypeFields();

    this.replaceForm(formGroup);
  }
  setWorkTypeFields(revalidateFields: boolean = false) {
    const addressIsWorkType = (this.formGroup.get(Constants.FormSubFieldNameConstants.addressType).value === 'WORK'
      || this.formGroup.get(Constants.FormSubFieldNameConstants.addressType).value === 'WORK2');

    const jobTitleField = this.formGroup.get(Constants.FormSubFieldNameConstants.jobTitle);
    jobTitleField.clearValidators();

    const organizationNameField = this.formGroup.get(Constants.FormSubFieldNameConstants.organization);
    organizationNameField.clearValidators();

    const validator = [];
    if (addressIsWorkType && this.field.ruleId.toLowerCase() === FormFieldRuleConstants.RequiredEditableId.toLowerCase()) {
      validator.push(Validators.required);
      jobTitleField.setValidators(validator);
      organizationNameField.setValidators(validator);
    }
    if (revalidateFields) {
      jobTitleField.updateValueAndValidity();
      organizationNameField.updateValueAndValidity();
    }
  }
  populateAddressFields(countryCode: string) {

    const countryControl = this.formGroup.get(Constants.FormSubFieldNameConstants.countryCode);
    const address1 = this.formGroup.get(Constants.FormSubFieldNameConstants.addressLine1);
    const address2 = this.formGroup.get(Constants.FormSubFieldNameConstants.addressLine2);
    const address3 = this.formGroup.get(Constants.FormSubFieldNameConstants.addressLine3);
    const city = this.formGroup.get(Constants.FormSubFieldNameConstants.city);
    const state = this.formGroup.get(Constants.FormSubFieldNameConstants.state);
    const postalCode = this.formGroup.get(Constants.FormSubFieldNameConstants.postalCode);
    const organization = this.formGroup.get(Constants.FormSubFieldNameConstants.organization);
    const jobtitle = this.formGroup.get(Constants.FormSubFieldNameConstants.jobTitle);
    const addressIncludeInDirectoryFlag = this.formGroup.get(Constants.FormSubFieldNameConstants.addressIncludeInDirectoryFlag);
    const addressType = this.formGroup.get(Constants.FormSubFieldNameConstants.addressType);

    let newAddress1Value: string;
    let newAddress2Value: string;
    let newAddress3Value: string;
    let newCityValue: string;
    let newStateValue: string;
    let newPostalCodeValue: string;
    let newAddressType: string;
    let newOrganizationValue: string;
    let newJobTitleValue: string;
    let newAddressIncludeInDirectoryFlagValue: boolean;

    if (this.formModified) {
      const oldAddress1 = this.previousForm.get(Constants.FormSubFieldNameConstants.addressLine1);
      if (oldAddress1) {
        newAddress1Value = oldAddress1.value;
      }
      const oldAddress2 = this.previousForm.get(Constants.FormSubFieldNameConstants.addressLine2);
      if (oldAddress2) {
        newAddress2Value = oldAddress2.value;
      }
      const oldAddress3 = this.previousForm.get(Constants.FormSubFieldNameConstants.addressLine3);
      if (oldAddress3) {
        newAddress1Value = oldAddress3.value;
      }
      const oldCity = this.previousForm.get(Constants.FormSubFieldNameConstants.city);
      if (oldCity) {
        newCityValue = oldCity.value;
      }
      const oldState = this.previousForm.get(Constants.FormSubFieldNameConstants.state);
      if (oldState) {
        newStateValue = oldState.value;
      }
      const oldPostalCode = this.previousForm.get(Constants.FormSubFieldNameConstants.postalCode);
      if (oldPostalCode) {
        newPostalCodeValue = oldPostalCode.value;
      }
      const oldAddressType = this.previousForm.get(Constants.FormSubFieldNameConstants.addressType);
      if (oldAddressType) {
        newAddressType = oldAddressType.value;
      }
      const oldOrganization = this.previousForm.get(Constants.FormSubFieldNameConstants.organization);
      if (oldOrganization) {
        newOrganizationValue = oldOrganization.value;
      }
      const oldjobtitle = this.previousForm.get(Constants.FormSubFieldNameConstants.jobTitle);
      if (oldjobtitle) {
        newJobTitleValue = oldjobtitle.value;
      }
      const oldAddressIncludeInDirectoryFlag = this.previousForm.get(Constants.FormSubFieldNameConstants.addressIncludeInDirectoryFlag);
      if (oldAddressIncludeInDirectoryFlag) {
        newAddressIncludeInDirectoryFlagValue = oldAddressIncludeInDirectoryFlag.value;
      }

      // tslint:disable-next-line: max-line-length
    } else if (this.address.StatusCode !== Constants.AddressStatusCodes.Bad) { // don't populate the existing address if it is in a "bad" state
      newAddress1Value = this.address.Address1;
      newAddress2Value = this.address.Address2;
      newAddress3Value = this.address.Address3;
      newCityValue = this.address.City;
      newStateValue = this.address.State;
      newPostalCodeValue = this.address.PostalCode;
      newAddressType = this.address.AddressTypeCode;
      newOrganizationValue = this.address.CompanyName;
      newJobTitleValue = this.address.JobTitle;
      newAddressIncludeInDirectoryFlagValue = this.address.IncludeInDirectoryFlag;
    }

    if (countryControl != null) {
      countryControl.setValue(countryCode);
    }
    if (address1 != null) {
      address1.setValue(newAddress1Value);
    }
    if (address2 != null) {
      address2.setValue(newAddress2Value);
    }
    if (address3 != null) {
      address3.setValue(newAddress3Value);
    }
    if (city != null) {
      city.setValue(newCityValue);
    }
    if (state != null) {
      state.setValue(newStateValue);
    }
    if (postalCode != null) {
      postalCode.setValue(newPostalCodeValue);
    }
    if (addressType != null) {
      addressType.setValue(newAddressType);
    }
    if (organization != null) {
      organization.setValue(newOrganizationValue);
    }
    if (jobtitle != null) {
      jobtitle.setValue(newJobTitleValue);
    }
    if (addressIncludeInDirectoryFlag != null) {
      addressIncludeInDirectoryFlag.setValue(newAddressIncludeInDirectoryFlagValue);
    }

  }
  flipIncludeInDirectoryFlagForAddress() {
    const addressIncludeInDirectoryFlagControl = this.formGroup.get(Constants.FormSubFieldNameConstants.addressIncludeInDirectoryFlag);
    if (addressIncludeInDirectoryFlagControl) {
      addressIncludeInDirectoryFlagControl.setValue(!(addressIncludeInDirectoryFlagControl.value as boolean));
      addressIncludeInDirectoryFlagControl.markAsDirty();
    }
  }
  showAddressTypeMessage() {
    return this.formGroup.get(Constants.FormSubFieldNameConstants.addressType).hasError('required');
  }
  IsOrganizationValid() {
    return this.formGroup.get(Constants.FormSubFieldNameConstants.organization).valid;
  }

  IsJobTitleValid() {
    return this.formGroup.get(Constants.FormSubFieldNameConstants.jobTitle).valid;
  }
  deleteAddress(id: string) {
    this.addressListComponent.deleteAddress(id);
  }
  isAddressPrimary() {
    return this.address.IsPrimaryAddress === true;
  }

  isBlankAdditionalItem() {
    return !this.editMode && !this.address.Address1 && this.listIndex > 0;
  }

  resetAddressStructure(countryCode: any) {
    this.selectedCountryCode = countryCode;
    const sub$ = this.profileService
      .getAddressStructure(countryCode)
      .pipe(
        tap(_ => {
          this.states = [];
          const fields = _.filter(
            field => field.LabelFlag === true && field.FieldName !== 'Country'
          ).sort((a, b) => a.LineNumber - b.LineNumber || a.Position - b.Position);
          this.setAddressStructure(fields, countryCode);
        }),
        takeUntil(this._destroy$)
      ).subscribe(_ => {
        this.populateAddressFields(countryCode);
        this.UpdateNpiFieldValidation();
      });
  }

  setAddressFieldName() {
    if (this.formGroup.get(Constants.AdditionalFieldConstants.primaryflag).value === false) {
      let addressType = '';
      let addressTypeText = '';
      addressType = this.formGroup.get(Constants.FormSubFieldNameConstants.addressType).value;
      if (addressType === Constants.Codes.Work2) {
        addressType = Constants.Codes.Work;
      }
      if (addressType === Constants.Codes.Home2) {
        addressType = Constants.Codes.Home;
      }
      if (this.address.AddressTypeCode === Constants.Codes.Work2) {
        addressTypeText = Constants.Codes.Work;
      }
      if (this.address.AddressTypeCode === Constants.Codes.Home2) {
        addressTypeText = Constants.Codes.Home;
      }

      if (this.address.AddressTypeCode !== undefined && this.address.AddressTypeCode !== '' && this.address.AddressTypeCode !== null) {
        this.AddressFieldName = (addressTypeText === Utilities.stringEmpty ?
          this.address.AddressTypeCode : addressTypeText) + ' ' + Constants.FieldName.Address;
      } else if ((this.address.AddressTypeCode === undefined || this.address.AddressTypeCode === null) && !addressType) {
        this.AddressFieldName = Constants.FieldName.Address;
      } else {
        this.AddressFieldName = addressType + ' ' + Constants.FieldName.Address;

      }
    } else {
      this.AddressFieldName = Utilities.stringEmpty;

    }
  }


  private UpdateNpiFieldValidation() {
    let topLevelForm = this.form;
    let stop = false;
    while (!stop) {
      if (topLevelForm.parent) {
        topLevelForm = topLevelForm.parent;
      } else {
        stop = true;
      }
    }
    try {
      const npiControl = ((topLevelForm as FormGroup).controls.Practice as FormGroup).get(FormFieldConstants.NPId.toLowerCase());
      npiControl.updateValueAndValidity();
    } catch (ex) {
      // do nothing catching this so if something goes wrong with this edge case the whole page isn't corrupted
    }
  }
  buildAddressTypeOptions(_: string[]) {
    const addressTypes = this.fieldOptionsMapperService.getAddressTypePreferences();
    const currentaddressType = this.formGroup.get(`${Constants.FormSubFieldNameConstants.addressType}`).value;
    _.forEach(selectedAddressType => {
      addressTypes.forEach(element => {
        if ((element.PreferenceCode === selectedAddressType) &&
          ((currentaddressType !== null && currentaddressType.toString() !== selectedAddressType) || currentaddressType == null)) {
          element.Active = false;
        }
      });
    });
    this.addressTypesOptions = addressTypes;
  }
  addressTypeChanged(addressType: string) {
    const addressTypeControl = this.formGroup.get(`${Constants.FormSubFieldNameConstants.addressType}`);
    const previouslySelectedValue = this.address.AddressTypeCode != null ? this.address.AddressTypeCode : addressTypeControl.value;
    addressTypeControl.setValue(addressType);
    this.addressListComponent.buildSelectedAddressTypes();
    if (previouslySelectedValue !== null) {
      const index = this.selectedAddressTypes.indexOf(previouslySelectedValue.toString());
      if (index !== -1) {
        this.selectedAddressTypes.splice(index, 1);
      }
    }
    this.selectedAddressTypes.push(addressType);
    this.addressTypeSubject.next(this.selectedAddressTypes);
    this.setAddressFieldName();
  }
}

