export class CustomerSearchResult {
    id: string;
    firstName: string;
    lastName: string;
    emailAddress: string;
    masterCustomerId: string;
    addressCity: string;
    addressState: string;
    addressCountry: string;
    memberGroup: string;
}
