import { Injectable } from '@angular/core';
import { OptInInterestPreference  } from '@shared/models/data';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { Constants } from '@shared/services/constants';

export interface PreferenceCategory {
    preference: OptInInterestPreference;
    values: OptInInterestPreference[];
}

export interface AllPreferenceCategoryResponse {
    categories: OptInInterestPreference[];
    preferences: OptInInterestPreference[];
}

@Injectable({ providedIn: 'root' })
export class EmailPreferencesLogicService {
  readonly ERROR_TITLE = `An error occurred while updating your email preferences`;

  preferencesToForm(categories: PreferenceCategory[], isMember: boolean): FormGroup {
    const group: any = {};
    categories.forEach(category => {
      const arr = category.values.map(_ => {
        const topic = new FormGroup({
          name: new FormControl(_.PreferenceDescription),
          code: new FormControl(_.PreferenceCode),
          active: new FormControl(_.Active)
        });

        if (!isMember && _.IsMemberOnly) {
          topic.disable();
          topic.get(`active`).setValue(false);
          topic.updateValueAndValidity();
        }

        return topic;
      });
      group[category.preference.PreferenceCode] = new FormArray(arr);
    });
    return new FormGroup({
      topics: new FormGroup({ ...group }),
      unsubscribeFromAll: new FormControl(false)
    });
  }

  formToPreferences(form: FormGroup) {
    const topics = form.get('topics') as FormGroup;
    const keys = Object.keys(topics.controls);
    const unsubscribe = form.get('unsubscribeFromAll').value;
    const dictionary = {};
    keys.forEach(key => {
      dictionary[key] = {};
      const formArray = form.get(`topics.${key}`) as FormArray;
      formArray.controls.forEach(control => {
        const value = unsubscribe ? false : control.get('active').value;
        dictionary[key][control.get('code').value] = value;
      });
    });
    return dictionary;
  }
  mergeUserTopics(_: [PreferenceCategory[], any]): PreferenceCategory[] {
    const [prefCategories, userTopics] = _;
    return prefCategories.map(category => {
      const key = category.preference.PreferenceCode;
      const onUserObject = userTopics[key];
      if (onUserObject) {
        category.values = category.values.map(p => {
          const active = userTopics[key][p.PreferenceCode];
          p.Active = active;
          return p;
        });
      }
      return category;
    });
  }
  allCategoriesToPreferences(result: AllPreferenceCategoryResponse) {
    const { categories, preferences } = result;
    const keys = Object.keys(preferences);
    const values: PreferenceCategory[] = keys.map(key => {
      const d: any = {};
      d.preference = categories.find(category => category.PreferenceCode === key);
      d.values = preferences[key];
      return d as PreferenceCategory;
    });
    return values;
  }
}
