<div class="form-group" *ngIf="form" [formGroup]="form">
  <aaos-form-field-title [editMode]="editMode" [field]="field"></aaos-form-field-title>
  <div *ngIf="(editMode && !fieldIsDisabled)">
    <mat-radio-group [formControlName]="field.fieldId" class="radio">
      <mat-radio-button *ngFor="let option of options" [value]="option.PreferenceCode">
        {{ option.PreferenceDescription }}</mat-radio-button>
    </mat-radio-group>
    <div class="required-text" *ngIf="showRequiredError()">
      {{field.fieldName}} is required
    </div>
  </div>
  <div *ngIf="!(editMode && !fieldIsDisabled)">
    <div *ngFor="let selectedValue of readOnlyInitialValues">

      <p *ngIf="readOnlyInitialValues.length>0 ">{{ selectedValue }}</p>

    </div>
    <p *ngIf="readOnlyInitialValues.length === 0" class="blank-text">(Blank)</p>
  </div>
</div>