<div *ngIf="user">
  <div class="profile-form-header img-cropper " *ngIf="user">
    <img [attr.src]="tmpProfilePictureUrl" (load)="tmpImageLoaded($event)" [ngClass]="{ 
      'img-square': tmpImageType === 'square',
      'img-portrait': tmpImageType === 'portrait',
      'img-landscape': tmpImageType === 'landscape'        
    }" *ngIf="tmpProfilePictureUrl" />
    <img [attr.src]="user.CustomerProfile.ProfilePictureUrl" (load)="imageLoaded($event)" (error)="updateUrl($event)"
      [ngClass]="{ 
        'img-square': imageType === 'square',
        'img-portrait': imageType === 'portrait',
        'img-landscape': imageType === 'landscape'        
      }" *ngIf="
              (tmpProfilePictureUrl === undefined || tmpProfilePictureUrl === null) &&
              user.CustomerProfile.ProfilePictureUrl
            " />
    <div *ngIf="!user.CustomerProfile.ProfilePictureUrl && !tmpProfilePictureUrl" class="profile-initials">
      {{ user.NameCredentials.FirstName | slice: 0:1
            }}{{ user.NameCredentials.LastName | slice: 0:1 }}
    </div>
  </div>
  <div *ngIf="editMode">
    <div class="image-upload btn--container btn-inline">
      <div class="upload-image-msg" [ngClass]="{ 'show-msg': uploadButtonHover }">
        JPG, GIF, or PNG. Max size of 10MB.
      </div>
      <label *ngIf="!maapApplication && parentFormValid && (profileImageDirty || profileImageDeleted)" for="save-input">
        <div class="btn btn--outline">
          {{saveButtonText}}
        </div>
      </label>
      <input *ngIf="!maapApplication && parentFormValid && (profileImageDirty || profileImageDeleted)" id="save-input" type="button"
        (click)="save()" />
      <label for="image-uploader-file-input">
        <div class="btn btn--outline" (mouseover)="onEnterButton()" (mouseout)="onLeaveButton()">
          CHOOSE PROFILE PHOTO
        </div>
      </label>
      <input id="image-uploader-file-input" type="file" accept="image/*" (change)="setFile($event)" />


      <button
        *ngIf="(user.CustomerProfile.ProfilePictureUrl || tmpProfilePictureUrl || profileImageDirty) && !profileImageDeleted"
        class="btn btn--outline btn--delete btn--cancel" (click)="deleteFile()">
        Delete
      </button>

      <div class="update-image-msg" [ngClass]="{ 'show-msg': profileImageDeleted || profileImageDirty }">
        <label *ngIf="!maapApplication && (profileImageDirty || profileImageDeleted) && !parentFormValid">
          Please make sure all the required fields below are completed before saving.
        </label>
        <label *ngIf="!maapApplication && profileImageDeleted">Please click 'Submit' to delete your photo.</label>
      </div>
    </div>
  </div>
</div>