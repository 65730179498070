import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { MatDialogRef } from '@angular/material';
import { FormConfirmgDialogComponent } from '../form-confirm-dialog/form-confirm-dialog.component';

@Component({
  selector: 'aaos-modal-fail',
  templateUrl: './modal-fail.component.html',
  styleUrls: ['./modal-fail.component.scss']
})
export class UpdateProfileModalFailComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<FormConfirmgDialogComponent>
  ) { }

  ngOnInit() {
    let interval = 2000;
    if (this.data && this.data.autoCloseInterval) {
      interval = this.data.autoCloseInterval;
    }
    setInterval(() => {
      this.dialogRef.close();
    }, interval);
  }
}
