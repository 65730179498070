import { Component, OnInit } from '@angular/core';
import { ProfilePromptLogicService } from './logic.service';
import { UserProfileService } from '@shared/services/api';
import { take, tap, delay, filter } from 'rxjs/operators';
import { ProfileScore } from '@shared/models/data';
import { ActivatedRoute, Router } from '@angular/router';
import { has } from 'lodash';
import { Constants } from '@shared/services/constants';
import { RouteNames } from '../routing/route-utils';
import { ProfileCompleteness } from '@shared/models/enums';
import { Utilities } from '@shared/services/utils';
import { APP_CONFIG } from '@config/main';
import { ContinueOnTrackerService } from '@shared/services/trackers';

@Component({
  selector: 'aaos-profile-prompt',
  templateUrl: './profile-prompt.component.html',
  styleUrls: ['./profile-prompt.component.scss']
})
export class ProfilePromptComponent implements OnInit {
  promptTitleText: string;
  promptText: string;
  primaryBtnText: string;
  secondaryBtnText: string;
  score: ProfileScore;
  redirectUri: string;
  loading: boolean = true;
  COMPLETENESS_INCOMPLETE: ProfileCompleteness = 'Incomplete';
  COMPLETENESS_COMPLETE: ProfileCompleteness = 'Complete';
  COMPLETENESS_ANNUAL: ProfileCompleteness = 'Annual';
  viewProfileLink = `/${RouteNames.User}`;
  editProfileLink = `/${RouteNames.UserProfile}/${RouteNames.Edit}`;
  confirmProfileLink = `/${RouteNames.User}`;

  constructor(
    private logic: ProfilePromptLogicService,
    private profileService: UserProfileService,
    private route: ActivatedRoute,
    private router: Router,
    private tracker: ContinueOnTrackerService
  ) {
    const params = this.route.snapshot.queryParams;
    if (has(params, Constants.QueryParams.RedirectUri)) {
      this.redirectUri = params[Constants.QueryParams.RedirectUri];
    } else if (has(params, Constants.QueryParams.RedirectUri.toLowerCase())) {
      this.redirectUri = params[Constants.QueryParams.RedirectUri.toLowerCase()];
    }
    if (Utilities.isDefined(this.redirectUri)) {
      this.tracker.set(this.redirectUri);
    }
    APP_CONFIG.pipe(
      take(1),
      filter((config: any) => {
        const disableFromConfig =
          config.disableProfileScore &&
          config.disableProfileScore.toString().toLowerCase() === 'true';
        const ssoAuthCookieExists = (document.cookie || '')
          .split(';')
          .filter(_ => _.indexOf(Constants.Cookies.SsoAuthStatus) > -1);
        return disableFromConfig || Utilities.arrayIsPopulated(ssoAuthCookieExists);
      }),
      tap(config => {
        if (Utilities.isDefined(this.redirectUri)) {
          window.location.href = this.redirectUri;
        } else {
          window.location.href = 'https://aaos.org';
        }
      })
    ).subscribe();
  }

  ngOnInit() {
    this.profileService
      .getProfileScore()
      .pipe(
        take(1),
        tap((score: ProfileScore) => {
          if (this.logic.shouldRedirect(score, this.redirectUri)) {
            window.location.replace(this.redirectUri);
          } else {
            this.promptTitleText = this.logic.promptTitleText(score);
            this.score = score;
            this.promptText = this.logic.promptText(score);
            this.primaryBtnText = this.logic.primaryBtnText(score.CompletenessType);
            this.secondaryBtnText = this.logic.secondaryBtnText();
          }
        }),
        delay(2000),
        tap(_ => {
          this.loading = false;
        })
      )
      .subscribe();
  }

  cancel() {
    if (Utilities.isDefined(this.redirectUri)) {
      window.location.href = this.redirectUri;
    } else {
      this.router.navigateByUrl(`/${RouteNames.User}`);
    }
  }
}
