﻿import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Constants } from '@shared/services/constants';

import { NotificationModal } from '@shared/models/data/notification-modal';

const apiUrl = Constants.ApiUrls.ModalSettings;

@Injectable()
export class NotificationModalService {

    constructor(private http: HttpClient) {

    }

    getSettings() {
        return this.http.get<NotificationModal>(apiUrl)
            .pipe(
                map(response => response),
                catchError(this.handleErrors)
            );
    }

    private handleErrors(error: any) {
        switch (error.status) {
            case 404:
                console.error('can\'t find resource: ' + apiUrl);
                break;
            default:
                console.error(error);
                break;
        }
        // return default configuration values
        return of<NotificationModal>(new NotificationModal());
    }
}
