import { Injectable } from '@angular/core';
import { Message } from '@angular/compiler/src/i18n/i18n_ast';

@Injectable({
  providedIn: 'root'
})
export abstract class PrimaryEmailChangedActionService {
  public originalEmail: string;
  abstract emailChanged(email: string): PrimaryEmailChangedAction;
}
export type PrimaryEmailChangedActionType = 'displayMessage' | 'none';
export class PrimaryEmailChangedAction {
  action: PrimaryEmailChangedActionType;
  message: string;
}
