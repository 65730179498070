import { AaosBaseService, LoggingService } from '@shared/services/api';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Constants } from '@shared/services/constants';
import { take, map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class AnnualMeetingRedirectService extends AaosBaseService {
    // BUG: workaround for https://github.com/angular/angular/pull/25033
    static ngInjectableDef = undefined;
    constructor(protected _h: HttpClient, protected _l: LoggingService) {
        super(_l, _h);
    }
    /**
     * getAllowedUrls
     */
    public getRedirectUrl(): Observable<any> {
        return this.http.get(Constants.ApiUrls.AnnualMeetingRedirect)
            .pipe(catchError(_ => this.handleError(_)), take(1), map((_: any) => {
                return _;
            }));
    }
}
