<div *ngIf="!loading" mat-dialog-actions>
  <div class="close-container">
    <mat-icon class="close-icon" (click)="dialogRef.close()"></mat-icon>
  </div>
  <div class="title title--lg dialog" mat-dialog-title>Save Changes</div>
  <div mat-dialog-content>
    <div class="align--left" *ngIf="data.length > 0">You have partial fields that will not be saved for:
      <div *ngFor="let missingBlock of data">
        <li>{{missingBlock}}</li>
      </div>
    </div>
    <p class="align-center padding--top">Would you like to save your changes?</p>
    <p *ngIf="showPrimaryEmailChangedMessage" class="align-center">Yes, I would like to change my username to my primary
      email</p>
  </div>
  <div class="btn--inline dialog-btn-container">
    <button (click)="save()" class="btn btn--small btn--solid btn--red">Save</button>
    <button (click)="dontSave()" class="btn btn--small btn--outline">Don't Save</button>
  </div>
</div>
<div *ngIf="loading">
  <div class="title text-save" mat-dialog-title>Saving Changes</div>
  <div class="loading-root">
    <aaos-spinner [displayInline]="true" [isLoading]="loading"></aaos-spinner>
  </div>
</div>