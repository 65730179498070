<div class="form-group login-credentials">
    <div class="form-group">
        <div class="form-group">
            <div class="title title--lg flex-align-center p-t-12">Login Credentials</div>
            <div class="title title--xs">Username/Email</div>
            <div *ngIf="user">{{user.CustomerProfile.UserName}}</div>
        </div>
        <label for="change-user-name-button" *ngIf="showButtons">
            <div class="btn btn--outline">
                CHANGE USERNAME
            </div>
        </label>
        <input id="change-user-name-button" type="submit" routerLink="/user-profile/username" [hidden]="true"
               *ngIf="showButtons" />
    </div>

    <div class="form-group">
        <div class="title title--xs">Password</div>
        <div>***************</div>
    </div>
    <label for="reset-password-button" *ngIf="showButtons">
        <div class="btn btn--outline">
            CHANGE PASSWORD
        </div>
    </label>
    <input id="reset-password-button" type="submit" routerLink="/user-profile/password" [hidden]="true"
           *ngIf="showButtons" />
</div>