import { Component, OnInit, Output, Input, EventEmitter, OnDestroy } from '@angular/core';
import { Customer, ApiResponse, CustomerAttachment } from '@shared/models/data';
import { FormFinishType, FormConfirmType } from '@shared/models/enums';
import { FormFieldGroup } from '@shared/form-fields/models/form-field-group';
import { FormField } from '@shared/form-fields/models/form-field';
import { FormGroup } from '@angular/forms';
import { UserProfileFormConfigurationService } from '@shared/services/api/user-profile-form-configuration.service';
import { take, catchError, map, switchMap, filter, tap, takeUntil } from 'rxjs/operators';
import { Constants } from '@shared/services/constants';
import { APP_CONFIG } from '@config/main';
import { FormBuilderService } from './form-builder.service';
import { EmailPreferencesService, UserProfileService } from '@shared/services/api';
import { Utilities } from '@shared/services/utils';
import { UserProfileBaseComponent } from '../user-profile-base.component';
import { MembershipFormUserMappingService } from './membership-form-user-mapping.service';
import { ApplicationFlowParameterCacheService } from '@shared/services/application-flow/application-flow-parameter-cache.service';
import { HttpErrorResponse } from '@angular/common/http';
import { of, Subject, Subscription } from 'rxjs';
import { FormConfirmgDialogComponent } from '@shared/components';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { ProfileFormOption } from '@shared/models/data';
import { UserProfilePrimaryEmailUpdatedActionService } from '../user-profile-primary-email-updated-action.service';
import { AllowedRedirectUrlsService } from '@shared/services/allowed-redirect-urls.service';
import { AnnualMeetingRedirectService } from '@shared/services/api/annual-meeting-redirect.service';
import { MembershipFormFieldResponse } from '@shared/services/api/membership-form-field-response';
import { GlobalNavigationService } from '@shared/services/api/global-nav-service';
import { FormFinishError } from '@shared/models/enums/form-finish';
import { SaveResult } from '@shared/models/contracts/saveResults';
import { UserProfileEditLogicService } from '../user-profile-edit/logic.service';

@Component({
    selector: 'aaos-dynamic-user-profile-edit',
    templateUrl: './dynamic-user-profile-edit.component.html',
    styleUrls: ['./dynamic-user-profile-edit.component.scss']
})
export class DynamicUserProfileEditComponent extends UserProfileBaseComponent implements OnInit, OnDestroy {

    @Input()
    editMode: boolean;
    @Input()
    enableDebug: boolean;
    @Output()
    finished: EventEmitter<SaveResult> = new EventEmitter<SaveResult>();
    groups: Array<FormFieldGroup> = new Array<FormFieldGroup>();
    dynamicProfileFormGroup: FormGroup = new FormGroup({});
    formFields: FormField[];
    formOptions: ProfileFormOption[];
    applicationType: string;
    loading: boolean = true;
    saveButtonActive = true;
    showOnlyFields = false;
    biographyAttachmentsSubject = new Subject<Array<CustomerAttachment>>();
    biographyAttachmentsSubscription$: Subscription;
    attachments: Array<CustomerAttachment> = new Array<CustomerAttachment>();
    saveSubject: Subject<void> = new Subject<void>();
    userSubscription: Subscription;
    @Input()
    refreshEditState$: Subject<void>;
    AMPMemberGroupId: string;
    maapOrigin: string;
    constructor(
        private fieldConfigurationService: UserProfileFormConfigurationService,
        private formBuilderService: FormBuilderService,
        profileService: UserProfileService,
        private membershipUserMapper: MembershipFormUserMappingService,
        flowParamCache: ApplicationFlowParameterCacheService,
        private dialog: MatDialog,
        router: ActivatedRoute,
        private userProfileEditLogicService: UserProfileEditLogicService,
        private primaryEmailUpdatedActionService: UserProfilePrimaryEmailUpdatedActionService,
        globalNavigationService: GlobalNavigationService,
        allowedUrlService: AllowedRedirectUrlsService, customerTokenService: AnnualMeetingRedirectService,
        emailPreferencesService: EmailPreferencesService) {
        super(router, flowParamCache, profileService, allowedUrlService,
            customerTokenService, globalNavigationService, emailPreferencesService);
        this.biographyAttachmentsSubject = new Subject<Array<CustomerAttachment>>();
        this.biographyAttachmentsSubscription$ = this.biographyAttachmentsSubject.subscribe(x => this.attachmentsUpdated(x));
    }
    ngOnInit() {
        super.ngOnInit();
        if (!this.applicationType) {
            this.applicationType = Constants.ApplicationTypes.Default;
        }

        if (this.applicationType === Constants.ApplicationTypes.MAAP) {
            this.saveButtonActive = false;
        }
        this.fieldConfigurationService.getMembershipConfiguration(this.applicationType,
            this.route.snapshot.queryParamMap.get(Constants.ApiUrls.ImpersonateMasterCustomerId))
            .pipe(
                take(1),
                catchError(_ => {
                    if (_.status === 401) {
                        // user isn't authenticated - let's auth them
                        const pathParam = Constants.ApiParams.RedirectPath;
                        const pathName = window.location.pathname;
                        APP_CONFIG.subscribe(config => {
                            window.location.href = `${config.authRedirectUrl}?${pathParam}=${pathName}`;
                        });
                    }
                    this.user = null;
                    return _;
                })
            )
            .subscribe((_: MembershipFormFieldResponse) => {
                this.AMPMemberGroupId = _.ampMemberGroupId;
                this.setUpForm(_.formFields);
            });
        this.saveSubject.subscribe(() => { this.openDialog(); });
        this.userSubscription = this.userTrackerService && this.userTrackerService.user$.subscribe(_ => this.updatePrimaryEmailAction(_));
        this.getAppConfig();
        window.addEventListener('message', this.handleMessage.bind(this));
    }

    getAppConfig() {
        APP_CONFIG.subscribe(_ => {
            this.maapOrigin = _.maapOrigin;
        });
    }

    handleMessage(event) {
        if ((event.origin !== this.maapOrigin) && (event.data !== 'maapSave')) {
            return;
        }
        console.log('AMP: maapSave received');
        if (this.dynamicProfileFormGroup.valid) {
            if (this.dynamicProfileFormGroup.dirty) {
                this.maapSave().subscribe(_ => {
                    if (_ === FormFinishType.Success) {
                        window.parent.postMessage('proceed', this.maapOrigin);
                        console.log('AMP: sending proceed');
                    }
                });
            } else {
                window.parent.postMessage('proceed', this.maapOrigin);
                console.log('AMP: sending proceed');
            }
        } else {
            window.parent.postMessage('invalidForm', this.maapOrigin);
            this.maapAlert();
            console.log('AMP: invalid form');
        }
    }

    postError() {
        window.parent.postMessage('ampSaveError', this.maapOrigin);
        console.log('AMP: Error on save.');
    }

    updatePrimaryEmailAction(user: Customer) {
        if (!user) { return; }
        this.primaryEmailUpdatedActionService.setOriginalValues(user.CustomerProfile.PrimaryEmailAddress,
            user.CustomerProfile.UserName);
    }

    ngOnDestroy() {
        this.biographyAttachmentsSubscription$.unsubscribe();
    }

    cancel() {
        this.finished.next(new SaveResult(FormFinishType.Cancel, null));
    }

    getProfileFormOptions() {
        const sub$ = this.profileService
            .getEditProfileFormOptions(this.user.AddressDetail[0].CountryCode, this.user.CustomerProfile.MemberGroupCode)
            .subscribe(_ => {
                this.populateForm(_);
                this.loading = false;
                sub$.unsubscribe();
                window.parent.postMessage('ampLoaded', this.maapOrigin);
            });
    }

    private setUpForm(formFields: FormField[]) {
        this.formFields = formFields;
        if (Utilities.isDefined(this.user)) {
            this.getProfileFormOptions();
            this.primaryEmailUpdatedActionService.setOriginalValues(this.user.CustomerProfile.PrimaryEmailAddress,
                this.user.CustomerProfile.UserName);
        } else {
            this.user$.subscribe(user => {
                this.getProfileFormOptions();
                this.primaryEmailUpdatedActionService.setOriginalValues(this.user.CustomerProfile.PrimaryEmailAddress,
                    this.user.CustomerProfile.UserName);
            });
        }
    }
    populateForm(formOptions: ProfileFormOption[]) {
        this.formOptions = formOptions;
        const configuration = this.formBuilderService.setUpForm(this.formFields, this.user);
        this.groups = configuration.groups;
        this.membershipUserMapper.populateFormGroup(configuration.formGroup, this.user, this.formOptions);
        this.dynamicProfileFormGroup = configuration.formGroup;
    }

    openDialog(): void {
        if (!this.dynamicProfileFormGroup.valid) {
            return;
        }
        const user = this.membershipUserMapper.formToUserSnapshot(this.dynamicProfileFormGroup, this.user, this.formOptions);
        const missingBlockNames = this.membershipUserMapper.checkPartiallyFilledOutBlocks(this.dynamicProfileFormGroup, user, this.user);
        const dialogRef = this.dialog.open(FormConfirmgDialogComponent, this.getModalConfig(missingBlockNames));
        const emailChangedAction = this.primaryEmailUpdatedActionService.emailChanged(user.Email);
        dialogRef.componentInstance.showPrimaryEmailChangedMessage = emailChangedAction.action === 'displayMessage';
        dialogRef.componentInstance.actionTaken
            .pipe(
                switchMap((result: FormConfirmType) => {
                    switch (result) {
                        case FormConfirmType.Save:
                            dialogRef.componentInstance.beginSpinner();
                            return this.save();
                        case FormConfirmType.DontSave:
                            dialogRef.close();
                            break;
                        default:
                            dialogRef.close();
                    }
                }),
                take(1)
            )
            .subscribe(_ => {
                this.finished.next(_);
            });
    }

    getModalConfig(messages: Array<string>) {
        return {
            panelClass: 'amp-dialog',
            disableClose: true,
            autoFocus: false,
            data: messages
        } as MatDialogConfig;
    }

    maapAlert() {
        alert('One or more fields are invalid, please review the form and submit again.');
        return;
    }

    private maapSave() {
        if (!this.dynamicProfileFormGroup.valid) {
            // this shouldn't happen but I added it just in case the save function
            // gets called through the save subject but the form isn't valid
            this.maapAlert();
        }
        console.log('AMP: saving');
        const user = this.membershipUserMapper.maapFormToUserSnapshot(this.dynamicProfileFormGroup, this.user, this.formOptions);
        return this.profileService
            .postProfileDetailedWithCommsWithoutPrefs(user, this.user.Communications)
            .pipe(
                map((responses: ApiResponse[]) => {
                    const anyUnsuccessful = responses.some(_ => _.hasUnsuccessfulResponse());
                    if (anyUnsuccessful) {
                        if (this.isPostalCodeError(responses)) {
                            this.postError();
                            return FormFinishError.PostalCodeError;
                        } else {
                            this.postError();
                            return FormFinishType.Error;
                        }
                    }
                    // this.userTrackerService.clearUser();
                    return FormFinishType.Success;
                }),
                catchError((_: HttpErrorResponse) => {
                    // tslint:disable-next-line: deprecation
                    this.postError();
                    return of(FormFinishType.Error);
                })
            );
    }

    private save() {
        if (!this.dynamicProfileFormGroup.valid) {
            // this shouldn't happen but I added it just in case the save function
            // gets called through the save subject but the form isn't valid
            alert('One or more fields are invalid, please review the form and submit again.');
            return;
        }
        const user = this.membershipUserMapper.formToUserSnapshot(this.dynamicProfileFormGroup, this.user, this.formOptions);
        this.membershipUserMapper.mapAttachmentTypesFromForm(this.dynamicProfileFormGroup, this.attachments);
        return this.profileService
            .postProfileDetailedWithCommunications(user, this.user.Communications, this.attachments)
            .pipe(
                map((responses: ApiResponse[]) => {
                    const anyUnsuccessful = responses.some(_ => _.hasUnsuccessfulResponse());
                    if (anyUnsuccessful) {
                        let errorsList: FormFinishError[];
                        errorsList = this.userProfileEditLogicService.parsePersonifyErrors(responses);
                        return (new SaveResult(FormFinishType.Error, errorsList));
                    }

                    this.clearAttachments();
                    this.userTrackerService.clearUser();
                    return (new SaveResult(FormFinishType.Success, null));
                }),
                catchError((_: HttpErrorResponse) => {
                    // tslint:disable-next-line: deprecation
                    return of(new SaveResult(FormFinishType.Error, null));
                })
            );
    }

    private clearAttachments() {
        this.attachments.forEach(x => {
            if (x.sessionStorageKey) {
                sessionStorage.removeItem(x.sessionStorageKey);
            }
        }
        );
        this.attachments = [];
    }

    attachmentsUpdated(attachments: Array<CustomerAttachment>): void {
        // only keep attachments which have an action to be performed
        this.attachments = attachments.filter(x => !Utilities.isUndefinedOrNull(x.Action));
        this.attachments.forEach(x => {
            x.AttachmentBase64String = sessionStorage.getItem(x.sessionStorageKey);
        });
    }


    isPostalCodeError(responses: ApiResponse[]) {
        return responses.some(
            _ =>
                !!_.unsuccessfulUpdateDetails &&
                _.unsuccessfulUpdateDetails.some(
                    x => x.search('The value is not valid for PostalCode') >= 0
                )
        );
    }

    isEmailError(responses: ApiResponse[]) {
        return responses.some(
            _ =>
                !!_.unsuccessfulUpdateDetails &&
                _.unsuccessfulUpdateDetails.some(x => x.search('Not a valid email') >= 0)
        );
    }
}
