import { Directive, Input, Renderer2, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Utilities } from '@shared/services/utils';
import { filter, take, map } from 'rxjs/operators';
import { Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { RouteParamAction } from '@shared/models/contracts';
import { RouteParamActionType } from '@shared/models/enums';
import { OnInit } from '@angular/core';

@Directive({
  selector: '[aaosUrlParamToClass]'
})
export class UrlParamToClassDirective implements OnInit {
  @Input()
  listeningFor: RouteParamAction[];
  @Output()
  deferredAction: EventEmitter<RouteParamAction> = new EventEmitter<RouteParamAction>();

  constructor(
    private route: ActivatedRoute,
    private renderer: Renderer2,
    private hostElement: ElementRef
  ) {}

  ngOnInit() {
    if (Utilities.isDefined(this.listeningFor)) {
      const params$ = this.route.queryParams
        .pipe(
          map(_ => {
            const keys = Object.keys(_);
            const vals = keys.map(k => _[k]);
            const ret = keys
              .map((k: string, ix: number) => {
                const rpa = this.listeningFor.find(lf => lf.key === k);
                return Utilities.isDefined(rpa)
                  ? ({ key: k, value: vals[ix], action: rpa.action } as RouteParamAction)
                  : null;
              })
              .filter(rpa => Utilities.isDefined(rpa));
            return ret;
          }),
          filter(_ => Utilities.isDefined(_) && _.length > 0)
        )
        .subscribe((params: RouteParamAction[]) => {
          params.forEach(param => {
            if (param.action === RouteParamActionType.AddClass) {
              this.renderer.addClass(this.hostElement.nativeElement, param.value);
            } else if (param.action === RouteParamActionType.DetermineFlow) {
              this.deferredAction.next(param);
            }
          });
          Utilities.destroySubscriptions(params$);
        });
    }
  }
}
