import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LoggingService, AaosBaseService } from '@shared/services/api';
import { Observable } from 'rxjs';
import { Constants } from '@shared/services/constants';
import { catchError, take, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApplicationTypesService extends AaosBaseService {


  constructor(protected _h: HttpClient, protected _l: LoggingService) {
    super(_l, _h);
  }

  public getApplicationTypes(): Observable<Array<string>> {
    return this.http.get(Constants.ApiUrls.ApplicationTypes).pipe(
      catchError(_ => this.handleError(_)),
      take(1),
      map((_: string[]) => {
        return _;
      }));
  }
}
