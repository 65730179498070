import { Component, OnInit, Injectable } from '@angular/core';
import { BaseMultipleOptionsFieldComponent } from '../BaseMultipleOptionsFieldComponent';

@Component({
  selector: 'aaos-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss']
})
@Injectable()
export class RadioButtonComponent extends BaseMultipleOptionsFieldComponent implements OnInit {

  ngOnInit() {
    super.ngOnInit();

  }
  showRequiredError() {
    if (this.form && this.field) {
      const control = this.form.get(this.field.fieldId);
      return control.hasError('required');
    } else { return null; }
  }
}
