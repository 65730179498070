import { Component, OnInit, Input } from '@angular/core';
import { Customer, CustomerProfile } from '@shared/models/data';
import { ResetPasswordComponent } from '../reset-password/reset-password.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'aaos-user-login-information',
  templateUrl: './user-login-information.component.html',
  styleUrls: ['./user-login-information.component.scss']
})
export class UserLoginInformationComponent implements OnInit {
  @Input() user: Customer;
  @Input() showButtons: boolean;
  constructor() { }

  ngOnInit() {
  }

}
