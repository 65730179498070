import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AaosBaseService } from './aaos-base.service';
import { LoggingService } from './logging.service';
import { Constants } from '@shared/services/constants';
import { map, catchError, take } from 'rxjs/operators';
import { GlobalNavigation } from '@shared/models/data/GlobalNavigation';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class GlobalNavigationService extends AaosBaseService {
    // BUG: workaround for https://github.com/angular/angular/pull/25033
    static ngInjectableDef = undefined;

    constructor(protected _h: HttpClient, protected _l: LoggingService) {
        super(_l, _h);
    }

    getGlobalNavigation() {
        return this.http.get(Constants.ApiUrls.GlobalNavigation).pipe(
            map((data: GlobalNavigation) => {
                return data;
            }));
    }
    getGlobalNavigationSetting(): Observable<any> {
        return this.http.get(Constants.ApiUrls.GlobalNavigationSetting)
        .pipe(catchError(_ => this.handleError(_)), take(1), map((_: any) => {
            return _;
        }));
    }




}
