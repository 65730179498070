<div class="outer-form-group" [formGroup]="dynamicProfileFormGroup" *ngIf="!loading">
  <div *ngIf="!showOnlyFields" class="title title--lg">{{ user.CustomerProfile.LabelName }}</div>
  <div class="width--full" *ngFor="let group of groups">
    <div class="fullWidth">

      <aaos-form-field-group [group]="group" [user]="user" [form]="dynamicProfileFormGroup.controls[group.name]"
        [formOptions]="formOptions" [biographyAttachmentsSubject]="biographyAttachmentsSubject"
        [primaryEmailChangedActionService]="primaryEmailUpdatedActionService" [saveSubject]="saveSubject"
        [editMode]="editMode" [maapApplication]="!saveButtonActive" [refreshEditState$]="refreshEditState$" [submitButtonText]="submitButtonText"
        [enableDebug]="enableDebug" [AMPMemberGroupId]="AMPMemberGroupId" [initialFocusField]="initialFocusField">
      </aaos-form-field-group>
    </div>

  </div>
  <div *ngIf="editMode && !impersonatedMasterCustomerId">
    <div *ngIf="saveButtonActive" class="btn--container btn--inline">
      <div *ngIf="!dynamicProfileFormGroup.valid" class="required-text-submit">
        <b>Required fields marked in red are missing. Please fill out all required fields for the submit button to appear.</b>
      </div>
      <div class="btn btn--solid" [ngClass]="{
            'btn--red': dynamicProfileFormGroup.valid,
            'btn--disabled': !dynamicProfileFormGroup.valid || submitting
          }" (click)="openDialog()">
        {{submitButtonText}}
      </div>
      <div *ngIf="showCancelButton" class="btn btn--outline btn--cancel" (click)="cancel()">Cancel</div>
    </div>
  </div>
</div>
<aaos-spinner [isLoading]="loading"></aaos-spinner>