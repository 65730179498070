import { Component, Input, OnInit } from '@angular/core';
import { BaseMultipleOptionsFieldComponent } from '@shared/form-fields/BaseMultipleOptionsFieldComponent';
import { Customer, Preference } from '@shared/models/data';
import { FieldOptionsMapperService } from '@shared/services/membershipForm/field-options-mapper.service';
import { FormFieldRuleConstants, FormField, FormFieldConstants } from '@shared/form-fields/models/form-field';

@Component({
  selector: 'aaos-drop-down',
  templateUrl: './drop-down.component.html',
  styleUrls: ['./drop-down.component.scss']
})
export class DropDownComponent extends BaseMultipleOptionsFieldComponent implements OnInit {

    @Input() user: Customer;
    requiredText;

  constructor(formFieldsOptionsMapper: FieldOptionsMapperService) {
    super(formFieldsOptionsMapper);
  }
  ngOnInit() {
    super.setUser(this.user);
    super.ngOnInit();
    if (this.field && this.field.ruleId.toLowerCase() === FormFieldRuleConstants.OptionalEditableId.toLowerCase()) {
      const emptyPreference = new Preference();
      emptyPreference.PreferenceCode = '';
      emptyPreference.PreferenceSubCode = '';
      emptyPreference.PreferenceDescription = '';
      this.options.unshift(emptyPreference);
      }

    this.requiredTextToggle();
  }

  showRequiredError() {
    if (this.form && this.field) {
      const control = this.form.get(this.field.fieldId);
      return control.hasError('required');
    } else { return false; }
    }

    requiredTextToggle() {
        if (this.field.placeholderText != null) {
            this.requiredText = this.field.placeholderText;
        } else {
            this.requiredText = this.field.fieldName;
        }
    }

}
