<div class="form-group" *ngIf="form" [formGroup]="form">
  <aaos-text-area-field [form]="form" [field]="textField" [formOptions]="formOptions" [editMode]="editMode">
  </aaos-text-area-field>
  <div *ngIf="enableDebug">
  <aaos-attachments [form]="form" [field]="attachmentsField" [formOptions]="formOptions"
    [biographyAttachmentsSubject]="biographyAttachmentsSubject" [user]="user" [editMode]="editMode"
    [refreshEditState$]="refreshEditState$">
  </aaos-attachments>
</div>
</div>
<div class="clearFloat"></div>