import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

import { FormConfirmgDialogComponent } from '../form-confirm-dialog/form-confirm-dialog.component';
import { of } from 'rxjs';
import { take, tap, debounceTime, delay } from 'rxjs/operators';

@Component({
  selector: 'aaos-modal-success',
  templateUrl: './modal-success.component.html',
  styleUrls: ['./modal-success.component.scss']
})
export class UpdateProfileModalSuccessComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<FormConfirmgDialogComponent>) { }

  ngOnInit() {
    of(true)
      .pipe(
        take(1),
        delay(2500),
        tap(_ => {
          this.dialogRef.close();
          window.scrollTo(0, 0);
        })
      )
      .subscribe();
  }
}
