export enum FormFinishType {
  Cancel = 'cancel',
  Success = 'success',
  Error = 'error',
}

export enum FormFinishError {
  PostalCodeError = 'postalcode',
  CityError = 'city',
  EmailError = 'email',
  PrimaryEmailExistsError = 'primaryEmailExits',
  PasswordError = 'passwordError',
  OldPasswordIncorrectError = 'oldPasswordIncorrectError',
  UsernameError = 'UsernameError'
}

export enum FormConfirmType {
  Cancel = 'cancel',
  Save = 'save',
  DontSave = 'dontSave'
}
