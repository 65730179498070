import { TestComponent } from '../test/test.component';
import { ConfirmComponent } from '../confirm/confirm.component';

export abstract class RouteNames {
  // Base routes
  public static readonly CatchAll: string = '**';
  public static readonly Root: string = '';
  // Custom routes
  public static readonly Legacy: string = 'v1';
  public static readonly User: string = 'user';
  public static readonly Edit: string = 'edit';
  public static readonly EmailPreferences: string = 'email-preferences';
  public static readonly EmailPreferencesId: string = 'email-preferences/:id';
  public static readonly ManagePreferences: string = 'manage-preferences';
  public static readonly Test: string = 'test';
  public static readonly Confirm: string = 'confirm';
  public static readonly UpdateProfile: string = 'update-profile';
  public static readonly UserProfile: string = 'user-profile';
  public static readonly UserAdministration: string = 'user-administration';
  public static readonly Unsubscribe: string = 'unsubscribe';
  public static readonly UnsubscribeOnlyMarketo: string = 'unsubscribe-only-marketo';
  public static readonly UnsubscribeFromMarketo: string = 'unsubscribe-from-marketo';
  public static readonly Dynamic: string = 'dynamic';
  public static readonly Password: string = 'password';
  public static readonly Username: string = 'username';
}

export abstract class KnownFlowsForRoutes {
  public static getComponentByName(flowStep: string): any {
    switch (flowStep) {
      case RouteNames.Test:
        return TestComponent;
      case RouteNames.Confirm:
        return ConfirmComponent;
      default:
        return null;
    }
  }
}
