<div class="spinner-parent">
  <div *ngIf="!(loading$ | async) && (authorized$ | async) === true">
    <div [formGroup]="emailForm" class="container mat-form-field-container">
      <div class="title title--lg">User Search</div>
      <div>
        <div class="title title--xs">Search for a user by email</div>
        <div class="form-group">
          <input (keyup)="handleKeyUp($event)" class="form-control" placeholder="Email" type="email"
            formControlName="address" />
          <div *ngIf="emailForm.get('address').dirty">
            <div class="required-text" *ngIf="
                emailForm.get('address').hasError('email') &&
                !emailForm.get('address').hasError('required')
              ">
              Please enter a valid email address
            </div>
            <div class="required-text" *ngIf="emailForm.get('address').hasError('required')">
              Email is required
            </div>
          </div>
        </div>
        <div class="form-group">
          <input (keyup)="handleKeyUp($event)" type="text" placeholder="Last Name" type="text"
            formControlName="lastName" />
          <div *ngIf="emailForm.get('lastName').dirty">
            <div class="required-text" *ngIf="emailForm.get('lastName').hasError('required')">
              Please enter a valid last name
            </div>
          </div>
        </div>
        <div class="form-group">
          <input (keyup)="handleKeyUp($event)" type="text" placeholder="First Name" type="text"
            formControlName="firstName" />
        </div>
        <div class="form-group">
          <mat-radio-group class="radio" formControlName="memberType">
            <mat-radio-button *ngFor="let m of memberTypes" [value]="m.code">{{
              m.description
            }}</mat-radio-button>
          </mat-radio-group>
        </div>
        <div>
          <button class="btn btn--solid" [ngClass]="{
              'btn--red': emailForm.valid,
              'btn--disabled': !emailForm.valid || (loading$ | async)
            }" [disabled]="!emailForm.valid" (click)="handleSearch()">
            Search
          </button>
        </div>
        <div class="form-group" *ngIf="applicationTypes && searchResults && searchResults.length >0">
          <div class="title title--xs">Profile Application:</div>
          <mat-select formControlName="selectedApplication">
            <mat-option *ngFor="let applicationType of applicationTypes" [value]="applicationType">{{
              applicationType |titlecase
            }}</mat-option>
          </mat-select>
        </div>

        <div class="mat-elevation-z8" *ngIf="searchResults && searchResults.length >0">
          <table mat-table [dataSource]="searchResults">

            <ng-container matColumnDef="masterCustomerId">
              <th mat-header-cell *matHeaderCellDef>Master Customer Id</th>
              <td mat-cell *matCellDef="let customer">{{customer.masterCustomerId}}</td>
            </ng-container>

            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef>Name</th>
              <td mat-cell *matCellDef="let customer">{{customer.firstName}} {{customer.lastName}}</td>
            </ng-container>

            <ng-container matColumnDef="memberGroup">
              <th mat-header-cell *matHeaderCellDef>Member Group</th>
              <td mat-cell *matCellDef="let customer">
                <span *ngIf="customer.memberGroup">{{customer.memberGroup}}</span>
                <span *ngIf="!customer.memberGroup">N/A</span>
              </td>
            </ng-container>

            <ng-container matColumnDef="city">
              <th mat-header-cell *matHeaderCellDef>City</th>
              <td mat-cell *matCellDef="let customer">{{customer.addressCity}}</td>
            </ng-container>

            <ng-container matColumnDef="state">
              <th mat-header-cell *matHeaderCellDef>State</th>
              <td mat-cell *matCellDef="let customer">{{customer.addressState}}</td>
            </ng-container>

            <ng-container matColumnDef="country">
              <th mat-header-cell *matHeaderCellDef>Country</th>
              <td mat-cell *matCellDef="let customer">{{customer.addressCountry}}</td>
            </ng-container>


            <ng-container matColumnDef="editEmailPreferences">
              <th mat-header-cell *matHeaderCellDef>Email Preferences</th>
              <td mat-cell *matCellDef="let customer"><span><a *ngIf="customer.id" class="cs-link"
                    [routerLink]="'/' + emailPreferencesLink"
                    [queryParams]="{ email: customer.emailAddress, id: customer.id }">Edit</a></span></td>
            </ng-container>

            <ng-container matColumnDef="editProfile">
              <th mat-header-cell *matHeaderCellDef>User Profile</th>
              <td mat-cell *matCellDef="let customer"><span><a *ngIf="customer.masterCustomerId" class="cs-link"
                [routerLink]="'/'" (click)="goToUserProfile(customer)">Edit</a></span></td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </div>

        <div class="search-results" *ngIf="userNotFound && error">
          <p class="error">{{ error }}</p>
        </div>
        <div class="title title--md" *ngIf="userNotFound">
          No users found.
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!(loading$ | async) && (authorized$ | async) === false">
    <h2>401 Unauthorized</h2>
    <p>You do not have the required permissions to view this page.</p>
  </div>
  <aaos-spinner [isLoading$]="loading$" [spinnerCaption]="spinnerCaption"></aaos-spinner>
</div>