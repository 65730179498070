<div class="profile-prompt--bg" *ngIf="!loading && score">
  <div class="profile-prompt--flexbox">
    <div>
      <div class="profile-prompt--body">
        <div class="title title--lg">{{ promptTitleText }}</div>
        <p>{{ promptText }}</p>
      </div>
      <div class="profile-prompt--border"></div>
      <div class="profile-prompt--footer">
        <div class="btn--container">
          <div [ngSwitch]="score.CompletenessType">
            <a *ngSwitchCase="COMPLETENESS_INCOMPLETE" class="btn btn--small btn--solid btn--red"
              [routerLink]="viewProfileLink">
              {{ primaryBtnText }}
            </a>
            <a *ngSwitchCase="COMPLETENESS_ANNUAL" class="btn btn--small btn--solid btn--red"
              [routerLink]="confirmProfileLink" [queryParams]="{ AnnualUpdate: true }">
              {{ primaryBtnText }}
            </a>
            <a *ngSwitchCase="COMPLETENESS_COMPLETE" class="btn btn--small btn--solid btn--red"
              [routerLink]="viewProfileLink">
              {{ primaryBtnText }}
            </a>
            <a *ngSwitchDefault class="btn btn--small btn--solid btn--red" [routerLink]="viewProfileLink">
              {{ primaryBtnText }}
            </a>

          </div>
          <button class="btn btn--small btn--outline" (click)="cancel()">
            {{ secondaryBtnText }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="loading-container" *ngIf="loading">
  <aaos-spinner [isLoading]="loading"></aaos-spinner>
</div>