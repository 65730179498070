import { Component, OnInit, Input } from '@angular/core';
import { BaseFieldComponent } from '@shared/form-fields/baseFieldComponent';
import { FormField } from '@shared/form-fields/models/form-field';
import { Constants } from '@shared/services/constants';
import { Subject } from 'rxjs';
import { Customer } from '@shared/models/data';

@Component({
  selector: 'aaos-biography',
  templateUrl: './biography.component.html',
  styleUrls: ['./biography.component.scss']
})
export class BiographyComponent extends BaseFieldComponent implements OnInit {

  textField: FormField = new FormField();
  attachmentsField: FormField = new FormField();
  @Input()
  biographyAttachmentsSubject: Subject<object>;
  @Input()
  user: Customer;
  @Input()
  enableDebug: boolean;

  constructor() { super(); }

  ngOnInit() {
    this.textField = this.createFormFieldForSubField(Constants.FormSubFieldNameConstants.biographyText);
    this.attachmentsField = this.createFormFieldForSubField(Constants.FormSubFieldNameConstants.biographyAttachments);
    this.attachmentsField.fieldName = 'Attachments';
    super.ngOnInit();
  }

}
