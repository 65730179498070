import { FormFinishType } from '@shared/models/enums';
import { FormFinishError } from '@shared/models/enums/form-finish';

export class SaveResult {

    constructor(finishType, errorList) {
        this.type = finishType,
        this.errors = errorList;
    }
    type: FormFinishType;
    errors: FormFinishError[];
}
