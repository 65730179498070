<div class="form-group phone-spacing" *ngIf="form" [formGroup]="form">
    <div *ngIf="!hasFormGroups()">
        <aaos-form-field-title [editMode]="editMode" [field]="field"></aaos-form-field-title>
      </div>

  <div class="form-group" [formGroup]="form">
    <div *ngFor="let formGroupForPhone of formGroups; let i = index">
      <aaos-phone-field [field]="field" [formOptions]="formOptions" [form]="formGroupForPhone" [editMode]="editMode" [listIndex]="i"
      [selectedPhoneTypes]="selectedPhoneTypes" [phoneTypeSubject]="phoneTypeSubject" [showDeleteIcon]="showDeleteIcon" [refreshEditState$]="refreshEditState$">
      </aaos-phone-field>
    </div>
    <div *ngIf="(editMode) && showAddButton === true">
      <button (click)="addNewPhone()" class="btn btn--outline" [ngClass] ="{'btn--disabled': !form.valid}">Add Phone</button>
    </div>
  </div>
</div>