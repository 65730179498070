<div mat-dialog-actions>
  <div class="close-container">
    <mat-icon class="close-icon" (click)="dialogRef.close()"></mat-icon>
  </div>
  <div class="title title--lg dialog align-center" mat-dialog-title></div>
  <div class="dialog-content-margin" mat-dialog-content>
    <ng-container>
      <p>
        {{data?.group?.toolTipTextDetail}}
      </p>
    </ng-container>
  </div>
</div>