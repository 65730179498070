import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { HomeComponent, HomeLogicService } from './home';
import { SharedModule } from '@shared/shared.module';
import { RouterModule } from '@angular/router';
import { MainRoutingModule } from './routing/routing.module';
import { RootComponent } from './root/root.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { UrlParamToClassDirective } from './shared/directives/url-param-to-class.directive';
import { SizeBroadcastDirective } from './shared/directives/size-broadcast.directive';
import { TestComponent } from './test/test.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { AppConfigService } from '@config/main';
import { HttpClientModule } from '@angular/common/http';
import { NgxMaskModule } from 'ngx-mask';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NotificationModalService } from '@shared/services/api/notification-modal-service';

export function loadConfigService(configService: AppConfigService) {
  return () => {
    return configService.load();
  };
}

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    RouterModule,
    MainRoutingModule,
    NgxMaskModule.forRoot()
  ],
  providers: [
    HomeLogicService,
    {
      provide: APP_INITIALIZER,
      useFactory: loadConfigService,
      deps: [AppConfigService, HttpClientModule],
      multi: true
    },
    NotificationModalService
  ],
  declarations: [
    HomeComponent,
    RootComponent,
    PageNotFoundComponent,
    UrlParamToClassDirective,
    SizeBroadcastDirective,
    TestComponent,
    ConfirmComponent
  ],
  entryComponents: [TestComponent, ConfirmComponent],
  bootstrap: [RootComponent]
})
export class AppModule { }
