<div *ngIf="user">
  <aaos-header [showEmailPreferencesLink]="showEmailPreferencesLink" [isProfilePage]="true"></aaos-header>
  <div class="flexbox container">
    <div class="profile-breadcrumb">
      <span class="breadcrumb-text" routerLink="/user-profile">MY PROFILE</span>&ensp;/&ensp;CHANGE
      PASSWORD
    </div>
    <div class="form-group reset-password-form" [formGroup]="form">
      <div class="form-group">
        <div class="title title--lg flex-align-center">Change Password</div>
        <div class="form-group">
          <div class="title title--xs">Current Password</div>
          <input type="password" formControlName="oldPassword">
          <div class="required-text" *ngIf="showRequiredError('oldPassword')">
            Current password is required
          </div>
        </div>
        <div class="form-group">
          <div class="title title--xs">New Password</div>
          <input type="password" formControlName="newPassword">
          <div class="validations-wrapper">
            <aaos-password-criteria [form]="form" fieldName="newPassword" [message]="passwordLengthTextMessage"
              errorName="length"></aaos-password-criteria>
            <aaos-password-criteria [form]="form" fieldName="newPassword" [message]="containsNumberMessage"
              errorName="containsNumber"></aaos-password-criteria>
            <aaos-password-criteria [form]="form" fieldName="newPassword" [message]="containsLowerCaseMessage"
              errorName="containsLowerCase"></aaos-password-criteria>
            <aaos-password-criteria [form]="form" fieldName="newPassword" [message]="containsUpperCaseMessage"
              errorName="containsUpperCase"></aaos-password-criteria>
            <aaos-password-criteria [form]="form" fieldName="newPassword" [message]="containsSpecialCharacter"
              errorName="containsSpecialChars"></aaos-password-criteria>
          </div>

        </div>
        <div class="form-group">
          <div class="title title--xs">Confirm New Password</div>
          <input type="password" formControlName="confirmNewPassword">
          <div class="required-text" *ngIf="showRequiredError('confirmNewPassword')">
            Confirm new password is required
          </div>
          <div class="required-text" *ngIf="form.hasError('passwordMismatch')">
            Passwords must match
          </div>
        </div>

        <div class="image-upload btn--container btn-inline">
          <div class="btn btn--solid saveButton" [ngClass]="{
            'btn--red': form.valid,
            'btn--disabled': !form.valid || submitting
          }" (click)="openDialog()">
            SAVE
          </div>
          <button class="btn btn--outline btn--delete btn--cancel" (click)="cancel()">
            CANCEL
          </button>
        </div>
      </div>
    </div>
  </div>
  <aaos-footer></aaos-footer>

  <aaos-spinner [isLoading]="loading"></aaos-spinner>