import { Component, OnInit, Input } from '@angular/core';
import { BaseFieldComponent } from '@shared/form-fields/baseFieldComponent';
import { PrimaryEmailChangedActionService } from '@shared/form-fields/primary-email-changed-action.service';
import { Subscription } from 'rxjs';
import { FormFieldValidatorsService } from '@shared/form-fields/form-field-validators.service';
import { FormGroup, FormControl } from '@angular/forms';
import { Customer } from '@shared/models/data';
import { Constants } from '@shared/services/constants';
import { BaseFieldListComponent } from '@shared/form-fields/baseFieldListComponent';
import { FormFieldRuleConstants } from '@shared/form-fields/models/form-field';

@Component({
  selector: 'aaos-emergency-list',
  templateUrl: './emergency-list.component.html',
  styleUrls: ['./emergency-list.component.scss']
})
export class EmergencyListComponent extends BaseFieldListComponent implements OnInit {

  @Input()
  primaryEmailChangedActionService: PrimaryEmailChangedActionService;
  primaryEmailChangeMessage: string;
  refreshSubscription: Subscription;
  @Input()
  user: Customer;
  @Input()
  editMode: boolean;
  formGroupsForEmergencies: FormGroup[] = [];
  showAddEmergencyButton: boolean;

  constructor(public formFieldValidatorsService: FormFieldValidatorsService) {
    super(formFieldValidatorsService);
  }
  ngOnInit() {
    super.ngOnInit();
    if (this.form != null) {
      const formGroup = this.form as FormGroup;
      for (const key of Object.keys(formGroup.controls)) {
        const formControl = formGroup.get(key) as FormGroup;
        this.formGroupsForEmergencies.push(formControl);
      }
    }
    this.setShowAddEmergencyButton();
  }
  addNewEmergencyContact() {
    const existingFormGroup = this.form as FormGroup;
    const existingFormControlsCountForEmergency = Object.keys(existingFormGroup.controls).length;
    const uniqueKey =
      this.user.CustomerProfile.MasterCustomerId + '-' + existingFormControlsCountForEmergency + '-'
      + Constants.AdditionalFieldConstants.Emergency;
    const newFormGroup = new FormGroup({});
    const contactNameValidator = this.formFieldValidatorsService.getRuleBaseValidators(FormFieldRuleConstants.OptionalEditableId,
      this.field.fieldTypeName);
    const relationshipValidator = this.formFieldValidatorsService.getRuleBaseValidators(FormFieldRuleConstants.OptionalEditableId,
      this.field.fieldTypeName);
    const phoneNumberValidator = this.formFieldValidatorsService.getRuleBaseValidators(FormFieldRuleConstants.OptionalEditableId,
      this.field.fieldTypeName);
    const commentsValidator = this.formFieldValidatorsService.getRuleBaseValidators(FormFieldRuleConstants.OptionalEditableId,
      this.field.fieldTypeName);
    const phoneTypeValidator = this.formFieldValidatorsService.getRuleBaseValidators(FormFieldRuleConstants.OptionalEditableId,
      this.field.fieldTypeName);
    const contactNameFieldState = this.formFieldValidatorsService.getFieldState(this.field);
    const relationshipFieldState = this.formFieldValidatorsService.getFieldState(this.field);
    const phoneNumberFieldState = this.formFieldValidatorsService.getFieldState(this.field);
    const commentsFieldState = this.formFieldValidatorsService.getFieldState(this.field);
    const phoneTypeFieldState = this.formFieldValidatorsService.getFieldState(this.field);
    newFormGroup.addControl(Constants.AdditionalFieldConstants.id, new FormControl());
    newFormGroup.get(Constants.AdditionalFieldConstants.id).setValue(uniqueKey);
    newFormGroup.addControl(`${this.field.fieldId}-${Constants.FormSubFieldNameConstants.contactName}`,
      new FormControl(contactNameFieldState, { validators: contactNameValidator }));
    newFormGroup.addControl(`${this.field.fieldId}-${Constants.FormSubFieldNameConstants.relationship}`,
      new FormControl(relationshipFieldState, { validators: relationshipValidator }));
    newFormGroup.addControl(`${this.field.fieldId}-${Constants.FormSubFieldNameConstants.phoneNumber}`,
      new FormControl(phoneNumberFieldState, { validators: phoneNumberValidator }));
    newFormGroup.addControl(`${this.field.fieldId}-${Constants.FormSubFieldNameConstants.comments}`,
      new FormControl(commentsFieldState, { validators: commentsValidator }));
    newFormGroup.addControl(`${this.field.fieldId}-${Constants.FormSubFieldNameConstants.phoneType}`,
      new FormControl(phoneTypeFieldState, { validators: phoneTypeValidator }));
    newFormGroup.addControl(`${this.field.fieldId}-${Constants.FormSubFieldNameConstants.emergencyContactId}`,
      new FormControl());
    newFormGroup.addControl(Constants.AdditionalFieldConstants.id, new FormControl());
    newFormGroup.get(Constants.AdditionalFieldConstants.id).setValue(uniqueKey);
    newFormGroup.addControl(Constants.AdditionalFieldConstants.newflag, new FormControl());
    newFormGroup.get(Constants.AdditionalFieldConstants.newflag).setValue(true);
    newFormGroup.addControl(Constants.AdditionalFieldConstants.deletedflag, new FormControl());
    newFormGroup.get(Constants.AdditionalFieldConstants.deletedflag).setValue(false);
    existingFormGroup.addControl(uniqueKey, newFormGroup);
    this.formGroupsForEmergencies.push(newFormGroup);
    this.setShowAddEmergencyButton();
  }
  deleteEmergencyContact(id: string) {
    const formGroup = this.form as FormGroup;
    const deletedFromGroup = formGroup.get(id) as FormGroup;
    const deletedFlagControl = deletedFromGroup.get(Constants.AdditionalFieldConstants.deletedflag);
    const newFlagControl = deletedFromGroup.get(Constants.AdditionalFieldConstants.newflag);
    deletedFlagControl.setValue(true);
    if (newFlagControl.value === true) {
      formGroup.removeControl(id);
    }
    this.formGroupsForEmergencies = [];
    for (const key of Object.keys(formGroup.controls)) {
      const formControl = formGroup.get(key) as FormGroup;
      const deletedFormControl = formControl.get(Constants.AdditionalFieldConstants.deletedflag);
      const deletedValue = deletedFormControl.value;
      if (key !== id && deletedValue === false) {
        this.formGroupsForEmergencies.push(formControl);
      }
    }
    this.setShowAddEmergencyButton();
  }
  setShowAddEmergencyButton() {
    this.showAddEmergencyButton = this.formGroupsForEmergencies !== null && this.formGroupsForEmergencies.length < 2;
  }
  hasFormGroupsForEmergencyContact() {
    return this.formGroupsForEmergencies.length !== 0;
}

}
