<div class="form-group" *ngIf="form" [formGroup]="form">
    <div *ngIf="!hasFormGroupsForEmergencyContact()">
        <aaos-form-field-title [editMode]="editMode" [field]="field"></aaos-form-field-title>
      </div>
  <div class="form-group" [formGroup]="form">
    <div *ngFor="let formGroupsForEmergency of formGroupsForEmergencies; let i = index">
      <aaos-emergency-contact-field [field]="field" [formOptions]="formOptions" [form]="formGroupsForEmergency"
        [editMode]="editMode" [refreshEditState$]="refreshEditState$" [listIndex]="i">
      </aaos-emergency-contact-field>
    </div>
    <div *ngIf="(editMode) && showAddEmergencyButton === true">
      <button (click)="addNewEmergencyContact()" class="btn btn--outline" [ngClass] ="{'btn--disabled': !form.valid}">Add Emergency
        Contact</button>
    </div>

    <div *ngIf="!(editMode && !fieldIsDisabled) && !hideFieldInReadonly">
      <p *ngIf="formGroupsForEmergencies.length==0" class="blank-text">(Blank)</p>
    </div>

  </div>