import { Component, OnInit, Input } from '@angular/core';
import { FormFieldRuleConstants } from '@shared/form-fields/models/form-field';
import { FormGroup } from '@angular/forms';
import { BaseFieldComponent } from '@shared/form-fields/baseFieldComponent';
import { UserProfileService } from '@shared/services/api';
import { tap } from 'rxjs/operators';
import { PhoneNumberStructure, Customer } from '@shared/models/data';
import { Utilities } from '@shared/services/utils';
import { FormFieldValidatorsService } from '@shared/form-fields/form-field-validators.service';
import { Preference } from '@shared/models/data';
import { FieldOptionsMapperService } from '@shared/services/membershipForm/field-options-mapper.service';
import { Constants } from '@shared/services/constants';
import { PhoneListComponent } from '@shared/form-fields/phone-list/phone-list.component';
import { Subject, BehaviorSubject } from 'rxjs';



@Component({
  selector: 'aaos-phone-field',
  templateUrl: './phone-field.component.html',
  styleUrls: ['./phone-field.component.scss']
})

export class PhoneFieldComponent extends BaseFieldComponent implements OnInit {
  @Input()
  user: Customer;
  @Input()
  showDeleteIcon: boolean;
  @Input()
  listIndex: number;
  @Input()
  phoneTypeSubject: Subject<string[]>;
  @Input()
  selectedPhoneTypes: string[] = [];

  constructor(
    private profileService: UserProfileService,
    private formFieldValidatorsService: FormFieldValidatorsService,
    private fieldOptionsMapperService: FieldOptionsMapperService,
    private phoneListComponent: PhoneListComponent) { super(); }
  placeholder: string;
  disableRadioButton: boolean;
  maskForPhoneNumber: string;
  phoneCountryCode: string;
  isAreaCodeAvailable: boolean;
  maskForCountryCode: string;
  maskForAreaCode: string;
  countryCode: string;
  maskForExtension: string;
  readonlyPhoneType: string;
  readonlyPhoneNumber: string;
  isCountryCodeUsaOrCan: boolean;
  PhoneId: string;
  PhoneFieldName: string;
  isPhoneDuplicate: boolean;
  countriesOptions: Preference[] = [];
  phoneTypeOptions: Preference[] = [];
  formControlName: string;
  isCommunicationInUse: boolean;
  phoneTypeObject: BehaviorSubject<object> = new BehaviorSubject<object>(null);
  readonlyDoNotCallFlag: boolean;
  readonlyPhoneIncludeInDirectoryFlag: boolean;
  minLength: number;
  maxLength: number;

  ngOnInit() {
    super.ngOnInit();
    this.countriesOptions = this.fieldOptionsMapperService.getOptionsForAddressField(this.formOptions).applicationCountries;
    if (this.field && this.field.ruleId.toLowerCase() === FormFieldRuleConstants.OptionalEditableId.toLowerCase()) {
      const emptyPreference = new Preference();
      emptyPreference.PreferenceCode = '';
      emptyPreference.PreferenceSubCode = '';
      emptyPreference.PreferenceDescription = '';
      this.countriesOptions.unshift(emptyPreference);
    }

    if (!this.form) {
      return;
    }

    this.countryCode = this.form.get('countryCode').value;
    this.countryCodeChanged(this.countryCode);
    const phoneType = this.form.get('phoneType');
    if (phoneType) {
      const phoneTypePreference = this.fieldOptionsMapperService.getPhoneTypesPreference().find(x => x.PreferenceCode === phoneType.value);
      if (phoneTypePreference) {
        this.readonlyPhoneType = phoneTypePreference.PreferenceDescription;
      }
    }
    this.buildPhoneTypeOptions(this.selectedPhoneTypes);
    const phoneNumberField = this.form.get('phoneNumber');
    if (phoneNumberField) {
      this.readonlyPhoneNumber = phoneNumberField.value;
    }
    if (this.countryCode === 'USA' || this.countryCode === 'CAN') {
      this.isCountryCodeUsaOrCan = true;
    } else {
      this.isCountryCodeUsaOrCan = false;
    }
    this.disableRadioButton = this.disableRadioButtons(phoneNumberField.value);
    this.PhoneId = this.form.get('Id').value;
    this.setPhoneFieldName();
    this.formControlName = Constants.FormSubFieldNameConstants.countryCode;

    const doNotCallFlag = this.form.get('doNotCallFlag');
    if (this.form.get('doNotCallFlag')) {
      this.readonlyDoNotCallFlag = doNotCallFlag.value;
    }

    const phoneIncludeInDirectoryFlag = this.form.get('phoneIncludeInDirectoryFlag');
    if (phoneIncludeInDirectoryFlag) {
      this.readonlyPhoneIncludeInDirectoryFlag = phoneIncludeInDirectoryFlag.value;
    }
    this.phoneTypeSubject.subscribe(_ => { this.buildPhoneTypeOptions(_); });
  }
  countryCodeChanged(countryCode: string) {
    const sub$ = this.profileService
      .getPhoneNumberStructure(countryCode)
      .pipe(
        tap(_ => {
          const fields = _;
          const phoneCountryCodeField = fields.PhoneNumberFields.find(x => x.FieldName === 'PHONE_COUNTRY_CODE');
          const phoneAreaCodeField = fields.PhoneNumberFields.find(x => x.FieldName === 'PHONE_AREA_CODE');
          const phoneExtensionField = fields.PhoneNumberFields.find(x => x.FieldName === 'PHONE_EXTENSION');
          const phoneNumberField = fields.PhoneNumberFields.find(x => x.FieldName === 'PHONE_NUMBER');

          this.maskForCountryCode = null;
          this.maskForPhoneNumber = null;
          this.maskForAreaCode = null;
          this.maskForExtension = null;

          this.phoneCountryCode = fields.PhoneCountryCode;
          this.isAreaCodeAvailable = phoneAreaCodeField != null;
          this.countryCode = countryCode;
          const phoneControl = this.form.get(Constants.FormSubFieldNameConstants.phoneNumber);
          const phoneCountryCodeNumberControl = this.form.get(Constants.FormSubFieldNameConstants.countryCodeNumber);
          if (fields.PhoneCountryCode) {
            phoneCountryCodeNumberControl.setValue(fields.PhoneCountryCode);
          } else {
            phoneCountryCodeNumberControl.setValue('');
          }
          const validators = [];
          phoneControl.clearValidators();
          validators.push(this.requiredValidation());
          if (this.countryCode === Constants.CountryConstants.USA || this.countryCode === Constants.CountryConstants.Canada) {
            validators.push(this.formFieldValidatorsService.phoneNumberValidation(this.field));
            this.buildPhoneFieldMask(phoneNumberField, phoneAreaCodeField, phoneCountryCodeField, phoneExtensionField);
          }
          const maxLengthValidator = this.formFieldValidatorsService.maxPhoneLengthValidator();
          validators.push(maxLengthValidator);
          phoneControl.setValidators(validators);
          this.setCountryCodeIsUsOrCan();

        }),
      )
      .subscribe();
  }

  private buildPhoneFieldMask(
    phoneNumberField: PhoneNumberStructure,
    phoneAreaCodeField: PhoneNumberStructure,
    phoneCountryCodeField: PhoneNumberStructure,
    phoneExtensionField: PhoneNumberStructure) {
    let maskForPhoneNumber = '';
    const maskForAreaCode = '';
    let maskForCountryCode = '';
    let maskForExtension = '';
    if (phoneNumberField !== undefined && phoneAreaCodeField !== undefined) {
      maskForPhoneNumber = phoneAreaCodeField.Prefix + phoneAreaCodeField.Format
        + phoneAreaCodeField.Suffix + phoneNumberField.Prefix + ' ' + phoneNumberField.Format
        + phoneNumberField.Suffix;
    } else {
      if (phoneCountryCodeField !== undefined) {
        maskForCountryCode = phoneCountryCodeField.Prefix + phoneCountryCodeField.Format + phoneCountryCodeField.Suffix;
      }
      if (phoneNumberField !== undefined) {
        maskForPhoneNumber = phoneNumberField.Format
          + phoneNumberField.Suffix;
      }
    }
    if (phoneExtensionField !== undefined) {
      maskForExtension = phoneExtensionField.Format;
      this.maxLength = phoneExtensionField.MaxLength;
      this.minLength = phoneExtensionField.MinLength;
    }
    this.maskForCountryCode = maskForCountryCode.replace(/\#/gi, '0');
    this.maskForPhoneNumber = maskForPhoneNumber.replace(/\#/gi, '0');
    this.maskForAreaCode = maskForAreaCode.replace(/\#/gi, '0');
    this.maskForExtension = maskForExtension.replace(/\#/gi, '0');
  }

  showRequiredErrorForFieldName(fieldName: string) {
    return this.formFieldValidatorsService.showRequiredError(fieldName, this.field, this.form);
  }
  showValidationError(fieldName: string) {
    return this.formFieldValidatorsService.showValidationError(fieldName, this.field, this.form);
  }

  requiredValidation() {
    return this.formFieldValidatorsService.requiredValidation(this.field);
  }
  isPhoneTypeWork() {
    return this.form.get(Constants.FormSubFieldNameConstants.phoneType).value === 'WORK' ||
      this.form.get(Constants.FormSubFieldNameConstants.phoneType).value === 'WORK2';
  }

  flipDoNotCallFlag() {
    const doNotCallFlagControl = this.form.get(Constants.FormSubFieldNameConstants.doNotCallFlag);
    if (doNotCallFlagControl) {
      doNotCallFlagControl.setValue(!(doNotCallFlagControl.value as boolean));
      doNotCallFlagControl.markAsDirty();
    }
  }

  flipIncludeInDirectoryFlagForPhone() {
    const phoneIncludeInDirectoryFlagControl = this.form.get(Constants.FormSubFieldNameConstants.phoneIncludeInDirectoryFlag);
    if (phoneIncludeInDirectoryFlagControl) {
      phoneIncludeInDirectoryFlagControl.setValue(!(phoneIncludeInDirectoryFlagControl.value as boolean));
      phoneIncludeInDirectoryFlagControl.markAsDirty();
    }
  }

  disableRadioButtons(phoneNumber: string) {
    if (this.field.ruleId.toLowerCase() === FormFieldRuleConstants.OptionalEditableId.toLowerCase()) {
      if (phoneNumber === null) {
        return true;
      } else { return false; }
    }
  }
  uncheckRadioButtons(phoneNumber: any) {
    if (this.field.ruleId.toLowerCase() === FormFieldRuleConstants.OptionalEditableId.toLowerCase()) {
      if (phoneNumber === Utilities.stringEmpty) {
        const phoneTypeControl = this.form.get(Constants.FormSubFieldNameConstants.phoneType);
        phoneTypeControl.setValue('None');
        const countryCodeControl = this.form.get(Constants.FormSubFieldNameConstants.countryCode);
        countryCodeControl.setValue('');
        const doNotCallFlagControl = this.form.get(Constants.FormSubFieldNameConstants.doNotCallFlag);
        const phoneIncludeInDirectoryFlagControl = this.form.get(Constants.FormSubFieldNameConstants.phoneIncludeInDirectoryFlag);
        phoneIncludeInDirectoryFlagControl.setValue(false);
        doNotCallFlagControl.setValue(false);
        this.disableRadioButton = true;
        this.phoneCountryCode = '';
      } else {
        this.disableRadioButton = false;
      }
    }
  }

  deletePhone(id: string) {
    this.phoneListComponent.delete(id);
  }
  isPhonePrimary() {
    return this.form.get(Constants.AdditionalFieldConstants.primaryflag).value === true;
  }

  setPhoneFieldName() {
    if (!this.isPhonePrimary()) {
      let phoneType = this.form.get(Constants.FormSubFieldNameConstants.phoneType).value;
      if (phoneType === Constants.Codes.Work2) {
        phoneType = Constants.Codes.Work;
      }
      if (phoneType === Constants.Codes.Home2) {
        phoneType = Constants.Codes.Home;
      }
      if (phoneType === null) {
        this.PhoneFieldName = Constants.FieldName.Phone;
      } else {
        this.PhoneFieldName = phoneType + ' ' + Constants.FieldName.Phone;
      }
    } else {
      this.PhoneFieldName = Utilities.stringEmpty;
    }
  }
  isPhoneDuplicateForCustomer(event: any) {

    if (event.value === '') {
      this.isPhoneDuplicate = false;
    }
    if (event.value !== '') {
      this.isPhoneDuplicate = this.phoneListComponent.isPhoneDuplicateForCustomer(event, this.isCountryCodeUsaOrCan);
      if (this.isPhoneDuplicate) {
        this.formFieldValidatorsService.
          setErrorForPhoneControlMatchesSameCustomer(this.form.get(Constants.FormSubFieldNameConstants.phoneNumber));
      }
    }
  }
  callAll(event: any) {
    this.uncheckRadioButtons(event.value);
    this.isPhoneNumberDuplicate(event);
  }
  showErrorByName(name: string) {
    if (this.form && this.field) {
      return this.form.get(Constants.FormSubFieldNameConstants.phoneNumber).hasError(name);
    }
  }
  setCountryCodeIsUsOrCan() {
    if (this.countryCode === 'USA' || this.countryCode === 'CAN') {
      this.isCountryCodeUsaOrCan = true;
    } else {
      this.isCountryCodeUsaOrCan = false;
    }
  }
  isPhoneNumberDuplicate(event: any) {
    if (event.value === '') {
      return false;
    }
    let phoneNumber: string;
    if (event.value !== '') {
      phoneNumber = this.phoneListComponent.buildPhoneNumberBasedOnCountryCode(event.value, this.isCountryCodeUsaOrCan);
    }
    if (this.phoneListComponent.isPhoneDuplicateForCustomer(event, this.isCountryCodeUsaOrCan)) {
      this.formFieldValidatorsService.
        setErrorForPhoneControlMatchesSameCustomer(this.form.get(Constants.FormSubFieldNameConstants.phoneNumber));
    }
  }
  uncheckPhoneType(_: any) {
    if (_.value.id === this.PhoneId) {
      return;
    }
    const formGroup = this.form as FormGroup;
    const phoneTypeControl = formGroup.get(Constants.FormSubFieldNameConstants.phoneType);
    const phoneTypeValue = phoneTypeControl.value;
    if (_.value.phoneType === phoneTypeValue && _.value.Id !== this.PhoneId) {
      phoneTypeControl.setValue(Utilities.stringEmpty);
      this.setPhoneFieldName();
    }

  }

  isBlankAdditionalItem() {
    const phoneType = this.form.get(Constants.FormSubFieldNameConstants.phoneType).value;
    return !this.editMode && !phoneType && this.listIndex > 0;
  }
  buildPhoneTypeOptions(_: string[]) {
    const phoneTypes = this.fieldOptionsMapperService.getPhoneTypesPreference();
    const currentPhoneType = this.form.get(`${Constants.FormSubFieldNameConstants.phoneType}`).value;
    _.forEach(selectedPhoneType => {
      phoneTypes.forEach(element => {
        if ((element.PreferenceCode === selectedPhoneType) &&
          ((currentPhoneType !== null && currentPhoneType.toString() !== selectedPhoneType) || currentPhoneType == null)) {
          element.Active = false;
        }
      });
    });
    this.phoneTypeOptions = phoneTypes;
  }
  phoneTypeChanged(phoneType: string) {
    const phoneTypeControl = this.form.get(`${Constants.FormSubFieldNameConstants.phoneType}`);
    const previouslySelectedValue = phoneTypeControl.value;
    phoneTypeControl.setValue(phoneType);
    this.phoneListComponent.buildSelectedPhoneTypes();
    if (previouslySelectedValue !== null) {
      const index = this.selectedPhoneTypes.indexOf(previouslySelectedValue.toString());
      this.selectedPhoneTypes.splice(index);
    }
    this.selectedPhoneTypes.push(phoneType);
    this.phoneTypeSubject.next(this.selectedPhoneTypes);
    this.setPhoneFieldName();
  }

}
