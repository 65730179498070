import { Injectable } from '@angular/core';
import { FormField, FormFieldConstants, FieldTypeConstants } from '@shared/form-fields/models/form-field';
import { ProfileFormOption, Preference, Customer } from '@shared/models/data';
import { Constants } from '@shared/services/constants';
import { AddressOptions } from './AddressOptions';
import { MatOptionSelectionChange } from '@angular/material';

@Injectable({ providedIn: 'root' })
export class FieldOptionsMapperService {
    // this should have a constant dictionary mapping between the fieldids and the constants. TODO(Jackson)
    getOptionsForField(fieldId: string, formOptions: ProfileFormOption[], user?: Customer): Preference[] {
        let options: any;
        const lowerCaseFieldId = fieldId.toLowerCase();
        if (lowerCaseFieldId === FormFieldConstants.NamePrefixId.toLowerCase()) {
            options = this.searchFormOption(Constants.UserProfileFormOptions.Prefixes, formOptions);
        } else if (lowerCaseFieldId === FormFieldConstants.NameSuffixId.toLowerCase()) {
            options = this.searchFormOption(Constants.UserProfileFormOptions.Suffixes, formOptions);
        } else if (lowerCaseFieldId === FormFieldConstants.PrimaryPracticeSpecializationId.toLowerCase()) {
            options = this.searchFormOption(Constants.UserProfileFormOptions.PracticeSpecializationsPrimary, formOptions);
            if (this.hasNAOption(user) === false) {
                options = options.filter(o => o.PreferenceCode !== 'N/A');
            }
        } else if (lowerCaseFieldId === FormFieldConstants.SecondaryPracticeSpecializationId.toLowerCase()) {
            options = this.searchFormOption(Constants.UserProfileFormOptions.PracticeSpecializationsSecondary, formOptions);
        } else if (lowerCaseFieldId === FormFieldConstants.NameCredentialsId.toLowerCase()
            || lowerCaseFieldId === FormFieldConstants.FAAOSCredentialId.toLowerCase()) {
            options = this.searchFormOption(Constants.UserProfileFormOptions.Credentials, formOptions);
        } else if (lowerCaseFieldId === FormFieldConstants.GenderId.toLowerCase()) {
            options = this.searchFormOption(Constants.UserProfileFormOptions.Genders, formOptions);
        } else if (lowerCaseFieldId === FormFieldConstants.IdentityId.toLowerCase()) {
            options = this.searchFormOption(Constants.UserProfileFormOptions.Identities, formOptions);
        } else if (lowerCaseFieldId === FormFieldConstants.IdentityPronounId.toLowerCase()) {
            options = this.searchFormOption(Constants.UserProfileFormOptions.IdentityPronouns, formOptions);
        } else if (lowerCaseFieldId === FormFieldConstants.RaceEthnicityId.toLowerCase()) {
            options = this.searchFormOption(Constants.UserProfileFormOptions.Ethnicities, formOptions);
        } else if (lowerCaseFieldId === FormFieldConstants.AreasofInterestId.toLowerCase()) {
            options = this.searchFormOption(Constants.UserProfileFormOptions.Specialty, formOptions);
            options = options.filter(o => o.PreferenceSubCode !== 'NONE');
        } else if (lowerCaseFieldId === FormFieldConstants.OtherAreaofInterest.toLowerCase()) {
            options = this.searchFormOption(Constants.UserProfileFormOptions.AreaOfInterest, formOptions);
        } else if (lowerCaseFieldId === FormFieldConstants.PracticeEmploymentSettingId.toLowerCase()) {
            options = this.searchFormOption(Constants.UserProfileFormOptions.PracticeTypes, formOptions);
            if (this.hasNAOption(user) === false) {
                options = options.filter(o => o.PreferenceCode !== 'N/A');
            }
        } else if (lowerCaseFieldId === FormFieldConstants.BeginPracticeYearId.toLowerCase()) {
            options = this.searchFormOption('BeginPracticeYear', formOptions);
        } else if (lowerCaseFieldId === `${FormFieldConstants.WorkStatusId}-status`.toLowerCase()) {
            options = this.searchFormOption(Constants.UserProfileFormOptions.WorkStatuses, formOptions);
        } else if (lowerCaseFieldId === `${FormFieldConstants.EmergencyContactInformationId}-relationship`.toLowerCase()) {
            options = this.searchFormOption(Constants.UserProfileFormOptions.RelationshipCodes, formOptions);
        } else if (lowerCaseFieldId === `${FormFieldConstants.EmergencyContactInformationId}-phoneType`.toLowerCase()) {
            options = this.phoneTypePreferenceEmergencyContact();
        } else if (lowerCaseFieldId === `${FormFieldConstants.WebSocialMediaLinksId}-webSiteName`.toLowerCase()) {
            options = this.getwebSiteNamePreference();
        } else if (lowerCaseFieldId === FormFieldConstants.LanguagesSpokenId.toLowerCase()) {
            options = this.searchFormOption(Constants.UserProfileFormOptions.Languages, formOptions);
        } else if (lowerCaseFieldId === FormFieldConstants.LevelofSpecializationId.toLowerCase()) {
            options = this.searchFormOption(Constants.UserProfileFormOptions.SpecialtyDegrees, formOptions);
        } else if (lowerCaseFieldId === FormFieldConstants.AgeGroupsTreatedId.toLowerCase()) {
            options = this.searchFormOption(Constants.UserProfileFormOptions.AgeGroups, formOptions);
        } else if (lowerCaseFieldId === FormFieldConstants.PracticeSizeId.toLowerCase()) {
            options = this.searchFormOption(Constants.UserProfileFormOptions.PracticeSize, formOptions);
        }

        return options;
    }
    getOptionsForAddressField(formOptions: ProfileFormOption[]): AddressOptions {
        const options = new AddressOptions();
        options.communicationTypes = this.searchFormOption('CommunicationTypes', formOptions);
        options.applicationCountries = this.searchFormOption('ApplicationCountries', formOptions);
        options.applicationStates = this.searchFormOption('ApplicationStates', formOptions);
        options.addressTypes = this.searchFormOption('AddressTypes', formOptions);

        return options;
    }

    getOptionsForEmergencyContacts(formOptions: ProfileFormOption[]): Preference[] {
        return this.searchFormOption(Constants.UserProfileFormOptions.RelationshipCodes, formOptions);
    }

    private searchFormOption(optionName: string, formOptions: ProfileFormOption[]): Preference[] {
        const communicationTypesFiltered = formOptions.find(x => x.key.toLowerCase() === optionName.toLowerCase());
        if (communicationTypesFiltered) {
            return communicationTypesFiltered.value;
        }
        return [];
    }
    private phoneTypePreferenceEmergencyContact() {
        const phoneTypeOptions: Preference[] = [
            { PreferenceCode: 'HOME', PreferenceDescription: 'Home', PreferenceSubCode: '', Active: null, SortBy: 0 },
            { PreferenceCode: 'WORK', PreferenceDescription: 'Work', PreferenceSubCode: '', Active: null, SortBy: 0 },
            { PreferenceCode: 'ASST', PreferenceDescription: 'Assistant', PreferenceSubCode: '', Active: null, SortBy: 0 },
            { PreferenceCode: 'CELL', PreferenceDescription: 'Cell', PreferenceSubCode: '', Active: null, SortBy: 0 }];
        return phoneTypeOptions;
    }
    getwebSiteNamePreference() {
        const preferences: Preference[] = [
            { PreferenceCode: 'FACEBOOK', PreferenceDescription: 'Facebook', PreferenceSubCode: '', Active: true, SortBy: 0 },
            { PreferenceCode: 'TWITTER', PreferenceDescription: 'Twitter', PreferenceSubCode: '', Active: true, SortBy: 0 },
            { PreferenceCode: 'INSTAGRAM', PreferenceDescription: 'Instagram', PreferenceSubCode: '', Active: true, SortBy: 0 },
            { PreferenceCode: 'LINKEDIN', PreferenceDescription: 'LinkedIn', PreferenceSubCode: '', Active: true, SortBy: 0 }];
        return preferences;
    }
    getPhoneTypesPreference() {
        const phoneTypeOptions: Preference[] = [
            { PreferenceCode: 'HOME', PreferenceDescription: 'Home', PreferenceSubCode: '', Active: true, SortBy: 0 },
            { PreferenceCode: 'WORK', PreferenceDescription: 'Work', PreferenceSubCode: '', Active: true, SortBy: 0 },
            { PreferenceCode: 'ASST', PreferenceDescription: 'Assistant', PreferenceSubCode: '', Active: true, SortBy: 0 },
            { PreferenceCode: 'WORK2', PreferenceDescription: 'Work2', PreferenceSubCode: '', Active: true, SortBy: 0 }];
        return phoneTypeOptions;
    }
    getEmailTypePreferences() {
        const options: Preference[] = [
            { PreferenceCode: 'HOME', PreferenceDescription: 'Home', PreferenceSubCode: '', Active: true, SortBy: 0 },
            { PreferenceCode: 'WORK', PreferenceDescription: 'Work', PreferenceSubCode: '', Active: true, SortBy: 0 },
            { PreferenceCode: 'PERSONAL', PreferenceDescription: 'Personal', PreferenceSubCode: '', Active: true, SortBy: 0 },
            { PreferenceCode: 'OTHER', PreferenceDescription: 'Other', PreferenceSubCode: '', Active: true, SortBy: 0 },
            { PreferenceCode: 'WORK2', PreferenceDescription: 'Work2', PreferenceSubCode: '', Active: true, SortBy: 0 }
        ];
        return options;
    }
    getAddressTypePreferences() {
        const addressTypes: Preference[] = [
            { PreferenceCode: 'HOME', PreferenceDescription: 'Home', PreferenceSubCode: '', Active: true, SortBy: 0 },
            { PreferenceCode: 'HOME2', PreferenceDescription: 'Home 2', PreferenceSubCode: '', Active: true, SortBy: 0 },
            { PreferenceCode: 'WORK', PreferenceDescription: 'Work', PreferenceSubCode: '', Active: true, SortBy: 0 },
            { PreferenceCode: 'WORK2', PreferenceDescription: 'Work 2', PreferenceSubCode: '', Active: true, SortBy: 0 }];
        return addressTypes;
    }

    hasNAOption(user: Customer): boolean {
        if (user) {
            const memberGroupCode = user.CustomerProfile.MemberGroupCode.toUpperCase();
            return Constants.UserProfileFormOptions.MembersWithNAOption.includes(memberGroupCode);
        }
        // default to show the option if something goes wrong?
        return true;
      }
}

