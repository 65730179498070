import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormFieldGroup } from '@shared/form-fields/models/form-field-group';
import { ProfileFormOption, Customer } from '@shared/models/data';
import { Subject } from 'rxjs';
import { PrimaryEmailChangedActionService } from '@shared/form-fields/primary-email-changed-action.service';
import { DependentFieldAllowedValueService } from '@shared/dependentFields/dependent-field-allowed-value.service';
import { MatDialog } from '@angular/material';
import { TooltipDialogComponent } from '@shared/components/tooltip-dialog/tooltip-dialog.component';
import { FormFieldConstants, ReadonlyConstants, FormFieldGroupConstants, NoToolTipGroup } from '@shared/form-fields/models/form-field';
import { Constants } from '@shared/services/constants';

@Component({
  selector: 'aaos-form-field-group',
  templateUrl: './form-field-group.component.html',
  styleUrls: ['./form-field-group.component.scss']
})
export class FormFieldGroupComponent implements OnInit {
  @Input()
  group: FormFieldGroup;
  @Input()
  biographyAttachmentsSubject: Subject<object>;
  @Input()
  form: FormGroup;
  @Input()
  formOptions: ProfileFormOption[] = [];
  @Input()
  user: Customer;
  @Input()
  primaryEmailChangedActionService: PrimaryEmailChangedActionService;
  @Input()
  saveSubject: Subject<void>;
  @Input()
  editMode: boolean;
  @Input()
  refreshEditState$: Subject<void>;
  @Input()
  enableDebug: boolean;
  @Input() submitButtonText: string = Constants.ButtonText.Save;
  @Input() AMPMemberGroupId: string;
  @Input() initialFocusField: string;
  @Input() maapApplication: boolean;
  dependentValuesSubject: Subject<any> = new Subject<any>();
  allFieldsHiddenInReadonly: boolean;
  showTooltipUnderneathHeader: boolean;

  constructor(private dependentFieldAllowedValueService: DependentFieldAllowedValueService, private dialog: MatDialog) {

  }

  ngOnInit() {
    if (this.group) {
      this.dependentFieldAllowedValueService.setFieldsForGroup(this.group.formFields, this.form, this.formOptions);
    }
    this.allFieldsHiddenInReadonly = true;

    if (this.group) {
        const groupId = this.group.id;
        const groupIdLowerCase = this.group.id.toLowerCase();
        if (groupId && groupIdLowerCase === FormFieldGroupConstants.AreasOfInterestId.toLowerCase() ||
            groupId && groupIdLowerCase === FormFieldGroupConstants.DemographicInformationId.toLowerCase()) {
        this.showTooltipUnderneathHeader = true;
        }

        for (const field of this.group.formFields) {
        const fieldNotShownInReadonly = ReadonlyConstants.FieldsHiddenInReadonlyMode
          .findIndex(_ => _.toLowerCase() === field.fieldId.toLowerCase()) >= 0;
        if (!fieldNotShownInReadonly) {
          this.allFieldsHiddenInReadonly = false;
          break;
        }
      }
    }
  }
  openTooltipDialog() {
    this.dialog.open(TooltipDialogComponent, {
      width: '400px',
      disableClose: false,
      data: {
        group: this.group
      }
    });
  }
  showToolTipOnLandingPage(groupname: string) {
    const groupName = NoToolTipGroup.GroupWithNoToolTip.find
      (_ => _ === groupname);
    if (groupName) {
      return false;
    }
    return true;
  }
}
