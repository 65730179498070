<div class="form-group email-spacing" *ngIf="form" [formGroup]="form">
    <div *ngIf="!hasFormGroups()">
        <aaos-form-field-title [editMode]="editMode" [field]="field"></aaos-form-field-title>
      </div>
  <div class="form-group" [formGroup]="form">
    <div *ngFor="let formGroupForEmail of formGroups; let i = index">
      <aaos-email-field [field]="field" [formOptions]="formOptions" [form]="formGroupForEmail" [editMode]="editMode"
      [selectedEmailTypes]="selectedEmailTypes" [emailTypeSubject]="emailTypeSubject" [refreshEditState$]="refreshEditState$"
        [initialFocusField]="initialFocusField" [listIndex]="i">
      </aaos-email-field>
    </div>
    <div *ngIf="(editMode) && showAddButton === true">
      <button (click)="addNewEmail()" class="btn btn--outline" [ngClass] ="{'btn--disabled': !form.valid}">Add Email</button>
    </div>
  </div>
  </div>