import { Pipe, PipeTransform } from '@angular/core';
import { Preference } from '@shared/models/data';

@Pipe({
  name: 'aaosSpecialtyFilter'
})
export class SpecialtyFilterPipe implements PipeTransform {
  transform(items: Preference[], preferenceType: string): Preference[] {
    if (!items || !preferenceType) {
      return items;
    }

    return items.filter(
      _ => (_.PreferenceCode || '').toLowerCase() === preferenceType.toLowerCase()
    );
  }
}
