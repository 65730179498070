import { Component, OnInit, Input } from '@angular/core';
import { BaseFieldComponent } from '@shared/form-fields/baseFieldComponent';
import { BaseMultipleOptionsFieldComponent } from '@shared/form-fields/BaseMultipleOptionsFieldComponent';
import { FormGroup, FormControl, ValidatorFn, ValidationErrors } from '@angular/forms';
import { FieldOptionsMapperService } from '@shared/services/membershipForm/field-options-mapper.service';
import { FormFieldRuleConstants, FormFieldConstants } from '@shared/form-fields/models/form-field';
import { Customer, Preference } from '@shared/models/data';
import { Utilities } from '@shared/services/utils';
import { Subject, Subscription } from 'rxjs';
import {
  DependentFieldAllowedValueService
} from '@shared/dependentFields/dependent-field-allowed-value.service';
import { DependentFieldAllowedValuesUpdatedMessage } from '@shared/dependentFields/DependentFieldAllowedValuesUpdatedMessage';
import { DependentFieldSelectedValuesMessage } from '@shared/dependentFields/DependentFieldSelectedValuesMessage';

@Component({
  selector: 'aaos-check-box',
  templateUrl: './check-box.component.html',
  styleUrls: ['./check-box.component.scss']
})
export class CheckBoxComponent extends BaseMultipleOptionsFieldComponent implements OnInit {
  dependentValuesSubscription: Subscription;
  allowedValuesUpdated$: Subject<DependentFieldAllowedValuesUpdatedMessage>;
  allowedValueSubscription: Subscription;
  selectedValuesChanged$: Subject<DependentFieldSelectedValuesMessage>;

  @Input() user: Customer;

  constructor(
    formFieldsOptionsMapper: FieldOptionsMapperService,
    private dependentFieldAllowedValueService: DependentFieldAllowedValueService) {
    super(formFieldsOptionsMapper);
  }

  ngOnInit() {
    super.setUser(this.user);
    super.ngOnInit();
    const wrapper = this.field ?
      this.dependentFieldAllowedValueService.registerField(this.field.fieldId, this.form as FormGroup, this.user) :
      null;
    if (wrapper) {
      this.allowedValuesUpdated$ = wrapper.allowedValuesUpdated$;
      this.selectedValuesChanged$ = wrapper.selectedValuesUpdated$;
      this.allowedValueSubscription = this.allowedValuesUpdated$.subscribe(_ => { this.allowedValuesChanged(_); });
      this.allowedValuesChanged(wrapper.message);
    }


  }
  allowedValuesChanged(message: DependentFieldAllowedValuesUpdatedMessage) {
    if (message) {
      this.options = message.allowedValues[this.field.fieldId];
      if (FormFieldConstants.AreasofInterestId.toLowerCase() === this.field.fieldId) {
        this.options = this.options.filter(o => o.PreferenceSubCode !== 'NONE');
      }
      this.formGroupField.setValue(message.values[this.field.fieldId]);
    }
  }
  selectionChanged(event: any) {
    this.formGroupField.setValue(event.value);

    if (this.selectedValuesChanged$) {
      const message = new DependentFieldSelectedValuesMessage();
      message.formGroup = this.form as FormGroup;
      this.selectedValuesChanged$.next(message);
    }
  }
}
