import { Utilities } from '@shared/services/utils';

/** API response from using the DataService API endpoint
 *
 */
export class ApiResponse {
  unsuccessfulUpdateDetails: any[];
  successfulUpdate: number;
  unsuccessfulUpdate: number;
  rowsAffected: string;
  successful: boolean;
  hasUnsuccessfulResponse() {
    const u = this.unsuccessfulUpdate > 0;
    const a = Utilities.arrayIsPopulated(this.unsuccessfulUpdateDetails);
    return u || a;
  }
}
