import { Component, OnInit, Input } from '@angular/core';
import { BaseFieldComponent } from '@shared/form-fields/baseFieldComponent';
import { FormField, FormFieldRuleConstants } from '@shared/form-fields/models/form-field';
import { FormControl, Validators, ValidatorFn } from '@angular/forms';
import { Constants } from '@shared/services/constants';
import { FormFieldValidatorsService } from '@shared/form-fields/form-field-validators.service';
import { Customer } from '@shared/models/data';

@Component({
  selector: 'aaos-work-status-field',
  templateUrl: './work-status-field.component.html',
  styleUrls: ['./work-status-field.component.scss']
})
export class WorkStatusFieldComponent extends BaseFieldComponent implements OnInit {
  statusField: FormField = new FormField();
  retirementAgeField: FormField = new FormField();
  retiredSelected: boolean = false;
  @Input() user: Customer;
  @Input() AMPMemberGroupId: string;

  constructor() { super(); }

  ngOnInit() {
    super.ngOnInit();
    this.statusField = this.createFormFieldForSubField('status');
    this.retirementAgeField = this.createFormFieldForSubField('retirementAge');
    this.retirementAgeField.fieldName = 'Retired Age';
    if (this.form != null) {
      const status = this.form.get(`${this.field.fieldId}-${Constants.FormSubFieldNameConstants.workStatusStatus}`) as FormControl;
      status.valueChanges.subscribe(_ => this.StatusChanged(_));
      this.StatusChanged(status.value);
    }
  }
  StatusChanged(newStatus: any) {
    this.retiredSelected = newStatus === 'RETIRED';
    const retirementFormField = this.form.get(`${this.field.fieldId}-${Constants.FormSubFieldNameConstants.retirementAge}`);
    if (!this.retiredSelected) {
      retirementFormField.disable();
    } else {
      const validator = FormFieldValidatorsService.getRetirementAgeValidator(this.AMPMemberGroupId);
      retirementFormField.setValidators(validator);
      retirementFormField.enable();
    }
  }
}
