import { Component, OnInit, Input } from '@angular/core';
import { Customer } from '@shared/models/data';
import { NotificationModalService } from '@shared/services/api/notification-modal-service';
import { UserProfileService } from '@shared/services/api';
import { NotificationModal } from '../../shared/models/data/notification-modal';

@Component({
    selector: 'aaos-notification-modal',
    templateUrl: './notification-modal.component.html',
    styleUrls: ['./notification-modal.component.scss']
})

export class NotificationModalComponent implements OnInit {
    @Input() user: Customer;
    memberTypes: any;
    isModalActive: boolean;
    isButtonActive: boolean;
    title: string;
    text: string;
    buttonText: string;
    buttonLink: string;
    hide: boolean;

    constructor(private service: NotificationModalService, private userProfileService: UserProfileService) { }

    ngOnInit() {
        this.hide = false;

        // set timeout to popup modal after page load
        setTimeout(() => {
            this.showModal();
        }, 2000);
    }

    showModal() {
        // get user member code
        const userMemberGroup = this.user.CustomerProfile.MemberGroupCode;
        // subscribe to modal data
        this.service.getSettings().subscribe(modal => {
            // check if activated
            if (modal.active === true) {
                // check if memberTypes is not null
                if (modal.memberTypes) {
                    // get list of member type codes
                    const activeMemberGroups = modal.memberTypes.split(';');
                    // check if user memberType code in settings data
                    if (activeMemberGroups.includes(userMemberGroup)) {
                        // check if custom logic is needed
                        if (modal.custom === true) {
                            this.runCustomLogic(modal);
                        }
                        this.assignValues(modal);
                    }
                }
            }
        });
    }

    runCustomLogic(modal: NotificationModal) {
        // membership-recently-dropped
        this.userProfileService.getHasNonePaymentDues().subscribe(noPayment => {
            if (noPayment === true) {
                this.assignValues(modal);
            }
        });
    }

    // assign html values
    assignValues(modal: NotificationModal) {
        // modal must have title and text
        if (modal.title && modal.text) {
            this.isModalActive = modal.active;
            this.title = modal.title;
            this.text = modal.text;

            // check if button activated
            if (modal.buttonActive === true) {
                this.isButtonActive = modal.buttonActive;
                this.buttonText = modal.buttonText;
                this.buttonLink = modal.buttonLink;
            }
        }
    }

    closeModal() {
        this.hide = true;
    }
}
