import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AaosBaseService } from './aaos-base.service';
import { LoggingService } from './logging.service';
import { Constants } from '@shared/services/constants';
import { map, catchError, take, tap, concatMap, finalize } from 'rxjs/operators';
import {
  Customer,
  DisclosureSummary,
  CustomerProfile,
  Preference,
  CustomerProfileSnapshot,
  ApiResponse,
  ProfileScore,
  CustomerCommunication,
  AddressField,
  ProfileFormOption,
  PhoneStructure,
  CustomerAttachment,
  AddressDetail,
  CustomerEmergencyContact
} from '@shared/models/data';
import { Utilities } from '@shared/services/utils';
import { combineLatest, Subject, Observable, forkJoin, of, EMPTY } from 'rxjs';
import * as moment from 'moment';
import {
    Credentials,
    Country,
    Gender,
    Identity,
    IdentityPronoun,
    SelectCredentials,
    MemberGroupsWithSelectCredentials
} from '@shared/form-fields/models/form-field';
import { UserProfileFormOptions } from '@shared/services/constants/constants';
import { CustomerAddressSnapshot } from '@shared/models/data/CustomerAddressSnapshot';
import { CustomerEmergencyContactSnapshot } from '@shared/models/data/CustomerEmergencyContactsSnapshot';
import { DownloadAttachment } from '@shared/models/data/DownloadAttachment';
import { AttachmentData } from '@shared/models/data/AttachmentData';
import { BirthdayService } from '@shared/services/utils/birthday.service';

@Injectable({
  providedIn: 'root'
})
export class UserProfileService extends AaosBaseService {
  // BUG: workaround for https://github.com/angular/angular/pull/25033
  static ngInjectableDef = undefined;

  constructor(protected _h: HttpClient, protected _l: LoggingService) {
    super(_l, _h);
  }

  getEncryptedMCID(): Observable<any> {
    return this.http.get(Constants.ApiUrls.EncryptString)
        .pipe(catchError(_ => this.handleError(_)), take(1), map((_: any) => {
            return _;
        }));
  }

  getProfileDetailed(impersonatedMasterCustomerId = null) {
    const headers = this.getImpersonationHeader(impersonatedMasterCustomerId);
    const params = new HttpParams().set(Constants.ApiParams.ValidCurrentDisclosure, 'false');
    return this.http.get(`${Constants.ApiUrls.CustomerProfileDetailed}`, { headers, params }).pipe(
      map((_: any) => {
        if (Utilities.isUndefinedOrNull(_)) {
          const error = _.error;
          throw new Error(error);
        }
        return _;
      }),
      catchError(_ => this.handleError(_)),
      map((_: string) => {
        return JSON.parse(_);
      }),
      map((_: Customer) => {
        // Use factory pattern for converting dates
        if (Utilities.isDefined(_.DisclosureSummary)) {
          _.DisclosureSummary = DisclosureSummary.create(_.DisclosureSummary);
        }
        if (Utilities.isDefined(_.CustomerProfile)) {
          _.CustomerProfile = CustomerProfile.create(_.CustomerProfile);
        }
        if (Utilities.isDefined(_.EmergencyContacts)) {
          _.EmergencyContacts = this.buildEmergencyContactInformation(_.EmergencyContacts);
        }

        _.CustomerProfile.BirthDate = BirthdayService.convertNullBirthday(_.CustomerProfile.BirthDate);
        return _;
      })
    );
  }


  getProfileDetailedCommunications(impersonatedMasterCustomerId = null) {
    const profileDetailed = this.getProfileDetailed(impersonatedMasterCustomerId).pipe(
      take(1),
      tap(_ => {
        return _;
      })
    );
    const addressDetails$ = this.getCustomerAddress(impersonatedMasterCustomerId).pipe(
      map(addresses => {
        return addresses;
      })
    );
    const phoneCommunications$ = this.getCustomerCommunications('phone', impersonatedMasterCustomerId).pipe(
      map(phones => {
        return phones;
      })
    );
    const mobileCommunications$ = this.getCustomerCommunications('mobile', impersonatedMasterCustomerId).pipe(
      map(mobile => {
        return mobile;
      })
    );
    const emailCommunications$ = this.getCustomerCommunications('email', impersonatedMasterCustomerId).pipe(
      map(emails => {
        return emails;
      })
    );
    const webCommunications$ = this.getCustomerCommunications('web', impersonatedMasterCustomerId).pipe(
      map(webLinks => {
        return webLinks;
      })
    );
    return combineLatest(phoneCommunications$,
      mobileCommunications$,
      emailCommunications$,
      webCommunications$,
      addressDetails$,
      profileDetailed).pipe(
        map(_ => {
          const [phones, mobile, emails, webLinks, addresses, profile] = _;
          const communications: CustomerCommunication[] = [];
          let addressDetails: AddressDetail[] = [];
          if (Utilities.isDefined(phones)) {
            this.buildPhoneCommunicationDetails(phones).forEach(x => { communications.push(x); });
          }
          if (Utilities.isDefined(mobile)) {
            this.buildMobileCommunicationDetails(mobile).forEach(x => { communications.push(x); });
          }
          if (Utilities.isDefined(emails)) {
            this.buildEmailCommunicationDetails(emails).forEach(x => { communications.push(x); });

          }
          if (Utilities.isDefined(webLinks)) {
            this.buildWebCommunicationDetails(webLinks).forEach(x => { communications.push(x); });
          }
          if (!Utilities.isUndefinedOrNull(addresses)) {
            addressDetails = this.buildAddressDetails(addresses);
          }
          profile.Communications = communications;
          profile.AddressDetail = addressDetails;
          return profile;
        })
      );
  }

  postProfileDetailedWithoutPref(customer: CustomerProfileSnapshot) {
    const profile$ = this.http
      .post(`${Constants.ApiUrls.UpdateProfileDetailed}`, customer)
      .pipe(map((_: ApiResponse) => this._createApiResponse(_)));
    const profileArray = profile$.pipe(concatMap(x => profile$.pipe(map(profile => [profile]))));
    return profileArray;
  }

  postProfileDetailedWithCommsWithoutPrefs(customer: CustomerProfileSnapshot, previousCommunication: CustomerCommunication[]) {
    const profile$ = this.http
      .post(`${Constants.ApiUrls.UpdateProfileDetailed}`, customer)
      .pipe(map((_: ApiResponse) => this._createApiResponse(_)));
    // const profileArray = profile$.pipe(concatMap(x => profile$.pipe(map(profile => [profile]))));

    const observables$ = customer.Communications.map(communication => {
      communication.MasterCustomerId = customer.MasterCustomerId;
      if (communication.IsNewFlag) {
        return this.createCustomerCommunications(communication);
      } else if (communication.LocationCodeUpdated && (communication.PhoneNumber ||
        communication.EmailAddress || communication.SocialMediaLink)) {
        const _previousCommunication = previousCommunication.find(
          c => c.CommunicationType.toLowerCase() === communication.CommunicationType.toLowerCase()
        );
        if (_previousCommunication) {
          _previousCommunication.MasterCustomerId = customer.MasterCustomerId;
          return this.removeCustomerCommunications(_previousCommunication).pipe(
            concatMap(_ => this.createCustomerCommunications(communication))
          );
        }
        return this.createCustomerCommunications(communication);
      } else if (communication.IsDeletedFlag) {
        return this.removeCustomerCommunications(communication);
      } else {
        return this.updateCustomerCommunications(communication);
      }
    });

    const profileAndComms$ = profile$.pipe(concatMap(x => profile$.pipe(map(profile => [profile]))));
    if (observables$.length > 0) {
      const combined = combineLatest(...observables$);
      return combined.pipe(
        concatMap(_ => combineLatest(..._.map(r => of(r)), profileAndComms$)),
        map(_ => [].concat(..._))
      );

    }
    return profileAndComms$;


  }

  postProfileDetailed(customer: CustomerProfileSnapshot) {
    const preferences = [];
    customer.SpecialtyArea.forEach(_ => preferences.push(_));
    customer.SpecialtyInterests.forEach(_ => preferences.push(_));

    const profile$ = this.http
      .post(`${Constants.ApiUrls.UpdateProfileDetailed}`, customer)
      .pipe(map((_: ApiResponse) => this._createApiResponse(_)));
    const preferences$ = this.http
      .post(`${Constants.ApiUrls.UpdatePreferencesAndAreas}`, preferences)
      .pipe(map((_: ApiResponse) => this._createApiResponse(_)));
    return preferences$.pipe(concatMap(prefs => profile$.pipe(map(profile => [profile, prefs]))));
  }

  postProfileDetailedWithCommunications(
    customer: CustomerProfileSnapshot,
    previousCommunication: CustomerCommunication[],
    attachments: CustomerAttachment[]
  ): Observable<ApiResponse[]> {

    const preferences = [];
    customer.SpecialtyArea.forEach(_ => preferences.push(_));
    customer.SpecialtyInterests.forEach(_ => preferences.push(_));
    const customerCopy = { ...customer };
    customerCopy.Phone = null;
    const profile$ = this.http
      .post(`${Constants.ApiUrls.UpdateProfileDetailed}`, customerCopy)
      .pipe(map((_: ApiResponse) => this._createApiResponse(_)));
    const preferences$ = this.http
      .post(`${Constants.ApiUrls.UpdatePreferencesAndAreas}`, preferences)
      .pipe(map((_: ApiResponse) => this._createApiResponse(_)));

    const observables$ = customer.Communications.map(communication => {
      if (communication.IsNewFlag) {
        return this.createCustomerCommunications(communication);
      } else if (communication.LocationCodeUpdated && (communication.PhoneNumber ||
        communication.EmailAddress || communication.SocialMediaLink)) {
        const _previousCommunication = previousCommunication.find(
          c => c.CommunicationType.toLowerCase() === communication.CommunicationType.toLowerCase()
        );
        if (_previousCommunication) {
          _previousCommunication.MasterCustomerId = customer.MasterCustomerId;
          return this.removeCustomerCommunications(_previousCommunication).pipe(
            concatMap(_ => this.createCustomerCommunications(communication))
          );
        }
        return this.createCustomerCommunications(communication);
      } else if (communication.IsDeletedFlag) {
        return this.removeCustomerCommunications(communication);
      } else {
        return this.updateCustomerCommunications(communication);
      }
    });
    const addressObservables$ = customer.Address.map(address => {
      if (address.IsNew) {
        return this.createCustomerAddress(address);
      } else if (address.IsDeleted) {
        return this.removeCustomerAddress(address);
      } else {
        return this.updateCustomerAddress(address);
      }
    });
    observables$.push(...addressObservables$);

    const emergencyObservables$ = customer.EmergencyContact.map(emergencyContact => {

      if (emergencyContact.IsNew) {
        return this.createCustomerEmergencyContact(emergencyContact);
      } else if (emergencyContact.IsDeleted) {
        return this.removeCustomerEmergencyContact(emergencyContact);
      } else {
        return this.updateCustomerEmergencyContact(emergencyContact);
      }
    });
    observables$.push(...emergencyObservables$);

    const attachmentsObservables$ = attachments.filter(_ => !Utilities.isUndefinedOrNull(_.Action)).map(attachment => {
      if (attachment.Action === 'delete') {
        const options = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json'
          }),
          body: attachment
        };
        return this.http
          .delete(`${Constants.ApiUrls.BiographyAttachment}`, options)
          .pipe(map((_: ApiResponse) => this._createApiResponse(_)));
      } else if (attachment.Action === 'upload') {
        return this.http
          .post(`${Constants.ApiUrls.BiographyAttachment}`, attachment)
          .pipe(map((_: ApiResponse) => this._createApiResponse(_)));
      }

    });
    if (attachmentsObservables$ && attachmentsObservables$.length > 0) {
      observables$.push(...attachmentsObservables$);
    }


    const profileAndPrefs$ = preferences$.pipe(
      concatMap(prefs => profile$.pipe(map(profile => [profile, prefs])))
    );
    if (observables$.length > 0) {
      const combined = combineLatest(...observables$);
      return combined.pipe(
        concatMap(_ => combineLatest(..._.map(r => of(r)), profileAndPrefs$)),
        map(_ => [].concat(..._))
      );

    }

    return profileAndPrefs$;
  }

  getStates(countryCode: string) {
    const params = new HttpParams().set(Constants.ApiParams.CountryCode, countryCode);
    return this.http.get(`${Constants.ApiUrls.GetStates}`, { params }).pipe(
      map((data: string) => {
        const json: Preference[] = JSON.parse(data).map(j => {
          const p = new Preference();
          p.PreferenceCode = j.PreferenceCode;
          p.PreferenceDescription = j.PreferenceDescription;
          p.PreferenceSubCode = j.PreferenceSubCode;
          return p;
        });
        return json;
      })
    );
  }

  getEditProfileFormOptions(countryCode: string, memberGroupCode: string): Observable<ProfileFormOption[]> {
    const params = new HttpParams().set(Constants.ApiParams.CountryCode, countryCode);
    return this.http.get(`${Constants.ApiUrls.ProfileFormOptions}`, { params }).pipe(
      map((data: any) => {
        const keys = Object.keys(data);
        const preferences: ProfileFormOption[] = keys.map((key: string) => {
          let value: Preference[] = JSON.parse(data[key]).map(j => {
            const p = new Preference();
            p.PreferenceCode = j.PreferenceCode;
            p.PreferenceDescription = j.PreferenceDescription;
            p.PreferenceSubCode = j.PreferenceSubCode;
            p.Active = true;
            p.SortBy = j.SortBy;
            return p;
          });
          value = Utilities.orderBy(value, 'PreferenceDescription');
          if (key === UserProfileFormOptions.Credentials) {
            const credsToRemove = SelectCredentials.SelectCredentialsList;
            const specialMemberGroupCodes = MemberGroupsWithSelectCredentials.MemberGroupsWithSelectCredentialsList;

            const mostUsedCreds = Credentials.MostUsedCredentials;
            mostUsedCreds.reverse().forEach(cred => {
              const pref = value.find(_ => _.PreferenceCode.toLowerCase() === cred.toLowerCase());
              if (value) {
                value.splice(value.indexOf(pref), 1);
                value.unshift(pref);
              }
            });

            if (memberGroupCode && value) {
              credsToRemove.forEach(cred => {
                if (specialMemberGroupCodes.includes(memberGroupCode)) {
                  const thisCred = value.find(_ => _.PreferenceCode.toLowerCase() === cred.toLowerCase());
                  value.splice(value.indexOf(thisCred), 1);
                }

              });
            }
          }
          if (key === UserProfileFormOptions.ApplicationCountries) {
            const mostUsedCreds = Country.MostUsedCountries;
            mostUsedCreds.reverse().forEach(cred => {
              const pref = value.find(_ => _.PreferenceCode.toLowerCase() === cred.toLowerCase());
              if (value) {
                value.splice(value.indexOf(pref), 1);
                value.unshift(pref);
              }
            });
          }
          if (key === UserProfileFormOptions.Genders) {
            const gendersOrdered = Gender.GendersOrderedList;
            gendersOrdered.reverse().forEach(cred => {
              const pref = value.find(_ => _.PreferenceCode.toLowerCase() === cred.toLowerCase());
              if (value) {
                value.splice(value.indexOf(pref), 1);
                value.unshift(pref);
              }
            });
          }
          if (key === UserProfileFormOptions.Identities) {
            const identitiesOrdered = Identity.IdentityOrderedList;
            identitiesOrdered.reverse().forEach(cred => {
               const pref = value.find(_ => _.PreferenceCode.toLowerCase() === cred.toLowerCase());
               if (value) {
                 value.splice(value.indexOf(pref), 1);
                 value.unshift(pref);
               }
            });
          }
          if (key === UserProfileFormOptions.IdentityPronouns) {
            const identityPronounsOrdered = IdentityPronoun.IdentityPronounOrderedList;
            identityPronounsOrdered.reverse().forEach(cred => {
               const pref = value.find(_ => _.PreferenceCode.toLowerCase() === cred.toLowerCase());
               if (value) {
                 value.splice(value.indexOf(pref), 1);
                 value.unshift(pref);
               }
            });
          }
          if (key === UserProfileFormOptions.SpecialtyDegrees) {
            value = Utilities.orderBy(value, 'SortBy');
          }
          // if (key == UserProfileFormOptions.PracticeSize) {
          //   const practiceSizeOrdered = PracticeSizes.PracticeSizeList;
          //   practiceSizeOrdered.reverse().forEach(cred => {
          //      const pref = value.find(_ => _.PreferenceCode.toLowerCase() === cred.toLowerCase());
          //      if (value) {
          //        value.splice(value.indexOf(pref), 1);
          //        value.unshift(pref);
          //      }
          //   });
          // }

          return { key, value };
        });
        const computed = this.getComputedProfileFormOptions();
        computed.forEach(_ => {
          preferences.push(_);
        });
        const constantFields = this.getConstantProfileFormFields();
        constantFields.forEach(_ => {
          preferences.push(_);
        });
        return preferences;
      })
    );
  }
  // todo move these to a constants file
  getConstantProfileFormFields() {
    const constantOptions = new Array<ProfileFormOption>();
    const workStatusPreferences = new Array<Preference>();
    const year = moment().year();

    const fullTime = new Preference();
    fullTime.PreferenceCode = 'Full-time';
    fullTime.PreferenceDescription = 'Full-time';
    fullTime.Active = true;
    workStatusPreferences.push(fullTime);

    const partTime = new Preference();
    partTime.PreferenceCode = 'Part-time';
    partTime.PreferenceDescription = 'Part-time';
    partTime.Active = true;
    workStatusPreferences.push(partTime);

    const retired = new Preference();
    retired.PreferenceCode = 'Retired';
    retired.PreferenceDescription = 'Retired';
    retired.Active = true;
    workStatusPreferences.push(retired);

    const practiceYearOptions = new ProfileFormOption();
    practiceYearOptions.key = 'WorkStatus';
    practiceYearOptions.value = workStatusPreferences;
    constantOptions.push(practiceYearOptions);
    return constantOptions;
  }


  getComputedProfileFormOptions(): ProfileFormOption[] {
    const computedOptions = new Array<ProfileFormOption>();
    const startYearPreferences = new Array<Preference>();
    const year = moment().year();
    for (let i = year; i > year - 100; i--) {
      const p = new Preference();
      p.PreferenceCode = i.toString();
      p.PreferenceDescription = i.toString();
      p.Active = true;
      startYearPreferences.push(p);

    }
    const practiceYearOptions = new ProfileFormOption();
    practiceYearOptions.key = 'BeginPracticeYear';
    practiceYearOptions.value = startYearPreferences;
    computedOptions.push(practiceYearOptions);
    return computedOptions;
  }

  getProfileScore() {
    const minimumCompletion = 90;
    const minimumYears = 1;
    return this.http.get(`${Constants.ApiUrls.ProfileScore}`).pipe(
      map((response: string) => {
        const profile = JSON.parse(response) as ProfileScore;

        try {
          let lastConfirmationYear = null;
          if (profile.LastConfirmationDate != null && profile.LastConfirmationDate != undefined)
            lastConfirmationYear = new Date(profile.LastConfirmationDate).getFullYear();
  
          const currentYear = new Date().getFullYear();
          if (profile.Completeness < 0) {
            profile.CompletenessType = 'Ignore';
          } else if (profile.Completeness < minimumCompletion || lastConfirmationYear == null) {
            // less than `minimumCompletion` complete && not null
            profile.CompletenessType = 'Incomplete';
            // removed dayjs that compared current year to last confirmation year
          } else if (currentYear - lastConfirmationYear >= minimumYears) {
            // hasn't been updated for more than `minimumYears`
            profile.CompletenessType = 'Annual';
          } else {
            profile.CompletenessType = 'Complete';
          }
        } catch (e) {
          profile.CompletenessType = 'Incomplete';
        }

        return profile;
      })
    );
  }

  getHasNonePaymentDues(): Observable<boolean> {
    return this.http.get<boolean>(`${Constants.ApiUrls.HasNonPaymentDues}`).pipe();
  }

  getCustomerCommunications(communicationType: string, impersonatedMasterCustomerId: string = null) {
    const headers = this.getImpersonationHeader(impersonatedMasterCustomerId);
    const options = { headers };
    return this.http.get(`${Constants.ApiUrls.CustomerCommunications}/${communicationType}`, options).pipe(
      map((data: string) => {
        const json: any = JSON.parse(data);
        return (json || ({} as any)).CustomerCommunications as CustomerCommunication[];
      })
    );
  }
  updateCustomerCommunications(communication: CustomerCommunication) {
    return this.http
      .post(
        `${Constants.ApiUrls.UpdateCustomerCommunication}/${communication.CommunicationType}`,
        communication
      )
      .pipe(map((_: ApiResponse) => this._createApiResponse(_)));
  }

  createCustomerCommunications(communication: CustomerCommunication) {
    return this.http
      .post(
        `${Constants.ApiUrls.CreateCustomerCommunication}/${communication.CommunicationType}`,
        communication
      )
      .pipe(map((_: ApiResponse) => this._createApiResponse(_)));
  }
  IsCommunicationInUse(emailAddress: string) {
    let headers = new HttpHeaders();
    headers = headers.append('emailAddress', emailAddress);

    return this.http.get(`${Constants.ApiUrls.CommunicationInUse}`, {headers}).pipe(
      map((data: string) => {
        const json: any = JSON.parse(data);
        return (json || ({} as any));
      })
    );
  }
  getAttachmentData(fileName: string, customerRelatedDocumentId: string) {
    let qs = `?${Constants.ApiParams.FileName}=${fileName}`;
    qs += `&${Constants.ApiParams.CustomerRelatedDocumentId}=${customerRelatedDocumentId}`;
    return this.http.get(`${Constants.ApiUrls.BiographyAttachment}${qs}`).pipe(
      map((data: string) => {
        const json: any = JSON.parse(data);
        return (json || ({} as any)) as AttachmentData;
      }));
  }
  removeCustomerCommunications(communication: CustomerCommunication) {
    return this.http
      .post(
        `${Constants.ApiUrls.RemoveCustomerCommunication}/${communication.CommunicationType}`,
        communication
      )
      .pipe(map((_: ApiResponse) => this._createApiResponse(_)));
  }
  updateCustomerAddress(address: CustomerAddressSnapshot) {
    return this.http
      .put(
        `${Constants.ApiUrls.UpdateCustomerAddress}`,
        address
      )
      .pipe(map((_: ApiResponse) => this._createApiResponse(_)));
  }

  createCustomerAddress(address: CustomerAddressSnapshot) {
    return this.http
      .post(
        `${Constants.ApiUrls.CreateCustomerAddress}`,
        address
      )
      .pipe(map((_: ApiResponse) => this._createApiResponse(_)));
  }

  removeCustomerAddress(address: CustomerAddressSnapshot) {
    return this.http
      .post(
        `${Constants.ApiUrls.RemoveCustomerAddress}`,
        address
      )
      .pipe(map((_: ApiResponse) => this._createApiResponse(_)));
  }

  updateCustomerEmergencyContact(customerEmergencyContactSnapshot: CustomerEmergencyContactSnapshot) {
    return this.http
      .put(
        `${Constants.ApiUrls.UpdateCustomerEmergencyContact}`,
        customerEmergencyContactSnapshot
      )
      .pipe(map((_: ApiResponse) => this._createApiResponse(_)));
  }

  createCustomerEmergencyContact(customerEmergencyContactSnapshot: CustomerEmergencyContactSnapshot) {
    return this.http
      .post(
        `${Constants.ApiUrls.CreateCustomerEmergencyContact}`,
        customerEmergencyContactSnapshot
      )
      .pipe(map((_: ApiResponse) => this._createApiResponse(_)));
  }

  removeCustomerEmergencyContact(customerEmergencyContactSnapshot: CustomerEmergencyContactSnapshot) {
    return this.http
      .post(
        `${Constants.ApiUrls.RemoveCustomerEmergencyContact}`,
        customerEmergencyContactSnapshot
      )
      .pipe(map((_: ApiResponse) => this._createApiResponse(_)));
  }

  getAddressStructure(countryCode: string) {
    const params = new HttpParams().set(Constants.ApiParams.CountryCode, countryCode);
    return this.http.get(`${Constants.ApiUrls.GetAddressStructure}`, { params }).pipe(
      map((data: string) => {
        const json: AddressField[] = JSON.parse(data).map(j => {
          const p = new AddressField();
          p.ActiveFlag = j.ActiveFlag;
          p.LabelFlag = j.LabelFlag;
          p.RequiredFlag = j.RequiredFlag;
          p.ScreenFlag = j.ScreenFlag;
          p.LineNumber = j.LineNumber;
          p.MaxLength = j.MaxLength;
          p.Position = j.Position;
          p.Caption = j.Caption;
          p.CountryCode = j.CountryCode;
          p.Delimiter = j.Delimiter;
          p.FieldName = j.FieldName;
          return p;
        });
        return json;
      })
    );
  }
  getCustomerAddress(impersonatedMasterCustomerId: string) {
    const headers = this.getImpersonationHeader(impersonatedMasterCustomerId);
    const options = { headers };
    return this.http.get(`${Constants.ApiUrls.CustomerAddress}`, options).pipe(
      map((data: string) => {
        const json: any = JSON.parse(data);
        return (json || ({} as any)).CustomerAddresses as AddressDetail[];
      })
    );
  }

  getPhoneNumberStructure(countryCode: string) {
    const params = new HttpParams().set(Constants.ApiParams.PersonifyCountryCode, countryCode);
    return this.http.get(`${Constants.ApiUrls.GetPhoneNumberStructure}`, { params }).pipe(
      map((data: string) => {
        return JSON.parse(data);
      }),
      map((data: PhoneStructure) => {
        return data;
      })
    );
  }
  buildPlaceholderImage(initials: string) {
    return this.http.get(`${Constants.ApiUrls.BuildPlaceholderImage}/?initials=${initials}`).pipe(
      tap(data => {
        return data || ({} as any);
      })
    );
  }

  private _createApiResponse(_: ApiResponse) {
    const response = new ApiResponse();
    response.unsuccessfulUpdateDetails = _.unsuccessfulUpdateDetails;
    response.successfulUpdate = _.successfulUpdate;
    response.unsuccessfulUpdate = _.unsuccessfulUpdate;
    response.rowsAffected = _.rowsAffected;
    return response;
  }
  buildAddressDetails(addressDetails: AddressDetail[]): AddressDetail[] {
    const newAddress: AddressDetail[] = [];
    for (const address of addressDetails) {
      if (newAddress.length < 3) {
        if (address.IsPrimaryAddress === true) {
          newAddress.push(address);
        } else if (address.StatusCode === Constants.AddressStatusCodes.Good) {
          newAddress.push(address);
        }
      }
    }
    return newAddress.sort((a, b) => a.PrioritySequence - b.PrioritySequence);
  }
  buildPhoneCommunicationDetails(Phones: CustomerCommunication[]): CustomerCommunication[] {
    const newPhoneCommunication: CustomerCommunication[] = [];
    for (const Phone of Phones) {
      if (newPhoneCommunication.length < 3) {
        newPhoneCommunication.push(Phone);
      }
    }
    return newPhoneCommunication.sort((a, b) => this.compareFunction(a.IsPrimary, b.IsPrimary));
  }
  buildMobileCommunicationDetails(mobiles: CustomerCommunication[]): CustomerCommunication[] {
    const newMobileCommunication: CustomerCommunication[] = [];
    for (const mobile of mobiles) {
      if (newMobileCommunication.length < 3) {
        newMobileCommunication.push(mobile);
      }
    }
    return newMobileCommunication.sort((a, b) => this.compareFunction(a.IsPrimary, b.IsPrimary));
  }
  buildEmailCommunicationDetails(emails: CustomerCommunication[]): CustomerCommunication[] {
    const newEmailCommunication: CustomerCommunication[] = [];
    for (const email of emails) {
      if (newEmailCommunication.length < 3) {
        newEmailCommunication.push(email);
      }
    }
    return newEmailCommunication.sort((a, b) => this.compareFunction(a.IsPrimary, b.IsPrimary));
  }
  buildWebCommunicationDetails(Webs: CustomerCommunication[]): CustomerCommunication[] {
    const newWebCommunication: CustomerCommunication[] = [];
    for (const Web of Webs) {
      if (newWebCommunication.length < 3) {
        newWebCommunication.push(Web);
      }
    }
    return newWebCommunication.sort((a, b) => this.compareFunction(a.IsPrimary, b.IsPrimary));
  }
  buildEmergencyContactInformation(emergencyContacts: CustomerEmergencyContact[]): CustomerEmergencyContact[] {
    const newEmergencyContacts: CustomerEmergencyContact[] = [];
    for (const emergencyContact of emergencyContacts) {
      if (newEmergencyContacts.length < 2) {
        newEmergencyContacts.push(emergencyContact);
      }
    }
    return newEmergencyContacts;

  }
  compareFunction(a: boolean, b: boolean) {
    if (a === b) {
      return 0;
    } else if (a === true) {
      return -1;
    } else {
      return 1;
    }
  }
}
